:root {
	--gray: #737376;
	--smooth-gray: #929294;
	--light-gray: #F1F1F1;
	--blue-color: #2f73f7;
	--primary-gray: #e8e8e8;
}

body {
	font-family: 'Segoe UI' !important;
	background-color: #fff !important;
	padding: 0px !important;
	overflow: auto;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	box-shadow: 0 0 0 1000px transparent inset;
	-webkit-box-shadow: 0 0 0 1000px transparent inset;
	transition: background-color 5000s ease-in-out 0s
}
::-webkit-scrollbar-track {
	border-radius: 4px;
	background-color: #ffffff;
}

::-webkit-scrollbar {
	width: 8px;
	background-color: #e7e7e7;
	height: 7px;
	border-radius: 4px;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: var(--primary-gray);
}
.nav-tabs {padding: 0px !important;}
.redColor {
	color: var(--red) !important;
}

.IndigoColor {
	color: #6610f2 !important;
}

.tealColor {
	color: #20c997 !important;
}

.w-20 {
	width: 20% !important;
}

.w-30 {
	width: 30% !important;
}

.w-40 {
	width: 40% !important;
}

.w-60 {
	width: 60% !important;
}

.w-70 {
	width: 70% !important;
}

.w-80 {
	width: 80% !important;
}

.w-90 {
	width: 90% !important;
}

.tb-1 {
	border-top: 1px var(--light-gray) solid;
}

.bb-1 {
	border-bottom: 1px var(--light-gray) solid;
}

@font-face {
	font-family: 'Segoe UI';
	src: url('./_metronic/_assets/fonts/SegoeUI-Bold.eot');
	src: url('./_metronic/_assets/fonts/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
		url('./_metronic/_assets/fonts/SegoeUI-Bold.woff2') format('woff2'),
		url('./_metronic/_assets/fonts/SegoeUI-Bold.woff') format('woff'),
		url('./_metronic/_assets/fonts/SegoeUI-Bold.ttf') format('truetype'),
		url('./_metronic/_assets/fonts/SegoeUI-Bold.svg#SegoeUI-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Segoe UI';
	src: url('./_metronic/_assets/fonts/SegoeUI-Semibold.eot');
	src: url('./_metronic/_assets/fonts/SegoeUI-Semibold.eot?#iefix') format('embedded-opentype'),
		url('./_metronic/_assets/fonts/SegoeUI-Semibold.woff2') format('woff2'),
		url('./_metronic/_assets/fonts/SegoeUI-Semibold.woff') format('woff'),
		url('./_metronic/_assets/fonts/SegoeUI-Semibold.ttf') format('truetype'),
		url('./_metronic/_assets/fonts/SegoeUI-Semibold.svg#SegoeUI-Semibold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Segoe UI';
	src: url('./_metronic/_assets/fonts/SegoeUI.eot');
	src: url('./_metronic/_assets/fonts/SegoeUI.eot?#iefix') format('embedded-opentype'),
		url('./_metronic/_assets/fonts/SegoeUI.woff2') format('woff2'),
		url('./_metronic/_assets/fonts/SegoeUI.woff') format('woff'),
		url('./_metronic/_assets/fonts/SegoeUI.ttf') format('truetype'),
		url('./_metronic/_assets/fonts/SegoeUI.svg#SegoeUI') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
    font-family: 'Segoe UI';
    src: url('./_metronic/_assets/fonts/SegoeUI-Regular.eot');
    src: url('./_metronic/_assets/fonts/SegoeUI-Regular.eot?#iefix') format('embedded-opentype'),
        url('./_metronic/_assets/fonts/SegoeUI-Regular.woff2') format('woff2'),
        url('./_metronic/_assets/fonts/SegoeUI-Regular.woff') format('woff'),
        url('./_metronic/_assets/fonts/SegoeUI-Regular.ttf') format('truetype'),
        url('./_metronic/_assets/fonts/SegoeUI-Regular.svg#SegoeUI-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
.align-right {
	text-align: right;
}
.align-left {
	text-align: left;
}
.green {
	color: var(--green) !important
}
.MaxBottomPadd {
	padding-bottom: 90px;
}
.MixhubbLogo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 20px;
	z-index: 6;
	    img {
		    width: 180px;
		}
}
.LogoMixhibbLeft {
	padding-right: 0px !important;
	padding-left: 40px !important;
	.MixhubbLeftIco {
		position: absolute;
		left: 13px;
		min-width: 18px;
		cursor: pointer;
		.MuiButton-label {
			width: 25px;
			height: 25px;
			svg {
				width: 100%;
				height: 100%;
			}
		}
		&:hover {
			background-color: transparent;
			outline: none;
		}
		&:focus {
			background-color: transparent;
			outline: none;
		}
	}
	.brand-logo {
		margin-left: 20px;
		height: 100%;
		img {
			width: auto;
			object-fit: scale-down;
		}
	}
	.brand-toggle {
		display: none;
	}
}
.aside-minimize .aside-menu-wrapper .MenuNavFirst .menu-item .menu-link .IconMenuSvg {
	margin: 0px !important;
}
.CommonGtBtn {
	background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
	color: var(--white) !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	text-transform: capitalize !important;
	padding: 6px 18px !important;
	&:hover {
		background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%) !important;
		color: var(--white);
	}

	&:focus {
		background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%) !important;
		color: var(--white);
	}
}
.BlueWhiteBtn {
	background: var(--white);
    font-weight: 600 !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    padding: 6px 42px !important;
    border: 2px var(--blue) solid !important;
	color: var(--blue) !important;
	&:hover {
		background: var(--white) !important;
		color: var(--blue) !important;
	}

	&:focus {
		background: var(--white) !important;
		color: var(--blue) !important;
	}
}
.WhiteBtn {
	background-color: var(--white) !important;
	color: var(--blue) !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	text-transform: capitalize !important;
	border: 1px var(--primary-gray) solid !important;
	padding: 6px 28px !important;
}
.BtnActionBtn {
	border-top: 1px var(--light-gray) solid !important;
    position: fixed;
    left: 0;
    width: 100%;
    margin: 0px auto;
    right: 0px;
    padding: 22px 272px 22px 358px;
    bottom: 0;
    background: #fff;
    z-index: 5;
}
.content {
	.card-custom {
		.card-header {
			margin: 0 0 18px;
			width: 100%;
			float: left;
			.card-title {
				.card-label {
					font-size: 22px;
					margin-right: 22px;
					float: left;
					small {
						display: block;
						color: #222222;
						font-size: 16px;
						font-weight: 600;
						margin: 8px 0 0;
					}
				}
				.MuiPaper-root {
					border: 1px var(--gray-dark) solid;
					width: 250px;
					box-shadow: none !important;
					position: relative;
					padding: 3px 0;

					.MuiInputBase-root {
						.MuiInputBase-input {
							padding-left: 30px;
							color: var(--gray);
							font-weight: 400;
						}

						&:placeholder {
							color: #000;
						}
					}

					.MuiIconButton-root {
						position: absolute;
						left: 6px;
						padding: 0;
						top: 5px;

						.MuiIconButton-label {
							svg {
								width: 25px;
								height: 25px;
								fill: var(--smooth-gray);
							}
						}
					}
				}
			}
			
			.CardRegReport {
				display: inline-block !important;
				svg {
					float: left;
					width: 44px;
					height: 44px;
					margin-right: 14px;
				}
			}
		}
		.RegAnalistHd {
			display: inline-block !important;
		}
		.ListHeaderTwo {
			.card-header {
				margin-bottom: 0px !important;
			}
		}
	}
}
.align-center {
	text-align: center;
}
.listType {
	background: var(--light-gray);
	border-radius: 6px;
	padding: 8px 0;

	h3 {
		color: var(--gray-dark);
		font-size: 2.8rem;
		font-weight: 600;
		margin: 0;
	}

	label {
		font-weight: 600;
		font-size: 1.1rem;
		color: var(--gray-dark);
		margin: 0 0 2px;
	}
}

.heading-main {
	margin: 32px 0 0;

	h2 {
		font-size: 20px;
		text-transform: uppercase;
		font-weight: 600;
	}
}
.allEvents-add {
	position: relative;
	padding-left: 70px;
	margin: 50px 0 0;
	padding-right: 26%;
	.yestDate {
		position: absolute;
		left: -14px;
		font-size: 12px;
		text-transform: uppercase;
		font-weight: 600;
		height: 100%;
		top: -30px;
		text-align: center;
		width: 70px;
		&:after {
			content: '';
			border-left: 1px var(--gray-dark) dashed;
			position: absolute;
			left: 0;
			right: 0;
			height: 100%;
			top: 30px;
			margin: 0px auto;
			width: 1px;
		}
	}

	.event-summary-all {
		background-color: var(--light-gray);
		display: flex;
		width: 100%;
		padding: 14px;
		border-radius: 10px;
		margin: 0 0 22px;
		height: 142px;
		.eventPicBg {
			float: left;
			width: 175px;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.event-all-schedule {
			padding: 0px 0 0 15px;
			position: relative;
			flex: 1;

			.eventStatus {
				color: var(--blue-color);
				position: absolute;
				right: 0px;
				font-size: 14px;
				font-weight: 600;
			}

			p {
				color: var(--gray-dark);
				margin: 0 0 7px;
				font-size: 13px;
				font-weight: 600;
			}

			h3 {
				color: var(--gray-dark);
				margin: 0 0;
				font-size: 22px;
				font-weight: 600;
				letter-spacing: .5px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}

			label {
				margin: 5px 0 10px;
				font-size: 12px;
				font-weight: 600;
				display: block;
				letter-spacing: .5px;
			}

			.detail-view {
				border: 1px var(--blue) solid;
				font-weight: 600;
				padding: 4px 12px;
				color: var(--blue);
				font-size: 12px;
				letter-spacing: .3px;

				&:hover {
					background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
					color: var(--white);
				}

				&:focus {
					background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
					color: var(--white);
				}
			}
		}
	}
}
.sub-heading-main {
	margin: auto;

	h4 {
		display: inline-block;
		font-size: 26px;
		font-weight: 600;
		margin-right: 45px;
		color: var(--gray-dark);
	}
	h5 {
		display: inline-block;
		font-size: 22px;
		font-weight: 600;
		margin-right: 45px;
		color: var(--gray-dark);
	}
	.editBtn-detil {
		background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
		color: var(--white);
		padding: 0 10px;
		font-size: 15px;
		border-radius: 100px;
		vertical-align: bottom;
		text-transform: capitalize;
	}
}

.eventExprience {
	color: var(--gray-dark);

	h3 {
		color: var(--gray-dark);
		font-weight: 600;
		font-size: 22px;
		margin: 0 0 20px;
	}

	p {
		color: var(--gray-dark);
		font-size: 14px;
		font-weight: 600;
		margin: 0;
	}
	
	.EventCopy-Edit {
		display: inline-flex;
		background: var(--light-gray);
		border-radius: 5px;
		height: max-content;
		max-width: 380px;

		.copyLinkDel {
			padding: 10px;
			background: var(--light-gray);
			border-radius: 5px 0px 0px 5px;
			font-style: normal;
			font-weight: 600;
			font-size: 11px;
			line-height: 14px;
			color: var(--gray-dark);
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow-x: hidden;
		}

		.CopyHere {
			padding: 9px 5px;
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 15px;
			color: #2f73f7;
			color: var(--blue-color);
			background: #F1F1F1;
			background: var(--light-gray);
			border-radius: 0px 5px 5px 0px;
			width: 120px;
			cursor: pointer;
			text-align: center;
			padding-left: 0;
		}
	}

	.EnterEventin {
		display: inline;

		.Event-EnterBtn {
			background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
			color: var(--white);
			font-weight: 600;
			font-size: 13px;
		}
	}
	.StreamIngLive {
		max-width: 100% !important;
	}
}
.EventUrlCopyTx {
	display: inline-flex;
	//margin-top: 24px;
}
.EventDescript {
	position: relative;
	.MuiInputBase-root {
		width: 100% !important;
		padding: 0 !important;
		.form-control {
			padding: 0 0;
		}
		&:after {display: none;}
		&:before {display: none;}
	}
	.form-control {
		background: var(--light-gray);
		height: 100px !important;
		font-size: 14px;
		border: 0px;;
		width: 100% !important;
		textarea {
			padding: 10px 12px 0 !important;
			height: 90px !important;
			font-size: 12px;
		}
	}
	.group-input-hit {
		height: auto !important;
		textarea {
			padding: 0 12px 0 !important;
    		margin: 10px 0 0;
			height: 65px !important;
		}
	}
	.LimitTxt {
		position: absolute;
		right: 4px;
		top: -20px;
		font-weight: 600;
		font-size: 12px;
		color: #a7a4a4;
	}
	.gradientBg {
		background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
		color: var(--white);
		font-size: 15px;
		text-transform: capitalize;
		font-family: "Segoe UI" !important;
		font-weight: bold;
	}

	.BlankBtn {
		border: 1px #e8e8e8 solid;
		color: var(--blue-color);
		font-family: "Segoe UI" !important;
		font-weight: bold;
		font-size: 15px;
		text-transform: capitalize;
	}

	textarea {
		&::placeholder {
			color: var(--gray-dark);
		}
	}
}
.DublicatEvent {
	.form-control {
		textarea {
			margin: 10px 0px 0 !important;
			height: 60px !important;
			padding: 0 !important;
		}
	}
}
.eventInserted {
	.form-control {
		textarea {
			margin: 10px 0px 0 !important;
			height: 60px !important;
			padding: 0 !important;
		}
	}
}
.AddStageArea {
	.EventDescript {
		.form-control {
			textarea {
				padding: 10px 0px 0 !important;
			}
		}
	}
}

.MuiPaper-rounded {
	/*padding: 25px 30px;*/
	display: grid;
	grid-gap: 25px;
	// box-shadow: none !important;
	// border: 1px var(--light-gray) solid;
}
.NavHeaderTop {
	.MuiPaper-rounded {
		padding: 15px 15px;
		grid-gap: 16px;
	}
}
.TextToolTip {
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
	span {
		color: #676464;
	    font-weight: 600;
	    font-size: 14px;
	    margin-left: 7px;
	    cursor: pointer;
	}
	label {
		display: inline-block;	
		color: #222222;
	    font-weight: 400;
	    font-size: 13px;
	}
}

.MuiTooltip-popper {
	.MuiTooltip-tooltip {
    	font-size: 10.4px;
	    background-color: #5f5f5f;
	    font-family: "Segoe UI" !important;
	    font-weight: 600;
	    letter-spacing: .1px;
	}
	.MuiTooltip-arrow {
		color: rgb(103 103 103);
	}
}



.colorCodePx {
    background: #F1F1F1;
    background: var(--light-gray);
    width: 130px;
    position: relative;
    height: 42px;
	z-index: 4;
	div {
		width: 100%;
		font-family: "Segoe UI" !important;
		button {
			&:hover {
				box-shadow: none !important;
				border: 0px;
			}
			&:focus {
				box-shadow: none !important;
				border: 0px;
			}
		}
	}
	.MuiButtonBase-root {
		box-shadow: none;
		border-radius: 0px;
		&:hover {
			box-shadow: none;
		    background-color: transparent !important;
		    opacity: 1;
		    border-radius: 0px !important;
		}
	}
	.MuiInputBase-root {
		.MuiInputBase-input {
			position: relative;
			font-family: "Segoe UI" !important;
			padding: 0px 0px 0 5px;
		}
		&:before {
			display: none;
		}

		&:after {
			display: none;
		}
	}
	.BtmIcons {
		position: absolute;
		right: 14px;
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			width: 20px;
			height: 20px;
		}
	}
}
.colorCodePx .color-picker-disabled {
	&:after {
		content: '';
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background: transparent;
	}
}
.colorCodePx .MuiButtonBase-root .MuiButton-label:hover div {
	box-shadow: none;
    opacity: 1;
    border-radius: 0px !important;
}
.colorCodePx div {
    display: flex;
    align-items: center;
    font-weight: 600;
	position: relative;
	z-index: 99;
}
.top-heading-main {
	display: inline-block;
	width: 100%;
	.headingH1 {
		float: left;

		h1 {
			font-size: 26px;
			font-weight: 600;
			color: var(--gray-dark);
			display: inline-block;
		}

		span {
			color: var(--blue-color);
			font-weight: 600;
			margin-left: 16px;
			font-size: 15px;
		}
	}
	.LogoutOrganization {
		h1 {
			span {
				border: 1px #ff0000 solid;
				border-radius: 100px;
				padding: 0 10px 3px !important;
				margin-left: 8px !important;
				color: #ff0000 !important;
				cursor: pointer;
			}
		}
	}
	.menuRight {
		float: right;
		margin: 9px 0px 0px;
		ul {
			margin: 0px;
			padding: 0px;

			li {
				display: inline-block;
				margin: 0 8px;

				a {
					color: var(--blue-color);
					font-weight: bold;
					font-size: 15px;
					border: 1px #e8e8e8 solid;
					padding: 6px 18px;
					border-radius: 6px;
					vertical-align: middle;
				}

				&:last-child {
					margin-right: 0px;
				}
				button {
					padding: 4px 15px !important;
					vertical-align: middle;
					border-radius: 6px
				}
			}
		}
	}
}
.EditInfoStage {
	.form-control {
		textarea {
			padding: 13px 0 0 !important;
			height: 58px !important;
			font-size: 12px;
		}
	}
}
.viewAllevent {
    text-align: right;
    margin: 20px 0 0;
	a {
		color: var(--blue-color);
		font-weight: 600;
		font-weight: 600;
		font-size: 15px;
		span {
			color: var(--blue-color);
			margin-left: 10px;
			.view-all-events-icon {
				top: 66px;
				left: 0;
				width: 14px;
				height: 14px;
			}
		}
	}
}

.tab-pane {
	position: relative;

	.form-group {
		position: relative;

		label {
			position: absolute;
			left: 16px;
			top: 12px;
			z-index: 3;
			color: #737376;
			font-size: 12px;
			text-transform: capitalize;
		}

		.form-control {
		    border: 0;
		    background-color: var(--light-gray);
		    &:before {display: none;}
		    &:after {display: none;}
		    .MuiInputBase-root {
		    	&:before {display: none;}
		    	&:after {display: none;}
		    }
			&:before {
				display: none;
			}

			&:after {
				display: none;
			}

			.MuiInputBase-root {
				&:before {
					display: none;
				}

				&:after {
					display: none;
				}
			}
			.MuiSelect-root {
				&:focus {
					background-color: transparent !important;
				}
			}
		}

		.limitTxt {
			position: absolute;
			right: 10px;
			top: 4px;
			font-weight: 600;
			font-size: 12px;
			color: #a7a4a4;
		}

		.btn-primary {
			text-transform: capitalize;
			background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
			color: var(--white);
			font-size: 15px;
			letter-spacing: .5px;
			width: 100%;
		}
	}
}

.Top-Event-Schedule {
	display: inline-block;
	width: 100%;
	border: 2px var(--light-gray) solid;
	border-radius: 8px;

	.Schedule-Date {
		float: left;
		background-color: #e8e8e8;
		text-align: center;
		padding: 18px 15px;

		h5 {
			font-size: 16px;
			font-weight: bold;
			margin: 0;
			text-transform: uppercase;
		}

		h2 {
			font-size: 54px;
			font-weight: bold;
			margin: 0 0 0px;
			line-height: 58px;
		}

		p {
			margin: 0px;
			font-weight: 600;
			color: #222;
		}
	}

	.Schedule-Day-Time {
		display: grid;
		padding: 12px 18px;
		align-items: center;
		place-content: initial;
		position: relative;

		h4 {
			font-weight: bold;
			font-size: 19px;
			margin: 0 0 12px;
		}

		span {
			font-weight: 600;
			font-size: 14px;
		}
	}
}

.Talk-Speakers {
	display: grid;
	place-content: center space-between;
	grid-auto-flow: column;
	align-items: center;
	margin: 20px 0 0;

	.btn {
		color: #2f73f7;
		font-weight: 600;
		font-size: 13px;
		border: 1px #2f73f7 solid;
		padding: 4px 8px;
	}

	svg {
		width: 18px;
		height: 18px;
		fill: var(--blue);
		display: inline-block;
		margin-right: 4px;
	}
}

.EnterSchedule {
	position: absolute;
	right: 20px;
	top: 12px;

	svg {
		width: 12px;
		height: 12px;
	}
}
.topbar {
	.dropdown {
		button {
			background-color: transparent;
			border: 0;
			padding: 0;
			.UserInfoDdl {
				background-color: #e8e8e8;
				background-color: var(--primary-gray);
				padding: 3px 4px;
				display: flex;
				align-items: center;
				flex-direction: row;
				height: 38px;
				border-radius: 100px;
	
				.userImg {
					border-radius: 100px;
					margin-right: 4px;
					width: 32px;
					height: 32px;
					background-color: #d1d1d1;
				}
	
				svg {
					margin: 0 6px;
				}
			}
		}
	}
	
}
	
.topbar {
	.dropdown {
		.btn-primary {
			background-color: transparent !important;
			border: 0px !important;

			&:after {
				display: none;
			}

			&:hover {
				background-color: transparent !important;
				border: 0px !important;
				outline: none;
				box-shadow: none;
				padding: 0;
			}

			&:focus {
				background-color: transparent !important;
				border: 0px !important;
				outline: none;
				box-shadow: none;
				padding: 0;
			}
		}
		.btn-secondary {
			background-color: transparent !important;
			border: 0px !important;

			&:after {
				display: none;
			}

			&:hover {
				background-color: transparent !important;
				border: 0px !important;
				outline: none;
				box-shadow: none;
				padding: 0;
			}

			&:focus {
				background-color: transparent !important;
				border: 0px !important;
				outline: none;
				box-shadow: none;
				padding: 0;
			}
		}
	}
}

.CreateAnEvent {
	display: flex;
	align-items: center;
	justify-content: center;

	.AccountNevent {
		text-transform: capitalize;
		background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
		color: var(--white);
		font-size: 14px;
		padding: 5px 12px;
		a {
			&:hover {
				color: var(--white) !important;	
			}
			&:focus {
				color: var(--white) !important;	
			}	
		}
	}
}


.WelcomePage {
	width: 100%;
	margin: 0px auto;
	text-align: left;

	.TopWelcome {
		margin: 0 0 45px;
    	text-align: center;
		h4 {
			color: var(--light-color);
			font-weight: 600;
		    font-size: 22px;
		    margin: 0 0 12px;
		    letter-spacing: .5px;
		}

		p {
			color: var(--light-color);
			font-size: 13px;
			margin: 0;
		}
	}

	.RadioGroup {
		color: var(--light-color);

		p {
			color: var(--light-color);
			font-size: 13px;
			margin-bottom: 14px;
		}

		.RadioWrapperIn {
			background-color: #e8e8e8;
		    padding: 16px 14px;
		    margin-bottom: 16px;
		    border-radius: 8px;
			.MuiFormLabel-root {
				padding-left: 28px;	
			}
			.ButtonRadio {
				color: var(--light-color);
			}
		}
	}

	.BottomActivity {
		color: var(--light-color);
		// margin-top: 14px;
		display: block;

		p {
			color: var(--light-color);
			font-size: 13px;
			margin-bottom: 18px;
		}

		ul {
			margin: 0px;
			padding: 0px;
			margin: 0px;
			padding: 0px;
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			margin-left: -1rem;

			li {
				color: var(--light-color);
				display: inline-block;

				button {
					color: var(--light-color);
					border: 1px #e7e9ec solid;
					border-radius: 100px;
					padding: 8px 14px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 13px;
					text-transform: capitalize;
					font-weight: 500;
					margin: 0 8px 15px;
					margin-right: 0;
					position: relative;
					background-color: transparent;
				
					.wapperFix {
						color: var(--light-color);
						margin-right: 6px;

						svg {
							color: var(--light-color);
						}

						.textIn {
							color: var(--light-color);
						}
					}
				}

			}
		}
	}
}
.WelcomePage .BottomActivity ul li button.text-light {
	background-color: #e6e6e6 !important;
	color: #3F4254 !important;
}
.ContBtn {
		text-transform: capitalize !important;
	    background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
	    color: var(--white) !important;
	    font-size: 16px !important;
	    padding: 12px 12px !important;
	    width: 100%;
	    margin-top: 8px !important;
	    font-weight: 600 !important;
	    border: 0px !important;
	}
	.checkcontainer {
		display: block;
		position: relative;
		padding-left: 28px;
		cursor: pointer;
		font-size: 14px;
		margin: 0px;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
		font-family: 'Poppins';
		font-weight: 400;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;

			&:checked~.checkmark {
				background-color: #2196F3;
			}

			&:checked~.checkmark:after {
				display: block;
			}
		}
	.radiobtn {
		position: absolute;
		top: -2px;
		left: 0;
		height: 18px;
		width: 18px;
		background-color: #fff;
		border-radius: 50%;
		border: 0.0625rem solid #dcd9d9;

		&:after {
			top: 5px;
			left: 5px;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: white;
		}

		&:after {
			content: "";
			position: absolute;
			display: none;
		}
	}

	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	.checkmark:after {
		left: 10px;
		top: 6px;
		width: 7px;
		height: 12px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	input:checked~.radiobtn {
		background-color: #2196F3;
	}

	input:checked~.radiobtn:after {
		display: block;
	}
}

.width-column-fluid {
	width: 30rem;
	height: 100%;
	margin: 0px auto !important;
}

.login-form {
	width: 30rem;

	h3 {
		font-size: 30px;
		font-weight: 600;
		margin: 0 0 12px;
		color: var(--gray-dark);
	}

	p {
		color: var(--gray-dark);
		font-size: 16px;
	    font-weight: 400;
	    line-height: 22px;
	}	
	.forgotPass {
	    text-align: right;
	    font-size: 14px;
	    margin: 0 0 4px;
	    a {
	    	color: #007bff;
	    	text-decoration: underline;	
	    }
	}
	.LoginIcon {
	    display: flex;
	    align-items: center;
	    justify-content: center;
		button {
			    background: transparent !important;
			    border: 0 !important;
			    display: inline-block !important;
			    box-shadow: none !important;
			    text-align: center;
			    flex-direction: column;
			    align-items: center !important;
			    justify-content: center;
			    width: auto;
			    padding: 0 0px !important;
			    font-family: "Poppins", sans-serif !important;
			    font-weight: 600 !important;
			    opacity: 1 !important;
			    font-size: 12px !important;
			    cursor: pointer;
		    span {
		    	padding: 0 !important;
			    display: block;
			    margin: 2px 0 0;
			    font-family: "Poppins", sans-serif !important;
			    color: #222222 !important;
			    font-weight: 400 !important;
			    font-size: 12px;
		    }
		    div {
		    	margin: 0px !important;
			    background: transparent !important;
			    padding: 14px 10px 9px !important;
		    }
		    
		    &:hover {
		    	background-color: #ecf2ff !important;	
		    }
		}
		.LinkedBtn {
			padding: 10px 14px !important;	
		}
		.GoogleLogin {
	    	padding: 10px 16.52px !important;	
	    	div {
	    		padding: 0px 0 !important;	
	    	}
	    	svg {
	    		width: 18px;
	    		height: 22px;
	    	}
	    }    
    	.twitterDiv {
    	padding: 0px;	
    	cursor: pointer;
    	margin: 5px 15px 4px !important;
	    	.socicon-twitter {
	    		padding: 0 0 !important;	
		    	}
		    	&:hover {
		    		background: #ecf2ff !important;
		    	}
	    	}
			div {
				display: inline-block;	
				padding: 0 0px;
				font-size: 20px;
			    color: #0fc4ec;
			}
			.socicon-twitter {
				font-size: 20px;
				color: #0fc4ec;	
				line-height: initial;
				
			}
			span {
		    	padding: 0 !important;
			    display: block;
			    margin: 3px 0 0;
			    font-family: 'Poppins', sans-serif !important;
			    font-weight: 400;
			    font-size: 12px !important;
			    color: #222222 !important;
				text-transform: capitalize !important;
		    }
		    .twitterDiv {
		    	padding: 10.5px 18.13px;
		    	span {
		    		margin: 0 0 0 !important;	
		    	}	
		    }
			span {
				font-weight: 400;
				line-height: 22px;
				margin: 0px;
				.FacebookLogin {
					text-align: center;
					padding: 10px 14px !important;
					margin: 2px 0 0;
					font-family: "Poppins", sans-serif !important;
					color: #222222 !important;
					font-weight: 400 !important;
					font-size: 12px !important;
					text-transform: capitalize !important;
					img {
						display: block;
						margin: 0px auto 8px;
						width: 22px;
						height: 22px;
					}
				}
			}

	}

	.followIcon {
		button {
			background: transparent !important;
			border: 0 !important;
			display: inline-block !important;
			box-shadow: none !important;
			margin: 0 15px;

			div {
				margin: 0px !important;
				background: transparent !important;
				padding: 0px !important;
			}
		}

		.socicon-linkedin {
			background: #3b77b7;
		    color: #fff;
		    padding: 3px 4px;
		    border-radius: 3px;
		    font-size: 15px;
			color: #fff;
			padding: 1px 4px;
			border-radius: 3px;
		}
	}
	.boundary {
		display: flex;
		flex-direction: row;
		font-style: normal;
		font-weight: 600;
		font-size: 13px;
		line-height: 16px;
		color: #908d8d;
		margin-bottom: 2.125rem;

		&:after {
			content: '';
			flex: 1 1;
			border-bottom: 2px #e4e6eb solid;
			margin: auto 0;
			margin-left: 8px;
		}

		&:before {
			content: '';
			flex: 1 1;
			border-bottom: 2px #e4e6eb solid;
			margin: auto 0;
			margin-right: 8px;
		}
	}
	.skipBtn {
		.MuiButtonBase-root {
			background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
		    color: #fff;
		    margin: 0 0 20px;
		    border-radius: 100px;
		    padding: 8px 20px;
		    text-transform: capitalize;
		    font-size: 12px;	
		}	
	}

	.form-group {
		color: var(--gray-dark);

		label {
			color: var(--gray-dark);
			font-size: 13px;
			font-family: "Segoe UI" !important;
			margin-bottom: 10px;
		}

		.form-control {
			color: var(--gray-dark);
			background-color: #fff;
			border: 1px #e4e6eb solid;
			border-radius: 8px;
		}
		select {
			height: 45px;	
		}
		.MuiFormControl-root {
			width: 100% !important;
		    max-width: 100% !important;
		    border: 1px #e4e6eb solid !important;
		    box-shadow: none !important;
		    margin: 0px !important;
			border-radius: 4px;
	    	.MuiFormLabel-root {
	    		padding-left: 12px;
	    		font-weight: 400;
			    }
			    .MuiInputBase-root {
			    	padding-left: 12px;
			    	&:after {
			    		display: none;
			    	}
			    	&:before {
			    		display: none;
			    	}	
			    	.MuiSelect-root {
			    		&:hover {
			    			background: transparent !important;
			    		}
			    		&:focus {
			    			background: transparent !important;
			    		}	
			    	}
				}
			}
			fieldset {
				border: 0px;
			}
		}
	}
	.Ctlable {
		.MuiFormControl-root {
			.MuiFormLabel-root {
				padding-left: 0px !important;
			}
		}
	}
.timer {
	text-align: left;
	p {
		text-align: left;
		color: var(--blue);
		font-weight: 200;
		font-size: 13px;
		margin: 0;
	}
	a {color: var(--blue);}
}
.resendOtp {
	text-align: left;
    color: var(--blue);
    font-weight: 200;
    font-size: 13px;
	button {
		color: var(--blue);
		&:hover {
			color: var(--bllue);
		}
		&:focus {
			color: var(--bllue);
		}
	}
	p {
		margin: 0;
	}
}
.NoSchedeleEvent {
	border: 2px #d1d1d1 dashed;
	padding: 20px;
	border-radius: 10px;
	width: 100%;
	display: flex;

	.AddNewEvents {
		width: 100%;
		display: flex;
		color: var(--gray-dark);

		.SchedulePicBg {
			float: left;
			background-color: var(--light-gray);
			padding: 13px;
    		width: 95px;
		}

		.EventScheduleTxt {
			flex: 1;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;

			p {
				margin: 0;
				font-weight: 600;
				font-size: 21px;
				color: var(--gray-dark);
			}
		}
	}
}

.table.AllEventTable {
	thead {
		position: relative !important;

		th {
			background-color: var(--light-gray) !important;
			color: var(--gray-dark) !important;
			text-transform: capitalize;
			font-size: 14px !important;
			font-weight: bold;

			&:first-child {
				padding-left: 40px !important;
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;

			td {
				color: var(--gray-dark);
				font-weight: 600;
				font-size: 13px;
				position: relative;
				padding: 22px 12px !important;
				letter-spacing: 0.4px;

				.dropdown-menu {
					.navi {
						&:after {
							content: "";
							height: 20px;
							width: 20px;
							position: absolute;
							background-color: white;
							top: -11px;
							right: 16%;
							border-top: #dcd8d8 solid 1px;
							border-left: #dcd8d8 solid 1px;
							transform: rotate(45deg);
							z-index: -1;
						}

						.navi-item {
							span {
								height: auto !important;
								padding: 0px !important;
							}
						}
					}
				}

				img {
					margin-right: 12px;
					float: left;
					display: inline-block;
					width: 60px;
				}

				p {
					margin: 0;
					display: flex;
					align-items: center;
					min-height: 40px;
				}

				&:first-child {
					padding-left: 40px !important;
					width: 36%;
					line-height: 18px;
					padding-right: 40px !important;
					cursor: pointer;
				}

				.btn {
					width: auto !important;

					img {
						margin: 0px !important;
						width: auto;
					}
				}
				.btn-secondary {
					border: 0;
					padding: 0;
					background-color: transparent;
					&:hover {
						outline: none;
						box-shadow: none;
						border: 0;
						padding: 0;
						background: transparent !important;
					}
					&:focus {
						outline: none;
						background-color: transparent;
						box-shadow: none;
						border: 0;
						padding: 0;
					}
				}
				.dropdown-menu.dropdown-menu-sm {
					border: 1px #dcd8d8 solid;
					top: 44px !important;
					left: auto !important;
					left: initial !important;
					padding: 0 0 !important;
					right: -28px;
					transform: initial !important;

					&:after {
						content: "";

					}

					.navi-item {
						.navi-link {
							padding: 4px 16px;
							cursor: pointer;
						}

						&:first-child {
							margin-top: 5px;
						}

						&:last-child {
							margin-bottom: 5px;
						}
					}
				}
				.ImgLogo {
					height: 42px;
					float: left;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
}
.table.RecordingTable {
	thead {
		position: relative !important;

		th {
			background-color: var(--light-gray) !important;
			color: var(--gray-dark) !important;
			text-transform: capitalize;
			font-size: 14px !important;
			font-weight: bold;
			width: 33.33%;
			&:first-child {
				padding-left: 40px !important;
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;

			td {
				color: var(--gray-dark);
				font-weight: 600;
				font-size: 14px;
				position: relative;
				padding: 22px 12px !important;
				letter-spacing: 0.4px;
				img {
					margin-right: 12px;
					float: left;
					display: inline-block;
					width: 24px;
					margin-top: 8px;
					cursor: pointer;
				}

				p {
					margin: 0;
					display: flex;
					align-items: center;
					min-height: 40px;
					vertical-align: middle;
				}
			}
		}
	}
}
.ActionIcon {
	width: 18px;
    height: 18px;
    display: flex;
	cursor: pointer;
		svg {
			width: 100%;
			height: 100%;
		}
	}
.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-clean:focus:not(.btn-text),
.btn.btn-clean.focus:not(.btn-text) {
	background-color: transparent;
	border-color: transparent;
}

.ScheduleHeader {
	h5 {
		color: var(--blue);
		font-weight: 600;
		font-size: 20px;
		/*letter-spacing: 0.5px;*/
		margin-right: 15px;
		display: inline-block;
		vertical-align: middle;
		margin-bottom: 0;
		line-height: 30px;
	}
}

.StageEventPg {
	border: 1px #E4E6EF solid;
	padding: 20px;
	border-radius: 10px;
	position: relative;
	margin: 0 0 22px;

	h4 {
		font-weight: bold;
		font-size: 18px;
		padding-right: 32px;
		line-height: 24px;
		letter-spacing: .1px;
		margin-bottom: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	p {
		font-size: 14px;
		font-weight: normal;
		color: #000;
		padding-right: 0px;
		line-height: 18px;
		margin-bottom: 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		min-height: 36px;
	}

	.Talk-Speakers {
		width: 100%;

		.btn {
			margin-right: 8px;
		}
	}
}

.EnterStage {
	position: absolute;
	right: 20px;
	top: 20px;

	svg {
		width: 15px;
		height: 15px;
	}
}

.RightIcons {
	display: grid;
	place-content: center space-between;
	grid-auto-flow: column;
	align-items: center;

	.Talk-Speakers {
		margin: 0px;
	}

	.EditDel {
		grid-auto-flow: column;

		.iconSvg {
			width: 32px;
			height: 32px;
			vertical-align: middle;
			background-color: var(--light-gray);
			margin: 0 4px;
			border-radius: 100px;
			padding: 6px 7px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			svg {
				width: 100%;
				height: 100%;
			}
		}
	}
}

.HeadingStatic {
	h2 {
		font-weight: 600;
		font-size: 20px;
		margin: 0;
		letter-spacing: .5px;
	}
	img {
		margin-right: 8px;
		display: inline-block;
		vertical-align: middle;
		width: 34px;
	}

	small {
		font-size: 13px;
		font-weight: 600;
	}
}


.table.SpeakersTables {
	thead {
		position: relative !important;

		th {
			background-color: var(--light-gray) !important;
			color: var(--gray-dark) !important;
			text-transform: capitalize;
			font-size: 14px !important;
			font-weight: bold;
			width: 21%;
			&:first-child {
				padding-left: 15px !important;
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;

			td {
				color: var(--gray-dark);
				font-weight: 600;
				font-size: 13px;
				position: relative;
				padding: 22px 12px !important;
				letter-spacing: 0.4px;
				line-height: 16px;

				.dropdown-menu {
					.navi {
						&:after {
							content: "";
							height: 20px;
							width: 20px;
							position: absolute;
							background-color: white;
							top: -11px;
							right: 16%;
							border-top: #dcd8d8 solid 1px;
							border-left: #dcd8d8 solid 1px;
							transform: rotate(45deg);
							z-index: -1;
						}

						.navi-item {
							span {
								height: auto !important;
								padding: 0px !important;
							}
						}
					}
				}
				img {
					margin-right: 12px;
					width: 50px;
					height: 50px;
					border-radius: 100px;
					float: left;
					display: inline-block;
				}
				p {
					margin: 0;
					display: flex;
					align-items: self-start;
					min-height: 50px;
					flex-direction: column;
					text-align: left;
					justify-content: center;
					span {
						display: block;
						font-weight: 600;
						font-size: 11px;
						margin: 3px 0 0;
					}
				}
				&:nth-child(3) {
					width: 26%;
				}
				&:nth-child(4) {
					width: 12%;
				}
				&:last-child {
					width: 11%;
				}
				.btn {
					width: auto !important;

					img {
						margin: 0px !important;
						width: auto;
					}
				}

				.dropdown-menu.dropdown-menu-sm {
					border: 1px #dcd8d8 solid;
					top: 12px !important;
					padding: 0 0 !important;
					margin-left: 28px !important;

					&:after {
						content: "";

					}
				}
				.ActionSvg { 
					display: inline-block;
					cursor: pointer;
					.ActionIcon {
						display: inline-block;
						margin: 0 4px;
						img {
							width: auto;
							border-radius: 0px;
							margin: 0px;
						}
					}
					.EmailSvgIcon {
						width: 18px !important;
						height: 18px !important;
					}
					.ReportSvgIcon {
						width: 17px !important;
						height: 17px !important;
					}
					.DeleteSvgIcon {
						width: 16px  !important;
						height: 16px  !important;
					}
					.EditSvgIcon {
						width: 14px !important;
						height: 14px !important;
					}
				}
			}
		}
	}
}

.table.NotificatonTable {
	thead {
		position: relative !important;

		th {
			background-color: var(--light-gray) !important;
			color: var(--gray-dark) !important;
			text-transform: capitalize;
			font-size: 14px !important;
			font-weight: 600;
			vertical-align: middle;
			padding: 12px 22px;
			&:first-child {
				padding-left: 15px !important;
				width: 20% !important;
			}
			&:last-child {
				width: 12% !important;
			}
			&:nth-child(2) {
				padding-left: 15px !important;
				width: 22% !important;
			}
			&:nth-child(3) {
				padding-left: 15px !important;
				width: 14% !important;
			}
			&:nth-child(4) {
				padding-left: 15px !important;
				width: 10% !important;
			}
			&:nth-child(5) {
				padding-left: 15px !important;
				width: 12% !important;
			}
			&:nth-child(6) {
				padding-left: 15px !important;
				width: 10% !important;
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;

			td {
				color: var(--gray-dark);
				font-weight: 600;
				font-size: 13px;
				position: relative;
				padding: 22px 12px !important;
				letter-spacing: 0.4px;
				line-height: 16px;
				word-break: break-all;
				.dropdown-menu {
					.navi {
						&:after {
							content: "";
							height: 20px;
							width: 20px;
							position: absolute;
							background-color: white;
							top: -11px;
							right: 16%;
							border-top: #dcd8d8 solid 1px;
							border-left: #dcd8d8 solid 1px;
							transform: rotate(45deg);
							z-index: -1;
						}

						.navi-item {
							span {
								height: auto !important;
								padding: 0px !important;
							}
						}
					}
				}
				img {
					margin-right: 12px;
					width: 50px;
					height: 50px;
					border-radius: 100px;
					float: left;
					display: inline-block;
				}

				p {
					margin: 0;
					display: flex;
					align-items: self-start;
					min-height: 50px;
					flex-direction: column;
					text-align: left;
					justify-content: center;
					span {
						display: block;
						font-weight: 600;
						font-size: 11px;
						margin: 3px 0 0;
					}
				}

				

				.btn {
					width: auto !important;

					img {
						margin: 0px !important;
						width: auto;
					}
				}

				.dropdown-menu.dropdown-menu-sm {
					border: 1px #dcd8d8 solid;
					top: 12px !important;
					padding: 0 0 !important;
					margin-left: 28px !important;

					&:after {
						content: "";

					}
				}
				.ActionSvg { 
					display: inline-block;
					cursor: pointer;
					.ActionIcon {
						display: inline-block;
						margin: 0 4px;
						img {
							width: auto;
							border-radius: 0px;
							margin: 0px;
						}
					}
					.EmailSvgIcon {
						width: 18px !important;
						height: 18px !important;
						.spinner-grow {
							width: 12px;
							height: 12px;
							background-color: rgb(0 0 0 / 41%) !important;
						}
					}
					.ReportSvgIcon {
						width: 17px !important;
						height: 17px !important;
					}
					.DeleteSvgIcon {
						width: 16px  !important;
						height: 16px  !important;
					}
					.EditSvgIcon {
						width: 14px !important;
						height: 14px !important;
					}
				}
				.ActionSvg {
					cursor: pointer;
					.ActionIcon {
						button {
							width: 18px !important;
							height: 18px !important;
							min-width: 18px !important;
							padding: 0;
							margin-right: 0 !important;
							span {
								width: 18px !important;
    							height: 18px !important;
							}
						}
					}
				}
				
			}
		}
	}
}
.table.EmailTempTable {
	thead {
		position: relative !important;

		th {
			background-color: var(--light-gray) !important;
			color: var(--gray-dark) !important;
			text-transform: capitalize;
			font-size: 14px !important;
			font-weight: 600;
			vertical-align: middle;
			padding: 12px 22px;
			&:first-child {
				padding-left: 15px !important;
				width: 20% !important;
			}
			&:nth-child(2) {
				padding-left: 15px !important;
				width: 21% !important;
			}
			&:nth-child(3) {
				padding-left: 15px !important;
				width: 21% !important;
			}
			&:nth-child(4) {
				padding-left: 15px !important;
				width: 12% !important;
			}
			&:nth-child(5) {
				padding-left: 15px !important;
				width: 12% !important;
			}
			&:last-child {
				width: 14% !important;
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;

			td {
				color: var(--gray-dark);
				font-weight: 600;
				font-size: 13px;
				position: relative;
				padding: 22px 12px !important;
				letter-spacing: 0.4px;
				line-height: 16px;
				word-break: break-all;
				.dropdown-menu {
					.navi {
						&:after {
							content: "";
							height: 20px;
							width: 20px;
							position: absolute;
							background-color: white;
							top: -11px;
							right: 16%;
							border-top: #dcd8d8 solid 1px;
							border-left: #dcd8d8 solid 1px;
							transform: rotate(45deg);
							z-index: -1;
						}

						.navi-item {
							span {
								height: auto !important;
								padding: 0px !important;
							}
						}
					}
				}
				img {
					margin-right: 12px;
					width: 50px;
					height: 50px;
					border-radius: 100px;
					float: left;
					display: inline-block;
				}

				p {
					margin: 0;
					display: flex;
					align-items: self-start;
					min-height: 50px;
					flex-direction: column;
					text-align: left;
					justify-content: center;
					span {
						display: block;
						font-weight: 600;
						font-size: 11px;
						margin: 3px 0 0;
					}
				}

				

				.btn {
					width: auto !important;

					img {
						margin: 0px !important;
						width: auto;
					}
				}

				.dropdown-menu.dropdown-menu-sm {
					border: 1px #dcd8d8 solid;
					top: 12px !important;
					padding: 0 0 !important;
					margin-left: 28px !important;

					&:after {
						content: "";

					}
				}
				.ActionSvg { 
					display: inline-block;
					cursor: pointer;
					.ActionIcon {
						display: inline-block;
						margin: 0 4px;
						img {
							width: auto;
							border-radius: 0px;
							margin: 0px;
						}
					}
					.EmailSvgIcon {
						width: 18px !important;
						height: 18px !important;
						.spinner-grow {
							width: 12px;
							height: 12px;
							background-color: rgb(0 0 0 / 41%) !important;
						}
					}
					.ReportSvgIcon {
						width: 17px !important;
						height: 17px !important;
					}
					.DeleteSvgIcon {
						width: 16px  !important;
						height: 16px  !important;
					}
					.EditSvgIcon {
						width: 14px !important;
						height: 14px !important;
					}
				}
				.ActionSvg {
					cursor: pointer;
					.ActionIcon {
						button {
							width: 18px !important;
							height: 18px !important;
							min-width: 18px !important;
							padding: 0;
							margin-right: 0 !important;
							span {
								width: 18px !important;
    							height: 18px !important;
							}
						}
					}
				}
				
			}
		}
	}
}
.table.DynamicKeyTable {
	thead {
		position: relative !important;

		th {
			background-color: var(--light-gray) !important;
			color: var(--gray-dark) !important;
			text-transform: capitalize;
			font-size: 14px !important;
			font-weight: 600;
			vertical-align: middle;
			padding: 12px 22px;
			&:first-child {
				padding-left: 15px !important;
				width: 30% !important;
			}
			&:nth-child(2) {
				padding-left: 15px !important;
				width: 30% !important;
			}
			&:last-child {
				width: 40% !important;
			}
			
		}
	}

	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;

			td {
				color: var(--gray-dark);
				font-weight: 500;
				font-size: 13px;
				position: relative;
				padding: 22px 12px !important;
				letter-spacing: 0.4px;
				line-height: 16px;
				word-break: break-all;
				.dropdown-menu {
					.navi {
						&:after {
							content: "";
							height: 20px;
							width: 20px;
							position: absolute;
							background-color: white;
							top: -11px;
							right: 16%;
							border-top: #dcd8d8 solid 1px;
							border-left: #dcd8d8 solid 1px;
							transform: rotate(45deg);
							z-index: -1;
						}

						.navi-item {
							span {
								height: auto !important;
								padding: 0px !important;
							}
						}
					}
				}
				img {
					margin-right: 12px;
					width: 50px;
					height: 50px;
					border-radius: 100px;
					float: left;
					display: inline-block;
				}

				p {
					margin: 0;
					display: flex;
					align-items: self-start;
					min-height: 50px;
					flex-direction: column;
					text-align: left;
					justify-content: center;
					span {
						display: block;
						font-weight: 600;
						font-size: 11px;
						margin: 3px 0 0;
					}
				}

				

				.btn {
					width: auto !important;

					img {
						margin: 0px !important;
						width: auto;
					}
				}

				.dropdown-menu.dropdown-menu-sm {
					border: 1px #dcd8d8 solid;
					top: 12px !important;
					padding: 0 0 !important;
					margin-left: 28px !important;

					&:after {
						content: "";

					}
				}
				.ActionSvg { 
					display: inline-block;
					cursor: pointer;
					.ActionIcon {
						display: inline-block;
						margin: 0 4px;
						img {
							width: auto;
							border-radius: 0px;
							margin: 0px;
						}
					}
					.EmailSvgIcon {
						width: 18px !important;
						height: 18px !important;
						.spinner-grow {
							width: 12px;
							height: 12px;
							background-color: rgb(0 0 0 / 41%) !important;
						}
					}
					.ReportSvgIcon {
						width: 17px !important;
						height: 17px !important;
					}
					.DeleteSvgIcon {
						width: 16px  !important;
						height: 16px  !important;
					}
					.EditSvgIcon {
						width: 14px !important;
						height: 14px !important;
					}
				}
				.ActionSvg {
					cursor: pointer;
					.ActionIcon {
						button {
							width: 18px !important;
							height: 18px !important;
							min-width: 18px !important;
							padding: 0;
							margin-right: 0 !important;
							span {
								width: 18px !important;
    							height: 18px !important;
							}
						}
					}
				}
				
			}
		}
	}
}
.table.Rooms {
	thead {
		position: relative !important;

		th {
			background-color: var(--light-gray) !important;
			color: var(--gray-dark) !important;
			text-transform: capitalize;
			font-size: 14px !important;
			font-weight: bold;

			&:first-child {
				padding-left: 15px !important;
			}

			
		}
	}

	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;

			td {
				color: var(--gray-dark);
				font-weight: 600;
				font-size: 13px;
				position: relative;
				padding: 22px 12px !important;
				letter-spacing: 0.4px;
				line-height: 16px;

				.dropdown-menu {
					.navi {
						&:after {
							content: "";
							height: 20px;
							width: 20px;
							position: absolute;
							background-color: white;
							top: -11px;
							right: 16%;
							border-top: #dcd8d8 solid 1px;
							border-left: #dcd8d8 solid 1px;
							transform: rotate(45deg);
							z-index: -1;
						}

						.navi-item {
							span {
								height: auto !important;
								padding: 0px !important;
							}
						}
					}
				}
				&:first-child {
					width: 6% !important;
    				text-align: center;
					img {
						width: 14px;
						float: none;	
					}	
				}

				img {
					float: left;
					width: 50px;
					display: inline-block;
				}

				p {
					margin: 0;
					display: flex;
					align-items: center;
					min-height: 50px;
				}
				&:last-child {
					width: 11%;
				}

				.btn {
					width: auto !important;

					img {
						margin: 0px !important;
						width: auto;
					}
				}

				.dropdown-menu.dropdown-menu-sm {
					border: 1px #dcd8d8 solid;
					top: 12px !important;
					padding: 0 0 !important;
					margin-left: 28px !important;

					&:after {
						content: "";

					}
				}

				.ActionIcon {
					display: inline-block;
					margin: 0 6px;

					img {
						width: auto;
						border-radius: 0px;
						margin: 0px;
					}
				}
			}
		}
	}
}

.table.StageDelTables {
	thead {
		position: relative !important;

		th {
			background-color: var(--light-gray) !important;
			color: var(--gray-dark) !important;
			text-transform: capitalize;
			font-size: 14px !important;
			font-weight: bold;
			text-align: center;
			&:first-child {
				text-align: left;
				word-break: break-word;
				padding: 22px 20px !important;
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;

			td {
				color: var(--gray-dark);
				font-weight: 600;
				font-size: 13px;
				position: relative;
				padding: 22px 6px !important;
				letter-spacing: 0.4px;
				line-height: 16px;
				text-align: center;
				svg {
					width: 16px;
    				height: 16px;
					cursor: pointer;
				}
				&:first-child {
					width: 26%;
					text-align: left;
					padding: 22px 20px !important;
				}
				&:nth-child(2) {
					width: 15%;
				}
				&:nth-child(3) {
					width: 16%;
				}
				&:nth-child(4) {
					width: 20%;
				}
				&:nth-child(5) {
					width: 10%;
				}
				&:last-child {
					width: 10%;
				}

				.btn {
					width: auto !important;

					img {
						margin: 0px !important;
						width: auto;
					}
				}

				.dropdown-menu.dropdown-menu-sm {
					border: 1px #dcd8d8 solid;
					top: 12px !important;
					padding: 0 0 !important;
					margin-left: 28px !important;

					&:after {
						content: "";

					}
				}
				.ActionSvg { 
					display: inline-block;
					cursor: pointer;
					.ActionIcon {
						display: inline-block;
						margin: 0 4px;
						img {
							width: auto;
							border-radius: 0px;
							margin: 0px;
						}
					}
					.EmailSvgIcon {
						width: 18px !important;
						height: 18px !important;
					}
					.ReportSvgIcon {
						width: 17px !important;
						height: 17px !important;
					}
					.DeleteSvgIcon {
						width: 16px  !important;
						height: 16px  !important;
					}
					.EditSvgIcon {
						width: 14px !important;
						height: 14px !important;
					}
				}
			}
		}
	}
}

.topbar {
	.dropdown {
		.dropdown-menu {
			border: #dcd8d8 solid 1px;
			border-radius: 3px;
			// margin: 0px 0px 0 -10px !important;
			padding: 0px 0px;

			.ddlList {
				position: relative;

				&::before {
					content: "";
					height: 16px;
					width: 16px;
					position: absolute;
					background-color: white;
					top: -15px;
					right: 16%;
					border-top: #dcd8d8 solid 1px;
					border-left: #dcd8d8 solid 1px;
					transform: rotate(45deg);
					z-index: -1;
				}

				.dropdown-item {
					padding: 6px 13px;
					font-size: 13px;

					.MenuSvg {
						display: inline-block;
					    height: 18px;
					    margin-right: 8px;
					    margin-bottom: 0px;
					    width: 30px;
					    text-align: center;

						svg {
							width: 100%;
							height: 100%;
							margin: -4px 0 0;
						}
					}

					&:first-child {
						margin-top: 8px;
					}

					&:last-child {
						border-top: #dcd8d8 solid 1px;
						padding: 8px 0;
						text-align: center;
						margin-top: 8px;
					}
				}
			}
		}
	}
}
.dropdown-item.active, .dropdown-item:active {
    color: #000000 !important;
    background-color: var(--light-gray) !important;
}
.HelpYou {
	float: right;
    position: fixed;
    z-index: 9;
    right: 93px;
    bottom: 6px;

	.CommonGtBtn {
		color: var(--white);
		font-size: 14px;

		img {
			margin-right: 10px;
			width: 18px;
		}
	}
}

.nav-tabs {
	.nav-item {
		.nav-link {
			text-transform: capitalize;
			margin: 0 2rem;

			.nav-text {
				font-size: 14px;
			}
		}
	}
}

.nav.nav-tabs.nav-tabs-line .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line .nav-link.active,
.nav.nav-tabs.nav-tabs-line .show>.nav-link {
	border-bottom: 3px solid #3699FF !important;
}

.stateForm {
	label {
		color: #222222;
		font-weight: 400;
		font-size: 13px;
	}
	.form-control {
		background: var(--light-gray);
		border: 0;
		box-shadow: none;
		&:before {
			display: none;
		}
		&:before {
			display: none;
		}
		.MuiInput-input {
			height: 40px;
			padding: 0;
			background-color: transparent;
			box-shadow: none;
		}
	}
	.Mui-focused {
		&:after {
			display: none;
		}
	}
}

.SelectImgs {
	cursor: pointer;
	position: relative;
	aspect-ratio: 16/9;
	img {
		border-radius: 10px;
		border: 1px #e8e8e8 solid;
		height: 100%;
		width: 100%;
	}
	.SelectImgBg {
		position: absolute;
		top: 12px;
		right: 12px;
		width: 24px;
		height: 24px;
		img {
			border: 0px !important;
		}
	}
}
.minHeightBox {
	border: 1px #1A4788 dashed !important;
    height: auto !important;
    min-height: auto !important;
    border-radius: 10px;
    margin: 5px 0 0 !important;
    aspect-ratio: 16/9;
	.UploaderFiles {
		.UploadLabel {
			font-weight: 600;
			font-size: 11px;
			line-height: 17px;
			span {
				color: var(--blue);	
			}
			.img-fluid {
				margin: 0 0 4px;	
			}
			.img-fluid {
				width: 45px;	
			}	
			p {
				margin: 0;
			}
		}
	}
}
.UploaderFiles {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.uploadBrowser {
	.minHeightBox {
		.UploaderFiles {
			.UploadLabel {
				font-weight: 600;
				font-size: 12px;
				line-height: 18px;
				padding: 0;
				width: 100%;
				margin: 0 !important;
				.img-fluid {
					width: 45px;
					margin-bottom: 8px;
				}
			}
		}
	}
}
.UploaFileStage {
	.drop-zone-img-preview {
		.dzu-previewStatusContainer {
			.upload-icons {
				width: 20px;
    			height: 20px;
    			padding: 2px;
    			right: 18px !important;
    			top: 18px !important;
    			svg {
    				width: 100%;
    				height: 100%;	
    			}
			}
		}
	}
}




.ToggleDiv {
	label {
		color: #222222;
		font-weight: 400;
		font-size: 13px;
	}
	fieldset {
		vertical-align: middle;
		margin-right: 6px;
	}
}
.scrolltop {
	background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
    opacity: 1 !important;
    width: 55px;
    height: 55px;
	bottom: 72px;
    clip-path: polygon(50% 0%, 50% 0%, 50% 0%, 50% 0%, 0% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 100% 50%, 100% 50%, 100% 50%);

}
.CheckBoxTicl {
	.check-group {
		margin: 0 !important;
	}
}
.feaderList {
    border: 1px #f0f0f0 solid;
    padding: 14px 16px;
    border-radius: 6px;
    position: relative;
    min-height: 138px;
    margin-top: 21px;
    h4 {
    	color: #222222;
	    font-weight: bold;
	    font-size: 17px;
	    margin: 0 0 12px;
	    padding-right: 34px;
    }
    p {
    	color: #222222;
	    font-size: 14px;
	    margin-bottom: 10px;
    }
    h5 {
    	font-size: 14px;
	    font-weight: 600;
	    color: #222222;
	    margin: 0 0 20px;	
    }
	.ChatGroutAction {
		margin-top: 16px;
		.BdrButton {
			background-color: #fff;
			color: var(--blue);
			border: 1px #e5e5e5 solid;
			border-radius: 4px;
			margin-right: 12px;
			padding: 6px 10px;
			svg {
				margin-right: 8px;
				width: 16px;
				height: 16px;
			}
		}
	}
	h3 {
		color: #222222;
		font-weight: 600;
		font-size: 20px;
		margin: 0 0 12px;
		padding-right: 34px;
	}
	p {
		color: #222222;
		font-size: 14px;
		margin: 0;
	}
	.UserfeaderList {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 24px;
		.helpheader {
			background-color: #f1f1f1;
			padding: 6px 14px;
			border-radius: 100px;
			font-size: 14px;
		}
		.HelpUserAction {
			margin-right: -3px;
			.ActionSvg {
				display: inline-flex;
				margin: 0 5px;
				background-color: #f1f1f1;
				padding: 8px;
				border-radius: 100px;
				.ActionIcon {
					width: 16px;
					height: 16px;
				}
				&:last-child {
					margin-right: 0px !important;
				}
			}
		}
	}
}


.check-group {
  display: block;
  margin-bottom: 15px;
}

.check-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.check-group label {
    position: relative;
    cursor: pointer;
    font-weight: 600;
    margin: 0;
}

.check-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #e0e0e0;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 12px;
}


.check-group input:checked + label:after {
  	content: "";
	display: block;
	position: absolute;
	top: 5px;
	left: 8px;
	width: 6px;
	height: 11px;
	border: solid #ffffff;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}
.check-group input:checked + label:before {
	background-color: #2f73f7;
	border-color: transparent;
}
.SwitchRight {
	position: absolute;
    right: 10px;
    top: 5px;
}
.OnoffSwitch {
	.MuiFormControlLabel-root {
		margin: 0px !important;
	}
	.MuiSwitch-root {
		padding: 12px 7px !important;
		width: 48px;
		height: 37px;
		.MuiButtonBase-root {
			padding: 13px 8px !important;
			.MuiSwitch-thumb {
				width: 11px !important;
				height: 11px !important;
			}	
		}
	}	
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #19dd22 !important;
}
.MuiSwitch-colorSecondary.Mui-checked {
    color: #ffffff !important;
}
.MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: transparent !important;
}
.MuiSwitch-colorSecondary.Mui-checked:hover, .MuiIconButton-root:hover {
	 background-color: transparent !important;
}
.EnableEmoji {
    background-color: var(--light-gray);
    padding: 12px 0;
    height: 138px;
    overflow-y: auto;
    overflow-x: hidden;
	ul {
		margin: 0px;
	    padding: 0;
	    text-align: center;
		li {
	    	display: inline-block;
		    text-align: center;
		    padding: 0 4px;
		    margin: 0 0 10px;
		    img {
			    width: 28px;
			}
			.emoji {
				font-size: 18px;
			}
		}	
	}	
}
.EnableEmoji .check-group input:checked + label:before {
    background-color: #2f73f7;
    border-color: transparent;
}
.EnableEmoji .check-group input:checked + label:after {
	left: 7px !important
}
.EnableEmoji .check-group label:before {
  margin-right: 0px;
}
.EnableEmoji .check-group {
	margin: 4px 0 0;	
}
.EnableEmoji .check-group label:before {
    border: 2px solid #a09c9c;
    padding: 8px;
    border-radius: 3px;
}


.EnableEmoji::-webkit-scrollbar-track {
	background-color: var(--light-gray);
}

.EnableEmoji::-webkit-scrollbar {
	width: 3px;
}

.EnableEmoji::-webkit-scrollbar-thumb {
	background-color:#b9b7b7;
}
.LiveStream {
	padding: 0px;
	border: 0px;
	margin: 35px 0 0;
	h4 {
		margin: 0 0 20px	
	}
	h5 {
		margin: 0 0 14px	
	}	
	img {
		width: 48px;
		margin-right: 8px;
	}
}

.filepond--wrapper {
	.filepond--root {
		.filepond--drop-label {
			label {
				font-weight: 600;
    			font-size: 12px;
				span {
					color: var(--blue)	
				}
				p {
					color: #6f6d6d;
				    font-size: 10px;
				    margin: 3px 0 0;
				}
			}	
		}	
		.filepond--panel-root {
			background-color: transparent !important;
		}
	}	
}
.NetworkingSetup {
    padding: 17px 0;
	position: relative;
	.NetworTypeMode {
		h4 {
			    color: #222222;
			    font-weight: 600;
			    font-size: 17px;
			    margin: 0 0 12px;
			    padding-right: 34px;
		}	
	}
	.uplodLogo {
		label {
			font-weight: 600;
		    font-size: 12px;
		    color: var(--gray-dark);	
		    margin: 0 0 10px;
		}
		h5 {
			font-size: 14px;
			color: var(--dark);
			margin: 12px 0;
			line-height: 20px;
		}	
	}
}
.QuickNetworking {
	h4 {
	    color: #222222;
	    font-weight: 600;
	    font-size: 21px;
	    margin: 0 0 6px;
	    padding-right: 34px;
	}
	p {
	    color: #222222;
	    font-size: 14px;
	    margin: 0 0 12px;
	    padding-right: 34px;
	    font-weight: 600;
	}	
}
.NetworkingMatching {
	position: relative;
	.SelectMatch {
		h3 {
			color: #222;
		    font-weight: 600;
		    font-size: 18px;
		}
		p {
			font-size: 13px;
		    color: #333;
		    font-weight: 600;
		}
		.SwitchMatch {
		    position: absolute;
		    right: 0px;
		    top: -7px;
		}	
	}
}
.NetwokMatchTable {
	border-radius: 10px;
    background-color: var(--light-gray);
    float: left;
    width: 100%;
    min-height: 320px;
    position: relative;
    padding: 14px;
	.table.NetworkMatch {
		margin: 0px;
		tbody {
			tr {
				border-bottom: 1px #e6e4e4 solid;

				td {
					color: var(--gray-dark);
					font-weight: 600;
					font-size: 13px;
					position: relative;
					padding: 12px 7px !important;
					letter-spacing: 0.4px;
					line-height: 16px;

					.dropdown-menu {
						.navi {
							&:after {
								content: "";
								height: 20px;
								width: 20px;
								position: absolute;
								background-color: white;
								top: -11px;
								right: 16%;
								border-top: #dcd8d8 solid 1px;
								border-left: #dcd8d8 solid 1px;
								transform: rotate(45deg);
								z-index: -1;
							}

							.navi-item {
								span {
									height: auto !important;
									padding: 0px !important;
								}
							}
						}
					}
					&:first-child {
						width: 7%;
	    				text-align: center;
						img {
							width: 14px;
							float: none;	
						}	
					}
					
					img {
						float: left;
						width: 45px;
						display: inline-block;
					}

					p {
						margin: 0;
						display: flex;
						align-items: center;
						min-height: 50px;
					}
					&:last-child {
						width: 22%;
					}

					.btn {
						width: auto !important;

						img {
							margin: 0px !important;
							width: auto;
						}
					}

					.dropdown-menu.dropdown-menu-sm {
						border: 1px #dcd8d8 solid;
						top: 12px !important;
						padding: 0 0 !important;
						margin-left: 28px !important;

						&:after {
							content: "";

						}
					}

					.ActionIcon {
						display: inline-block;
						margin: 0 3px;

						img {
							width: auto;
							border-radius: 0px;
							margin: 0px;
						}
					}
				}
				.check-group {
					margin-bottom: 0px;
					label {
						&:before {
							margin-right: 0px;
						    border: 2px solid #bbb5b5;
						    padding: 9px;		
						}
					}	
				}
				&:last-child {
					border-bottom: 0px;	
				}
				form {
					display: none;	
				}
			}
		}
	}
	.TableMaxAuto {
		height: 190px;
	    overflow-x: hidden;
	    overflow: auto;	
	}
}
.NetworkMatch .check-group input:checked + label:before {
    border-color: transparent !important;
}
.check-group input:checked + label:after {
	top: 4px;
}
.filterAdd  {
	position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin-bottom: 22px !important;
    margin-top: 22px !important;
}

.stateForm {
    position: relative;
    .LimitTxt {
    	position: absolute;
	    right: 4px;
	    top: 3px;
	    font-weight: 600;
	    font-size: 12px;
	    color: #a7a4a4;
    }
	.MainAddDiv {
		display: block;

		.MixhubbTagInner {
			display: flex;
			align-items: center;
			align-content: center;
			.AddTagesInput {
				width: 320px;
				display: inline-flex;
				margin-right: 20px;
				position: relative;
				.LimitTxt {
					top: -20px !important;
				}
			}
			.EnterEventin {
				display: inline;
			}
		}	
		.AaddingTagBox {
			width: 600px;
			background-color: var(--light-gray);
			margin: 30px 0 0;
			padding: 4px;
			border-radius: 6px;
			.TagName {
				display: inline-block;
				background-color: #fff;
				margin: 5px 6px;
				padding: 6px 25px 6px 13px;
				position: relative;
				border-radius: 4px;
				font-weight: 500;
				font-size: 13px;
				.TagCloseBtn {
					position: absolute;
					right: 4px;
					padding: 0;
					min-width: 13px;
					height: 13px;
					top: 10px;
					cursor: pointer;
					.MuiButton-label {
						width: 13px;
						height: 13px;
					}
					svg {
						width: 13px;
						height: 13px;
						position: absolute;
					}
				}
			}
		}
	}
	.InputSelect {
		.form-group {
			.DialNumberCode {
				background: #F1F1F1;
    			background: var(--light-gray);
				border-radius: 6px;
				position: relative;
				.DailCode {
					height: 40px !important;
					padding: 4px 6px !important;
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					background: transparent !important;
					position: relative;
					z-index: 3;
					cursor: pointer;
					text-align: center;
    				padding-right: 23px !important;
				}
				&::after {
					content: "";
					display: inline-block;
					width: 8px;
					height: 8px;
					border-top: 2px solid #514f4f;
					border-right: 2px solid #514f4f;
					transform: rotate(135deg);
					position: absolute;
					right: 12px;
					top: 14px;
					z-index: 1;
				}
			}
			
		}
	}
	
}
.AccessCode {
	padding-bottom: 20px;
	position: relative;
	h5 {
		text-align: center;
	    font-weight: 600;
	    font-size: 14px;
	    letter-spacing: .5px;
	    color: #000;
	    margin: 0 0 15px;
	}
	.EnterOptValue {
	    text-align: center;
		display: block;
		width: 100%;
		margin: 0px;
	    .OptValue {
	    	input {
	    		margin-right: 10px;
				width: 42px !important;
				height: 42px !important;
				border-radius: 8px;
				box-shadow: none;
				outline: none;
				border: 1px #E8E8E8 solid;
				font-size: 18px;
				font-weight: 600;
	    	}	
	    }
	}
	.timer {
		position: absolute;
		left: 0;
		bottom: 6px;
	}
	.resendOtp {
		position: absolute;
		left: 0;
		bottom: 6px;
		p {
			font-size: 13px;
			color: var(--bllue);

			&:hover {
				color: var(--bllue);
			}
			&:focus {
				color: var(--bllue);
			}
		}
		button {
			padding: 0px !important;
		}
	}
}
.CountPagination {
	 text-align: center;
	.pagination {
	    align-items: center;
	    justify-content: center;
	    .page-item {
	    	border: 0px;
		    
		    .page-link {
		    	 border: 1px var(--light-gray) solid;
			    color: #222;
			    font-size: 16px;
			    font-weight: 600;
			    padding: 2px 7px;
			    margin: 0 4px;
			    border-radius: 4px;
			    &:hover {
					 background: transparent;
					 border: 1px #3d73f7 solid;
				}
				&:focus {
					 background: transparent;
					 border: 1px #3d73f7 solid;
				}
		    }
	    }
    	.active {
	    	.page-link {
	    		    background: transparent;
				    border: 1px #3d73f7 solid;
				    color: #3d73f7;
				    border-radius: 4px;
				
	    	}	
    	}
    	.FirstBtn {
    		.page-link {
    			border: 0px !important;	
    		}
    	}
    	.PrevBtn {
    		.page-link {
    			border: 0px !important;	
    		}
    	}
    	.NextBtn {
    		.page-link {
    			border: 0px !important;	
    		}
    	}
    	.LastBtn {
    		.page-link {
    			border: 0px !important;	
    		}
    	}
	}	
	
}























.AddEventFirst {
	width: 30rem;

	h3 {
		font-size: 28px;
		font-weight: 600;
		margin: 0 0 12px;
		color: var(--gray-dark);
	}

	p {
		color: var(--gray-dark);
		font-size: 16px;
	    font-weight: 400;
	    line-height: 22px;
	}	

	
	.form-group {
		color: var(--gray-dark);

		label {
			color: var(--gray-dark);
			font-size: 13px;
			font-weight: 600;
			font-family: "Segoe UI" !important;
			margin-bottom: 10px;
		}

		.form-control {
			color: var(--gray-dark);
			background-color: #fff;
			border: 1px #e4e6eb solid;
			border-radius: 4px;
			.MuiSelect-root {
				padding: 0 0;
			}
			
		}
		
		.MuiInputBase-root {
			padding: 0 0;
			.MuiInputBase-input {
				padding: 17px 8px !important;	
			}
			.MuiInputAdornment-root {
				position: absolute;
			    right: 0;
			    background: #fff;
			    height: 100%;
			    top: 0;
			    padding: 24px 6px;	
			    margin: 0px;
			}
			.MuiSelect-root {
				background: transparent;
			}
			&:after {
				display: none;
			}
			&:before {
				display: none;
			}
			.MuiButtonBase-root {
				padding: 0px 0px 0 0px;	
			}
		}
		
		select {
			height: 45px;	
		}
		.MuiFormControl-root {
			width: 100% !important;
		    max-width: 100% !important;
		    border: 1px #e4e6eb solid !important;
		    box-shadow: none !important;
		    margin: 0px !important;
	    	.MuiFormLabel-root {
	    		padding-left: 12px;
	    		font-weight: 400;
			    }
			    .MuiInputBase-root {
			    	&:after {
			    		display: none;
			    	}
			    	&:before {
			    		display: none;
			    	}	
			    	.MuiSelect-root {
			    		&:hover {
			    			background: transparent !important;
			    		}
			    		&:focus {
			    			background: transparent !important;
			    		}	
			    	}
			}
		}
	}
}
.SkipPage {
	.SkipBtn {
		color: var(--blue);
	    font-weight: 600;
	    left: .8px;
	    font-size: 15px;	
	}
}

.CommonModalPopup  {
	.modal-dialog {
		margin: 0% auto;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.modal-content {
			padding: 18px 18px;
			border-radius: 16px;
			.modal-header {
				padding: 0 0 22px;
				border-bottom: 0px;
				.modal-title {
					h4 {
						color: #222;
						font-size: 22px;
						font-weight: 600;
						letter-spacing: .5px;
						margin: 0;
					}
				}
				.ModalCloseBtn {
					position: absolute;
					right: 8px;
					padding: 0;
					display: block;
					top: 8px;
					min-width: 25px;
					.MuiButton-label {
						width: auto;
						padding: 0;
						display: block;
						position: relative;
						height: 25px;
						width: 25px;
						line-height: 0;	
						float: right;
					}
					svg {
						width: 100%;
						height: 100%;	
						opacity: 0.5;
					}
					&:hover {
						background-color: transparent;	
					}
					&:focus {
						background-color: transparent;	
					}
				}
			}
			.modal-body {
				padding: 0 0;
				.GroupForm {
					position: relative;
					label {
						position: absolute;
						left: 14px;
						top: 9px;
						font-size: 12px;
						letter-spacing: 0.1px;
						color: #808080;
						margin: 0;
						z-index: 4;
					}
					.form-control {
							background-color: var(--light-gray);
							border-radius: 6px;
							padding: 20px 14px 6px !important;
							font-size: 14px;
					}
					.MuiInputBase-root {
						&:after {
							display: none;
						}
						&:before {
							display: none;
						}
						textarea {
							height: 40px;	
						}
					}
					.LimitDivTxg {
						position: absolute;
						font-size: 11px;
						font-weight: 600;
						right: 10px;
						top: 6px;
						color: #696969;	
					}
				}
				.ConfirmAction {
					h4 {
						font-size: 18px;
						font-weight: 600;	
					}
				}
			}
			.modal-footer {
				padding: 0 0;
				.ContBtn {
					margin-top: 20px !important;
					padding: 11px 0 !important;
					font-weight: 500 !important;
				}
			}
		}
	}
}
.DeleteCancel {
	.modal-footer {
		border-top: 0px !important;
	}
}
.IconSvg {
    position: absolute;
    right: 16px;
    top: 15px;
    width: 22px;
    height: 22px;
    svg {
	    width: 100%;
	    height: 100%;
	}
}

.CommonModalPopup  {
	.modal-content {
		
		.modal-body {
			.GroupAddRoom {
				position: relative;
				label {
					position: absolute;
				    left: 14px;
				    top: 9px;
				    font-size: 11px;
				    letter-spacing: 0.1px;
				    color: #808080;
				    margin: 0;
				    z-index: 4;
				}
				.form-control {
					    background-color: var(--light-gray);
					    border-radius: 6px;
					    padding: 21px 0 6px !important;
					    font-size: 13px;
					    height: 50px !important;
						border: 0px;
					    .MuiNativeSelect-root {
					    	padding: 0 12px;
					    	background-color: transparent;
					    }
				}
				.MuiInputBase-root {
					&:after {
						display: none;
					}
					&:before {
						display: none;
					}
					textarea {
						height: 40px;	
					}
				}
				.LimitDivTxg {
					position: absolute;
				    font-size: 11px;
				    font-weight: 600;
				    right: 10px;
				    top: 6px;
				    color: #696969;	
				}
			}
		}
		.modal-footer {
			padding: 0 0;
			.ContBtn {
				margin-top: 20px !important;
				padding: 11px 0 !important;
				font-weight: 500 !important;
			}
			.DeleteCancleRoom {
				width: 100%;	
			}
		}
	}
}

.drop-zone-img-preview {
	position: relative;
	width: 100%;
    height: 100%;
    img {
	    position: absolute;
	    left: 0;
	    top: 0;
	    padding: 0px;
	    border-radius: 10px;
		object-fit: scale-down;
	}	
	video {
		width: 100% !important;
		height: 100% !important;
	}
	.dzu-previewStatusContainer {
		justify-content: center;
		height: 100%;
		flex-direction: column;
		.dzu-previewFileName {
			padding: 0px 10px;
    		text-align: center;
		}
	}
	.preview-docx {
		background-color: #fff;
	}
}
.dzu-previewStatusContainer {
	.upload-icons {
	    background-color: #fff;
		width: 22px;
		height: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100px;
		right: 12px !important;
		left: auto !important;
		left: initial !important;
		cursor: pointer;
		top: 12px !important;
		padding: 3px;
		svg {
			width: 100%;
    		height: 100%;	
		}
	}
}

.BrandingColor {
	label {
		font-weight: 600;
		font-size: 12px;	
	}	
}



.aside-menu-wrapper {
	background-color: var(--light-gray) !important;	
	.aside-menu {
		background-color: var(--light-gray) !important;	
		margin: 0px !important;
			.MenuNavSecond  {
				.menu-item {
					margin-bottom: 4px;
					position: relative;
					
					.MenuSwitch {
						position: absolute;
						right: 15px;
						top: 4px;
					}
					.menu-link {
						display: flex;
						align-items: center;
						background-color: #e8e8e8 !important;
						padding: 10px 26px !important;
						.menu-arrow {
							color: #222222;
							font-size: 12px;	
						}
						.Dropdown-arrow {
							&:after {
								content: "";
								display: inline-block;
								width: 9px;
								height: 9px;
								border-top: 2px solid #514f4f;
								border-right: 2px solid #514f4f;
								transform: rotate(45deg);
								transition: all 0.3s ease;
								position: absolute;
								right: 20px;
								top: 18px;
								z-index: 1;
							}
						}
						.menu-text {
							color: var(--dark) !important;	
							font-size: 14px !important;
							font-weight: 600;
							&:hover {
								color: var(--blue) !important;
							}
							&:focus {
								color: var(--blue) !important;
							}
						}
						
					}
					.menu-subnav {
						.menu-item {
							position: relative;
							.active {
								.IconMenuSvg {
									fill: var(--blue) !important;
								}
								.menu-text {
									color: var(--blue) !important;
								}
							}
						}
					}
				}	
				.menu-item-open {
					.Dropdown-arrow {
						&:after {
							content: "";
							transform: rotate(135deg) !important;
							transition: all 0.3s ease;
							top: 16px !important;
						}
					}
					.active {
						.menu-text {
							color: var(--blue) !important;
						}
					} 
				}

			}
		.menu-item-open {
			.menu-toggle {
				border-left: 3px solid #3653f6;
			}
		}
		.MenuNavSecond {
			.menu-item-open {
				.menu-toggle {
					border-left: 3px solid #3653f6;
				}
			}
		}



		.MenuNavFirst {
			background-color: #ffffff;
			position: absolute;
			width: 100%;	
			height: 100%;
			padding-top: 0px !important;
			.menu-item {
				&:hover {
    				background-color: #e8e8e8;
				}
				.menu-link {
					background-color: transparent !important;	
					border-left: 3px #e8e8e8 solid;
					&:hover {
						background-color: #e8e8e8 !important;
						border-left: 0px !important;
						
					}
				}
			}	
		}
	}
}
.aside-menu-wrapper .aside-menu .menu-nav .menu-item .menu-link:hover .menu-text {
    color: var(--blue) !important;
}
.aside-menu-wrapper .aside-menu .menu-nav .menu-item .menu-link:hover .menu-arrow {
	color: var(--blue) !important;
}
.aside-menu-wrapper .aside-menu .menu-nav .menu-item .menu-link:hover .IconMenuSvg {
	fill: var(--blue) !important;
}
.IconMenuSvg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    svg {
	    width: 100%;
	    height: 100%;
	}
}
.BasicDetails {
    padding: 20px 20px 5px;
    border-bottom: 1px #cccccc solid;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    margin: 6px 0 3px;
    .UserNameTag {
    	background-color: #3d73f7;
	    float: left;
	    display: block;
	    width: 38px;
	    height: 38px;
	    border-radius: 100px;
	    text-align: center;
	    color: #ffff;
	    font-weight: 600;
	    font-size: 17px;
	    padding: 6px 0;
	    margin: 0px 0 12px;
    }
    h3 {
    	color: #222333;
	    font-weight: 600;
	    font-size: 19px;
	    margin: 0 0 7px;
    }
    p {
    	font-size: 13px;
	    font-weight: normal;
	    color: #636161;
    }
}

.stateForm {
	.multipalTagInput {
		background: var(--light-gray);
	    border: 0;
	    padding: 0px 0px;
	    box-shadow: none;
	    border-radius: 5px;
	    fieldset {
	    	border: 0;
	    }
	    .MuiInputBase-root {
	    	padding: 3px !important;	
	    	.MuiButtonBase-root {
	    		color: #222;
	    		opacity: 1;
    			visibility: visible;	
	    	}
	    	.MuiAutocomplete-clearIndicator {
				.MuiIconButton-label {
    				border-right: 1px #222 solid;
    				padding-right: 5px;
				}
			}
	    }
	    .MuiAutocomplete-tag {
	    	margin: 0 4px;
	    }
	    .MuiChip-root {
	    	height: 29px !important;
	    	.MuiChip-deleteIcon {
	    		height: 20px !important;	
	    		width: 20px !important;	
	    	}	
	    }
	}
}
.MuiAutocomplete-popper {
	label {
		margin: 0px !important;
		padding: 0px !important;
	}
	button {
		margin: 0px !important;
		padding: 0px !important;
	}
	.MuiInputBase-root {
		padding: 0px;	
	}		
}
.EditProfileEven .modal-title h4 {
	font-size: 25px !important;
}
.ProfileUploader {
    min-height: auto !important;
    background-color: var(--light-gray);
    border-radius: 8px;
}
.minHeightBox {
	.default-image-selected {
		position: relative;
		.UploadLabel {
			margin: 0px !important;
			margin-top: 0px !important;
			padding: 0px !important;
			border: 0px;
			.uploadImgIcon {
				position: absolute;
				height: 24px;
				right: 10px !important;
				top: 11px !important;
				background-color: var(--light-gray);
				border-radius: 100px;
				display: flex;
				align-items: center;
				cursor: pointer;
				z-index: 5;
				.UploadDeleteIco {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0px;
					width: 24px;
					height: 24px;
					background-color: var(--light-gray);
					border-radius: 100px;
					svg {
						width: 100%;
						height: 100%;
						fill: #000;
					}
				}
			}
		}
	}
}
.minHeightBox {
	.default-image-selected {
			position: relative;
			.UploadLabel {
				margin: 0px !important;
				padding: 0px !important;
				height: 100% !important;
				.img-fluid {
					width: 100% !important;
					height: 100% !important;
					margin: 0px !important;
					border-radius: 8px;
				}
				
			.uploadPreviewIcon {
				position: absolute;
				height: 24px;
				right: 46px !important;
				top: 11px !important;
				background-color: #caafaf;
				border-radius: 100px;
				display: flex;
				align-items: center;
				cursor: pointer;
				.UploadPreviewIco {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 1px;
					width: 24px;
					height: 24px;
					background-color: var(--light-gray);
					border-radius: 100px;
					svg {
						width: 100%;
						height: 100%;
						fill: #000;
					}
				}
			}
		}
	}
	.dzu-previewContainer {
		height: 100%;
		width: 100%;
		align-items: center;
		justify-content: center;
		.dzu-previewButton {
			position: absolute;
			top: 8px;
			right: 10px;
			width: 20px;
			height: 20px;
			margin: 0 !important;
			svg {
				width: 100%;
				height: 100%;
			}
		}
	}
}
.InputSelect {
	.form-group-input {
		margin-bottom: 15px;
	}
	.MuiFormControl-root {
		width: 100%;
		.MuiInputBase-root  {
			background-color: var(--light-gray) !important;
			border-radius: 8px !important;
			padding: 0;
    		height: auto;
			.MuiSelect-root {
				padding: 12px 15px;
				background-color: var(--light-gray) !important;
				border-radius: 8px !important;
    			height: auto;
				text-align: left;
			}
			&:before {
				display: none;
			}
			input {height: 40px;padding: 0 10px;}
			&:hover {
				background-color: var(--light-gray) !important;
			}
			&:focus {
				background-color: var(--light-gray) !important;
			}
		}
	}
}
.RequireSelectField {
	margin-bottom: 16px;
	label {
		color: var(--light-color) !important;
		&:hover {
			color: var(--light-color) !important;
		}
		&:focus {
			color: var(--light-color) !important;
		}
	}
	.MuiFormControl-root {
		width: 100%;
		.MuiInputBase-root  {
			background-color: transparent !important;
			border-radius: 8px !important;
			.MuiSelect-root {
				padding: 12px 15px;
				background-color: transparent !important;
				border-radius: 8px !important;
				border: 1px #e8e8e8 solid;
				color: var(--light-color) !important;
				font-family: "Segoe UI" !important;
			}
			&:before {
				display: none;
			}
			&:after {
				display: none;
			}
			input {height: 40px;}
			&:hover {
				background-color: transparent !important;
			}
			&:focus {
				background-color: transparent !important;
			}
		}
	}
	.MuiFormGroup-root {
		margin: 2px 0 6px;
		width: 100%;
		max-width: 100%;
    	min-width: 100%;
		position: relative;
		svg {
			position: absolute;
			right: 12px;
    		top: 18px;
			z-index: -1;
		}
		.MuiInputBase-root {
		
			svg {
				display: none;
			}
		}
	}
}
.MuiPopover-root {
	.MuiPaper-root {
		padding: 0 10px;
		background-color: var(--light-gray) !important;
		border-radius: 10px;
		.MuiList-root {
			.MuiButtonBase-root {
				background-color: transparent;
				border-bottom: 1px #d3d3d3 solid;
				padding-left: 0;
				padding-right: 0;
				&:last-child {
					border: 0px !important;
				}
				&:hover {
					background-color: transparent !important;
				}
				&:hover {
					background-color: transparent !important;
				}
				.MuiTouchRipple-root {
					&:hover {
						background-color: transparent !important;
					}
					&:hover {
						background-color: transparent !important;
					}
				}
			}
		}
	}
}
.SelectIntroVideo {
	position: relative;
    cursor: pointer;
    margin: 10px 0 10px;
	video {
		width: 100%;
		height: auto !important;
		border: 1px #000 dashed;
		border-radius: 8px;
		aspect-ratio: 16/9;
	}
	.SelectImgBg {
		position: absolute;
		top: 12px;
		right: 12px;
		width: 24px;
		height: 24px;
		img {
			border: 0px !important;
		}
	}
}

.aside-menu-wrapper {
	.aside-menu {
		.MenuNavFirst {
			.menu-item  {
				.menu-link {
					border-left: 3px solid #e8e8e8 !important;
					.menu-text {
						color: var(--dark) !important;
					}
				}
				&:hover {
					.menu-link {
						border-left: 3px solid #3653f6 !important;
						background-color: #e8e8e8 !important;
						
					}
				}
			}
			.menu-item-active {
				.active {
					border-left: 3px solid #3653f6 !important;
    				background-color: #e8e8e8 !important;
					.menu-text {
						color: var(--blue) !important;
					}
				}	
				.IconMenuSvg {
					fill: var(--blue) !important;
				}
				.menu-text {
					color: var(--blue) !important;
				}
				&:hover {
    				background-color: #e8e8e8 !important;
					border-left: 0px !important;
				}
			}
		}
	}
}













.ExpoZones {
	border: 1px var(--light-gray) solid;
    padding: 12px 20px;
    border-radius: 8px;
    box-shadow: 0 3px 10px #e8e8e8a1;
	h4 {
		margin: 0 0 4px;
	    font-size: 18px;
	    font-weight: 600;
	    color: var(--dark)
	}	
	p {
		margin: 0;
	    font-size: 14px;
	    font-weight: 500;
	    a {
	    	text-decoration: underline;	
	    }
	}
}

.ExpoMargeZones {
	border: 1px var(--light-gray) solid;
    padding: 20px 20px;
    border-radius: 8px;
    box-shadow: 0 3px 10px #e8e8e8a1;
	svg {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}
	h5 {
		margin: 0;
		font-size: 16px;
		font-weight: 600;
	    color: var(--dark)
	}
}


.BoothCardInfo {
	border: 1px #e8e8e8 solid;
    padding: 15px 22px;
    margin: 26px 0 35px;
    border-radius: 10px;
	.TypeOfBooth {
	    display: grid;
	    place-content: center space-between;
	    grid-auto-flow: column;
	    align-items: center;
	    .boothAddType {
	    	h4 {
			    color: var(--dark);
				font-weight: 600;
				font-size: 17px;
				letter-spacing: 0.5px;
				margin-right: 15px;
				display: inline-block;
				vertical-align: middle;
				margin-bottom: 0;
				line-height: 30px;
				min-width: 164px;
			}
		} 
		.SearchBooth {
		    width: 250px;
		    .MuiPaper-root {
		    	    // height: 42px;
				    // color: #f00;
				    // position: relative;
					// display: block;
					border: 1px var(--gray-dark) solid;
					// width: 250px;
					box-shadow: none !important;
					position: relative;
					padding: 3px 0;
		    	.MuiInputBase-root {
		    		// position: relative;
		    		// color: #f90;
	    			// width: 100%;
		    		input {
		    			color: #000;
		    			padding-left: 43px;
	    				height: 18px;
		    		}	
		    	}
		    }
		    .MuiButtonBase-root {
		    	    position: absolute;
				    padding: 0;
				    left: 7px;
				    top: 7px;
		    	.MuiIconButton-label {
		    		color: #737376;	
		    		svg {
		    			width: 26px;	
		    			height: 26px;	
		    		}
		    	}
		    	
		    }
		}
	}
	.BoothDetailsInfo {
		display: grid;
	    place-content: center space-between;
	    grid-auto-flow: column;
	    align-items: center;
	    background-color: var(--light-gray);
	    padding: 14px 15px;
	    border-radius: 10px;
	    margin: 18px 0 0;
		.BoothEditDelete {
			display: grid;
		    place-content: center space-between;
		    grid-auto-flow: column;
		    align-items: center;
		    .EditDel {
			    grid-auto-flow: column;
			     .iconSvg {
				    width: 32px;
				    height: 32px;
				    display: inline-block;
				    vertical-align: middle;
				    background-color: #ffffff;
				    margin: 0 4px;
				    border-radius: 100px;
				    padding: 6px 8px;
				     svg {
					    width: 100%;
					    height: 100%;
					}
				}
			}
		}
		.BoothLeftBg {
		    display: grid;
		    place-content: center space-between;
		    grid-auto-flow: column;
		    align-items: center;
		    .BoothLogo {
		    	width: 165px;
    			margin-right: 25px;
		    	.img-fluid {
					border-radius: 8px;
    				height: 68px;
					object-fit: scale-down;
		    	}
		    }
		    h3 {
		    	color: var(--dark);
			    font-weight: 600;
			    font-size: 18px
		    }
		}
	}
}

.IncludeCouponInfo {
	border: 1px var(--light-gray) solid;
    padding: 28px;
    margin: 30px 0 30px;
    border-radius: 10px;
    h3 {
    	color: var(--dark);
	    font-size: 15px;
	    font-weight: 600;
	    margin: 0 0 16px;	
    }
	.GroupForm {
		.LimitDivTxg {
			position: absolute;
		    font-size: 11px;
		    font-weight: 600;
		    right: 10px;
		    top: 6px;
		    color: #696969;	
		}
		.form-control {
			background-color: var(--light-gray);
		    border-radius: 6px;
		    padding: 26px 14px 7px !important;
		    font-size: 14px;
		}
		.EventDescript {
			.MuiInputBase-root {
				width: 100% !important;
				.MuiInput-root {
					textarea {
				    	height: 80px;
						
				    }
				}	
			}	
		}
	}	
}
.ExpoAdditionalFeature {
	.BoothSetupHeight {
		min-height: auto !important;	
	}	
	.EnterEventin {
		margin-top: 15px;
	}
}
.LandingPageTheme {
	border: 1px var(--light-gray) solid;
	border-radius: 10px;
	padding: 22px;
	.RegStyleHeight {
		background-color: #f6f6f6;
		min-height: auto !important;	
		padding: 14px 16px !important;
		margin: 0px !important;
	}	
	.RadioLandingPg {
	    position: absolute;
	    right: 6px;
		top: 16px;
		button {
			padding: 0px !important;
		}
	}
}





.RadioLandingPg {
	button {
		&:hover {
			background-color: transparent;
		}
		&:focus {
			background-color: transparent;
		}
	}
		.checkRadio {
			display: block;
			position: relative;
			padding-left: 28px;
			cursor: pointer;
			font-size: 14px;
			margin: 0px;
			-webkit-user-select: none;
			-ms-user-select: none;
			user-select: none;
			font-family: 'Poppins';
			font-weight: 400;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;

			&:checked~.checkmark {
				background-color: #2196F3;
			}

			&:checked~.checkmark:after {
				display: block;
			}
		}
		.radiobtn {
			position: absolute;
		    top: -2px;
		    left: 0;
		    height: 18px;
		    width: 18px;
		    background-color: #ddd;
		    border-radius: 50%;
		    border: 0.0625rem solid #bfbebe;

			&:after {
				top: 0px;
			    left: 0px;
			    width: 16px;
			    height: 16px;
			    border-radius: 50%;
			    border: 2px #ddd solid;
			}

			&:after {
				content: "";
				position: absolute;
				display: none;
			}
		}
		.checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.checkmark:after {
			left: 10px;
			top: 6px;
			width: 7px;
			height: 12px;
			border: solid white;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
		input:checked~.radiobtn {
			background-color: #2196F3;
		}
		input:checked~.radiobtn:after {
			display: block;
		}
	}
}  
.SelectPageTheme {
    margin: 26px 0 0;
	.ThemeSelectBrand {
		border: 2px var(--light-gray) solid;
		margin: 10px 0 0;
	    padding: 12px 12px;
	    border-radius: 10px;
		.ThemeBgInner {
			position: relative;
			img {
				width: 100%;
			}
			.NextUrl {
				position: absolute;
				top: 8px;
				right: 8px;
				width: 26px;
				height: 26px;
				background-color: #fff;
				border-radius: 100px;
				padding: 1px;
				cursor: pointer;
				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
		.RadioLandingPg{
			margin: 0 0 13px;
		    line-height: 15px;
			label {
				color: var(--dark);
	    		font-size: 14px;
				span {
					color: var(--dark);
	    			font-size: 14px;
				}
			}	
			.MuiButton-text {
				padding: 0px 0px 6px 0px !important;
			}
		}	
		.EditThemeBtn {
			width: 100%;
		    margin: 8px 0 0;
		    padding: 10px 0;
		    color: var(--blue);
		    font-size: 14px;
		    font-weight: 500;
		    text-transform: capitalize;
		    letter-spacing: .3px;
		    border: 1px var(--light-gray) solid;
		    background-color: #fff;
		    &:hover {
		    	background-color: #fff !important;	
		    }
		    &:focus {
		    	background-color: #fff !important;	
		    }
		}
	}
	.SelectedNewTheme {
		border: 2px #007bff solid !important;
	}
}

.LandingStylesInner {
	display: grid;
    place-content: center space-between;
    grid-auto-flow: column;
    align-items: center;
    margin: 0 0 28px;
    .ScheduleHeader {
    	grid-auto-flow: column;	
    }
    .menuRight {
	    grid-auto-flow: column;
	     ul {
		    margin: 0px;
		    padding: 0px;
		}
		li {
		    display: inline-block;
		    margin: 0 8px;
		}
		 a {
		    color: var(--blue);
		    font-weight: bold;
		    font-size: 15px;
		    border: 1px #e8e8e8 solid;
		    padding: 6px 18px;
		    border-radius: 6px;
		}
	}
}
.AboutEventPg {
	.form-control {
	    background: var(--light-gray);
	    height: 140px;
	    font-size: 14px;
		&:focus {
			border: 0px;
		}
	}	
}
.InformLandingTable {
	border: 1px var(--light-gray) solid;
	padding: 15px 20px;
	border-radius: 12px;
	margin: 22px 0 0;
	.EventSponsor {
		margin: 0px;
		tbody {
			tr {
			    background-color: var(--light-gray);
			    margin: 0 0 10px;
			    display: table;
				width: 100%;
			    &:last-child {
			    	margin-bottom: 0px;
			    }
				td {
					color: var(--gray-dark);
				    font-weight: 500;
				    font-size: 17px;
				    position: relative;
				    padding: 12px 12px !important;
				    letter-spacing: 0.4px;
				    width: 25%;
				    line-height: 24px;
					word-break: break-all;
					.sponsorBgImage {
						display: inline-block;
						margin-right: 24px;
						text-align: center;
						min-width: 110px;
					}
				    p {
					    margin: 0;
					    display: flex;
					    align-items: center;
					    min-height: 58px;
					    font-size: 20px;
					    font-weight: 600;
					}
					&:first-child {
						width: 3%;	
						text-align: center;
					}
					&:nth-child(2) {
						img {
							
						    border-radius: 10px;
						    display: inline-block;
						    width: auto;
							height: 80px;
						}	
					}
					&:last-child {
						width: 7%;
						text-align: right;
					}
					.ActionIcon {
					    background-color: #fff;
					    margin: 0 6px;
					    width: 35px;
					    height: 35px;
					    display: inline-flex;
					    align-items: center;
					    justify-content: center;
					    border-radius: 100px;
					}
				}	
			}	
		}
	}
}
.SpeakerSponsorTbl {
	border: 1px var(--light-gray) solid;
	padding: 15px 20px;
	border-radius: 12px;
	margin: 10px 0 0;
	.EventSponsor {
		margin: 0px;
		tbody {
			tr {
			    background-color: var(--light-gray);
			    margin: 0 0 10px;
			    display: table;
				border-radius: 10px;
				width: 100%;
			    &:last-child {
			    	margin-bottom: 0px;
			    }
				td {
					color: var(--gray-dark);
				    font-weight: 500;
				    font-size: 16px;
				    position: relative;
				    padding: 12px 10px !important;
				    letter-spacing: 0.4px;
				    width: 25%;
				    line-height: 24px;
					word-break: break-all;
					.sponsorBgImage {
						display: inline-block;
						margin-right: 24px;
						text-align: center;
						min-width: 110px;
					}
				    p {
					    margin: 0;
					    display: flex;
					    align-items: center;
					    min-height: 58px;
					    font-size: 20px;
					    font-weight: 600;
					}
					&:first-child {
						width: 3%;	
						text-align: center;
					}
					&:nth-child(2) {
						img {
							
						    border-radius: 10px;
						    display: inline-block;
						    width: auto;
							height: 60px;
						}	
					}
					&:last-child {
						width: 7%;
						text-align: right;
					}
					.ActionIcon {
					    background-color: #fff;
					    margin: 0 6px;
					    width: 35px;
					    height: 35px;
					    display: inline-flex;
					    align-items: center;
					    justify-content: center;
					    border-radius: 100px;
					}
				}	
			}	
		}
	}
}
.WithAddMore {
	display: grid;
	place-content: center space-between;
	grid-auto-flow: column;
	align-items: center;
	margin: 0 0 10px;
	.HeadingStatic {
		grid-auto-flow: column;
	}
	.AddNewFact {
		grid-auto-flow: column;
	}	
}
.EventTiers {
	.HeadingTiers {
		h2 {
			font-weight: bold;
			font-size: 16px;
			margin: 0 0 16px;
			letter-spacing: 0.5px;
		}
	}
}

.ThemePopup {
	.modal-dialog {
		max-width: 75%;
		.modal-content {
			.modal-header {
				padding: 0px;
				.ModalCloseBtn {
					z-index: 8;
				}
			}
		}
	}
}
.FAQNewQuestion {
	margin: 28px 0 28px;
	.FAQlistADD {
	    border: 1px var(--light-gray) solid;
	    border-radius: 10px;
	    padding: 26px;
		.fAQnewData {
			display: grid;
		    place-content: center space-between;
		    grid-auto-flow: column;
		    align-items: center;
		    margin: 0 0 18px;
		    padding: 0 0 18px;
		    border-bottom: 1px var(--light-gray) solid;
		    &:last-child {
				border: 0;
			    margin: 0;
			    padding: 0;	
			}
			.FAQuestionAns {
				grid-auto-flow: column;
				padding-right: 8%;
				h3 {
					font-size: 17px;
				    font-weight: 600;
				    color: var(--dark);
				}
				p {
					font-size: 15px;
				    font-weight: 500;
				    color: var(--dark);
				}
			}
			.FAQAction {
				grid-auto-flow: column;
				.ActionIcon {
				    background-color: var(--light-gray);
				    margin: 0 6px;
				    width: 35px;
				    height: 35px;
				    display: inline-flex;
				    align-items: center;
				    justify-content: center;
				    border-radius: 100px;
				}
			}
				
		}
		
	}
}

.InfoInputTable {
	margin: 0px;
	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;
		    padding-bottom: 6px;
		    margin-bottom: 0;
		    &:last-child {
		    	border: 0px !important;
    			padding: 0px !important;
		    }
			td {
				text-align: center;
				padding: 20px 18px;
				&:first-child {
					vertical-align: middle;
					width: 62px;
					.fieldTypeAction {
						width: 20px;
					}
				}
				
				.IconChecked {
					margin: 0px;
					label {
						&:before {
							background-color: #e8e8e8 !important	
						}
						span {
							&:after {
								content: "";
							    display: block;
							    position: absolute;
							    top: 4px;
							    left: 8px;
							    width: 6px;
							    height: 11px;
							    border: solid #ffffff;
							    border-width: 0 2px 2px 0;
							    transform: rotate(45deg);	
							}	
						}
					}	
				}
				label {
						text-align: left;	
						margin: 0 0 12px;
					}
				.fieldTypeAction {
					label {
						text-align: center;	
						color: #222222;
					    font-weight: 400;
					    font-size: 13px;
					    margin: 0 0 12px;
					}
					.ActionIcon {
						background-color: var(--light-gray);
					    margin: 0 6px;
					    width: 35px;
					    height: 35px;
					    display: inline-flex;
					    align-items: center;
					    justify-content: center;
					    border-radius: 100px;
					}
				}
				&:nth-child(2) {
					width:35%;
				}
				&:nth-child(3) {
					width:35%;
				}
			}	
		}	
		
	}	
}
.InfoInputTable .IconChecked input:checked + label span {
    display: none;
}
.InfoInputTable .IconChecked input:checked + label:before {
    background-color: #2f73f7 !important;
    border-color: transparent !important;
}







.DisclaimerTable {
	margin: 0px;
	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;
		    padding-bottom: 6px;
		    margin-bottom: 0;
		    &:last-child {
		    	border: 0px !important;
    			padding: 0px !important;
		    }
			td {
				text-align: center;
				padding: 20px 18px;
				
				.IconChecked {
					margin: 0px;
					label {
						&:before {
							background-color: #e8e8e8 !important	
						}
						span {
							&:after {
								content: "";
							    display: block;
							    position: absolute;
							    top: 4px;
							    left: 8px;
							    width: 6px;
							    height: 11px;
							    border: solid #ffffff;
							    border-width: 0 2px 2px 0;
							    transform: rotate(45deg);	
							}	
						}
					}	
				}
				label {
						text-align: left;	
						margin: 0 0 12px;
					}
				.fieldTypeAction {
					label {
						text-align: center;	
						color: #222222;
					    font-weight: 400;
					    font-size: 13px;
					    margin: 0 0 12px;
					}
					.ActionIcon {
						background-color: var(--light-gray);
					    margin: 0 6px;
					    width: 35px;
					    height: 35px;
					    display: inline-flex;
					    align-items: center;
					    justify-content: center;
					    border-radius: 100px;
					}
				}
				&:first-child {
					vertical-align: middle;
					padding-bottom: 25px;
					width: 6%;
					padding: 0 0 24px;
				}
				&:nth-child(2) {
					width: 60%;
				}
				&:nth-child(3) {
					width: 22%;
				}
				&:nth-child(4) {
					width: 22%;
				}
				&:first-last-child {
					vertical-align: middle;
					width:15%;
				}
			}	
		}	
		
	}	
}
.DisclaimerTable .IconChecked input:checked + label span {
    display: none;
}
.DisclaimerTable .IconChecked input:checked + label:before {
    background-color: #2f73f7 !important;
    border-color: transparent !important;
}









.AttendeePrior {
	.TextToolTip {
		width: 100%;
		.TooltopHover {
			margin-left: 0px !important;
			margin-right: 8px !important;
		}	
		.SelectPriorHere {
			width: 200px;
    		display: inline-block;	
    		margin-left: 15px;
		}
	}
}

.DesignColorPicker {
	.colorCodePx {
		width: 160px !important;	
	}	
}
.LogoBgUploadIn {
    margin: 38px 0 18px;
}


.uploadFileSlider {
	.slick-slider {
		.slick-list {
			.slick-track {
				margin: 0px !important;
				.UploaderImgRow {
					padding: 5px 10px;
				}
			}
		}
		.slick-prev {
			fill: var(--gray);
			position: absolute;
			bottom: -50px;
			right: 16px;
			top: inherit;
			left: inherit;
			z-index: 5;
			width: 16px;
    		height: 16px;
		}
		.slick-next {
			fill: var(--gray);
			position: absolute;
			bottom: -50px;
			right: 0px;
			top: inherit;
			left: inherit;
			z-index: 5;
			width: 16px;
    		height: 16px;
		}
	}
}
.RegisterBoothInfo {
	margin: 24px 0 32px;
	.BoothSetupHeight  {
		min-height: 114px !important;
	}
}
.MainNavYour {
	.tab-pane {
		.EventDescript {
			.form-control {
				height: 150px !important;
			}
		}
	}
}
.minHeightBox .UploaderFiles .UploadLabel video {
	width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.HeadingStageTxt h5 {
    color: var(--blue);
    font-weight: 600;
    font-size: 20px;
    /* letter-spacing: 0.5px; */
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    line-height: 30px;
}

.PoperSearchBar {
    display: inline-block;
    vertical-align: middle;
}
.AddSpekrRt {
	float: right;
}
.minHeightImgs {
    border: 1px;
    border-color: #1A4788;
    border-style: dashed;
    height: auto !important;
    min-height: auto !important;
    border-radius: 10px;
    margin: 5px 0 0 !important;
    aspect-ratio: 16/9;
	.UploaderFiles {
		.UploadLabel {
			font-weight: 600;
			font-size: 11px;
			line-height: 17px;
			margin: 0px !important;
    		padding: 0px !important;
			span {
				color: var(--blue);
			}
			.img-fluid {
				width: 45px;
				margin-bottom: 8px;
			}
		}
	}
}
.uploadBrowser {
	flex: 0 0 360px !important;
    max-width: 360px !important;
}
.NetworkingSetup .dzu-previewFileName {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.spinner:before {
    width: 13px !important;
    height: 13px !important;
    top: 4px !important;
}
.FilesRules {
		padding: 22px 22px;
		border: 1px #707070 solid;
		border-radius: 12px;
		margin: 22px 0 8px 80px;
	ul {
		margin: 0px;
    	padding-left: 24px;
		li {
			list-style: decimal;
			font-size: 13px;
			margin-bottom: 16px;
			color: var(--dark);
		}
	}
	h3 {
		color: var(--dark);
		font-weight: 600;
		font-size: 18px;
		margin: 0 0 32px;
	}
	h4 {
		font-weight: bold;
		font-size: 14px;
		margin: 24px 0 0 12px;
		a {
			color: var(--blue-color);
			text-decoration: underline;
		}
	}
}
.UploadActionInvite {
	display: flex;
    flex-direction: column;
	.CommonGtBtn {
		width: 100% !important;
		margin-bottom: 14px !important;
	}
	.WhiteBtn {
		width: 100% !important;
	}
}
.tab-pane {
	.UploadManullyInvite {
		margin-top: 32px;
		h4 {
			position: relative;
			color: var(--dark);
			font-weight: 600;
			font-size: 18px;
			margin-bottom: 30px;
			span {
				background: #fff;
				position: relative;
				z-index: 5;
				padding-right: 22px;
			}
			&::after {
				position: absolute;
				content: "";
				width: 100%;
				height: 1px;
				background: #f1f1f1;
				left: 0;
				top: 50%;
				z-index: 0;
			}
		}
		.form-group {
			position: initial;
			label {
				position: initial;
				color: var(--dark);
				font-size: 12px;
				margin: 0 0 13px;
			}
			.MuiFormControl-root {
				padding: 10px 14px;
				.MuiInputBase-root {
					textarea {
						font-size: 12px;
						height: 20px;
					}
				}
			}
		}
	}
}
.InviteSpeakerBox {
    padding-right: 112px;
}
.BoothInnerSetup {
	.BoothHostTag {
		margin: 0 0 30px;
		.stateForm {
			color: #000;
			.form-control {
				padding: 0px 12px !important;
    			height: 48px;
				input {
					color: #000;
				}
				&:after {
					display: none;
				}
			}
		}
	}
}
.ModalZoneMixhubb {
	.EventDescript {
		.form-control {
			textarea {
				height: 60px !important;
				padding: 10px 0px 0 !important;
			}
		}
	}
}
.CreateEventDescrib {
	.EventDescript {
		.form-control {
			textarea {
				height: 60px !important;
				padding: 10px 0px 0 !important;
			}
		}
	}
}
.text-danger {
    font-size: 11px !important;
}
.ExpoAddZones {
    margin: 32px 0 28px;
}



.ZoneTypeBooth {
	margin-top: 30px;
	.ZoonEventPg {
		border: 1px #E4E6EF solid;
		padding: 20px;
		border-radius: 10px;
		position: relative;
		margin: 0 0 22px;
		.ZoneQtn {
			display: inline-block;
			background-color: #efeeee;
			padding: 3px 12px;
			border-radius: 100px;
			font-weight: 600;
			margin: 0 0 14px;
		}
		h4 {
			font-weight: 600;
			font-size: 18px;
			padding-right: 54px;
			line-height: 24px;
			letter-spacing: .1px;
			margin-bottom: 10px;
		}

		p {
			font-size: 13px;
			font-weight: normal;
			color: #000;
			padding-right: 54px;
			line-height: 18px;
			margin-bottom: 15px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;

		}

		.Talk-Speakers {
			width: 100%;

			.btn {
				margin-right: 8px;
				background-color: #f1f1f1;
				border: 1px #f1f1f1 solid !important;
			}
		}
		.EnterStage {
			button {
				display: contents;
			}
		}

	}
}
.EditIconModal {
	vertical-align: middle;
    position: relative;
    background: #f1f1f1;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    overflow: hidden;
	padding: 0px 6px;
	.EditZoneModal {
		display: contents;
	
	}
}
.scrolltop .svg-icon svg g [fill] {
    opacity: 1;
}

.EditEventArea  {
	.form-control {
		&::after {
			display: none;
		}
		&::before {
			display: none;
		}
		textarea {
			padding: 5px 0px 0 !important;
			height: 110px;
		}
	}
	.gradientBg {
		background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
		color: var(--white);
		font-size: 15px;
		text-transform: capitalize;
		font-family: "Segoe UI" !important;
		font-weight: bold;
	}
}
.ploaFileStage {
	.minHeightBox {
		.default-image-selected {
			.mixhubb-uploader-label {
				width: 100%;
			}
		}
	}
}
.ploaFileStage .minHeightBox .default-image-selected .UploadLabel .uploadPreviewIcon {
	right: 18px !important;
}
.updateCoverDesign {
	padding-right: 60px !important;
}
.DesginLogoBgUpload {
    margin: 30px 0 0 !important;
}
.CompanyLogoUpload {
	padding: 0 30px !important;
}
.UpdateEventLogoDegns {
	padding-left: 60px !important;
}
.ZoneFillDetail {
    border: 1px #e8e8e8 solid;
    padding: 15px 22px;
    border-radius: 10px;
	h5 {
		font-size: 16px;
		line-height: 19px;
		font-weight: 600;
	}
	p {
		font-size: 14px;
		margin: 0;
	}
}
.hide-group {
    display: block;
    margin-bottom: 20px;
}
.hide-group input:checked + label:after {
    border: solid #2f73f7;
    border-width: 0 2px 2px 0;
}
.hide-group input:checked + label:before {
    background-color: #fff !important;
    border-color: #2f73f7 !important;
}


.EventLogoupload {
	padding-right: 60px !important;
}
.BreakerCreativeupload {
	padding-left: 60px !important;
}

.tab-pane  .SegmentTags .stateForm .form-group label {
	top: 10px;
}
.tab-pane  .SegmentTags .stateForm .form-group .MuiInputBase-root {
	padding-top: 22px !important;
	padding-left: 10px !important;
	padding-right: 60px !important;
}
.tab-pane .SegmentTags .stateForm .form-group .MuiInputBase-root .MuiChip-root {
	height: 22px !important;
}
.EventPageInfos {
	margin-top: 0px;
}

.TagsWebsite {
	.stateForm {
		.OwnersBoothTags {
			border: 0;
			padding: 0px 0px;
			box-shadow: none;
			border-radius: 5px;

		}
			div {
				.MuiButtonBase-root {
					color: #222;
					opacity: 1;
					visibility: visible;
				}
			}
			.MuiInputBase-root {
				input {
					padding: 6px 0;
				}
				#tags-outlined {
					padding: 9.5px 4px;
				}
				.MuiOutlinedInput-notchedOutline {
					border-color: rgba(0, 0, 0, 0.23);
					&:hover {
						border-color: rgba(0, 0, 0, 0.23);
					}
					&:focus {
						border-color:  rgba(0, 0, 0, 0.23);
					}
				}
			}
			.MuiInputBase-root {
				padding: 4px !important;
				overflow: hidden;
			}
			.MuiAutocomplete-clearIndicator {
				.MuiIconButton-label {
					border-right: 1px #222 solid;
					padding-right: 5px;
				}
			}
	}
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-width: 1px !important;
}
.MuiFormLabel-root.Mui-focused {
	color: #222222
}
.MuiPickersDay-daySelected:hover {
	color: #fff !important;
    background-color: #3367e3 !important;
}
.IconPloaDir .minHeightBox .default-image-selected .UploadLabel .uploadPreviewIcon{
	right: 45px !important;
}
.AllowEmojis {
	padding: 15px !important;
}
.AddSponsorUpload {
	flex: 0 0 340px !important;
	max-width: 340px !important;
}
.PrimaryWidgetLobby {
	padding: 0 0px;
	background-color: #000;
}
.WidgetBrowseList {
	background-color: var(--light-gray);
	overflow: hidden;
	height: 100%;
	.items-parent {
		.element-choices {
			.MuiPaper-root {
				grid-gap: 0;
				margin: 0 0 6px !important;
				box-shadow: none !important;
				background-color: var(--light-gray) !important;
				&::before {
					display: none;
				} 
				.MuiAccordionSummary-root {
					background-color: var(--primary-gray);
					min-height: 42px;
					padding: 0 18px;
					border-left: 3px var(--primary-gray) solid;
					&.Mui-expanded {
						border-left: 3px var(--blue) solid;
					}
					.MuiAccordionSummary-content {
						margin: 12px 0 !important;
						color: var(--blue);
						font-size: 15px;
						font-weight: 600;
					}
				}
			}
			.MuiCollapse-wrapper {
				.MuiAccordionDetails-root {
					display: inline-block;
					width: 100%;
					text-align: left;
					padding: 8px 16px 8px;
					.MuiButtonBase-root {
						width: 100%;
						background-color: transparent;
						text-align: left;
						padding: 15px 0 2px;
						.MuiButton-label {
							display: block;
							margin-right: 12px;
							font-size: 12px;
							font-weight: 500;
							.MuiButton-startIcon {
								width: 26px;
								height: 26px;
								display: inline-block;
								img {
									height: 100%;
    								width: 100%;
								}
							}
						}
						&:hover {
							background-color: transparent;
							outline: 0;
							box-shadow: none;
						}
						&:focus {
							background-color: transparent;
							outline: 0;
							box-shadow: none;
						}
					}
				}
			}
		}
	}
	.nav-tabs {
		width: 100%;
		display: block;
		margin-bottom: 10px;
		.nav-item {
			width: 50%;
			display: inline-block;
			.nav-link {
				width: 100%;
				border-radius: 0 !important;
				&:hover {
					box-shadow: none;
					outline: 0;
				}
				&:focus {
					box-shadow: none;
					outline: 0;
				}
			}
		}
	}
}
button .MuiTouchRipple-root  {
	display: none;
}
.ListBgUrl {
	.ListActionInfo {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 16px;
		h4 {
			margin: 0;
			font-size: 14px;
			font-weight: 600;
			svg {
				width: 24px;
				height: 24px;
				margin-right: 12px;
			}
		}
		.ActionListRt {
			span {
				margin: 0 5px;
				background-color: #fff;
				display: inline-flex;
				width: 26px;
				height: 26px;
				align-items: center;
				justify-content: center;
				border-radius: 100px;
				padding: 4px;
				cursor: pointer;
				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}




.OtherWidgets {
	padding: 0 20px;
    overflow-y: auto;
    height: calc(100vh - 80px);
	.widget-inputs-wrapper {
		.form-group {
			label {
				font-size: 13px;
				color: #444;
				font-weight: 400;
			}
			.form-control {
				background-color: #fff;
				border-radius: 0;
				padding: 0 14px;
				height: 42px;
				width: 100%;
				.MuiInput-formControl {
					height: 100%;
					&::before {
						display: none;
					}
					&::after {
						display: none;
					}
				}
			}
			.WidgetListInput {
				.MuiFormControl-root {
					display: block;
					.MuiInput-formControl {
						position: relative;
						z-index: 6;
						svg {
							display: none;
						}
						div {
							&:hover {
								background-color: transparent;
							}
							&:focus {
								background-color: transparent;
							}
						}
					}
					svg {
						position: absolute;
						right: 16px;
						top: 18px;
						z-index: 3;
					}
				}
			}
		}
	}
}
.CheckBoxEnable {
	margin: 0 0 18px;
	input {
		width: 16px;
		height: 16px;
		vertical-align: middle;
		margin-right: 10px;
	}
	label {
		color: #2d2d2d;
		font-size: 13px;
		margin: 0;
	}
}
.FooterButton {
	text-align: center;
	margin: 30px 0 0;
	.CommonGtBtn {
		min-width: 45% !important;
		padding: 12px 0 !important;
		margin-right: 17px;
	}
	.BlueWhiteBtn {
		min-width: 45% !important;
		padding: 10px 0 !important;
	}
}
.AddImgMore {
	margin: 20px 0 40px;
	.AddMore {
		background-color: #ffffff;
		border: 1px #9c9c9c solid;
		/* display: table; */
		margin: auto;
		border-radius: 0;
		padding: 10px 20px;
		&:hover {
			background-color: #ffffff;
		}
		&:hover {
			background-color: #ffffff;
		}
		svg {
			fill: #737376;
			width: 22px;
			height: 22px;
		}
	}
}
.mx-ticker-wrapper {
	marquee {
		height: 50px;
    	padding: 14px 0;
	}
}

.EditFileModal {
	max-width: 66.666667% !important;
    flex-basis: 66.666667% !important;
}
#myTabContent .tab-pane .SelectImgs {
	margin: 10px 0 10px;
}
.OtherWidgets {
	.widget-inputs-wrapper {
		.carouselBlock {
			background: #e4e3e3;
			padding: 20px 12px;
			margin: 0 0 55px;
			border-radius: 4px;
			position: relative;
			.carouselImageBlock {
				.DelIcon {
					background: #e4e3e3;
					position: absolute;
					top: -38px;
					right: 0;
					padding: 10px 13px;
					z-index: 5;
					border-radius: 6px 6px 0px 0px;
				}
			}
			.CheckBoxEnable {
				margin: 0;
			}
		}
	}
}


.table.AllBoothTable {
	thead {
		position: relative !important;

		th {
			background-color: var(--light-gray) !important;
			color: var(--gray-dark) !important;
			text-transform: capitalize;
			font-size: 14px !important;
			font-weight: bold;

			&:first-child {
				padding-left: 40px !important;
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;

			td {
				color: var(--gray-dark);
				font-weight: 600;
				font-size: 13px;
				position: relative;
				padding: 22px 12px !important;
				letter-spacing: 0.4px;

				.dropdown-menu {
					.navi {
						&:after {
							content: "";
							height: 20px;
							width: 20px;
							position: absolute;
							background-color: white;
							top: -11px;
							right: 16%;
							border-top: #dcd8d8 solid 1px;
							border-left: #dcd8d8 solid 1px;
							transform: rotate(45deg);
							z-index: -1;
						}

						.navi-item {
							span {
								height: auto !important;
								padding: 0px !important;
							}
						}
					}
				}

				img {
					margin-right: 12px;
					float: left;
					display: inline-block;
					width: 60px;
				}

				p {
					margin: 0;
					display: flex;
					align-items: center;
					min-height: 40px;
				}

				&:first-child {
					padding-left: 40px !important;
					width: 185px;
					line-height: 18px;
					padding-right: 40px !important;
				}
				&:last-child {
					width: 30%;
				}

				.btn {
					width: auto !important;

					img {
						margin: 0px !important;
						width: auto;
					}
				}

				.dropdown-menu.dropdown-menu-sm {
					border: 1px #dcd8d8 solid;
					top: 12px !important;
					padding: 0 0 !important;
					margin-left: 28px !important;

					&:after {
						content: "";

					}

					.navi-item {
						.navi-link {
							padding: 4px 16px;
						}

						&:first-child {
							margin-top: 5px;
						}

						&:last-child {
							margin-bottom: 5px;
						}
					}
				}
				.ImgLogo {
					height: 42px;
					float: left;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				
			}
		}
	}
}
.table.AllBoothTable tbody tr td .check-group input:checked + label:before {
	background-color: transparent;
	border: 2px #2f73f7 solid;
	border-radius: 2px;
	padding: 9px;
}
.table.AllBoothTable tbody tr td .check-group input:checked + label:after {
	border-color: #2f73f7;
	top: 4px;
    left: 8px;
}
.table.AllBoothTable tbody tr td .check-group label:before {
    background-color: transparent;
	border: 2px #2f73f7 solid;
	border-radius: 2px;
	padding: 9px;
}


.table.AllBoothTable thead th .check-group input:checked + label:before {
	background-color: transparent;
	border: 2px #2f73f7 solid;
	border-radius: 2px;
	padding: 9px;
}
.table.AllBoothTable thead th .check-group input:checked + label:after {
	border-color: #2f73f7;
	top: 6px;
    left: 8px;
}
.table.AllBoothTable thead th .check-group label:before {
    background-color: transparent;
	border: 2px #2f73f7 solid;
	border-radius: 2px;
	padding: 9px;
}
.table.AllBoothTable thead th .check-group, .table.AllBoothTable tbody tr td .check-group {
	margin: 0;
}
.BoothMoveTo {
	// padding: 0rem 2.25rem;
	.customLg-3 {
		flex: 0 0 300px;
		max-width: 300px;
		label {
			font-weight: 500;
		}
		.ZoneSelectBooths {
			position: relative;
			border: 1px #737376 solid;
			border-radius: 100px;
			padding: 1px 5px;
			margin-top: 8px;
			.form-control {
				width: calc(100% - 95px);
				background: transparent;
				position: relative;
				padding: 6px 6px 6px 10px;
				select {
					height: 40px;
					padding: 0;
					padding-right: 24px !important;
					background-color: transparent;
					overflow: hidden !important;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
				svg {
					display: none;
				}
				&::after {
					content: "";
					display: block;
					width: 10px;
					height: 10px;
					border: 0;
					border-top: 2px solid #737376;
					border-right: 2px solid #737376;
					transform: rotate(135deg);
					position: absolute;
					right: 12px;
					top: 12px;
					left: auto;
				}
			}
			.CommonGtBtn {
				position: absolute;
				right: 4px;
				margin-right: 0px !important;
				top: 3px;
				border-radius: 100px;
				padding: 5px 28px !important;
			}
		}
	}
}

.ScheduleCalanderForms {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	overflow: auto;
	width: 100%;
	height: 100%;
	padding-bottom: 30px;
}
.TooltopHover {
	cursor: pointer;
	svg {
		color: #a19c9c;
	}
}
.Upload-Max-View {
	flex: 0 0 329px;
    max-width: 329px;
}
.All_New_Added_File {
	margin-top: 12px;
	ul {
		padding: 0;
		margin: 0;
		li {
			background-color: #f1f1f1;
			padding: 8px 15px;
			display: inline-flex;
			align-items: center;
			position: relative;
			margin: 5px 5px;
			span {
				margin-left: 14px;
				background-color: #f00;
				width: 26px;
				height: 26px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 5px;
				border-radius: 4px;
				cursor: pointer;
				svg {
					color: #fff;
					fill: #fff;
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}
.FreeTrailPlan {
	padding-bottom: 130px;
	.Trial-lg-fix {
		flex: 0 0 75%;
    	max-width: 75%;
	}
	.TrialCharges {
		margin-bottom: 28px;
		h2 {
			color: var(--blue);
			font-size: 28px;
			font-weight: 600;
			letter-spacing: 0.5px;
			margin-bottom: 18px;
		}
		p {
			color: var(--dark);
			font-size: 16px;
			letter-spacing: .3px;
			margin: 0;
		}
	}
	.PlanEventChargesTable {
		.Plan-and-Addons {
			width: 100%;
			float: left;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.PlanTypeChoose {
				float: left;
				h3 {
					color: #007bff;
					font-weight: 600;
					font-size: 22px;
					margin: 20px 0 20px;
				}
			}
			.Add-Here-Addons {
				float: right;
				.Add-Addons-Btn {
					background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
						color: #ffffff;
						font-weight: 600;
						font-size: 14px;
						padding: 6px 18px;
						text-transform: capitalize;
				}
			}
		}
		.MainPlanTrial {
			background-color: #fafafa;
			border: 2px #007bff solid;
			border-radius: 10px;
			padding: 22px;
			margin-bottom: 20px;
			min-height: 485px;
			position: relative;
			//cursor: pointer;
			.PlanCoastTrial {
				border-bottom: 1px #e3e3e3 solid;
    			margin-bottom: 14px;
				h3 {
					font-size: 20px;
					text-transform: uppercase;
					font-weight: bold;
					margin-bottom: 5px;
					color: #181c32;
				}
				p {
					font-size: 14px;
					color: #181c32;
					margin-bottom: 10px;
					font-weight: normal;
				}
				h2 {
					color: #007bff;
					font-weight: bold;
					text-transform: uppercase;
					font-size: 22px;
					margin-bottom: 12px;
				}
				span {
					color: #181c32;
					text-transform: capitalize;
					font-weight: 600;
					font-size: 14px;
					letter-spacing: .1px;
				}				
			}
			.EP-Plan {
				padding: 5px 0 20px;
				text-align: center;
				p {
					margin: 0;
				}
			}
			.PlanListTrial {
				padding-bottom: 55px;
				ul {
					padding: 0px;
					margin: 0px;
					li {
						display: block;
						font-weight: 600;
						font-size: 14px;
						margin: 0 0 6px;
					}
				}
				h6 {
					color: #007bff;
					text-transform: uppercase;
					font-weight: 600;
					font-size: 12px;
					margin: 14px 0 12px;
				}
				p {
					font-size: 12.5px;
					color: #181c32;
					margin-bottom: 6px;
					font-weight: normal;
				}
			}
			.subscribeBtn {
				bottom: 20px;
				position: absolute;
				width: 88%;
				left: 0;
				margin: 0px auto;
				right: 0;
				.subscribeNow {
					text-transform: capitalize;
					background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
					color: var(--white);
					font-size: 14px;
					padding: 8px 12px;
					width: 100%;
					font-weight: 600;
				}
			}

			.EnterprisePlan {
				text-align: center;
				padding: 30px 0 30px;
			}
			.CustomRequire {
				text-align: center;
				h3 {
					color: #5c5bfb;
					font-weight: 600;
					font-size: 16px;
				}
				p {
					font-size: 13px;
					font-weight: 600;
					color: #424242;
					margin-bottom: 0px;
				}
			}
		}		
	}




	.TrialUpgradePlan {
		margin-bottom: 30px;
		border: 2px #f1f1f1 solid;
		border-radius: 10px;
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			font-size: 14px;
			font-weight: 600;
			color: #737376;
			margin-bottom: 0px;
		}
		h2 {
			color: #007bff;
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 6px;
		}
		h6 {
			font-size: 14px;
			font-weight: bold;
			color: #737376;
			margin-bottom: 6px;
		}
		small {
			font-size: 12px;
			font-weight: 600;
			color: #737376;
			margin-bottom: 0px;
			a {
				color: #5c5bfb;
				margin-left: 6px;
				svg {
					fill: #5c5bfb;
					width: 12px;
					height: 12px;
					margin-left: 5px;
				}
			}
		}
		.CraditBtnInfo {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			p {
				font-size: 12px;
			}
			.CraditBtnBlue {
				border: 1px var(--blue) solid;
				font-weight: 600;
				padding: 4px 12px;
				color: var(--blue);
				font-size: 12px;
				letter-spacing: 0.3px;
				text-transform: capitalize;
				margin-bottom: 10px;
				&:hover {
					background-color: transparent !important;
				}
				&:focus {
					background-color: transparent !important;
				}
			}
			.CancelBtnBlue {
				color: #5C5BFB;
				background-color: transparent;
				border: 0px;
				text-transform: capitalize;
				&:hover {
					background-color: transparent !important;
				}
				&:focus {
					background-color: transparent !important;
				}
			}
		}
	}
}
.Select-AddonsMx {
	h3 {
		color: #007bff;
		font-weight: 600;
		font-size: 22px;
		margin: 20px 0 20px;
	}
	.AddonsTable {
		border: 2px #f1f1f1 solid;
		border-radius: 10px;
		padding: 0px 20px 20px;
		.table-responsive {
			table.AddonsPreTables {
				border-collapse:separate; 
  				border-spacing: 0 1.5em;
				thead {
					position: relative !important;
					th {
						background-color: #fff !important;
						color: #3f4254 !important;
						text-transform: capitalize;
						font-size: 18px !important;
						font-weight: bold;
						text-align: center;
						&:first-child {
							text-align: left;
							padding-left: 20px;
						}
					}
				}
				tbody {
					tr {
						background-color: #f1f1f1;
    					border-radius: 10px;
						td:first-child {
							border-radius: 10px 0 0px 10px;
						}
						td {
							color: #3f4254;
							font-weight: 600;
							font-size: 18px;
							position: relative;
							padding: 20px 22px !important;
							letter-spacing: 0.4px;
							width: 25%;
							line-height: 16px;
							text-align: center;
							&:first-child {
								text-align: left;
							}
						}
						td:last-child {
							border-radius: 0 10px 10px 0px;
						}
					}
				}
			}
		}
	}
}
.FinalEventPlan {
	position: fixed;
    bottom: 0;
    width: 86.2%;
    right: 0;
    background-color: #2f73f7;
    padding: 10px 0;
	.MonthlyAddonchargs {
		width: 100%;
		float: left;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.EventMonthlyInr {
			float: left;
			p {
				color: #fff;
				font-weight: 600;
				font-size: 20px;
				margin: 0 0 4px;
			}
			h2 {
				color: #fff;
				font-weight: bold;
				font-size: 28px;
				margin: 0 0 4px;
			}
		}
		.Continue {
			float: right;
			.btnContinue {
				background-color: #fff;
				color: #2f73f7;
				font-weight: 700;
				font-size: 18px;
				padding: 6px 18px;
			}
		}
	}
}
.AddonsQty {
	text-align: center;
	button {
		background: transparent;
		border: 0px;
		svg {
			width: 18px;
			height: 18px;
		}
	}
	input {
		background-color: #fff;
		border: 0px;
		width: 70px;
		padding: 10px 0;
		text-align: center;
		font-size: 18px;
		border-radius: 3px;
		margin: 0 12px;
		font-weight: 600;
		&:hover {
			outline: none;
			border: 0;
			box-shadow: none;
		}
		&:focus {
			outline: none;
			border: 0;
			box-shadow: none;
		}
	}
}
.table.teamsMixTables {
	thead {
		position: relative !important;

		th {
			background-color: var(--light-gray) !important;
			color: var(--gray-dark) !important;
			text-transform: capitalize;
			font-size: 14px !important;
			font-weight: bold;
			width: 20%;
			&:first-child {
				padding-left: 15px !important;
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;

			td {
				color: var(--gray-dark);
				font-weight: 600;
				font-size: 13px;
				position: relative;
				padding: 22px 12px !important;
				letter-spacing: 0.4px;
				line-height: 16px;

				.dropdown-menu {
					.navi {
						&:after {
							content: "";
							height: 20px;
							width: 20px;
							position: absolute;
							background-color: white;
							top: -11px;
							right: 16%;
							border-top: #dcd8d8 solid 1px;
							border-left: #dcd8d8 solid 1px;
							transform: rotate(45deg);
							z-index: -1;
						}

						.navi-item {
							span {
								height: auto !important;
								padding: 0px !important;
							}
						}
					}
				}
				img {
					margin-right: 12px;
					width: 50px;
					height: 50px;
					border-radius: 100px;
					float: left;
					display: inline-block;
				}

				p {
					margin: 0;
					display: flex;
					align-items: self-start;
					min-height: 50px;
					flex-direction: column;
					text-align: left;
					justify-content: center;
					span {
						display: block;
						font-weight: 600;
						font-size: 11px;
						margin: 3px 0 0;
					}
				}

				&:nth-child(3) {
					width: 30%;
				}
				&:nth-child(4) {
					width: 12%;
				}
				&:last-child {
					width: 11%;
				}

				.btn {
					width: auto !important;

					img {
						margin: 0px !important;
						width: auto;
					}
				}

				.dropdown-menu.dropdown-menu-sm {
					border: 1px #dcd8d8 solid;
					top: 12px !important;
					padding: 0 0 !important;
					margin-left: 28px !important;

					&:after {
						content: "";

					}
				}
				.ActionSvg { 
					display: inline-block;
					cursor: pointer;
					.ActionIcon {
						display: inline-block;
						margin: 0 4px;
						img {
							width: auto;
							border-radius: 0px;
							margin: 0px;
						}
					}
					.EmailSvgIcon {
						width: 18px !important;
						height: 18px !important;
					}
					.ReportSvgIcon {
						width: 17px !important;
						height: 17px !important;
					}
					.DeleteSvgIcon {
						width: 16px  !important;
						height: 16px  !important;
					}
					.EditSvgIcon {
						width: 14px !important;
						height: 14px !important;
					}
				}
			}
		}
	}
}
.table.teamsMixTables tbody tr td .ActionIcon {
	display: inline-block;
	margin: 0 4px;
}
.orgName {
	display: flex;
    align-items: center;
	h3 {
		font-size: 20px;
		font-weight: 600;
		margin: 0;
	}
	a {
		color: #000;
	}
}
.OrganizationListAll {
	margin-top: 28px;
	.event-summary-all {
		border: 1px dashed #a7a3a3;
		background-color: #fdfdfd;
		text-align: center;
		height: auto;
		margin-bottom: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border-radius: 3px;
		box-shadow: 5px 5px 0px rgb(120 120 120 / 13%);
		padding: 12px 0;
    	height: 140px;
		&:hover {
			cursor: pointer;
		}
		
		h3 {
			color: #222;
			font-weight: 600;
			font-size: 20px;
		}
		p {
			color: #222;
			font-size: 14px;
			margin: 0;
			span {
				display: block;
			}
		}
	}
	.OrgActiveNow {
		border: 1px dashed #b550f9 !important;
    	background-color: #f2e8f9 !important;
	}
}
.optRequire {
	text-align: center;
}
.HeaderNofify {
	position: relative;
    display: flex;
    justify-content: space-between;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    // border-top: 1px solid #EBEDF3 !important;
    border-radius: 0.42rem;
    width: 100%;
	margin: 16px 0;
	.PoperSearchBar {
		display: inline-block;
		vertical-align: middle;
		margin: 0px !important;
		.MuiPaper-root {
			border:1px var(--gray-dark) solid;
			width: 250px;
			box-shadow: none !important;
			position: relative;
			padding: 4px 0;
			align-items: flex-start !important;
			.MuiInputBase-input {
				padding-left: 35px;
				color: #737376;
				font-weight: 400;
				padding-right: 10px;
				font-size: 13px;
			}
		}
		.NofifySearch {
			position: absolute;
			left: 6px;
			padding: 0;
			top: 5px;
			.MuiIconButton-label {
				svg {
					width: 25px;
					height: 25px;
					fill: #929294;
				}
			}
		}
	}
	.AddNofifications {
		.CommonGtBtn {
			width: 185px;
		}
	}
}
.NotifyInofsAdd {
	
	.row {
		.form-group {
			label {
				color: #222222;
				font-weight: 400;
				font-size: 13px;
			}
			.form-control {
				background: #F1F1F1;
				border: 0;
				box-shadow: none;
				padding: 6px 14px !important;
				.MuiInputBase-root {
					&::after {
						display: none;
					}
					&::before {
						display: none;
					}
				}
				textarea {
					padding: 6px 0 6px !important;
					height: 72px !important;
					margin: 0 !important;
					overflow-y: auto !important;
				}
				input {
					font-size: 12px;
    				color: #323232;
				}
			}
			textarea {
				height: 94px !important;
			}
			.LimitDivTxg {
				top: 0;
				color: #a7a4a4;
				font-family: Poppins, Helvetica, "sans-serif";
			}
			.eventInserted {
				.form-control {
					height: 95px !important;
				}
			}
		}
		.CreateNotifyForm {
			// margin: 10px 0 0;
    		padding: 14px 0 0px;
			.AccountNevent {
				text-transform: capitalize;
				background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
				color: var(--white);
				font-size: 14px;
				padding: 8px 42px;
			}
		}
	}
	.RedirectionCheck {
		margin: 12px 0 25px;
		input {
			vertical-align: middle;
			cursor: pointer;
		}
		span {
			top: 0;
			color: #565454;
			font-family: Poppins, Helvetica, "sans-serif";
			vertical-align: middle;
			margin: 1px 3px 0px;
			display: inline-block;
		}
	}
}
.NotifyTemplete {
	border: 1px #e0e0e0 solid;
	padding: 25px;
}
.DynamicKeyWords {
    border: 1px #e0e0e0 solid;
    padding: 15px;
    // height: calc(100vh - 197px);
    overflow-y: auto;
    overflow-x: hidden;
	.UseKeyword {
		margin: 5px 0 18px;
		h3 {
			font-size: 15px;
			font-weight: 600;
			margin: 0 0 5px;
		}
		p {
			font-size: 14px;
			font-weight: 500;
			margin: 0 0 2px;
		}
	}
	&::-webkit-scrollbar-track {
		border-radius: 80px;
		background-color: #999da2;
	}
	&::-webkit-scrollbar {
		width: 4px;
		background-color: #999da2;
		height: 7px;
		border-radius: 80px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 80px;
		background-color: #1a78e5 !important;
	}
}
input#ScheduleTimeDate::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
  div#menu-dial_code .MuiPaper-root {
    position: absolute;
    width: 90px;
    height: 290px;
}
.CommonModalPopup {
	.modal-dialog {
		.modal-content {
			.modal-body {
				.NotifyInofsAdd {
					.row {
						.form-group {
							textarea {
								height: 36px !important;
								padding: 0px !important;
								margin: 6px 0 !important;
							}
							.eventInserted {
								.form-control {
									height: 72px !important;
								}
							}
						}
						.CreateNotifyForm {
							// margin: 10px 0 0;
							border-top: 1px solid #EBEDF3;
							padding: 14px 0 0px;
							.AccountNevent {
								text-transform: capitalize;
								background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
								color: var(--white);
								font-size: 14px;
								padding: 8px 42px;
								width: 100%;
								margin: 0px !important;
							}
						}
					}
					.RedirectionCheck {
						margin: 12px 0 25px;
						input {
							vertical-align: middle;
							cursor: pointer;
						}
						span {
							top: 0;
							color: #565454;
							font-family: Poppins, Helvetica, "sans-serif";
							vertical-align: middle;
							margin: 1px 3px 0px;
							display: inline-block;
						}
					}
				}
			}
		}
	}
}



.CreateFormpopUp {
	width: 100%;
	padding: 14px 0 0px;
	.AccountNevent {
		text-transform: capitalize;
		background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
		color: var(--white);
		font-size: 14px;
		padding: 8px 42px;
		width: 100%;
		margin: 0px !important;
	}
}
.BackArrowNotify {
	display: inline-flex;
    width: 22px;
    height: 22px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
	button {
		background-color: transparent;
		border: 0;
		padding: 0;
		svg {
			width: 100%;
			height: 100%;
		}
	}
}
.RecordModalPopup {
	.modal-dialog {
		width: 100%;
		max-width: 75%;
		.modal-content {
			.modal-header {
				padding: 0px !important;
				.ModalCloseBtn {
					right: -8px !important;
					top: -8px !important;
					z-index: 99;
					background: #fff !important;
					box-shadow: 0px 0px 10px #0000004a;
					border-radius: 100px;
					svg {
						opacity: 1 !important;
					}
				}
			}
		}
	}
}
.FeedbackCreate {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    text-align: center;
	h4 {
		color: var(--blue);
		font-size: 2.4rem;
		font-weight: bold;
		margin: 0 0 32px;
		line-height: 48px;
	}
}
.top-heading-main {
	.NofifyH1 {
		h1 {
			font-size: 24px !important;
		}
	}
}
.FilterAllReports {
    clear: both;
    // border-bottom: 1px #bfbdbd dashed;
    // margin-bottom: 28px;
	.filterReportTable {
		.form-control {
			border: 0;
			background-color: #F1F1F1;
			height: 45px;
			&::before {
				display: none;
			}
			&::after {
				display: none;
			}
			.MuiSelect-select {
				&:focus {
					background-color: transparent;
				}
			}
		}
	}
	.filterDatePic{
		.form-control {
			border: 0;
			background-color: #F1F1F1;
			height: 45px !important;
			margin-top: 0px !important;
			margin-bottom: 0px !important;
			justify-content: center;
			padding-left: 15px;
			.MuiInputBase-root {
				&::before {
					display: none;
				}
				&::after {
					display: none;
				}
				.MuiSelect-select {
					&:focus {
						background-color: transparent;
					}
				}
			}
		}
	}
}
.ReportTabsInfosBox {
	.report-tab {
		border: 1px #d9d9d9 solid;
		margin-bottom: 0px;
		padding: 20px 20px;
		box-shadow: 0px 0px 10px rgb(0 0 0 / 17%);
	}
	.nav-tabs {
		margin: 0 0 30px;
	}
	.ListHeaderFour {
		.card-header {
			margin: 0 0 0 !important;
			padding: 0px 0 0 !important;
		}
	}
}

.table.ReportsTables{
	thead {
		position: relative !important;
		tr {
			th {
				background-color: #F1F1F1 !important;
				background-color: var(--light-gray) !important;
				color: #3f4254 !important;
				text-transform: capitalize;
				font-size: 15px !important;
				font-weight: 600;
				padding: 12px 12px !important;
				width: 50%;
			}
		}
	}
	tbody {
		tr {
			border-bottom: 1px #F1F1F1 solid;
			td {
				color: #3f4254;
				font-weight: 600;
				font-size: 14px;
				position: relative;
				padding: 18px 12px !important;
				letter-spacing: 0.4px;
				width: 50%;
				line-height: 16px;
			}
			&:nth-child(even) {background: #fbfbfb}
			&:nth-child(odd) {background: #ffffff}
		}
	}
}


.table.StreamingHour{
	thead {
		position: relative !important;
		tr {
			th {
				background-color: #F1F1F1 !important;
				background-color: var(--light-gray) !important;
				color: #3f4254 !important;
				text-transform: capitalize;
				font-size: 15px !important;
				font-weight: 600;
				padding: 16px 12px !important;
				width: 33.3333%;
			}
		}
	}
	tbody {
		tr {
			border-bottom: 1px #F1F1F1 solid;
			td {
				color: #3f4254;
				font-weight: 600;
				font-size: 14px;
				position: relative;
				padding: 18px 12px !important;
				letter-spacing: 0.4px;
				width: 33.3333%;
				line-height: 16px;
			}
			&:nth-child(even) {background: #fbfbfb}
			&:nth-child(odd) {background: #ffffff}
		}
	}
}

.MuiSelect-select:focus {
	background-color: transparent !important;
}
.FeedbackMixhubbForm {
	//margin-top: 20px;
	.FeedbackInnerAdd {
		.FeedBackNstatus {
			position: relative;
			display: flex;
			justify-content: space-between;
			min-width: 0;
			word-wrap: break-word;
			background-color: #ffffff;
			background-clip: border-box;
			border-radius: 0.42rem;
			width: 100%;
			margin: 10px 0 0;
			.feedBackNameDiscs {
				display: flex;
				align-items: center;
				width: calc(100% - 50%);
				padding-right: 8px;
				h2 {
					color: var(--blue);
					font-size: 24px;
					font-weight: 600;
					margin: 0 0;
				}
				h4 {
					color: var(--gray-dark);
					font-size: 20px;
					font-weight: 600;
				}
			}
			.EditAddFb {
				display: inline-flex;
    			max-height: 40px;
				.EditFb {
					margin-left: 12px;
				}
			}

			.feedBackStatusMix {
				.OnoffSwitch {
					strong {
						font-size: 14px;
					}
					.MuiSwitch-root {
						height: 40px !important;
						.MuiButtonBase-root {

							.MuiSwitch-thumb {
								width: 14px !important;
								height: 14px !important;
							}
						}
						.Mui-checked {
							padding: 13px 6px !important;
						}
					}
				}
			}
		}
	}
}
.FBPopForm {
	width: 100%;
    height: calc(100vh - 212px);
    display: flex;
    align-items: center;
    justify-content: center;
	.FeedbackInnerAdd {
		width: 80%;
		margin: 0px auto;
		box-shadow: 0px 0px 10px #00000040;
		border-radius: 12px;
		padding: 20px 40px 50px;
	}
}
.FbUrlCopyTx {
	width: 100%;
    position: relative;
    margin: 70px 0 0;
	.CopyRrlFd {
		width: 100% !important;
		max-width: 100% !important;
		border-radius: 100px;
		height: 70px;
		//box-shadow: 0px 0px 10px rgb(0 0 0 / 18%);
		.FormUrlHd {
			position: absolute;
			top: -15px;
			background-color: #fff;
			padding: 4px 14px;
			border-radius: 100px;
			box-shadow: 0px 0px 6px rgb(0 0 0 / 29%);
			left: 45px;
			p {
				margin: 0;
				text-transform: uppercase;
				font-size: 14px;
			}
		}
		.copyLinkDel {
			width: 100%;
			text-align: left;
			height: 70px;
			border-radius: 100px !important;
			font-size: 16px;
			padding: 26px 80px 26px 28px;
		}
		.CopyHere {
			position: absolute;
			right: 4px;
			background: #fff !important;
			top: 4px;
			width: 73px !important;
			height: 62px;
			border-radius: 0px 100px 100px 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 0 !important;
			span {
				width: 30px;
    			height: 30px;
				display: block;
				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}

.table.FeedBackTable {
	margin-top: 35px;
	thead {
		position: relative !important;

		th {
			background-color: var(--light-gray) !important;
			color: var(--gray-dark) !important;
			text-transform: capitalize;
			font-size: 14px !important;
			font-weight: 600;
			vertical-align: middle;
			padding: 12px 22px;
			&:first-child {
				padding-left: 15px !important;
				width: 5% !important;
			}

			&:last-child {
				width: 12% !important;
			}
			&:nth-child(2) {
				padding-left: 15px !important;
				width: 35% !important;
			}
			&:nth-child(3) {
				padding-left: 15px !important;
				width: 16% !important;
			}
			&:nth-child(4) {
				padding-left: 15px !important;
				width: 16% !important;
			}
			&:nth-child(5) {
				padding-left: 15px !important;
				width: 16% !important;
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;

			td {
				color: var(--gray-dark);
				font-weight: 600;
				font-size: 13px;
				position: relative;
				padding: 22px 12px !important;
				letter-spacing: 0.4px;
				line-height: 16px;
				word-break: break-all;
				.dropdown-menu {
					.navi {
						&:after {
							content: "";
							height: 20px;
							width: 20px;
							position: absolute;
							background-color: white;
							top: -11px;
							right: 16%;
							border-top: #dcd8d8 solid 1px;
							border-left: #dcd8d8 solid 1px;
							transform: rotate(45deg);
							z-index: -1;
						}

						.navi-item {
							span {
								height: auto !important;
								padding: 0px !important;
							}
						}
					}
				}

				p {
					margin: 0;
					display: flex;
					align-items: self-start;
					min-height: 50px;
					flex-direction: column;
					text-align: left;
					justify-content: center;
					span {
						display: block;
						font-weight: 600;
						font-size: 11px;
						margin: 3px 0 0;
					}
				}



				.btn {
					width: auto !important;

					img {
						margin: 0px !important;
						width: auto;
					}
				}

				.dropdown-menu.dropdown-menu-sm {
					border: 1px #dcd8d8 solid;
					top: 12px !important;
					padding: 0 0 !important;
					margin-left: 28px !important;

					&:after {
						content: "";

					}
				}
				.ActionSvg {
					display: inline-block;
					cursor: pointer;
					.ActionIcon {
						display: inline-block;
						margin: 0 4px;
						img {
							width: auto;
							border-radius: 0px;
							margin: 0px;
						}
					}
					.EmailSvgIcon {
						width: 18px !important;
						height: 18px !important;
						.spinner-grow {
							width: 12px;
							height: 12px;
							background-color: rgb(0 0 0 / 41%) !important;
						}
					}
					.ReportSvgIcon {
						width: 17px !important;
						height: 17px !important;
					}
					.DeleteSvgIcon {
						width: 16px  !important;
						height: 16px  !important;
					}
					.EditSvgIcon {
						width: 14px !important;
						height: 14px !important;
					}
				}
				.ActionSvg {
					cursor: pointer;
					.ActionIcon {
						button {
							width: 18px !important;
							height: 18px !important;
							min-width: 18px !important;
							padding: 0;
							margin-right: 0 !important;
							span {
								width: 18px !important;
    							height: 18px !important;
							}
						}
					}
				}

			}
		}
	}
}
.CommonModalPopup .modal-dialog .modal-content .modal-body .NotifyInofsAdd .MoreInputAdd .GroupForm .form-control {
	padding-right: 46px !important;
}
.DeleteInput {
    position: absolute;
    right: 26px;
    background: #ff0c0c;
    width: 28px;
    height: 28px;
    top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 100px;
    cursor: pointer;
	svg {
		fill: #fff;
		width: 100%;
		height: 100%;
	}
}
.AddMoreOptons {
	cursor: pointer;
    color: var(--blue);
    font-weight: bold;
    font-size: 13px;
	padding-bottom: 15px;
}
.CommonModalPopup .modal-dialog .modal-content .modal-body .QueAddPopup .GroupForm .LimitDivTxg {
	top: -18px !important;
}
.AddHereTeam {
	float: right;
	a {
		float: right;
    	display: inline-block;
	}
}
.Plan-Remove-Addons {
	float: right;
	.Add-Addons-Btn {
		text-transform: capitalize;
		background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
		color: var(--white);
		font-size: 14px;
		padding: 6px 12px;
		width: 100%;
		font-weight: 600;
	}
}
.subscribeHeader {
	border-bottom: 1px #f1f1f1 solid;
    padding: 0px 25px;
    height: 65px;
	.subscribeLogo {
		height: 100%;
		width: 200px;
		height: 65px;
		img {
			width: auto;
			height: 100%;
			object-fit: scale-down;
		}
	}
}
.SubscribePlan {
	margin: 50px 0 20px;
	padding: 0px !important;
}
.YourCredits {
    position: fixed;
    top: 90px;
    right: 8px;
	border-radius: 6px;
	padding: 12px;
    height: calc(100vh - 192px);
    box-shadow: 0px 0px 6px rgb(0 0 0 / 23%);
    width: 200px;
	transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
	.CreditsScroll {
		p {
			color: #2F73F7;
			font-size: 15px;
			font-weight: 600;
			margin: 0;
		}
		.CloseCradit {
			position: absolute;
			right: 8px;
			top: 8px;
			cursor: pointer;
			svg {
				width: 18px;
				height: 18px;
			}
		}
		.CreditListMix {
			background-color: #fafafa;
			border: 1px #D0D0D0 solid;
			margin: 18px 0;
			padding: 10px;
			text-align: center;
			border-radius: 10px;
			position: relative;
			//cursor: pointer;
			.CloseCradit {
	
				svg {
	
				}
			}
			.CreditType {
				margin-bottom: 8px;
				svg {
					width: 22px;
					height: 22px;
					margin-right: 10px;
				}
				span {
					font-weight: 600;
					font-size: 12px;
					color: #222222;
				}
			}
			.CountCradit {
				strong {
					font-size: 16px;
					font-weight: 600;
					font-family: Poppins, Helvetica, "sans-serif";
				}
			}
			&:after {
				width: 5px;
				background-color: #00FF27;
				content: "";
				height: 38px;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				border-radius: 0px 10px 10px 0px;
			}
		}
	}
}
.StartFreeTrial {
    transition: all 0.9s ease;
    -webkit-transition: all 0.9s ease;
    -moz-transition: all 0.9s ease;
    -o-transition: all 0.9s ease;
    -o-transition: all 0.9s ease;
}
.Trial-Fix-Cradit {
    margin: 0 !important;
    flex: 0 0 91.666667% !important;
    max-width: 91.666667% !important;
    transition: all 0.9s ease;
    -webkit-transition: all 0.9s ease;
    -moz-transition: all 0.9s ease;
    -o-transition: all 0.9s ease;
    -o-transition: all 0.9s ease;
}
.SelectCradit {
	.CountCradit {
		strong {
			color: #FC0D1B !important;
		}
	}
	&:after {
		background-color: #FC0D1B !important;
	}
}
.TopAnalytics {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px;
    border-top: 1px #D2D2D2 solid;
    padding: 24px 0 0;
	h3 {
		color: #2F73F7;
		font-weight: 600;
		font-size: 18px;
	}
	.UsersLive {
		color: #2DB11D;
		font-weight: 600;
		font-size: 15px;
		svg {
			width: 18px;
			height: 18px;
		}
		strong {
			color: #222222;
		}
	}
}
.DashBoardInfo {
	background-color: #F1F1F1;
    border: 2px #E8E8E8A6 solid;
	display: flex;
	position: relative;
	padding: 24px 30px;
	border-radius: 10px;
	.IconDBInfo {
		display: inline-flex;
		margin-right: 24px;
		svg {
			width: 50px;
			height: 50px;
		}
	}
	.DBIngoFull {
		width: 100%;
		h3 {
			color: #737376;
			font-weight: 600;
			font-size: 15px;
			margin: 0 0 10px;
		}
		p {
			color: #222222;
			font-weight: 600;
			font-size: 15px;
			margin: 0px;
		}
	}
	.PathLink {
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		svg {
			width: 14px;
			height: 14px;
		}
	}
}
.VistLocat {
	align-items: center;
	.DBIngoFull {
		h3 {
			margin: 0;
		}
	}
}
.liveSessions {
    border: 2px #E8E8E8A6 solid;
    padding: 20px 20px;
    margin: 30px 0 0;
	h3 {
		color: #222222;
		font-weight: 600;
		font-size: 18px;
		margin: 0 0 20px
	}
	.SessionCounts {
		background-color: #F1F1F1;
		padding: 12px 18px;
		border-radius: 8px;
		//min-height: 205px;
		.SessionListLive {
			background-color: #fff;
			padding: 10px 14px;
			margin: 8px 0;
			min-height: 68px;
			border-radius: 8px;
			h4 {
				color: #2F73F7;
				font-weight: 600;
				font-size: 16px;
			}
			span {
				color: #222222;
				font-size: 16px;
				font-weight: 600;
			}
		}
	}
	.AverageTimeLogged {
		h4 {
			color: #222222;
			font-weight: 600;
			font-size: 16px;
			margin: 0 0 20px
		}
		.ActiveLoggedCount {
			background-color: #F5F5F5;
			padding: 8px 18px;
			border-radius: 8px;
			p {
				margin: 0px;
				color: #2F73F7;
				font-weight: 600;
				font-size: 18px;
			}
			.SpeakerDBactive {
				display: flex;
				align-items: center;
				justify-content: space-between;
				span {
					font-size: 18px;
					color: #737376;
					font-weight: 600;
				}
				b {
					color: #222222;
					font-size: 18px;
				}
			}
		}

	}
	.ActiveTimeCountAll {
		display: flex;
		align-items: center;
		min-height: 72px;
		h5 {
			color: #2F73F7;
			font-weight: 600;
			font-size: 18px;
			margin: 0;
		}
	}
}
.LiveCountSess {
	.SessionCounts {
		min-height: 205px;
	}
}
.ListHeaderThree {
	.card-custom {
		.card-header {
			.FilterAnalitsSearch {
				.ListSearchBar {
					margin-right: 10px;
					.MuiPaper-root {
						border: 1px #333 solid;
    					padding: 4px 0;
						box-shadow: none !important;
					}
					.FilterAllReports {
						.filterDatePic {
							.form-control {
								background-color: #ffffff !important;
								border: 1px #333 solid;
    							height: 38px !important;
								width: 160px !important;
							}
						}
					}
				}
			}
			.EditAddFb {
				display: inline-flex;
				.EditFb {
					margin-right: 12px;
				}
			}
		}
	}
}
.table.AnalyticsTable{
	thead {
		position: relative !important;
		tr {
			th {
				background-color: #F1F1F1 !important;
				background-color: var(--light-gray) !important;
				color: #3f4254 !important;
				text-transform: capitalize;
				font-size: 15px !important;
				font-weight: 600;
				padding: 12px 12px !important;
				width: 20%;
				&:first-child {
					width: 10%;
				}
				&:nth-child(2) {
					width: 25%;
				}
				&:nth-child(3) {
					width: 35%;
				}
				&:nth-child(4) {
					width: 20%;
				}
				&:last-child {
					width: 10%;
				}
			}
		}
	}
	tbody {
		tr {
			border-bottom: 1px #F1F1F1 solid;
			td {
				color: #3f4254;
				font-weight: 600;
				font-size: 14px;
				position: relative;
				padding: 18px 12px !important;
				letter-spacing: 0.4px;
				line-height: 16px;
			}
			&:nth-child(even) {background: #fbfbfb}
			&:nth-child(odd) {background: #ffffff}
		}
	}
}
.BoothInnerSetup {
	.TagsWebsite {
		.stateForm {
			.MuiInputBase-root {
				#tags-outlined {
					padding: 13px 4px !important;
				}
			}
		}
	}
}
.bar-chart {
	canvas {
		border-radius: 10px 10px 0px 0px;
	}
	.LayoutRangeBox {
		position: relative;
		background-color: #33333f;
		border-radius: 0px 0px 10px 10px;
		height: 100px;
    	width: 100%;
		.RangeFeedBak {
			position: absolute;
			top: 6px;
			left: 0;
			.ButtonRange {
				background-color: #495166;
				border: 0;
				color: #fff;
				padding: 8px 8px;
				font-size: 12px;
				border-radius: 4px;
				width: 78px;
				span {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow-x: hidden;
					display: flow-root;
				}
				p {
					margin: 0;
				}
				svg {
					fill: #fff;
					width: 10px;
					height: 10px;
				}
			}
			&:first-child {
				left: 9%;
			}
			&:nth-child(2) {
				left: 28.6%;
			}
			&:nth-child(3) {
				left: 48.2%;
			}
			&:nth-child(4) {
				left: 67.8%;
			}
			&:last-child {
				left: 87.2%;
			}
		}
	}
}
.ChartBarTop {
	margin-top: 30px !important;
}
.CripViewImages {
	.modal-dialog {
	  max-width: 530px;
	  height: 100%;
	  z-index: 46;
	  margin: 30px auto 30px !important;
		//display: flex;
		align-items: center;
		justify-content: center;
		transition: transform 0s ease-out !important;
		transform: translate(0, 0px) !important;
		.modal-content {
			
			width: 100%;
			height: auto;
			border-radius: 6px !important;
			.modal-body {
			  //height: 285px;
			  aspect-ratio: 16/9;
			  background: #d6d6d6;
			  width: 96%;
			  margin: 10px auto 10px;
			  padding: 0;
			  display: flex;
			  align-items: center;
			  justify-content: center;
			  div {
				//height: 100%;
				img {
				  height: 100%;
				}
			  }
			}
			.modal-header {
				padding: 16px 30px 0;
				margin-top: 10px;
				border: 0;
			}
			.modal-footer {
			  justify-content: center;
			  padding: 12px 0 !important;
			  .btn {
				width: 135px;
				color: #fff;
				font-weight: 600 !important;
    			font-size: 14px;
			  }
			  .CropImg {
				background-color: #237be2;
				border: 1px #237be2 solid;
				&:hover {
					background-color: #237be2;
					border: 1px #237be2 solid;
				}
				&:focus {
					background-color: #237be2;
					border: 1px #237be2 solid;
				}
			  }
			  .ChangeImg {
				background-color: #4e4e4e;
				border: 1px #4e4e4e solid;
				&:hover {
					background-color: #4e4e4e;
					border: 1px #4e4e4e solid;
				}
				&:focus {
					background-color: #4e4e4e;
					border: 1px #4e4e4e solid;
				}
			  }
			}
		}
	}
	&:after {
	  content: "";
	  position: fixed;
	  width: 100%;
	  top: 0;
	  left: 0;
	  background-color: rgb(0 0 0 / 85%);
	  height: 100%;
	}
  }
  .table.PurchaseHistory {
	thead {
		position: relative !important;

		th {
			background-color: var(--light-gray) !important;
			color: var(--gray-dark) !important;
			text-transform: capitalize;
			font-size: 14px !important;
			font-weight: 600;
			vertical-align: middle;
			padding: 12px 12px;
			text-align: center;
			&:first-child {
				text-align: left;
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px var(--light-gray) solid;

			td {
				color: var(--gray-dark);
				font-weight: 600;
				font-size: 13px;
				position: relative;
				padding: 22px 12px !important;
				letter-spacing: 0.4px;
				line-height: 16px;
				word-break: break-all;
				text-align: center;
				&:first-child {
					padding-left: 15px !important;
					width: 18% !important;
					text-align: left;
				}
				&:nth-child(2) {
					padding-left: 15px !important;
					width: 14% !important;
				}
				&:nth-child(3) {
					padding-left: 15px !important;
					width: 20% !important;
				}
				&:nth-child(4) {
					padding-left: 15px !important;
					width: 20% !important;
				}
				&:nth-child(5) {
					padding-left: 15px !important;
					width: 16% !important;
				}
				&:last-child {
					width: 12% !important;
				}
			}
		}
	}
}
.PasswordColl {
	position: relative;
	.PasswordEye {
		position: absolute;
		top: 50%;
		right: 14px;
		transform: translateY(-50%);
		.EyeStatus {
			svg {
				width: 22px;
    			height: 22px;
				cursor: pointer;
			}
		}
		.OnEye {
			display: none;
		}
	}
}
.EyeActionPass {
	.PasswordEye {
		.OnEye {
			display: block !important;
		}
		.OffEye {
			display: block !important;
		}
	}
}
.PurchageHisTable {
	border-bottom: 1px #D2D2D2 solid;
    margin: 0 0 30px;
}
.boothTagIngfo {
	display: flex;
    align-items: center;
    justify-content: space-between;
	.EditRooms {
		width: 100%;
		margin-right: 18px;
	}
	.AddHereTeam {
		.AddSpekrRt {
			height: 52px;
			min-width: 52px;
			font-size: 22px !important;
		}
	}
}
.EditEventArea {
	.SocialBasicDetail {
		.form-group {
			.form-control {
				padding: 10px 14px !important;
			}
		}
	}	
}
.ContryCodeNumber {
	.DialCodePin {
	  width: 100%;
	  select {
		text-align: center;
	  }
	.stateForm label {
		color: #222222;
		font-weight: 400;
		font-size: 13px;
	}
	  .MuiFormControl-root {
		  padding: 0px !important;
		  background-color: #F1F1F1 !important;
    		border-color: #F1F1F1 !important;
		  .MuiInputBase-root {
			  .MuiSelect-select {
				padding: 10px 18px 10px 10px !important;
				height: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
			  }
			  &::after {
				  display: none;
			  }
			  &::before {
				display: none;
			}
			input {
				height: 100%;
			}
		  }
	  }
	  .TimeZoneProfile {
		top: 1px;
		.ZoneSelectBox {
			height: 40px; 
			.valueInput {
				height: 40px; 
				margin: 0px;
			}
			&::after {
				top: 14px !important;
			}
		}
		.arrow-icon {
			&:after {
				top: 18px !important;
			}
		}
		.TimeZoneActive {
			top: 35px;
		}
	  }
	}
	.PhoneNoInput {
	  width: 88%;
	  .InputSelect {
		 .form-group {
			 .text-danger {
				position: absolute;
				font-size: 11px;
				bottom: 2px;
				left: 0;
			 }
		 } 
	  }
	}
  }
.EditNameSideBr {
	display: inline-flex;
	position: relative;
	.form-control {
		border: 1px var(--gray-dark) solid;
		width: 260px;
		box-shadow: none !important;
		position: relative;
		border-radius: 4px;
		padding: 3px 0;
		.MuiInputBase-root {
			&::after {
				display: none;
			}
			&::before {
				display: none;
			}
			.MuiInputBase-input {
				padding: 8px 10px;
				color: #737376;
				color: var(--gray);
				font-weight: 400;
			}
		}
	}
	h5 {
		span {
			display: inline-flex;
    		margin-left: 8px;
			cursor: pointer;
		}
	}
	p {
		position: absolute;
		bottom: -18px;
		margin: 0;
	}
	.CommonGtBtn {
		display: inline-flex;
	}
}
.stageAddNew {
	display: inline-flex;
    justify-content: space-between;
    width: 100%;
	.LobbyAddStage {
		display: inline-flex;
	}
  }


  .RegisterCodeNumber {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.DialCodePin {
	  width: 106px;
	  padding-right: 12px !important;
	  select {
		text-align: center;
	  }
	}
	.PhoneNoInput {
	  width: 88%;
	  .InputSelect {
		 .form-group {
			 .text-danger {
				position: absolute;
				font-size: 11px;
				bottom: 2px;
				left: 0;
			 }
		 } 
	  }
	  .form-control {
			padding-left: 10px !important;
			padding-right: 10px !important;
		}
	  .fv-help-block {
			position: absolute;
			bottom: -16px;
			left: 0;
		}
	}
	.stateForm {
		.InputSelect {
			.DialNumberCodeReg {
				position: relative;
				.DailCodeReg {
					height: auto !important;
					-webkit-appearance: none;
					appearance: none;
					background: transparent !important;
					position: relative;
					z-index: 3;
					cursor: pointer;
					text-align: center;
					padding-right: 25px !important;
				}
				&::after {
					content: "";
					display: inline-block;
					width: 8px;
					height: 8px;
					border-top: 2px solid #514f4f;
					border-right: 2px solid #514f4f;
					transform: rotate(135deg);
					position: absolute;
					right: 11px;
    				top: 21px;
					z-index: 1;
				}
			}
		}
	}
}
.HeaderLink {
    display: block;
	p {
		font-size: 12px;
		font-weight: 500;
		a {
			color: #007eff;
			font-weight: 600;
		}
	}
}
.YurselfCodepin {
	.TimeZoneProfile {
		margin-right: 10px;
    	width: 140px;
		.ZoneSelectBox  {
			border-radius: 4px;
			.valueInput {
				height: 50px;
				margin: 0 0 0;
			}
			&:after {
				top: 19px;
			}
		}
		.arrow-icon {
			&::after {
				top: 23px;
			}
		}
		.TimeZoneActive {
			border: 1px #e4e6eb solid;
			top: 46px;
			.ZoneSearchHere {
				.SearchBarInput {
					border: 1px #e4e6eb solid;
					&:hover {
						border: 1px #e4e6eb solid !important;
					}
					&:focus {
						border: 1px #e4e6eb solid !important;
					}
				}
			}
			.ZoneOptionBox {
				height: 190px;
			}
		}
	}
}

.MixhubbNoSelf {
	.TimeZoneProfile {
		margin-right: 10px;
    	width: 200px;
		.ZoneSelectBox  {
			border-radius: 4px;
			.valueInput {
				height: 50px;
				margin: 0 0 0;
				padding-right: 24px;
				height: 50px;
				margin: 0 0 0;
				padding-right: 28px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}
			&:after {
				top: 19px;
				right: 10px;
			}
			
		}
		.arrow-icon {
			&::after {
				top: 23px;
			}
		}
		.TimeZoneActive {
			border: 1px #e4e6eb solid;
			top: 46px;
			width: 318px;
			.ZoneSearchHere {
				.SearchBarInput {
					border: 1px #e4e6eb solid;
					&:hover {
						border: 1px #e4e6eb solid !important;
					}
					&:focus {
						border: 1px #e4e6eb solid !important;
					}
				}
			}
			.ZoneOptionBox {
				height: 190px;
			}
		}
	}
}
.klXIKA {
	cursor: pointer;
}
.UserListHelp {
	min-height: auto !important;
}
.AttendeeUtm {
    display: flex;
    align-items: center;
    justify-content: space-between;
	.AttendeePrior {
		width: 28%;
	}
}
.ScheduleFrameFlex {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	.eventDayOPtion {
		display: flex;
		align-items: center;
		.DayEventStage {
			margin-right: 25px;
			h4 {
				font-size: 18px;
				color: #2F73F7;
				font-weight: 600;
				margin: 0px 0 4px;
			}
			p {
				font-size: 14px;
				color: #333;
				margin: 0;
			}
		}
	}
	.ddlListframe {
		position: relative;
		select {
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border: 1px var(--gray-dark) solid;
			border-radius: 4px;
			width: 240px;
			height: 42px;
			background-color: transparent;
			position: relative;
			padding-right: 30px;
			z-index: 2;
			cursor: pointer;
		}
		&::after {
			content: "";
			display: inline-block;
			width: 10px;
			height: 10px;
			border-top: 2px solid var(--gray-dark);
			border-right: 2px solid var(--gray-dark);
			transform: rotate(135deg);
			position: absolute;
			right: 12px;
			top: 12px;
			z-index: 1;
		}
	}
}
.metaTagsBasic {
	.container-fluid {
		.metaInfoAll {
			.form-group {
				.form-control {
					padding: 10px 16px !important;
				}
				textarea {
					height: 100px !important;
				}
			}
			.MuiGrid-root {
				.BreakerCreativeupload {
					padding: 0px !important;
					width: 100% !important;
					max-width: 100%;
    				flex-basis: 100%;
					.UploaFileStage {
						.minHeightBox {
							aspect-ratio: 16/9;
							flex: 0 0 280px !important;
							max-width: 280px !important;
							height: auto !important;
							min-height: auto !important;
							margin: 0 !important;
						}
					}
				}
			}
			.MuiGrid-root {
				margin-left: 0px !important;
			}
		}
	}
}
.RegisteruserPt {
	display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 62px);
    position: absolute;
    width: 100%;
    bottom: 0;
	.row {
		width: 100%;
		.sepratorMid {
			position: absolute;
			right: 0;
			left: 0;
			width: 22px;
			margin: 0px auto;
			top: 50%;
			transform: translateY(-50%);
			height: 200px;
			display: flex;
			align-items: center;
			span {
				padding: 4px 0;
				background-color: #fff;
    			font-size: 16px;
			}
			&:before {
				content: "";
				width: 2px;
				background-color: #E8E8E8;
				height: 100%;
				position: absolute;
				top: 0;
				margin: 0px auto;
				left: 0;
				right: 0;
				z-index: -1;
			}
		}
	}
}
.ForgotPasswd {
	text-align: right;
	font-size: 14px;
	margin: 0 0 4px;
	a {
		color: #007bff;
		text-decoration: none;	
	}
}


.FbUrlCopyTxTfX {
	width: 100%;
    position: relative;
    margin: 70px 0 0;
	display: flex;
    align-items: center;
    justify-content: center;
	.CopyRrlFdUrl {
		width: 100% !important;
		max-width: 100% !important;
		border-radius: 10px;
		position: relative;
		//box-shadow: 0px 0px 10px rgb(0 0 0 / 18%);
		.FormUrlHdIn {
			position: absolute;
			top: -12px;
			background-color: #fff;
			padding: 2px 12px;
			border-radius: 10px;
			box-shadow: 0px 0px 6px rgb(0 0 0 / 29%);
			left: 20px;
			p {
				margin: 0;
				text-transform: uppercase;
				font-size: 12px;
			}
		}
		.copyLinkDtal {
			width: 100%;
			text-align: left;
			height: 58px;
			border-radius: 10px !important;
			font-size: 16px;
			padding: 14px 80px 14px 8px;
			background-color: #f1f1f1;
			.form-control-solid {
				background-color: transparent !important;
				border-color: transparent !important;
				input {
					padding: 0px;
				}
				&::after {
					display: none;
				}
				&::before {
					display: none;
				}
			}
		}
		.CopyHere {
			position: absolute;
			right: 4px;
			background: #fff !important;
			top: 4px;
			width: 50px !important;
			height: 50px;
			border-radius: 0px 6px 6px 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 0 !important;
			cursor: pointer;
			span {
				width: 24px;
    			height: 24px;
				display: block;
				svg {
					width: 100%;
					height: 100%;
				}
			}
			
		}
	}
	.EventDescript {
		.gradientBg {
			margin-left: 14px;
			margin-top: 0px !important;
			width: 150px;
			padding: 8px 0 !important;
			border-radius: 4px !important;
			text-transform: uppercase;
			font-size: 14px;
		}
	}
}


footer {
	width: 100%;
    background-color: #F1F3F4;
    text-align: center;
    padding: 14px 0;
    position: fixed;
    bottom: 0;
	left: 0;
	ul {
	  margin: 0px;
	  padding: 0px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  height: 100%;
	  li {
		display: inline-block;
		font-size: 12px;
		font-weight: 300;
		a {
		  color: #8944FC;
		  border-right: 1px #8944FC solid;
		  padding: 0 12px;
		  height: 12px !important;
		  display: block;
		  line-height: 8px;
		  &:hover {
			color: #8944FC;
			text-decoration: underline !important;
		  }
		}
		&:last-child {
		  a {
			border-right: 0px;
		  }
		}
	  }
	}
  }
.AbtEventUrl {
	p {
		font-size: 13px;
		margin-bottom: 16px;
	}
}
.LanguageEventType {
	margin: 0 0 10px;
    font-size: 15px;
    color: #222222;
    font-weight: 400;
}
.MobileViewInfo {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #333;
    z-index: 999999;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
	.MobDeshboard {
		text-align: center;
		padding: 0 20px;
		span {
			width: 54px;
			height: 54px;
			display: inline-block;
			margin-bottom: 12px;
			svg {
				width: 100%;
				height: 100%;
			}
		}
		p {
			font-size: 16px;
			font-weight: 600;
			margin: 0;
			line-height: 24px;
		}
	}
}
.RunningLoader {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmerlarge;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: #eee;
    background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
    background-size: 400% 16px;
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    // height: 100%;
}
@keyframes placeHolderShimmerlarge {
	0% {
		background-position: 30% 0;
	}
	100% {
		background-position: -30% 0;
	}
}
.InfoInputTable {
	.stateForm {
		.text-danger {
			position: absolute;
			bottom: -20px;
			left: 0;
		}
	}
}
.UtmGenerator {
	.modal-dialog {
		max-width: 800px !important;
		.modal-content {
			.modal-header {
				padding: 0 0 22px;
				border-bottom: 1px solid #EBEDF3;
				margin: 0 0 20px;
				.ModalCloseBtn {
					right: 18px;
					top: 18px;
				}
			}
			.modal-body {
				max-height: 300px;
				overflow-y: auto;
				.utmForm {
					.form-control {
						.MuiInputBase-root {
							&::after {
								display: none;
							}
							&::before {
								display: none;
							}
							input {
								padding: 0 8px;
							}
						}
					}
				}
				.Utm_row {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.Colutm-1 {
						width: 100%;
						margin: 0 6px;
					}
					.Colutm-2 {
						margin: 0 10px;
						.AddNewUtm {
							button {
								min-width: 50px;
								min-height: 38px;
								.MuiButton-label {
									font-size: 20px;
									padding: 0;
									line-height: 0;
								}
							}
						}
					}
				}
			}
			.modal-footer {
				justify-content: space-between !important;
				padding: 12px 0 0;
				.Colutm-3 {
					margin: 0 10px;
					.AddNewUtm {
						button {
							min-width: 50px;
							min-height: 38px;
							.MuiButton-label {
								font-size: 20px;
								padding: 0;
								line-height: 0;
							}
						}
					}
				}
			}
		}
	}
}
.ImportCSVFile {
	.uplodLogo {
		.minHeightImgs {
			.UploaFileStage {
				.UploadLabel {
					p {
						margin: 0px !important;
					}
				}
			}
		}
	}
}

.head-info-wrapper{display: flex;gap:10px;align-items:center;}
// --------------------

.color-light-ambiance label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated {
    white-space: nowrap;
}
.color-light-ambiance {gap:15px;}
.row.embd-section .form-group.GroupForm {
    width: 700px;
	max-width:calc(100% - 40px);
}
.row.embd-section .col-md-11.m-auto {
    display: flex;
    gap: 10px;
	flex-grow: 1;
}
.row.embd-section .CopyHere {
    margin-top: 18px;
}
.embed-header {
    padding-bottom: 10px;
    border-bottom: 2px solid #fafafa;
    position: fixed;
    background-color: #fff;
    z-index: 99;
    display: block;
    width: calc(100% - 320px);
    max-width: 100%;
    margin-top: -25px;
    padding-top: 10px;
}
.embed-header .ScheduleHeader h5 {
    color: #333;
    font-family: 'Poppins';
	font-size: 18px;
}
.MuxhubbTagInfo.mixhubb-embed-wrapper div#myTabContent {
    padding-top: 50px;
}
h2.emb-head {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
	font-family: 'Poppins';
}
ul.emb-list {
    color: #6F6F6F;
    font-size: 16px;
    padding-left: 20px;
    font-family: 'Poppins';
	margin-bottom: 30px;
}
.ambience-options-wrapper {
    display: flex;
    gap: 15px;
	margin-bottom: 30px;
}
.ambience-option {
    position: relative;
}
input.radio-ambience {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
	z-index: 99;
}
.ambience-option label {
    margin: 0;
    font-size: 16px;
    color: #333;
    font-family: 'Poppins';
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 10px 50px;
    position: relative;
}
.ambience-option label:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #ccc;
    left: 15px;
    border-radius: 50px;
    top: 12px;
}
.ambience-option label:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #ccc;
    left: 19px;
    border-radius: 50px;
    top: 16px;
}
input.radio-ambience:checked + label{border:1px solid #1877F2;}
input.radio-ambience:checked + label:after { background-color: #1877F2;}
.color-options-wrapper {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
}
.color-option {
    position: relative;
}
input.radio-color {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    opacity: 0;
	cursor: pointer;
}	
.color-option label {
    width: 50px;
    height: 50px;
    margin: 0;
    display: block;
    font-size: 0;
    background-color: #333;
    border-radius: 50px;
	position: relative;
	box-shadow: 0 0 3px 1px rgba(0,0,0,0.15);
}
.color-option label.blue{background-color: #1877F2;}
.color-option label.orange{background-color: #F9BC34;}
.color-option label.red{background-color: #DE3C34;}
.color-option label.black{background-color: #333333;}
.color-option label.grey{background-color: #6F6F6F;}

input.radio-color:checked + label:before {
    height: 10px;
    width: 20px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    content: "";
    position: absolute;
    top: 17px;
    left: 15px;
    transform: rotate(-45deg);
}
.choose-color-input {
    position: relative;
    padding: 10px 30px;
    display: inline-block;
    font-size: 16px;
    font-family: 'Poppins';
    border: 1px solid #ccc;
    border-radius: 6px;
}
.choose-color-input .colorCodePx {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.choose-color-wrapper {
    margin-bottom: 30px;
}
.btn-done-gradient {
    text-transform: capitalize!important;
    background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
    color: var(--white)!important;
    font-size: 14px!important;
    padding: 5px 12px!important;
    width: 100px;
    margin-top: 20px!important;
    margin-left: auto!important;
    display: block!important;
}
.embed-code-box {
    width: 850px;
    max-width: 100%;
}
.embed-code-box textarea.form-control {
    min-height: 150px;
    background-color: transparent;
    color: #6F6F6F;
}
.copy-code-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
	margin-bottom: 15px;
}
.choose-color-input svg {
    margin-left: -15px;
    max-width: 22px;
    margin-right: 10px;
}
.copy-code-wrapper p {
    margin: 0;
    font-size: 14px;
}
.CopyHere {
    color: #1877f2;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins';
	cursor: pointer;
}
.MuxhubbTagInfo.mixhubb-embed-wrapper {
    font-family: 'Poppins';
}
h2.emb-head + p {
    font-size: 14px;
}
.ExpoHeader h5 {
    font-weight: 600;
    color: #222;
    font-size: 18px;
}

.expo-report-big-cards {
    padding: 0;
    display: flex;
    gap: 15px;
    margin-bottom: 50px;
	flex-wrap: wrap;
}

.expo-report-big-cards li {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 30px;
	width:100%;
	font-family:"Segoe UI" !important;
   
}
.expo-report-big-cards button, .expo-report-big-cards button:hover {
   
    background-color: rgba(74, 178, 138, 0.1);
    border-radius: 10px;
    max-width: 300px;
	flex: 0 0 300px;
	padding:0;
}
.expo-report-big-cards button:nth-child(2), .expo-report-big-cards button:nth-child(2):hover {background-color: rgba(199, 97, 74, 0.1);}
.expo-report-big-cards button:nth-child(3), .expo-report-big-cards button:nth-child(3):hover {background-color: rgba(224, 175, 53, 0.1);}
.big-card-details { flex-grow: 1; text-align: left;}
h2.big-card-head { font-size: 20px; font-weight: 700;text-transform: unset!important; }
.big-card-details p { margin: 0;text-transform: initial; }



.expo-report-small-cards {
    padding: 0;
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    flex-wrap: wrap;
    max-width: 970px;
	margin-bottom: 30px;
}

.expo-report-small-cards button {
    max-width: 300px;
    flex: 0 0 300px;
    padding: 0;
    text-align: left;
    margin-bottom: 20px;
}
.expo-report-small-cards li {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 15px;
    font-family:"Segoe UI" !important;
}
.expo-report-small-cards .MuiButton-root:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
}
.expo-report-small-cards .MuiButton-label{display: block;}
h2.small-card-head {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
	text-transform: initial;
}
.small-card-details p { margin: 0; }

button.MuiButton-text.btn-download-report {
    text-transform: capitalize;
    background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
    color: var(--white);
    font-size: 14px;
    padding: 5px 12px;
}
.EditNameSideBr.flex-space {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
ul.check-allow-list {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;
}
ul.check-allow-list li {
    display: flex;
    align-items: center;
    gap: 10px;
	position: relative;
}
ul.meeting-date-list {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 30px;
	flex-wrap: wrap;
}
ul.meeting-date-list li {
    border: 1px solid #DCE2E6;
    border-radius: 10px;
    overflow: hidden;
    flex: 0 0 25%;
    max-width: 25%;
}
.meeting-date-list-header {
    background-color: #E8E8E8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}
.meeting-date-list-header p {
    margin: 0;
}
.meeting-date-list-details {
    padding: 10px;
}
.meeting-time {
    margin-bottom: 20px;
}
.slot-time-value {
    background-color: #F1F1F1;
    padding: 10px 10px;
    width: 75px;
    text-align: center;
    border-radius: 5px;
    font-weight: 600;
    border: none;
}
.slot-time-value:focus{outline: none;border:none;}
p.slot-time-text {
    margin: 0;
}
.meeting-time input[type="time"] {
    border: 1px solid #D0D0D0;
    border-radius: 4px;
    padding: 8px;
	background-image: url('./images/arrow_profile.svg');
	background-repeat: no-repeat;
    background-position: 90% center;
}
.slot-time {
    display: flex;
    align-items: center;
    gap: 15px;
}
// .Mui-disabled {
// 	opacity: 0.6 !important;
// }
.MuiInputBase-root {
	.MuiFormControl-root {
		label {

		}
	}
	&::after {display: none;}
	&::before {display: none;}
}

input[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
}
.meeting-date-list-details.disable-schedule {
    pointer-events: none;
	opacity: 0.85;
}
// #menu-end_time1.MuiPopover-root .MuiPaper-root{max-height: 300px;}
// #menu-end_time2.MuiPopover-root .MuiPaper-root{max-height: 300px;}

.add-new-field-sticky-bar {
    position: sticky;
    top: 64px;
    background-color: #fff;
    z-index: 1;
    padding-top: 10px;
}
ul.check-allow-list li span {
    display: block;
    position: relative;
    padding-left: 20px;
}
ul.check-allow-list li span:before {
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    border: 1px solid #666;
    border-radius: 3px;
    left: 0;
    top: 3px;
}
ul.check-allow-list li span:after {
    content: "";
    position: absolute;
    width: 9px;
    height: 5px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    left: 2px;
    top: 6px;
    transform: rotate(-45deg);
	opacity: 0;
}
ul.check-allow-list li input[type="checkbox"]{position: absolute;width: 100%;height:100%;width:100%;left:0;top:0;z-index:9;opacity: 0;cursor: pointer;}
ul.check-allow-list li input[type="checkbox"]:checked + span:before{border:1px solid var(--blue);background-color:var(--blue)}
ul.check-allow-list li input[type="checkbox"]:checked + span:after{opacity: 1;}

.btn-submit-master-otp.CommonGtBtn {
    width: 100%;
}
.master-otp-modal .OtpValue input {
    margin: 0 6px;
    width: 55px !important;
    height: 55px !important;
    border-radius: 4px;
    box-shadow: none;
    outline: none;
    border: 1px solid #B7B7B7;
    font-size: 21px;
    font-weight: 600;
    background-color: #F1F1F1;
}
h2.master-otp-heading {
    text-align: center;
    font-weight: 600;
    font-size: 20.5px;
    margin-bottom: 40px;
}
.master-otp-modal .modal-footer {
    padding: 20px 20px 10px!important;
}
.master-otp-modal form {
    padding-bottom: 30px;
}
.master-otp-wrapper > div {
    justify-content: space-between;
    margin: 0 -5px;
}
.master-otp-wrapper {
    padding: 0 20px;
}
.feaderList h4.master-otp{color:var(--blue)}
p.otp-err {
    color: #f00;
    position: absolute;
    margin: 0;
    margin-top: 5px;
}
.no-password-generated{color:#737376!important;font-weight: 600;font-size: 16px!important;}
.mo-wrapper .BdrButton-edit {
    background-color: #fff;
    color: var(--blue);
    border: 1px #e5e5e5 solid;
    border-radius: 4px;
    padding: 6px 10px;
}
.mo-wrapper .BdrButton-edit svg {
    fill: var(--blue);
    width: 15px;
    margin-right: 10px;
}

.mo-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.password-wrapper {
    position: relative;
}
.password-wrapper input.form-control {
    padding-right: 40px!important;
}
button.btn-eye {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border: none;
    background: transparent;
    z-index: 9;
}
button.btn-eye svg {
    fill: var(--gray-dark);
}
ul.meeting-analytics-list {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    margin-bottom: 30px;
	justify-content: space-between;
}
ul.meeting-analytics-list li {
    display: flex;
    align-items: center;
    gap: 10px;
    // flex: 0 0 calc(25% - 50px);
}
.ma-detail p.ma-text {
    margin: 0;
    color: #222222;
    opacity: 0.8;
    font-size: 14px;
    font-weight: 600;
}
p.ma-value {
    color: #000;
    opacity: 0.5;
    font-weight: 600;
    font-size: 16px;
	margin: 0;
}
.ma-icon {
    background-color: #dbf0e8;
    width: 50px;
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
ul.meeting-analytics-list li:nth-child(2n) .ma-icon {
	background-color: #f4dfdb;
}
ul.meeting-analytics-list li:nth-child(4n) .ma-icon {
	background-color: #f9efd7;
}
ul.meeting-analytics-list li:nth-child(5n) .ma-icon {
	background-color: #d8e4fb;
}
.multi-select-box > div{height:46px}
.PoperSearchBar.MeetingFilterBar {
    display: flex;
    align-items: center;
    gap: 10px;
}
h2.meeting-list-head {
    font-size: 18px;
    font-weight: 600;
    color: #222;
    margin-right: 10px;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button .PrivateSwitchBase-root-1 {
    padding: 4px;
}
li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button .MuiListItemIcon-root {
    min-width: 30px;
}
.multi-select-box .MuiSelect-select.MuiSelect-select {
    padding-left: 10px;
}
.MuiPaper-root.align-items-start.MuiPaper-elevation1.MuiPaper-rounded{box-shadow: none!important;}
.multi-select-box .MuiSelect-nativeInput{opacity: 1;height: 100%;border:none;border-radius: 5px;padding-right:15px;overflow: hidden;text-overflow: ellipsis;}
.multi-select-box .MuiOutlinedInput-input { padding: 0 14px; }
.multi-select-box .MuiOutlinedInput-notchedOutline { border: none;}
.card-header.MeetingFilterHead.border-0.pl-0.pr-0 { min-height: auto;}








.AutoCompleteMui {
	padding-right: 6px;
	.form-group {
		.MuiAutocomplete-root {
			background-color: #F1F1F1 !important;
			.MuiFormControl-root {
				label {
					top: -9px !important;
				}
				// .Mui-focused {
				// 	font-size: 15.3px  !important;
				// 	top: 2px !important;
				// 	color: #808080 !important;
				// 	font-family: Poppins, Helvetica, "sans-serif" !important;
				// }
			}
			.MuiFormControl-root {
				.MuiFormLabel-filled {
					font-size: 15.3px  !important;
					color: #808080 !important;
					top: 2px !important;
					font-family: Poppins, Helvetica, "sans-serif" !important;
				}
			}
		}
		.Mui-focused {
			.MuiFormControl-root {
				.MuiInputBase-root {
					input {
						font-size: 13px !important;
						color: #333333 !important;
					}
				}
			}

		}
	}
	
}
@media(min-width:1920px) {
	.OrganizationListAll {
		.col-lg-3 {
			flex: 0 0 20%;
			max-width: 20%;
		}
	}
}
@media(min-width:1600px) and (max-width:1919px) {
	.allEvents-add {
		padding-right: 10%;
	}
	.Top-Event-Schedule .Schedule-Date {
		padding: 14px 7px;
	}
	.Top-Event-Schedule .Schedule-Date h5 {
		font-size: 14px;
	}
	.Top-Event-Schedule .Schedule-Date h2 {
		font-size: 42px;
		line-height: 50px;
	}
	.Top-Event-Schedule .Schedule-Date p {
		font-size: 12px;
	}
	.Top-Event-Schedule .Schedule-Day-Time {
		padding: 10px 10px;
	}
	.Top-Event-Schedule .Schedule-Day-Time h4 {
		font-size: 17px;
		margin: 0 0 8px;
	}
	.Top-Event-Schedule .Schedule-Day-Time span {
		font-size: 12px;
	}
	.Talk-Speakers {
		margin: 16px 0 0;
	}
	.Talk-Speakers .btn {
		padding: 3px 4px;
	}
	.Talk-Speakers svg {
		width: 15px;
		height: 15px;
		margin-right: 4px;
	}
	.StageEventPg {
		padding: 15px;
	}
	.Talk-Speakers .btn {
		font-size: 12px;
	}
	.RightIcons .EditDel .iconSvg {
		width: 29px;
		height: 29px;
		align-items: revert;
	}
	.Responsive-col {
		flex: 0 0 58.3333333333% !important;
    	max-width: 58.3333333333% !important;
	}
	.FilesRules {
		margin: 22px 0 8px 46px;
	}
	.BoothInnerSetup .TagsWebsite {
		margin: 0 0 22px;
	}
	.EventLogoupload {
		padding-right: 20px !important;
	}
	.BreakerCreativeupload {
		padding-left: 20px !important;
	}
	.BrandingHeadMain .sub-heading-main h4 {
		font-size: 25px;
	}
	.updateCoverDesign {
		padding-right: 20px !important;
	}
	.CompanyLogoUpload {
		padding: 0 10px !important;
	}
	.UpdateEventLogoDegns {
		padding-left: 20px !important;
	}
	.LiveStream h4 {
		font-size: 15px !important;
		padding-right: 0px !important;
	}
	.FreeTrailPlan .PlanEventChargesTable .MainPlanTrial .PlanCoastTrial h3 {
		font-size: 18px;
	}
	.FreeTrailPlan .Trial-lg-fix {
		flex: 0 0 85% !important;
		max-width: 85% !important;
	}
	.Trial-Fix-Cradit {
		margin: 0 !important;
		flex: 0 0 88.666667% !important;
		max-width: 88.666667% !important;
	}
	.FreeTrailPlan {
		.Trial-Fix-Cradit {
			.PlanEventChargesTable {
				.MainPlanTrial {
					padding: 14px;
					min-height: 420px;
					.PlanCoastTrial {
						h3 {
							font-size: 16px;
							margin-bottom: 4px;
							span {
								font-size: 12px;
							}
						}
						p {
							font-size: 13px;
						}
						h2 {
							font-size: 18px;
						}
					}
					.PlanListTrial {
						ul {
							li {
    							font-size: 12px;
							}
						}
					}
					.EnterprisePlan {
						img {
							width: 70px;
						}
					}
					.CustomRequire {
						h3 {
							font-size: 14px;
						}
						p {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
	.bar-chart {
		.LayoutRangeBox {
			.RangeFeedBak {
				&:first-child {
					left: 9%;
				}
				&:nth-child(2) {
					left: 28.6%;
				}
				&:nth-child(3) {
					left: 47.8%;
				}
				&:nth-child(4) {
					left: 67%;
				}
				&:last-child {
					left: 86.6%;
				}
			}
		}
	}
	.MiscellanLg-wt {
		flex: 0 0 33.3333333333%;
    	max-width: 33.3333333333%;
	}
	
}

@media(max-width:1440px){
	.multi-select-box {max-width: 180px;}
	.MuiPaper-root.align-items-start.meeting-search-filter.MuiPaper-elevation1.MuiPaper-rounded { max-width: 180px; }
	ul.meeting-analytics-list{gap:20px;}
}

@media(max-width:1400px){
	.card-header.MeetingFilterHead.border-0.pl-0.pr-0 {
		min-height: auto;
		margin-bottom: 18px;
	}
}
@media(max-width:1366px){
	.multi-select-box {max-width: 180px;}
	.MuiPaper-root.align-items-start.meeting-search-filter.MuiPaper-elevation1.MuiPaper-rounded { max-width: 180px; }
	
}
@media(max-width:1280px){
	.multi-select-box {max-width: 160px;}
	.MuiPaper-root.align-items-start.meeting-search-filter.MuiPaper-elevation1.MuiPaper-rounded { max-width: 160px; }
	
	// .EditAddFb.MeetingFilterBtns { margin-top: 15px; margin-bottom: 15px; margin-left: 121px; }
}
@media(max-width:1024px){
	.EditAddFb.MeetingFilterBtns { margin-top: 15px; margin-bottom: 15px;  }
}
@media(min-width:1200px) and (max-width:1600px) {
	.ImportCSVFile {
		.uplodLogo {
			.minHeightImgs {
				.UploaFileStage {
					.UploadLabel {
						font-size: 10px;
						img {
							width: 32px;
						}
						p {
							margin: 0px !important;
							font-size: 10px;
						}
					}
				}
			}
		}
	}
}
@media(min-width:1440px) and (max-width:1599px) {
	.allEvents-add {
		padding-left: 55px !important;
		padding-right: 0% !important;
	}
	.sub-heading-main h4 {
		font-size: 22px;
		margin-right: 25px;
	}
	.sub-heading-main .editBtn-detil {
		padding: 5px 18px !important;
		font-size: 14px;
		font-weight: 600;
	}

	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label {
		font-size: 12px;
		padding-left: 24px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label span {
		font-size: 11px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label .radiobtn {
		height: 16px;
    	width: 16px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label .radiobtn:after {
		width: 14px;
    	height: 14px;
	}
	.SelectPageTheme .ThemeSelectBrand .EditThemeBtn {
		padding: 7px 0;
	}
	.SelectPageTheme .ThemeSelectBrand {
		padding: 14px 14px;
	}

	.BasicDetails h3 {
		font-size: 18px;
		line-height: 24px;
	}
	.BasicDetails p {
		font-size: 12px;
	}




	.Top-Event-Schedule .Schedule-Date {
		padding: 14px 7px;
	}
	.Top-Event-Schedule .Schedule-Date h5 {
		font-size: 13px;
   		font-weight: 700;
	}
	.Top-Event-Schedule .Schedule-Date h2 {
		font-size: 36px;
		line-height: 50px;
	}
	.Top-Event-Schedule .Schedule-Date p {
		font-size: 11px;
	}
	.Top-Event-Schedule .Schedule-Day-Time {
		padding: 10px 8px;
	}
	.Top-Event-Schedule .Schedule-Day-Time h4 {
		font-size: 17px;
		margin: 0 0 8px;
	}
	.Top-Event-Schedule .Schedule-Day-Time span {
		font-size: 12px;
	}
	.Talk-Speakers {
		margin: 16px 0 0;
	}
	.Talk-Speakers .btn {
		padding: 3px 4px;
		font-size: 10px !important;
	}
	.Talk-Speakers svg {
		width: 13px;
		height: 13px;
		margin-right: 3px;
	}
	.EnterSchedule {
		right: 12px;
		top: 10px;
	}
	.bar-chart .LayoutRangeBox .RangeFeedBak .ButtonRange {
		padding: 8px 6px;
		font-size: 11px;
	}

	
	.minHeightBox .UploaderFiles .UploadLabel {
		font-size: 10px;
		margin-top: 0px !important;
		padding: 0;
		margin: 0;
	}
	.minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		width: 35px;
	}
	.HeadingStatic h2 {
		font-size: 18px;
	}
	.HeadingStatic img {
		width: 30px;
	}
	.minHeightBox {
		height: auto !important;
		min-height: auto !important;
		aspect-ratio: 16/9;
	}
	.StageEventPg {
		padding: 15px;
	}
	.RightIcons .EditDel .iconSvg {
		width: 27px;
		height: 27px;
		align-items: revert;
		padding: 5px 6px;
	}
	.StageEventPg h4 {
		font-weight: bold;
		font-size: 17px;
		padding-right: 27px;
	}
	.StageEventPg p {
		font-size: 13px;
	}
	.ScheduleHeader h5 {
		font-size: 18px;
	}
	.EnterStage {
		right: 16px;
		top: 16px;
	}
	.StageEventPg .Talk-Speakers .btn {
		font-size: 11px;
	}

	.Responsive-col {
		flex: 0 0 66.6666666667% !important;
    	max-width: 66.6666666667% !important;
	}
	.NetworkingSetup .uplodLogo h5 {
		font-size: 12px;
		line-height: 15px;
	}
	.FilesRules {
		margin: 22px 0 8px 10px;
	}
	.FilesRules ul li {
		font-size: 12px;
		margin-bottom: 14px;
	}
	.BoothInnerSetup .TagsWebsite {
		margin: 0 0 0px;
	}
	.feaderList p {
		font-size: 13px;
		margin: 0;
	}
	.feaderList h4 {
		font-size: 15px;
	}
	.ExpoAdditionalFeature .BoothSetupHeight {
		min-height: 118px !important;
	}
	.ExpoAdditionalFeature .EnterEventin {
		margin: 20px 0 0;
	}
	.WithAddMore {
		margin: 0 0 20px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) img {
		//width: 120px;
	}
	.InformLandingTable .EventSponsor tbody tr td p {
		font-size: 17px;
		min-height: 49px;
	}
	.InformLandingTable .EventSponsor tbody tr td {
		font-size: 16px;
	}
	.InformLandingTable .EventSponsor tbody tr td:last-child {
		width: 10%;
	}
	.InformLandingTable .EventSponsor tbody tr td .ActionIcon {
		width: 32px;
		height: 32px;
		margin: 0 4px;
	}
	.AboutEventPg .form-control {
		height: 110px;
		font-size: 14px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns h3 {
		font-size: 16px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns p {
		font-size: 14px;
	}

	.EventLogoupload {
		padding-right: 20px !important;
	}
	.BreakerCreativeupload {
		padding-left: 20px !important;
	}
	.BrandingHeadMain .sub-heading-main h4 {
		font-size: 22px;
	}
	.minHeightBox .default-image-selected .UploadLabel {
		margin: 0px !important;
		padding: 0px !important;
		width: 100% !important;
		height: 100% !important;
	}
	.minHeightBox .default-image-selected .UploadLabel img {
		height: 100%;
	}
	
	.DesginLogoBgUpload {
		margin: 10px 0 0 !important;
	}
	.updateCoverDesign {
		padding-right: 20px !important;
	}
	.CompanyLogoUpload {
		padding: 0 10px !important;
	}
	.UpdateEventLogoDegns {
		padding-left: 20px !important;
	}
	.DesignColorPicker .colorCodePx {
		width: 155px !important;
	}
	.LiveStream h4 {
		font-size: 15px !important;
		padding-right: 0px !important;
	}
	.LiveStream img {
		width: 42px;
		margin-right: 4px;
	}
	.EnableEmoji ul li {
		padding: 0 2px;
	}
	.FreeTrailPlan .Trial-lg-fix {
		flex: 0 0 95% !important;
		max-width: 95% !important;
	}

	.FreeTrailPlan {
		.TrialUpgradePlan {
			h2 {
				font-size: 22px;
			}
		}
		.PlanEventChargesTable {
			.MainPlanTrial {
				padding: 14px;
			.PlanCoastTrial {
				h3 {
					font-size: 17px;
					span {
						font-size: 12px;
					}
				}
				h2 {
					font-size: 20px;
				}
				p {
					font-size: 13px;
				}
			 }
			}
			.Plan-and-Addons {
				.PlanTypeChoose {
					h3 {
						font-size: 20px;
					}
				}
				.Add-Here-Addons {
					.Add-Addons-Btn {
						font-size: 14px;
						padding: 4px 14px;
					}
				}
			}
		}
	}
	.Select-AddonsMx {
		h3 {
			font-size: 20px;
		}
		.AddonsTable {
			.table-responsive {
				table.AddonsPreTables {
					thead {
						th {
							font-size: 16px !important;
							padding: 8px 10px;
						}
					}
					tbody {
						tr {
							td {
								font-size: 16px;
								padding: 18px 22px !important;
							}
						}
					}
				}
			}
		}
		.AddonsQty {
			input {
				width: 70px;
				padding: 8px 0;
				font-size: 18px;
				margin: 0 8px;
			}
		}
	}
	.FreeTrailPlan {
		.PlanEventChargesTable {
			.MainPlanTrial {
				.CustomRequire {
					h3 {
						font-size: 14px;
					}
					p {
						font-size: 12px;
					}
				}
				.PlanListTrial {
					ul {
						li {
							font-size: 13px;
						}
					}
				}
			}
		}
	}
	.Trial-Fix-Cradit {
		margin: 0 !important;
		flex: 0 0 85.666667% !important;
		max-width: 85.666667% !important;
	}
	.FreeTrailPlan {
		.Trial-Fix-Cradit {
			.PlanEventChargesTable {
				.MainPlanTrial {
					padding: 12px;
					min-height: 410px;
					.PlanCoastTrial {
						h3 {
							font-size: 14px;
							margin-bottom: 4px;
							span {
								font-size: 10px;
							}
						}
						p {
							font-size: 12px;
						}
						h2 {
							font-size: 18px;
						}
					}
					.PlanListTrial {
						ul {
							li {
    							font-size: 12px;
							}
						}
					}
					.EnterprisePlan {
						img {
							width: 70px;
						}
					}
					.CustomRequire {
						h3 {
							font-size: 12px;
						}
						p {
							font-size: 10px;
						}
					}
					.subscribeBtn {
						.subscribeNow {
							font-size: 14px;
							padding: 6px 12px;
						}
					}
				}
			}
		}
	}
	.bar-chart {
		.LayoutRangeBox {
			.RangeFeedBak {
				&:first-child {
					left: 9%;
				}
				&:nth-child(2) {
					left: 28%;
				}
				&:nth-child(3) {
					left: 47.4%;
				}
				&:nth-child(4) {
					left: 66.8%;
				}
				&:last-child {
					left: 86.1%;
				}
			}
		}
	}
	.MiscellanLg-wt {
		flex: 0 0 33.3333333333%;
    	max-width: 33.3333333333%;
	}
	// .MiscellanList {
	// 	padding: 14px 12px !important;
	// 	.ChatGroutAction {
	// 		margin-top: 12px;
	// 		.BdrButton {
	// 			margin-right: 8px;
	// 			padding: 4px 6px;
	// 			font-size: 9px;
	// 			svg {
	// 				margin-right: 4px;
	// 				width: 12px;
	// 				height: 12px;
	// 			}
	// 			&:last-child {
	// 				margin-right: 0px !important;
	// 			}
	// 		}
	// 	}
	// }
	// .UserListHelp {
	// 	.UserfeaderList {
	// 		.helpheader {
	// 			font-size: 11px;
	// 			padding: 4px 8px;
	// 		}
	// 		.HelpUserAction {
	// 			.ActionSvg {
	// 				margin: 0 4px;
	// 				padding: 5px 5px;
	// 				.ActionIcon {
	// 					width: 12px;
	// 					height: 12px;
	// 				}
	// 			}
	// 		}
	// 	}
	// 	h3 {
	// 		font-size: 15px;
	// 		word-break: break-word;
	// 	}
	// }
}
@media(max-width:1400px){
	ul.meeting-date-list li {
		flex: 0 0 30%;
		max-width: 30%;
	}
}
@media(min-width:1366px) and (max-width:1435px) {
	.allEvents-add {
		padding-left: 55px !important;
		padding-right: 0% !important;
	}
	.allEvents-add .event-summary-all .event-all-schedule h3 {
		font-size: 17px;
	}
	.allEvents-add .event-summary-all .event-all-schedule label {
		margin: 5px 0 10px;
	}
	.allEvents-add .event-summary-all .eventPicBg {
		width: 156px;
	}
	.allEvents-add .event-summary-all .event-all-schedule .eventStatus {
		font-size: 13px;
	}
	.allEvents-add .event-summary-all .event-all-schedule p {
		font-size: 12px;
	}
	.content .card-custom .card-header .card-title .card-label {
		font-size: 22px;
	}
	.content .card-custom .card-header {
		margin: 0 0 0px;
	}
	.NoSchedeleEvent .AddNewEvents .SchedulePicBg {
		padding: 5px;
	}
	.NoSchedeleEvent .AddNewEvents .EventScheduleTxt p {
		font-size: 18px;
	}


	.aside-fixed .aside {
		width: 200px !important;
	}
	.aside-fixed .wrapper {
		padding-left: 200px !important;
	}
	.aside-enabled .header.header-fixed {
		left: 200px !important;
	}

	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .menu-link {
		padding: 10px 14px !important;
	}
	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .menu-link .menu-text {
		font-size: 14px !important;
	}
	.sub-heading-main h4 {
		font-size: 22px;
		margin-right: 25px;
	}
	.sub-heading-main .editBtn-detil {
		padding: 5px 18px !important;
		font-size: 14px;
		font-weight: 600;
	}
	.eventExprience h3 {
		font-size: 20px;
	}
	.eventExprience p {
		font-size: 13px;
	}
	.YourNavBarInfo label {
		font-size: 11px !important;
		font-weight: 500;
	}
	.top-heading-main .headingH1 h1 {
		font-size: 24px;
	}
	.top-heading-main .NofifyH1 h1 {
		font-size: 22px !important;
	}
	.top-heading-main .headingH1 span {
		font-size: 14px;
	}
	.top-heading-main .menuRight ul li {
		margin: 0 5px;
	}
	.top-heading-main .menuRight ul li a {
		font-size: 14px;
	}

	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label {
		font-size: 12px;
		padding-left: 20px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label span {
		font-size: 11px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label .radiobtn {
		height: 14px;
    	width: 14px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label .radiobtn:after {
		width: 12px;
    	height: 12px;
	}
	.SelectPageTheme .ThemeSelectBrand .EditThemeBtn {
		padding: 4px 0;
	}
	.SelectPageTheme .ThemeSelectBrand {
		padding: 14px 14px;
	}
	.feaderList h4 {
		font-size: 15px;
	}
	.HeadingStatic h2 {
		font-size: 18px;
	}
	.BtnActionBtn {
		padding: 22px 84px 22px 283px;
	}
	.ScheduleHeader h5 {
		color: var(--blue);
		font-weight: 600;
		font-size: 18px;
	}





	.Top-Event-Schedule .Schedule-Date {
		padding: 14px 7px;
	}
	.Top-Event-Schedule .Schedule-Date h5 {
		font-size: 13px;
   		font-weight: 700;
	}
	.Top-Event-Schedule .Schedule-Date h2 {
		font-size: 36px;
		line-height: 50px;
	}
	.Top-Event-Schedule .Schedule-Date p {
		font-size: 11px;
	}
	.Top-Event-Schedule .Schedule-Day-Time {
		padding: 13px 6px;
	}
	.Top-Event-Schedule .Schedule-Day-Time h4 {
		font-size: 17px;
		margin: 0 0 8px;
	}
	.Top-Event-Schedule .Schedule-Day-Time span {
		font-size: 11px;
	}
	.Talk-Speakers {
		margin: 16px 0 0;
	}
	.Talk-Speakers .btn {
		padding: 3px 3px;
		font-size: 10px !important;
	}
	.Talk-Speakers svg {
		width: 12px;
		height: 12px;
		margin-right: 3px;
	}
	.EnterSchedule {
		right: 12px;
		top: 10px;
	}

	.bar-chart .LayoutRangeBox .RangeFeedBak .ButtonRange {
		padding: 8px 6px;
		font-size: 11px;
	}

	.minHeightBox {
		border: 1px #1A4788 dashed;
		height: auto !important;
		min-height: auto !important;
		aspect-ratio: 16/9;
	}
	.minHeightBox .UploaderFiles .UploadLabel {
		font-size: 10px;
		width: 100%;
		padding: 0;
	}
	.minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		width: 38px;
		margin: 0 0 7px;
	}
	.HeadingStatic h2 {
		font-size: 18px;
	}
	.HeadingStatic img {
		width: 30px;
	}
	.IconMenuSvg {
		width: 21px;
		height: 21px;
	}



	.CheckBoxTicl .check-group label:before {
		padding: 8px;;
	}
	.CheckBoxTicl .check-group input:checked + label:after {
		height: 9px;
		left: 7px;
    	width: 5px;
	}
	.CheckBoxTicl .check-group input:checked + label:after {
		top: 6px;
	}
	.LiveStream h4 {
		padding-right: 0px !important;
		font-size: 14px !important;
	}
	.LiveStream img {
		width: 44px;
		margin-right: 5px;
	}
	.LiveStream h5 {
		font-size: 13px;
	}

	.eventExprience .EventCopy-Edit .CopyHere {
		width: 120px;
		padding-left: 0px;
	}
	.eventExprience .StreamIngLive .CopyHere {
		padding: 7px 2px;
		font-size: 10px;
		width: 178px;
		font-weight: 600;
	}
	.StageEventPg {
		padding: 13px;
	}
	.RightIcons .EditDel .iconSvg {
		width: 27px;
		height: 27px;
		align-items: revert;
		padding: 5px 6px;
		margin: 0;
		margin-left: 7px;
	}
	.StageEventPg h4 {
		font-weight: bold;
		font-size: 17px;
		padding-right: 27px;
	}
	.ScheduleHeader h5 {
		font-size: 18px;
	}
	.EnterStage {
		right: 16px;
		top: 19px;
	}
	.eventExprience .EventCopy-Edit {
		max-width: 340px;
	}
	.HeadingStatic {
		margin-bottom: 24px !important;
	}
	.BasicDetails h3 {
		font-size: 17px;
		margin: 0 0 3px;
	}
	.BasicDetails p {
		font-size: 12px;
	}
	.nav-tabs .nav-item .nav-link .nav-text {
		font-size: 13px;
	}
	.StageEventPg p {
		font-size: 13px;
	}
	.ScheduleHeader h5 {
		font-size: 18px;
	}
	.Responsive-col {
		flex: 0 0 66.6666666667% !important;
    	max-width: 66.6666666667% !important;
	}

	.table.SpeakersTables tbody tr td:last-child {
		width: 13%;
	}	
	.NetworkingSetup .uplodLogo h5 {
		font-size: 13px;
	}
	.FilesRules {
		margin: 22px 0 8px 0px;
	}
	.FilesRules ul li {
		font-size: 12px;
	}
	.FilesRules h3 {
		font-size: 17px;
		margin: 0 0 26px;
	}
	.FilesRules h4 {
		font-size: 13px;
	}
	.NetworkingSetup .uplodLogo h5 {
		font-size: 12px;
		line-height: 17px;
	}


	.BoothCardInfo .TypeOfBooth .SearchBooth {
		width: 270px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg .BoothLogo .img-fluid {
		height: 60px;
	}


	.BoothInnerSetup .TagsWebsite {
		margin: 0 0 0px;
	}
	.feaderList p {
		font-size: 12px;
		margin: 0;
	}
	.feaderList h4 {
		font-size: 14px;
		font-weight: 700;
	}
	.ExpoAdditionalFeature .BoothSetupHeight {
		min-height: 118px !important;
	}
	.LogoCoverUpload .minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		width: 37px;
		margin: 0 0 5px;
	}

	.WithAddMore {
		margin: 0 0 20px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) img {
		height: 72px;
	}
	.InformLandingTable .EventSponsor tbody tr td p {
		font-size: 15px;
		min-height: 45px;
	}
	.InformLandingTable .EventSponsor tbody tr td {
		font-size: 14px;
	}
	.InformLandingTable .EventSponsor tbody tr td:last-child {
		width: 10%;
	}
	.InformLandingTable .EventSponsor tbody tr td .ActionIcon {
		width: 32px;
		height: 32px;
		margin: 0 4px;
	}
	.AboutEventPg .form-control {
		height: 110px;
		font-size: 14px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns h3 {
		font-size: 16px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns p {
		font-size: 14px;
	}
	.EventLogoupload {
		padding-right: 20px !important;
	}
	.BreakerCreativeupload {
		padding-left: 20px !important;
	}
	.BrandingHeadMain .sub-heading-main h4 {
		font-size: 22.5px;
	}
	.brandingColorPix .MuiGrid-root {
		margin-bottom: 24px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .img-fluid {
		height: 100%;
	}
	.minHeightBox .default-image-selected .UploadLabel {
		height: 100% !important;
		width: 100% !important; 
	}



	.LogoBgUploadIn .HeadingStatic, .LogoBgUploadIn .WithAddMore {margin-bottom: 0px !important;}
	.updateCoverDesign {
		padding-right: 20px !important;
	}
	.CompanyLogoUpload {
		padding: 0 10px !important;
	}
	.UpdateEventLogoDegns {
		padding-left: 20px !important;
	}
	.DesignColorPicker .colorCodePx {
		width: 155px !important;
	}
	.EnableEmoji ul li {
		padding: 0 2px;
	}
	.widgetContainer {
		max-width: 100% !important;
	}
	.FreeTrailPlan .Trial-lg-fix {
		flex: 0 0 95% !important;
		max-width: 95% !important;
	}

	.FreeTrailPlan {
		.TrialUpgradePlan {
			h2 {
				font-size: 22px;
			}
		}
		.PlanEventChargesTable {
			.MainPlanTrial {
				padding: 14px;
			.PlanCoastTrial {
				h3 {
					font-size: 16px;
					span {
						font-size: 12px;
					}
				}
				h2 {
					font-size: 20px;
				}
				p {
					font-size: 13px;
				}
			 }
			}
			.Plan-and-Addons {
				.PlanTypeChoose {
					h3 {
						font-size: 20px;
					}
				}
				.Add-Here-Addons {
					.Add-Addons-Btn {
						font-size: 14px;
						padding: 4px 14px;
					}
				}
			}
		}
	}
	.Select-AddonsMx {
		h3 {
			font-size: 20px;
		}
		.AddonsTable {
			.table-responsive {
				table.AddonsPreTables {
					thead {
						th {
							font-size: 16px !important;
							padding: 8px 10px;
						}
					}
					tbody {
						tr {
							td {
								font-size: 16px;
								padding: 18px 22px !important;
							}
						}
					}
				}
			}
		}
		.AddonsQty {
			input {
				width: 70px;
				padding: 8px 0;
				font-size: 18px;
				margin: 0 8px;
			}
		}
	}
	.Trial-Fix-Cradit {
		margin: 0 !important;
		flex: 0 0 83.666667% !important;
		max-width: 83.666667% !important;
			.Select-AddonsMx {
				.AddonsTable {
					.table-responsive {
						table.AddonsPreTables {
							tbody {
								tr {
									td {
										padding: 18px 14px !important;
										.Select-AddonsMx {
											.AddonsQty {
												input {
													width: 60px !important;
													padding: 8px 0;
													font-size: 18px;
													margin: 0 4px;
												}
											}
										}
									}
								}
							}
						}
						
					}
				}
			}
	}

	.FreeTrailPlan {
		.PlanEventChargesTable {
			.MainPlanTrial {
				.CustomRequire {
					h3 {
						font-size: 14px;
					}
					p {
						font-size: 12px;
					}
				}
				.PlanListTrial {
					ul {
						li {
							font-size: 13px;
						}
					}
				}
			}
		}
	}
	.FreeTrailPlan {
		.Trial-Fix-Cradit {
			.PlanEventChargesTable {
				.MainPlanTrial {
					padding: 13px;
					min-height: 414px;
					.PlanCoastTrial {
						h3 {
							font-size: 13px;
							margin-bottom: 4px;
							span {
								font-size: 9px;
							}
						}
						p {
							font-size: 12px;
						}
						h2 {
							font-size: 16px;
						}
					}
					.PlanListTrial {
						ul {
							li {
    							font-size: 12px;
							}
						}
					}
					.EnterprisePlan {
						img {
							width: 55px;
						}
					}
					.CustomRequire {
						h3 {
							font-size: 11px;
						}
						p {
							font-size: 10px;
						}
					}
					.subscribeBtn {
						.subscribeNow {
							font-size: 12px;
							padding: 6px 12px;
						}
					}
				}
			}
		}
	}
	.bar-chart {
		.LayoutRangeBox {
			.RangeFeedBak {
				&:first-child {
					left: 9%;
				}
				&:nth-child(2) {
					left: 28%;
				}
				&:nth-child(3) {
					left: 47.4%;
				}
				&:nth-child(4) {
					left: 66.8%;
				}
				&:last-child {
					left: 86.1%;
				}
			}
		}
	}
	.MiscellanLg-wt {
		flex: 0 0 33.3333333333%;
    	max-width: 33.3333333333%;
	}
	.MiscellanList {
		padding: 14px 12px !important;
		.SwitchRight {
			right: 2px;
		}
		.ChatGroutAction {
			margin-top: 12px;
			.BdrButton {
				margin-right: 4px;
				&:last-child {
					margin-right: 0px !important;
				}
			}
		}
		p {
			font-size: 14px;
		}
	}
	// .UserListHelp {
	// 	.UserfeaderList {
	// 		margin: 0 0 20px;
	// 		.helpheader {
	// 			font-size: 11px;
	// 			padding: 4px 8px;
	// 		}
	// 		.HelpUserAction {
	// 			.ActionSvg {
	// 				margin: 0 4px;
	// 				padding: 5px 5px;
	// 				.ActionIcon {
	// 					width: 12px;
	// 					height: 12px;
	// 				}
	// 			}
	// 		}
	// 	}
	// 	h3 {
	// 		font-size: 14px;
	// 		margin: 0 0 8px;
	// 	}
	// 	p {
	// 		font-size: 12px;
	// 		word-break: break-word;
	// 	}
	// }
}
@media(max-width: 1280px) {
	ul.meeting-date-list {
		
		grid-gap: 15px;
		gap: 15px;
	}
	ul.meeting-date-list li {
		flex: 0 0 30%;
		max-width: 30%;
	}
	
}
@media(max-width: 1200px) {
	
	ul.meeting-date-list li {
		flex: 0 0 30%;
		max-width: 30%;
	}
	.meeting-time input[type=time] {
		padding: 8px 3px;
	}
}
@media(min-width:1280px) and (max-width:1365px) {
	.ListHeaderOne .top-heading-main .headingH1 h1 {
		font-size: 24px;
	}
	.ScheduleHeader h5 {
		font-size: 18px;
	}


	.aside-fixed .aside {
		width: 200px !important;
	}
	.aside-fixed .wrapper {
		padding-left: 200px !important;
	}
	.aside-enabled .header.header-fixed {
		left: 200px !important;
	}
	.allEvents-add {
		padding-left: 55px !important;
		padding-right: 4% !important;
	}
	.allEvents-add .event-summary-all .event-all-schedule h3 {
		font-size: 17px;
	}
	.allEvents-add .event-summary-all .event-all-schedule label {
		margin: 5px 0 10px;
	}
	.allEvents-add .event-summary-all .eventPicBg {
		width: 156px;
	}
	.allEvents-add .event-summary-all .event-all-schedule .eventStatus {
		font-size: 13px;
	}
	.allEvents-add .event-summary-all .event-all-schedule p {
		font-size: 12px;
	}
	.content .card-custom .card-header .card-title .card-label {
		font-size: 22px;
	}
	.content .card-custom .card-header {
		margin: 0 0 0px;
	}
	.NoSchedeleEvent .AddNewEvents .SchedulePicBg {
		padding: 5px;
	}
	.NoSchedeleEvent .AddNewEvents .EventScheduleTxt p {
		font-size: 18px;
	}
	.BrandingMixhubInfo .col-md-11 {
		flex: 0 0 100%;
    	max-width: 100%;
	}
	.viewAllevent {
		padding-bottom: 70px;
	}
	.top-heading-main .NofifyH1 h1 {
		font-size: 22px !important;
	}

	.IconMenuSvg {
		width: 20px;
		height: 20px;
	}
	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .menu-link .menu-text {
		font-size: 14px !important;
	}	
	.BasicDetails p {
		font-size: 12px;
	}
	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .menu-link {
		padding: 10px 15px !important;
	}
	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .MenuSwitch {
		right: 7px;
	}
	.BasicDetails h3 {
		font-size: 17px;
	}
	.sub-heading-main h4 {
		font-size: 22px;
		margin-right: 25px;
	}
	.sub-heading-main .editBtn-detil {
		padding: 5px 18px !important;
		font-size: 14px;
		font-weight: 600;
	}
	.eventExprience h3 {
		font-size: 20px;
	}
	.eventExprience p {
		font-size: 13px;
	}
	.YourNavBarInfo label {
		font-size: 11px !important;
		font-weight: 500;
	}
	.top-heading-main .headingH1 h1 {
		font-size: 24px;
	}
	.top-heading-main .headingH1 span {
		font-size: 14px;
	}
	.top-heading-main .menuRight ul li {
		margin: 0 5px;
	}
	.top-heading-main .menuRight ul li a {
		font-size: 14px;
	}


	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label {
		font-size: 11px;
		padding-left: 17px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label span {
		font-size: 10px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label .radiobtn {
		height: 12px;
    	width: 12px;
		margin: 2px 0 0;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label .radiobtn:after {
		width: 10px;
    	height: 10px;
	}
	.SelectPageTheme .ThemeSelectBrand .EditThemeBtn {
		padding: 4px 0;
		font-size: 12px;
	}
	.SelectPageTheme .ThemeSelectBrand {
		padding: 14px 14px;
	}
	.HeadingStatic h2 {
		font-size: 18px;
	}
	.BtnActionBtn {
		padding: 22px 84px 22px 283px;
	}
	.ScheduleHeader h5 {
		color: var(--blue);
		font-weight: 600;
		font-size: 18px;
	}
	.CommonGtBtn {
		padding: 6px 18px !important;
	}
	.WhiteBtn {
		padding: 6px 28px !important;
	}

	.bar-chart .LayoutRangeBox .RangeFeedBak .ButtonRange {
		padding: 8px 6px;
		font-size: 11px;
	}



	.Top-Event-Schedule .Schedule-Date {
		padding: 14px 4px;
	}
	.Top-Event-Schedule .Schedule-Date h5 {
		font-size: 13px;
   		font-weight: 700;
	}
	.Top-Event-Schedule .Schedule-Date h2 {
		font-size: 36px;
		line-height: 50px;
	}
	.Top-Event-Schedule .Schedule-Date p {
		font-size: 11px;
	}
	.Top-Event-Schedule .Schedule-Day-Time {
		padding: 13px 7px;
	}
	.Top-Event-Schedule .Schedule-Day-Time h4 {
		font-size: 17px;
		margin: 0 0 8px;
	}
	.Top-Event-Schedule .Schedule-Day-Time span {
		font-size: 11px;
	}
	.Talk-Speakers {
		margin: 16px 0 0;
	}
	.Talk-Speakers .btn {
		padding: 3px 3px;
		font-size: 9px;
	}
	.Talk-Speakers svg {
		width: 12px;
		height: 12px;
		margin-right: 3px;
	}
	.EnterSchedule {
		right: 12px;
		top: 10px;
	}

	.HeadingStatic {
		margin-bottom: 22px !important;
	}
	.feaderList h4 {
		font-size: 14px;
		margin: 0 0 16px;
	}
	.eventExprience .EventCopy-Edit .CopyHere {
		width: 105px;
		padding-left: 0;
		font-weight: 600;
	}
	.minHeightBox .UploaderFiles .UploadLabel {
		font-size: 9px;
		width: 100%;
		padding: 0;
	}
	.minHeightBox {
		height: auto !important;
		min-height: auto !important;
		aspect-ratio: 16/9;
	}
	.minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		width: 42px;
	}
	.nav-tabs .nav-item .nav-link .nav-text {
		font-size: 13px;
	}
	.CheckBoxTicl .check-group label:before {
		padding: 8px;
	}
	.CheckBoxTicl .check-group input:checked + label:after {
		top: 6px;
		height: 9px;
		left: 7px;
		width: 5px;
	}



	.minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		margin: 0 0 5px;
	}
	.LiveStream h4 {
		padding-right: 0px !important;
		font-size: 12px !important;
	}
	.LiveStream img {
		width: 35px;
    	margin-right: 2px;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadPreviewIcon .UploadPreviewIco {
		width: 21px !important;
    	height: 21px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadPreviewIcon {
		width: 21px !important;
    	height: 21px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadImgIcon {
		width: 21px !important;
    	height: 21px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadImgIcon .UploadDeleteIco {
		width: 21px !important;
    	height: 21px !important;
	}
	.feaderList {
		padding: 14px 12px;
		min-height: 132px;
	}

	.StageEventPg p {
		font-size: 13px;
	}
	.ScheduleHeader h5 {
		font-size: 18px;
	}
	.RightIcons .EditDel .iconSvg {
		width: 26px;
		height: 26px;
		padding: 6px 5px;
	}
	.StageEventPg {
		border: 1px #E4E6EF solid;
		padding: 12px;
	}
	.StageEventPg h4 {
		font-size: 15px;
		padding-right: 26px;
	}
	.EnterStage {
		right: 18px;
		top: 15px;
	}
	.stateForm {
		padding: 0 !important;
	}
	.EnableEmoji .check-group label:before {
		border: 1px solid #a09c9c;
	}
	.EnableEmoji .check-group input:checked + label:after {
		top: 6px !important;
		width: 5px;
		height: 10px;
	}

	.Responsive-col {
		flex: 0 0 66.6666666667% !important;
    	max-width: 66.6666666667% !important;
	}
	.table.SpeakersTables tbody tr td:last-child {
		width: 13%;
	}
	.NetworkingSetup .uplodLogo h5 {
		font-size: 13px;
	}
	.FilesRules {
		margin: 22px 0 8px 0px;
	}
	.FilesRules ul li {
		font-size: 12px;
	}
	.FilesRules h3 {
		font-size: 17px;
		margin: 0 0 26px;
	}
	.FilesRules h4 {
		font-size: 13px;
	}


	.InviteSpeakerBox {
		padding-right: 0px !important;
	}
	.NetworkingSetup .uplodLogo h5 {
		font-size: 12px;
		line-height: 17px;
	}

	.BoothCardInfo .TypeOfBooth .SearchBooth {
		width: 270px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg .BoothLogo .img-fluid {
		height: 60px;
	}







	.BoothInnerSetup .TagsWebsite {
		margin: 0 0 0px;
	}
	.feaderList p {
		font-size: 13px;
		margin: 0;
	}
	.feaderList h4 {
		font-size: 15px;
	}
	.ExpoAdditionalFeature .BoothSetupHeight {
		min-height: 118px !important;
	}
	.LogoCoverUpload .minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		width: 37px;
		margin: 0 0 5px;
	}
	.BoothInnerSetup .TagsWebsite .mt-6 {
		margin-top: 8px !important;
	}
	.BoothInnerSetup .TagsWebsite .stateForm label {
		font-size: 12px;
	}
	.BoothInnerSetup .LogoCoverUpload .TextToolTip label {
		font-size: 12px;
	}
	.DisclaimerTable tbody tr td .fieldTypeAction label {
		font-size: 12px;
	}
	.LandingStylesInner .menuRight a {
		font-weight: 600;
	}
	.LandingStylesInner .menuRight li {
		margin: 0 5px;
	}


	.WithAddMore {
		margin: 0 0 20px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) img {
		height: 72px;
	}
	.InformLandingTable .EventSponsor tbody tr td p {
		font-size: 14px;
		min-height: 45px;
	}
	.InformLandingTable .EventSponsor tbody tr td {
		font-size: 14px;
	}
	.InformLandingTable .EventSponsor tbody tr td:last-child {
		width: 10%;
	}
	.InformLandingTable .EventSponsor tbody tr td .ActionIcon {
		width: 32px;
		height: 32px;
	}
	.AboutEventPg .form-control {
		height: 110px;
		font-size: 14px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns h3 {
		font-size: 16px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns p {
		font-size: 14px;
	}



	.bar-chart {
		.LayoutRangeBox {
			.RangeFeedBak {
				&:first-child {
					left: 8.5%;
				}
				&:nth-child(2) {
					left: 27.8%;
				}
				&:nth-child(3) {
					left: 47.4%;
				}
				&:nth-child(4) {
					left: 66.4%;
				}
				&:last-child {
					left: 85.8%;
				}
			}
		}
	}



	.EventLogoupload {
		padding-right: 20px !important;
	}
	.BreakerCreativeupload {
		padding-left: 20px !important;
	}
	.BrandingHeadMain .sub-heading-main h4 {
		font-size: 20.5px;
	}
	.brandingColorPix .MuiGrid-root {
		margin-bottom: 24px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .img-fluid {
		height: 100%;
	}
	.minHeightBox .default-image-selected .UploadLabel {
		height: 100% !important;
		width: 100% !important;
	}
	.colorCodePx {
		height: 38px;
	}


	.LogoBgUploadIn .HeadingStatic, .LogoBgUploadIn .WithAddMore {margin-bottom: 0px !important;}
	.updateCoverDesign {
		padding-right: 20px !important;
	}
	.CompanyLogoUpload {
		padding: 0 10px !important;
	}
	.UpdateEventLogoDegns {
		padding-left: 20px !important;
	}
	.DesignColorPicker .colorCodePx {
		width: 140px !important;
	}
	.EnableEmoji ul li {
		padding: 0 2px;
	}
	.LiveStream {
		margin: 15px 0 0;
	}
	.InformLandingTable .EventSponsor tbody tr td:first-child {
		width: 5%;
	}
	.widgetContainer {
		max-width: 100% !important;
	}
	.FreeTrailPlan .Trial-lg-fix {
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}



	.FreeTrailPlan {
		.TrialUpgradePlan {
			h2 {
				font-size: 22px;
			}
		}
		.PlanEventChargesTable {
			.MainPlanTrial {
				padding: 14px;
			.PlanCoastTrial {
				h3 {
					font-size: 15px;
					span {
						font-size: 10px;
					}
				}
				h2 {
					font-size: 18px;
				}
				p {
					font-size: 12px;
				}
			 }
			}
			.Plan-and-Addons {
				.PlanTypeChoose {
					h3 {
						font-size: 20px;
					}
				}
				.Add-Here-Addons {
					.Add-Addons-Btn {
						font-size: 14px;
						padding: 4px 14px;
					}
				}
			}
		}
	}

	.Select-AddonsMx {
		h3 {
			font-size: 20px;
		}
		.AddonsTable {
			.table-responsive {
				table.AddonsPreTables {
					thead {
						th {
							font-size: 16px !important;
							padding: 8px 10px;
						}
					}
					tbody {
						tr {
							td {
								font-size: 16px;
								padding: 18px 22px !important;
							}
						}
					}
				}
			}
		}
		.AddonsQty {
			input {
				width: 70px;
				padding: 8px 0;
				font-size: 18px;
				margin: 0 8px;
			}
		}
	}
	.Trial-Fix-Cradit {
		margin: 0 !important;
		flex: 0 0 84.666667% !important;
		max-width: 84.666667% !important;
			.Select-AddonsMx {
				.AddonsTable {
					.table-responsive {
						table.AddonsPreTables {
							tbody {
								tr {
									td {
										font-size: 16px;
										padding: 18px 14px !important;
									}
								}
							}
						}
					}
				}
			}
	}
	.FreeTrailPlan {
		.PlanEventChargesTable {
			.MainPlanTrial {
				.CustomRequire {
					h3 {
						font-size: 14px;
					}
					p {
						font-size: 12px;
					}
				}
				.PlanListTrial {
					ul {
						li {
							font-size: 13px;
						}
					}
				}
			}
		}
	}
	.FreeTrailPlan {
		.Trial-Fix-Cradit {
			.PlanEventChargesTable {
				.MainPlanTrial {
					padding: 10px;
					min-height: 425px;
					.PlanCoastTrial {
						h3 {
							font-size: 13px;
							margin-bottom: 4px;
							span {
								font-size: 9px;
							}
						}
						p {
							font-size: 12px;
						}
						h2 {
							font-size: 16px;
						}
					}
					.PlanListTrial {
						ul {
							li {
    							font-size: 12px;
							}
						}
					}
					.EnterprisePlan {
						img {
							width: 55px;
						}
					}
					.CustomRequire {
						h3 {
							font-size: 11px;
						}
						p {
							font-size: 10px;
						}
					}
					.subscribeBtn {
						.subscribeNow {
							font-size: 12px;
							padding: 6px 12px;
						}
					}
				}
			}
		}
	}
	.MiscellanLg-wt {
		flex: 0 0 33.3333333333%;
    	max-width: 33.3333333333%;
	}
	.MiscellanList {
		padding: 14px 12px !important;
		.SwitchRight {
			right: 2px;
		}
		.ChatGroutAction {
			margin-top: 12px;
			.BdrButton {
				margin-right: 4px;
				&:last-child {
					margin-right: 0px !important;
				}
			}
		}
		p {
			font-size: 14px;
		}
	}
	// .MiscellanList {
	// 	padding: 14px 12px !important;
	// 	.SwitchRight {
	// 		right: 4px;
	// 	}
	// 	h4 {
	// 		font-size: 14px;
	// 		font-weight: 700;
	// 	}
	// 	p {
	// 		font-size: 12px;
	// 	}
	// 	.ChatGroutAction {
	// 		margin-top: 12px;
	// 		.BdrButton {
	// 			margin-right: 4px;
	// 			padding: 4px 6px;
	// 			font-size: 9px;
	// 			svg {
	// 				margin-right: 2px;
	// 				width: 10px;
	// 				height: 10px;
	// 			}
	// 			&:last-child {
	// 				margin-right: 0px !important;
	// 			}
	// 		}
	// 	}
	// }
	// .UserListHelp {
	// 	.UserfeaderList {
	// 		margin: 0 0 18px;
	// 		.helpheader {
	// 			font-size: 11px;
	// 			padding: 4px 8px;
	// 		}
	// 		.HelpUserAction {
	// 			.ActionSvg {
	// 				margin: 0 4px;
	// 				padding: 5px 5px;
	// 				.ActionIcon {
	// 					width: 12px;
	// 					height: 12px;
	// 				}
	// 			}
	// 		}
	// 	}
	// 	h3 {
	// 		font-size: 14px;
	// 		margin: 0 0 8px;
	// 	}
	// 	p {
	// 		font-size: 11px;
	// 		word-break: break-word;
	// 	}
	// }
	.AttendeeUtm .AttendeePrior {
		width: 35%;
	}
	.liveSessions .SessionCounts .SessionListLive h4 {
		font-size: 14px;
	}
	.liveSessions .SessionCounts .SessionListLive span {
		font-size: 14px;
	}
	.liveSessions .AverageTimeLogged .ActiveLoggedCount .SpeakerDBactive span {
		font-size: 15px;
	}
	.liveSessions .AverageTimeLogged .ActiveLoggedCount .SpeakerDBactive b {
		font-size: 15px;
	}
}
@media(min-width:1200px) and (max-width:1279px) {
	.ListHeaderOne .top-heading-main .headingH1 h1 {
		font-size: 24px;
	}
	.ScheduleHeader h5 {
		font-size: 18px;
	}


	.aside-fixed .aside {
		width: 170px !important;
	}
	.aside-fixed .wrapper {
		padding-left: 170px !important;
	}
	.aside-enabled .header.header-fixed {
		left: 170px !important;
	}
	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .menu-link {
		padding: 10px 12px !important;
	}
	.IconMenuSvg {
		width: 20px;
		height: 20px;
	}

	.OnoffSwitch .MuiSwitch-root {
		padding: 12px 6px !important;
		width: 41px;
		height: 36px;
	}
	.OnoffSwitch .MuiSwitch-root .MuiButtonBase-root {
		padding: 13px 7px !important;
	}
	.OnoffSwitch .MuiSwitch-root .MuiButtonBase-root .MuiSwitch-thumb {
		width: 10px !important;
		height: 10px !important;
	}
	.OnoffSwitch .MuiSwitch-root .Mui-checked .MuiIconButton-label {
		position: relative;
		right: 3px;
	}

	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .MenuSwitch {
		right: 5px;
	}
	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .menu-link .menu-text {
		font-size: 13px !important;
	}
	.allEvents-add {
		padding-left: 55px !important;
		padding-right: 0% !important;
	}
	.allEvents-add .event-summary-all .event-all-schedule h3 {
		font-size: 17px;
	}
	.allEvents-add .event-summary-all .event-all-schedule label {
		margin: 5px 0 10px;
	}
	.allEvents-add .event-summary-all .eventPicBg {
		width: 156px;
	}
	.allEvents-add .event-summary-all .event-all-schedule .eventStatus {
		font-size: 13px;
	}
	.allEvents-add .event-summary-all .event-all-schedule p {
		font-size: 12px;
	}
	.content .card-custom .card-header .card-title .card-label {
		font-size: 22px;
	}
	.content .card-custom .card-header {
		margin: 0 0 0px;
	}
	.NoSchedeleEvent .AddNewEvents .SchedulePicBg {
		padding: 5px;
	}
	.NoSchedeleEvent .AddNewEvents .EventScheduleTxt p {
		font-size: 18px;
	}
	.BrandingMixhubInfo .col-md-11 {
		flex: 0 0 100%;
    	max-width: 100%;
	}
	.AllEventContainer .container-fluid {
		padding: 0 10px !important;
	}
	.ListHeaderMain .card-header {
		padding: 0 10px !important;
	}
	.viewAllevent {
		padding-bottom: 70px;
	}



	.sub-heading-main h4 {
		font-size: 20px;
		margin-right: 25px;
	}
	.sub-heading-main .editBtn-detil {
		padding: 5px 18px !important;
		font-size: 14px;
		font-weight: 600;
	}
	.eventExprience h3 {
		font-size: 20px;
	}
	.eventExprience p {
		font-size: 13px;
	}
	.YourNavBarInfo label {
		font-size: 11px !important;
		font-weight: 500;
	}
	.top-heading-main .headingH1 h1 {
		font-size: 24px;
	}
	.top-heading-main .headingH1 span {
		font-size: 14px;
	}
	.top-heading-main .menuRight ul li {
		margin: 0 5px;
	}
	.top-heading-main .menuRight ul li a {
		font-size: 14px;
	}
	.minHeightBox {
		height: auto !important;
		min-height: auto !important;
		aspect-ratio: 16/9;
	}

	.NavHeaderTop {width: 100% !important;padding: 0px !important;}


	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label {
		font-size: 11px;
		padding-left: 17px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label span {
		font-size: 10px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label .radiobtn {
		height: 12px;
    	width: 12px;
		margin: 2px 0 0;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label .radiobtn:after {
		width: 10px;
    	height: 10px;
	}
	.SelectPageTheme .ThemeSelectBrand .EditThemeBtn {
		padding: 6px 0;
		font-size: 13px;
	}
	.SelectPageTheme .ThemeSelectBrand {
		padding: 14px 14px;
	}
	.HeadingStatic h2 {
		font-size: 18px;
	}
	.BtnActionBtn {
		padding: 22px ​48px 20px 228px !important;
	}
	.ScheduleHeader h5 {
		color: var(--blue);
		font-weight: 600;
		font-size: 18px;
	}
	.CommonGtBtn {
		padding: 6px 24px !important;
	}
	.WhiteBtn {
		padding: 6px 28px !important;
	}
	.ThemePageLandingGo .col-md-11 {max-width: 100%; width: 100%;flex: 0 0 100%}
	.BasicDetails {
		padding: 20px 14px 5px;
	}
	.BasicDetails h3 {
		font-size: 15px;
		margin: 0 0 4px;
	}
	.BasicDetails p {
		font-size: 11px;
	}




	.Top-Event-Schedule .Schedule-Date {
		padding: 14px 3px;
	}
	.Top-Event-Schedule .Schedule-Date h5 {
		font-size: 13px;
   		font-weight: 700;
	}
	.Top-Event-Schedule .Schedule-Date h2 {
		font-size: 36px;
		line-height: 50px;
	}
	.Top-Event-Schedule .Schedule-Date p {
		font-size: 10px;
	}
	.Top-Event-Schedule .Schedule-Day-Time {
		padding: 13px 5px;
	}
	.Top-Event-Schedule .Schedule-Day-Time h4 {
		font-size: 15px;
		margin: 0 0 8px;
	}
	.Top-Event-Schedule .Schedule-Day-Time span {
		font-size: 11px;
	}
	.Talk-Speakers {
		margin: 16px 0 0;
	}
	.Talk-Speakers .btn {
		padding: 3px 3px;
    	font-size: 9px;
	}
	.Talk-Speakers svg {
		width: 12px;
		height: 12px;
		margin-right: 3px;
	}
	.EnterSchedule {
		right: 12px;
		top: 10px;
	}
	.EnterSchedule svg {
		width: 11px;
		height: 11px;
	}

	.top-heading-main .NofifyH1 h1 {
		font-size: 22px !important;
	}


	.HeadingStatic {
		margin-bottom: 10px !important;
	}

	.eventExprience .EventCopy-Edit .CopyHere {
		padding: 10px 2px;
		font-size: 11px;
		width: 80px;
	}
	.eventExprience .EventCopy-Edit .copyLinkDel {
		padding-right: 0;
		width: 260px;
	}
	.minHeightBox .UploaderFiles .UploadLabel {
		font-size: 9px;
		width: 100%;
		padding: 0;
	}
	.minHeightBox {
		height: auto !important;
		min-height: auto !important;
		aspect-ratio: 16/9;
	}
	.minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		width: 42px;
	}
	.nav-tabs .nav-item .nav-link .nav-text {
		font-size: 13px;
	}
	.CheckBoxTicl .check-group label:before {
		padding: 8px;
	}
	.CheckBoxTicl .check-group input:checked + label:after {
		top: 6px;
		height: 9px;
		left: 7px;
		width: 5px;
	}
	.HeadingStatic img {
		width: 28px;
	}
	.HeadingStatic.mt-10.mb-6 {
		margin: 32px 0 12px!important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadPreviewIcon {
		right: 40px !important;
	}

	.minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		margin: 0 0 5px;
	}
	.LiveStream h4 {
		padding-right: 0px !important;
		font-size: 11px !important;
	}
	.LiveStream img {
		width: 28px;
		margin-right: 2px;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadPreviewIcon .UploadPreviewIco {
		width: 21px !important;
    	height: 21px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadPreviewIcon {
		width: 21px !important;
    	height: 21px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadImgIcon {
		width: 21px !important;
    	height: 21px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadImgIcon .UploadDeleteIco {
		width: 21px !important;
    	height: 21px !important;
	}
	.feaderList {
		padding: 14px 12px;
	}
	.feaderList p {
		font-size: 13px;
		line-height: 17px;
	}
	
	

	.StageEventPg {
		border: 1px #E4E6EF solid;
		padding: 13px;
	}
	.EnterStage {
		right: 19px;
		top: 17px;
	}
	.StageEventPg h4 {
		font-size: 15px;
		padding-right: 25px;
	}
	.RightIcons .EditDel .iconSvg {
		width: 23px;
		height: 24px;
		padding: 4px 4px;
	}
	.EnterStage svg {
		width: 14px;
		height: 14px;
	}
	.RightIcons .EditDel .DelIcon {
		margin-right: 0px !important;
	}


	.stateForm {
		padding: 0 !important;
	}
	.EnableEmoji .check-group label:before {
		border: 1px solid #a09c9c;
	}
	.EnableEmoji .check-group input:checked + label:after {
		top: 6px !important;
		width: 5px;
		height: 10px;
	}
	.Responsive-col {
		flex: 0 0 75% !important;
    	max-width: 75% !important;
	}
	.table.SpeakersTables tbody tr td:last-child {
		width: 13%;
	}



	.InviteSpeakerBox {
		padding-right: 0px !important;
	}
	.NetworkingSetup .uplodLogo h5 {
		font-size: 12px;
		line-height: 17px;
	}
	.FilesRules {
		margin: 22px 0 8px 0px;
	}
	.FilesRules h3 {
		font-size: 16px;
	}
	.FilesRules ul li {
		font-size: 12px;
		margin-bottom: 14px;
	}


	.BoothCardInfo .TypeOfBooth .SearchBooth {
		width: 270px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg .BoothLogo .img-fluid {
		height: 60px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothEditDelete .EditDel .iconSvg {
		width: 28px;
		height: 28px;
		padding: 3px 6px;
	}

	.BoothInnerSetup .TagsWebsite {
		margin: 0 0 0px;
	}
	.feaderList p {
		font-size: 13px;
		margin: 0;
	}
	.feaderList h4 {
		font-size: 15px;
	}
	.ExpoAdditionalFeature .BoothSetupHeight {
		min-height: 111px !important;
	}
	.LogoCoverUpload .minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		width: 37px;
		margin: 0 0 5px;
	}
	.BoothInnerSetup .TagsWebsite .mt-6 {
		margin-top: 8px !important;
	}
	.BoothInnerSetup .TagsWebsite .stateForm label {
		font-size: 12px;
	}
	.BoothInnerSetup .LogoCoverUpload .TextToolTip label {
		font-size: 12px;
	}
	.AddBoothSetup .w-90 {
		width: 100% !important;
	}
	.DisclaimerTable tbody tr td .fieldTypeAction label {
		font-size: 12px;
	}
	.DisclaimerTable tbody tr td:nth-child(4) {
		width: 22%;
	}
	.LandingStylesInner .menuRight a {
		font-weight: 600;
	}
	.LandingStylesInner .menuRight li {
		margin: 0 5px;
	}



	.WithAddMore {
		margin: 0 0 28px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) img {
		height: 65px;
	}
	.InformLandingTable .EventSponsor tbody tr td p {
		font-size: 14px;
		min-height: 45px;
	}
	.InformLandingTable .EventSponsor tbody tr td {
		font-size: 14px;
	}
	.InformLandingTable .EventSponsor tbody tr td:last-child {
		width: 10%;
	}
	.InformLandingTable .EventSponsor tbody tr td .ActionIcon {
		width: 32px;
		height: 32px;
	}
	.AboutEventPg .form-control {
		height: 110px;
		font-size: 14px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns h3 {
		font-size: 15px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns p {
		font-size: 13px;
	}



	.EventLogoupload {
		padding-right: 20px !important;
	}
	.BreakerCreativeupload {
		padding-left: 20px !important;
	}
	.BrandingHeadMain .sub-heading-main h4 {
		font-size: 20px;
	}
	.brandingColorPix .MuiGrid-root {
		margin-bottom: 24px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .img-fluid {
		height: 100%;
	}
	.minHeightBox .default-image-selected .UploadLabel {
		height: 100% !important;
		width: 100% !important;
	}
	.colorCodePx {
		height: 38px;
	}

	.LogoBgUploadIn .HeadingStatic, .LogoBgUploadIn .WithAddMore {margin-bottom: 0px !important;}
	.updateCoverDesign {
		padding-right: 10px !important;
	}
	.CompanyLogoUpload {
		padding: 0 5px !important;
	}
	.UpdateEventLogoDegns {
		padding-left: 10px !important;
	}
	.DesignColorPicker .colorCodePx {
		width: 140px !important;
	}
	.EnableEmoji ul li {
		padding: 0 2px;
	}
	.InformLandingTable .EventSponsor tbody tr td:first-child {
		width: 5%;
	}
	.widgetContainer {
		max-width: 100% !important;
	}
	.FooterButton .CommonGtBtn, .FooterButton .BlueWhiteBtn {
		min-width: 100% !important;
		margin-right: 0px;
		margin-bottom: 15px;
	}
	.Trial-Fix-Cradit {
		margin: 0 !important;
		flex: 0 0 80.666667% !important;
		max-width: 80.666667% !important;
			.Select-AddonsMx {
				.AddonsTable {
					.table-responsive {
						table.AddonsPreTables {
							width: 780px;
							tbody {
								tr {
									td {
										font-size: 16px;
										padding: 18px 14px !important;
									}
								}
							}
						}
					}
				}
			}
		.PlanEventChargesTable {
			.row {
				.Plan-max-trial {
					flex: 0 0 50% !important;
					max-width: 50% !important;
				}
			}
		}
	}
	.UpgradePlaneFx  {
		.ChrgeBee {
			.Plan-max-trial {
				flex: 0 0 50% !important;
				max-width: 50% !important;
			}
		}
	}
  .Select-AddonsMx {
    .AddonsTable {
      .table-responsive {
        table.AddonsPreTables {
          thead {
            tr {
              th {
                font-size: 15px !important;
              }
            }
          }
          tbody {
            tr {
              td {
                font-size: 15px !important;
                padding: 20px 18px !important;
              }
            }
          }
        }
      }
    }
  }
  .bar-chart {
		.LayoutRangeBox {
			.RangeFeedBak {
				&:first-child {
					left: 8.5%;
				}
				&:nth-child(2) {
					left: 27.8%;
				}
				&:nth-child(3) {
					left: 47.4%;
				}
				&:nth-child(4) {
					left: 66.2%;
				}
				&:last-child {
					left: 85.4%;
				}
			}
		}
	}
	.MiscellanLg-wt {
		flex: 0 0 33.3333333333%;
    	max-width: 33.3333333333%;
	}
	.MiscellanList {
		padding: 14px 10px !important;
		.SwitchRight {
			right: 4px;
		}
		h4 {
			font-size: 14px;
			font-weight: 700;
		}
		p {
			font-size: 12px;
		}
		.ChatGroutAction {
			margin-top: 12px;
			.BdrButton {
				font-size: 11px;
				padding: 4px 6px;
				margin-right: 10px;
				svg {
					margin-right: 8px;
					width: 14px;
					height: 14px;
				}
				&:last-child {
					margin-right: 0px !important;
				}
			}
		}
	}
	.UserListHelp {
		.UserfeaderList {
			margin: 0 0 18px;
			.helpheader {
				font-size: 13px;
    			padding: 5px 12px;
			}
			.HelpUserAction {
				.ActionSvg {
					margin: 0 4px;
					padding: 6px 6px;
					.ActionIcon {
						width: 16px;
						height: 16px;
					}
				}
			}
		}
		h3 {
			font-size: 14px;
			margin: 0 0 8px;
		}
		p {
			font-size: 12px;
			word-break: break-word;
		}
	}

	.content .card-custom .RegAnalistHd {
		align-items: center !important;
	}
	.ScheduleFrameFlex {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		.ddlListframe {
			position: relative;
			select {
				appearance: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				border: 1px var(--gray-dark) solid;
				border-radius: 4px;
				width: 240px;
				background-color: transparent;
				position: relative;
				padding-right: 30px;
				z-index: 2;
				cursor: pointer;
			}
			&::after {
				content: "";
				display: inline-block;
				width: 10px;
				height: 10px;
				border-top: 2px solid var(--gray-dark);
				border-right: 2px solid var(--gray-dark);
				transform: rotate(135deg);
				position: absolute;
				right: 12px;
				top: 12px;
				z-index: 1;
			}
		}
	}
	.AttendeeUtm {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.AttendeePrior {
			width: 35%;
		}
	}
	.UtmGenerator {
		.modal-dialog {
			max-width: 800px !important;
			.modal-content {
				.modal-header {
					padding: 0 0 22px;
					border-bottom: 1px solid #EBEDF3;
					margin: 0 0 20px;
					.ModalCloseBtn {
						right: 18px;
						top: 18px;
					}
				}
				.modal-body {
					max-height: 300px;
					overflow-y: auto;
					.utmForm {
						.form-control {
							.MuiInputBase-root {
								&::after {
									display: none;
								}
								&::before {
									display: none;
								}
								input {
									padding: 0 8px;
								}
							}
						}
					}
					.Utm_row {
						display: flex;
						align-items: center;
						justify-content: space-between;
						.Colutm-1 {
							width: 100%;
							margin: 0 6px;
						}
						.Colutm-2 {
							margin: 0 10px;
							.AddNewUtm {
								button {
									min-width: 50px;
									min-height: 38px;
									.MuiButton-label {
										font-size: 20px;
										padding: 0;
										line-height: 0;
									}
								}
							}
						}
					}
				}
				.modal-footer {
					justify-content: space-between !important;
					padding: 12px 0 0;
					.Colutm-3 {
						margin: 0 10px;
						.AddNewUtm {
							button {
								min-width: 50px;
								min-height: 38px;
								.MuiButton-label {
									font-size: 20px;
									padding: 0;
									line-height: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
@media(max-width: 1199px) {
	.AddonsQty {
		input {
			width: 45px;
			padding: 10px 0;
			font-size: 18px;
			margin: 0 6px;
		}
	}
	.SubscribePlan {
		margin: 25px 0 20px;
	}
	.StageDelTables {
		width: 1040px;
	}
	table.PurchaseHistory {
		width: 800px;
	}
}
@media(min-width:1024px) and (max-width:1199px) {
	.aside-fixed .aside {
		width: 180px !important;
	}
	.aside-fixed .wrapper {
		padding-left: 180px !important;
	}
	.aside-enabled .header.header-fixed {
		left: 180px !important;
	}
	.eventExprience .EventCopy-Edit {
		max-width: 340px;
	}
	.EventDescript .form-control {
		height: 100px !important;
	}
	.EventDescript .gradientBg {
		font-size: 14px !important;
		padding: 7px 22px !important;
	}
	.EventDescript .MuiFormControl-root .MuiInputBase-root textarea {
		font-size: 12px !important;
	}
	.sub-heading-main .editBtn-detil {
		font-size: 14px;
		font-weight: 600;
		padding: 6px 16px !important;
	}
	.eventExprience p {
		font-size: 13px;
	}
	.sub-heading-main h4 {
		font-size: 23px;
		margin-right: 20px;
	}
	.BasicDetails h3 {
		font-weight: 600;
		font-size: 16px;
	}
	.BasicDetails p {
		font-size: 11px;
	}
	.IconMenuSvg {
		width: 20px;
		height: 20px;
	}
	.top-heading-main .NofifyH1 h1 {
		font-size: 22px !important;
	}


	.top-heading-main .menuRight ul li {
		margin: 0 4px;
	}
	.top-heading-main .menuRight ul li a {
		font-size: 13px;
		padding: 6px 12px;
	}
	.CreateAnEvent .AccountNevent {
		font-weight: 600;
		margin-right: 10px !important;
		font-size: 12px !important;
	}
	.MixhubbLogo img {
		width: 150px;
	}
	.MixhubbLogo img {
		width: 150px;
	}
	.BoothDetailsInfos .EventDescript .form-control {
		height: 70px !important;
	}
	.AddBoothSetup .w-90 {
		width: 100% !important;
	}
	.BtnActionBtn {
		padding: 22px 77px 22px 276px;
	}
	.BtnActionBtn .CommonGtBtn {
		font-size: 13px !important;
		padding: 6px 18px !important;
	}
	.BtnActionBtn .WhiteBtn {
		font-size: 13px !important;
		padding: 6px 10px !important;
	}
	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .menu-link .menu-text {
		font-size: 13px !important;
	}
	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .menu-link {
		padding: 10px 15px !important;
	}
	.nav-tabs .nav-item .nav-link .nav-text {
		font-size: 13px;
	}
	



	.allEvents-add {
		padding-left: 55px !important;
		padding-right: 0% !important;
	}
	.allEvents-add .event-summary-all .event-all-schedule h3 {
		font-size: 15px;
	}
	.allEvents-add .event-summary-all .event-all-schedule label {
		margin: 5px 0 10px;
	}
	.allEvents-add .event-summary-all .eventPicBg {
		width: 140px;
	}
	.allEvents-add .event-summary-all .event-all-schedule .eventStatus {
		font-size: 11px;
	}
	.allEvents-add .event-summary-all .event-all-schedule p {
		font-size: 10px;
	}
	.content .card-custom .card-header .card-title .card-label {
		font-size: 20px;
	}
	.content .card-custom .card-header {
		margin: 0 0 0px;
	}
	.NoSchedeleEvent {
		padding: 12px;
	}
	.NoSchedeleEvent .AddNewEvents .SchedulePicBg {
		padding: 10px;
		float: left;
		width: 90px;
	}
	.NoSchedeleEvent .AddNewEvents .SchedulePicBg img {width: 100%;}
	.NoSchedeleEvent .AddNewEvents .EventScheduleTxt p {
		font-size: 16px;
	}
	.BrandingMixhubInfo .col-md-11 {
		flex: 0 0 100%;
    	max-width: 100%;
	}
	.AllEventContainer .container-fluid {
		padding: 0 10px !important;
	}
	.ListHeaderMain .card-header {
		padding: 0 10px !important;
	}
	.viewAllevent {
		padding-bottom: 70px;
	}
	.OnoffSwitch .MuiSwitch-root {
		padding: 12px 6px !important;
		width: 41px;
		height: 36px;
	}
	.OnoffSwitch .MuiSwitch-root .MuiButtonBase-root {
		padding: 13px 7px !important;
	}
	.OnoffSwitch .MuiSwitch-root .Mui-checked .MuiIconButton-label  {
		position: relative;
    	right: 3px;
	}
	.OnoffSwitch .MuiSwitch-root .MuiButtonBase-root .MuiSwitch-thumb {
		width: 10px !important;
		height: 10px !important;
	}
	.allEvents-add .event-summary-all {
		padding: 10px !important;
		height: 115px;
	}
	.allEvents-add .event-summary-all .event-all-schedule .detail-view {
		padding: 2px 7px;
	}
	.HelpYou {
		right: 36px;
	}
	.allEvents-add .event-summary-all .event-all-schedule {
		padding: 0px 0 0 10px;
	}
	.aside-menu .menu-nav > .menu-item > .menu-link {
		padding: 9px 18px;
	}
	.listType h3 {
		font-size: 28px;
	}
	.listType label {
		font-size: 13px;
	}
	.heading-main h2 {
		font-size: 18px;
	}
	html body .aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
		font-size: 14px !important;
	}


	.sub-heading-main h4 {
		font-size: 20px;
		margin-right: 25px;
	}
	.sub-heading-main .editBtn-detil {
		padding: 5px 18px !important;
		font-size: 14px;
		font-weight: 600;
	}
	.eventExprience h3 {
		font-size: 20px;
	}
	.eventExprience p {
		font-size: 13px;
	}
	.YourNavBarInfo label {
		font-size: 11px !important;
		font-weight: 500;
	}
	.top-heading-main .headingH1 h1 {
		font-size: 22px;
	}
	.top-heading-main .headingH1 span {
		font-size: 13px;
	}
	.top-heading-main .menuRight ul li {
		margin: 0 3px;
	}
	.top-heading-main .menuRight ul li a {
		font-size: 13px;
		font-weight: 600;
	}

	.MainNavYour .tab-pane .NavHeaderTop {width: 100% !important; max-width: 100% !important;margin: 50px auto 0 !important}
	.MainNavYour .tab-pane .NavHeaderTop .MuiGrid-grid-md-12 {padding: 0px !important;}
	.MainNavYour .tab-pane {width: 100% !important; max-width: 100% !important;}
	.BasicDetails {
		padding: 20px 15px 5px;
	}
	.sub-heading-main {
		margin: 12px 0 15px !important;
	}
	.YourNavBarInfo div {
		gap: 4px;
		margin-right: 0px !important;
	}



	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label {
		font-size: 10px;
		padding-left: 17px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label span {
		font-size: 9px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label .radiobtn {
		height: 12px;
    	width: 12px;
		margin: 2px 0 0;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label .radiobtn:after {
		width: 10px;
    	height: 10px;
	}
	.SelectPageTheme .ThemeSelectBrand .EditThemeBtn {
		padding: 6px 0;
		font-size: 12px;
	}
	.SelectPageTheme .ThemeSelectBrand {
		padding: 14px 7px;
		margin: 10px 0 0;
	}
	.feaderList h4 {
		font-size: 14px;
	}
	.HeadingStatic h2 {
		font-size: 16px;
	}
	.BtnActionBtn {
		padding: 22px 48px 22px 228px;
	}
	.ScheduleHeader h5 {
		color: var(--blue);
		font-weight: 600;
		font-size: 16px;
	}
	.CommonGtBtn {
		padding: 6px 18px !important;
	}
	.WhiteBtn {
		padding: 6px 28px !important;
	}
	.card-custom .col-md-11 {max-width: 100%; width: 100%; flex: 0 0 100%}
	.card-custom .col-lg-11 {max-width: 100%; width: 100%; flex: 0 0 100%}

	.card-custom .tab-pane {width: 100% !important; }
	.BasicDetails h3 {
		font-size: 16px;
	}
	.feaderList p {
		font-size: 13px;
		line-height: 17px;
	}
	.RadioLandingPg .checkRadio .radiobtn {
		height: 14px;
		width: 14px;
		margin-left: 8px;
	}
	.RadioLandingPg .checkRadio .radiobtn:after {
		top: -1px;
		left: -1px;
		width: 14px;
		height: 14px;
	}

	.Top-Event-Schedule .Schedule-Date {
		padding: 11px 3px;
	}
	.Top-Event-Schedule .Schedule-Date h5 {
		font-size: 10px;
   		font-weight: 700;
	}
	.Top-Event-Schedule .Schedule-Date h2 {
		font-size: 32px;
		line-height: 50px;
	}
	.Top-Event-Schedule .Schedule-Date p {
		font-size: 9px;
	}
	.Top-Event-Schedule .Schedule-Day-Time {
		padding: 13px 4px;
	}
	.Top-Event-Schedule .Schedule-Day-Time h4 {
		font-size: 12px;
		margin: 0 0 8px;
	}
	.Top-Event-Schedule .Schedule-Day-Time span {
		font-size: 10px;
	}
	.Talk-Speakers {
		margin: 12px 0 0;
	}
	.Talk-Speakers .btn {
		padding: 3px 3px;
		font-size: 8px;
		border-radius: 4px;
	}
	.StageEventPg .Talk-Speakers .btn {
		margin-right: 5px;
	}
	.Talk-Speakers svg {
		width: 10px;
		height: 10px;
		margin-right: 3px;
	}
	.EnterSchedule {
		right: 12px;
		top: 10px;
	}
	.Top-Event-Schedule {
		border: 1px #F1F1F1 solid;
	}
	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .MenuSwitch {
		right: 5px;
	}
	
	
	
	.bar-chart {
		.LayoutRangeBox {
			height: 82px;
			.RangeFeedBak {
				.ButtonRange {
					padding: 6px 6px;
					font-size: 10px;
				}
				&:last-child {
					left: 86.5%;
				}
			}
		}
	}



	.minHeightBox {
		height: auto !important;
		min-height: auto !important;
		aspect-ratio: 16/9;
	}
	.minHeightBox .UploaderFiles .UploadLabel {
		font-weight: 600;
		font-size: 8px;
		line-height: 12px;
		padding: 0;
		width: 100%;
	}
	.minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		width: 35px;
		margin: 0 0 5px;
	}
	.nav-tabs .nav-item .nav-link .nav-text {
		font-size: 13px;
	}
	.CheckBoxTicl .check-group label:before {
		padding: 8px;
	}
	.CheckBoxTicl .check-group input:checked + label:after {
		top: 6px;
		height: 9px;
		left: 7px;
		width: 5px;
	}
	.HeadingStatic img {
		width: 28px;
	}
	.HeadingStatic.mt-10.mb-6 {
		margin: 32px 0 12px!important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadPreviewIcon {
		right: 40px !important;
	}
	.LiveStream h4 {
		padding-right: 0px !important;
		font-size: 11px !important;
	}
	.LiveStream img {
		width: 30px;
		margin-right: 2px;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadPreviewIcon .UploadPreviewIco {
		width: 21px !important;
    	height: 21px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadPreviewIcon {
		width: 21px !important;
    	height: 21px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadImgIcon {
		width: 21px !important;
    	height: 21px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadImgIcon .UploadDeleteIco {
		width: 21px !important;
    	height: 21px !important;
	}
	.LiveStream {
		margin: 15px 0 0;
	}
	.EnableEmoji ul li {
		padding: 0 2px;
	}
	.feaderList {
		padding: 14px 12px;
		min-height: 122px;
	}
	.feaderList p {
		font-size: 13px;
		line-height: 16px;
	}
	.feaderList h5 {
		font-size: 12px;
	}
	.scrolltop {
		width: 42px;
		height: 42px;
		bottom: 22px;
	}
	.scrolltop .svg-icon svg {
		height: 21px;
		width: 21px;
	}
	.eventExprience .EventCopy-Edit .CopyHere {
		font-size: 11px;
		width: 105px;
		padding-left: 0 !important;
	}
	.eventExprience .EventCopy-Edit .copyLinkDel {
		padding-right: 4px !important;
	}
	.eventExprience .StreamIngLive .CopyHere {
		padding: 7px 6px;
		font-size: 9px;
		width: 190px;
		padding-left: 0 !important;
		font-weight: bold;
	}
	.eventExprience .StreamIngLive .copyLinkDel {
		padding: 7px;
		padding-right: 4px !important;
	}

	.DesignSetupStage .stateForm {padding-left: 0px !important;}


	.StageEventPg {
		border: 1px #E4E6EF solid;
		padding: 13px;
	}
	.EnterStage {
		right: 19px;
		top: 17px;
	}
	.StageEventPg h4 {
		font-size: 15px;
		padding-right: 25px;
		font-weight: 600;
	}
	.RightIcons .EditDel .iconSvg {
		width: 23px;
		height: 24px;
		padding: 4px 4px;
	}
	.EnterStage svg {
		width: 14px;
		height: 14px;
	}
	.RightIcons .EditDel .DelIcon {
		margin-right: 0px !important;
	}
	.StageEventPg p {
		font-size: 12px;
	}

	.Responsive-col {
		flex: 0 0 83.3333333333% !important;
    	max-width: 83.3333333333% !important;
	}

	.table.SpeakersTables tbody tr td .ActionSvg .ActionIcon {
		display: inline-block;
		cursor: pointer;
		margin: 0 2px;
	}
	.table.SpeakersTables tbody tr td:last-child {
		width: 15%;
	}
	.table.SpeakersTables tbody tr td img {
		margin-right: 10px;
		width: 40px;
	}
	.table.SpeakersTables thead th, .table.SpeakersTables tbody tr td {
		font-size: 13px !important;
	}
	.table.Rooms thead th, .table.Rooms tbody tr td {
		font-size: 13px !important;
	}
	.ScheduleHeader {
		margin-bottom: 10px !important;
	}





	.InviteSpeakerBox {
		padding-right: 0px !important;
	}
	.NetworkingSetup .uplodLogo h5 {
		font-size: 13px;
		line-height: 17px;
	}
	.FilesRules {
		margin: 22px 0 8px 0px;
	}
	.FilesRules h3 {
		font-size: 16px;
	}
	.FilesRules ul li {
		font-size: 12px;
		margin-bottom: 14px;
	}
	.InviteSpeakerBox {
		.View-Col-Min {
			flex: 0 0 360px !important;
			max-width: 360px !important;
			.minHeightImgs {
				.img-fluid {
					margin-bottom: 12px;
				}
			}
		}
		.View-Col-Max {
			flex: 0 0 100% !important;
			max-width: 100% !important;
		}
	}


	.BoothCardInfo .TypeOfBooth .SearchBooth {
		width: 250px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg .BoothLogo .img-fluid {
		height: 60px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothEditDelete .EditDel .iconSvg {
		width: 28px;
		height: 28px;
		padding: 3px 6px;
	}






	.BoothInnerSetup .TagsWebsite {
		margin: 0 0 0px;
	}
	.feaderList p {
		font-size: 13px;
	}
	.feaderList h4 {
		font-size: 15px;
	}
	.ExpoAdditionalFeature .BoothSetupHeight {
		min-height: 108px !important;
	}
	.LogoCoverUpload .minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		width: 37px;
		margin: 0 0 5px;
	}
	.BoothInnerSetup .TagsWebsite .mt-6 {
		margin-top: 8px !important;
	} 
	.BoothInnerSetup .TagsWebsite .stateForm label {
		font-size: 12px;
	}
	.BoothInnerSetup .LogoCoverUpload .TextToolTip label {
		font-size: 12px;
	}
	.AddBoothSetup .w-90 {
		width: 100% !important;
	}
	.DisclaimerTable tbody tr td .fieldTypeAction label {
		font-size: 12px;
	}
	.DisclaimerTable tbody tr td:nth-child(4) {
		width: 26%;
	}
	.LandingStylesInner .menuRight a {
		font-weight: 600;
		font-size: 14px;
	}
	.LandingStylesInner .menuRight li {
		margin: 0 5px;
	}

	.WithAddMore {
		margin: 0 0 20px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) img {
		height: 65px;
	}
	.InformLandingTable .EventSponsor tbody tr td p {
		font-size: 14px;
		min-height: 35px;
	}
	.InformLandingTable .EventSponsor tbody tr td {
		font-size: 14px;
	}
	.InformLandingTable .EventSponsor tbody tr td:last-child {
		width: 10%;
	}
	.InformLandingTable .EventSponsor tbody tr td .ActionIcon {
		width: 32px;
		height: 32px;
	}
	.AboutEventPg .form-control {
		height: 110px;
		font-size: 14px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns h3 {
		font-size: 15px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns p {
		font-size: 13px;
	}


	.EventLogoupload {
		padding-right: 20px !important;
	}
	.BreakerCreativeupload {
		padding-left: 20px !important;
	}
	.BrandingHeadMain .sub-heading-main h4 {
		font-size: 19px;
		margin: 0;
	}
	.brandingColorPix .MuiGrid-root {
		margin-bottom: 14px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .img-fluid {
		height: 100%;
	}
	.minHeightBox .default-image-selected .UploadLabel {
		height: 100% !important;
		width: 100% !important;
	}
	.colorCodePx {
		height: 38px;
		width: 110px;
	}


	.DesignColorPicker .colorCodePx {
		width: 130px !important;
	}
	.LogoBgUploadIn .HeadingStatic, .LogoBgUploadIn .WithAddMore {margin-bottom: 0px !important;}
	.updateCoverDesign {
		padding-right: 0px !important;
	}
	.CompanyLogoUpload {
		padding: 0 0px !important;
	}
	.UpdateEventLogoDegns {
		padding-left: 0px !important;
	}
	.widgetContainer {
		max-width: 100% !important;
	}
	.FooterButton .CommonGtBtn, .FooterButton .BlueWhiteBtn {
		min-width: 100% !important;
		margin-right: 0px;
		margin-bottom: 15px;
		padding: 10px 0 !important;
	}
	.FooterButton .BlueWhiteBtn { 
		padding: 8px 0 !important;
	}
	.OtherWidgets {
		padding: 0 10px;
	}
	.OrganizationListAll {
		.col-lg-3 {
			flex: 0 0 33.3333333333% !important;
			max-width: 33.3333333333% !important;
		}
	}
	.table.NotificatonTable {
		width: 1000px;
	}
	.table.EmailTempTable {
		width: 1000px;
	}
	.table.FeedBackTable {
		width: 900px;
	}
	.LogoMixhibbLeft .brand-logo img {
		width: 170px;
	}
	.subscribeHeader .subscribeLogo img {
		width: 170px;
	}

	.Trial-Fix-Cradit {
		margin: 0 !important;
		flex: 0 0 75.666667% !important;
		max-width: 75.666667% !important;
			.Select-AddonsMx {
				.AddonsTable {
					.table-responsive {
						table.AddonsPreTables {
							width: 700px;
							tbody {
								tr {
									td {
										font-size: 16px;
										padding: 18px 14px !important;
									}
								}
							}
						}
					}
				}
			}
		.PlanEventChargesTable {
			.row {
				.Plan-max-trial {
					flex: 0 0 50% !important;
					max-width: 50% !important;
				}
			}
		}
	}
	.FreeTrailPlan {
		.PlanEventChargesTable {
			.MainPlanTrial {
				padding: 15px;
				.PlanCoastTrial {
					h3 {
						font-size: 18px;
						span {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
	.UpgradePlaneFx  {
		.ChrgeBee {
			.Plan-max-trial {
				flex: 0 0 50% !important;
				max-width: 50% !important;
			}
		}
	}
  .Select-AddonsMx {
    .AddonsTable {
      .table-responsive {
        table.AddonsPreTables {
          thead {
            tr {
              th {
                font-size: 14px !important;
              }
            }
          }
          tbody {
            tr {
              td {
                font-size: 14px !important;
                padding: 20px 14px !important;
              }
            }
          }
        }
      }
    }
  }
	.DashBoardInfo {
		padding: 16px 16px;	
		min-height: 85px;
		.DBIngoFull {
			h3 {
				font-size: 13px;
				margin: 0 0 10px;
			}
		}
		.PathLink {
			svg {
				width: 12px;
				height: 12px;
			}
		}
		.IconDBInfo {
			margin-right: 16px;
			svg {
				width: 36px;
				height: 36px;
			}
		}
	}
	.VistLocat {
		justify-content: center;
		align-items: center;
		.DBIngoFull {
			h3 {
				margin: 0;
			}
		}
	}
	.liveSessions {
		.ActiveTimeCountAll {
			h5 {
				font-size: 16px;
			}
		}
		.AverageTimeLogged {
			.ActiveLoggedCount {
				.SpeakerDBactive {
					span {
						font-size: 14px;
					}
					b {
						font-size: 15px;
					}
				}
			}
		}
	}
	.MiscellanLg-wt {
		flex: 0 0 50%;
    	max-width: 50%;
	}
	.MiscellanList {
		padding: 14px 10px !important;
		.SwitchRight {
			right: 4px;
		}
		h4 {
			font-size: 14px;
			font-weight: 700;
		}
		p {
			font-size: 12px;
		}
		.ChatGroutAction {
			margin-top: 12px;
			.BdrButton {
				font-size: 11px;
				padding: 4px 6px;
				margin-right: 10px;
				svg {
					margin-right: 8px;
					width: 14px;
					height: 14px;
				}
				&:last-child {
					margin-right: 0px !important;
				}
			}
		}
	}
	.UserListHelp {
		.UserfeaderList {
			margin: 0 0 18px;
			.helpheader {
				font-size: 13px;
    			padding: 5px 12px;
			}
			.HelpUserAction {
				.ActionSvg {
					margin: 0 4px;
					padding: 6px 6px;
					.ActionIcon {
						width: 16px;
						height: 16px;
					}
				}
			}
		}
		h3 {
			font-size: 14px;
			margin: 0 0 8px;
		}
		p {
			font-size: 12px;
			word-break: break-word;
		}
	}
	.AttendeeUtm .AttendeePrior {
		width: 38%;
	}
}
@media (max-width:1024px) {
	ul.expo-report-big-cards li{max-width: 230px;}
}
@media(min-width:992px) and (max-width:1023px) {
	.table.EmailTempTable {
		width: 1000px;
	}
	.aside-fixed .aside {
		width: 180px !important;
	}
	.aside-fixed .wrapper {
		padding-left: 180px !important;
	}
	.aside-enabled .header.header-fixed {
		left: 180px !important;
	}
	.eventExprience .EventCopy-Edit {
		max-width: 340px;
	}
	.EventDescript .form-control {
		height: 130px !important;
	}
	.EventDescript .gradientBg {
		font-size: 14px !important;
		padding: 7px 22px !important;
	}
	.EventDescript .MuiFormControl-root .MuiInputBase-root textarea {
		font-size: 12px !important;
	}
	.sub-heading-main .editBtn-detil {
		font-size: 14px;
		font-weight: 600;
		padding: 6px 16px !important;
	}
	.eventExprience p {
		font-size: 13px;
	}
	.sub-heading-main h4 {
		font-size: 23px;
		margin-right: 20px;
	}
	.BasicDetails h3 {
		font-weight: 600;
		font-size: 16px;
	}
	.BasicDetails p {
		font-size: 11px;
	}
	.IconMenuSvg {
		width: 20px;
		height: 20px;
	}


	.top-heading-main .menuRight ul li {
		margin: 0 4px;
	}
	.top-heading-main .menuRight ul li a {
		font-size: 13px;
		padding: 6px 12px;
	}
	.CreateAnEvent .AccountNevent {
		font-weight: 600;
		margin-right: 10px !important;
		font-size: 12px !important;
	}
	.MixhubbLogo img {
		width: 150px;
	}
	.MixhubbLogo img {
		width: 150px;
	}
	.BoothDetailsInfos .EventDescript .form-control {
		height: 70px !important;
	}
	.AddBoothSetup .w-90 {
		width: 100% !important;
	}
	.BtnActionBtn {
		padding: 22px 77px 22px 276px;
	}
	.BtnActionBtn .CommonGtBtn {
		font-size: 13px !important;
		padding: 6px 18px !important;
	}
	.BtnActionBtn .WhiteBtn {
		font-size: 13px !important;
		padding: 6px 10px !important;
	}
	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .menu-link .menu-text {
		font-size: 13px !important;
	}
	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .menu-link {
		padding: 10px 15px !important;
	}
	.nav-tabs .nav-item .nav-link .nav-text {
		font-size: 13px;
	}
	



	.allEvents-add {
		padding-left: 55px !important;
		padding-right: 0% !important;
	}
	.allEvents-add .event-summary-all .event-all-schedule h3 {
		font-size: 15px;
	}
	.allEvents-add .event-summary-all .event-all-schedule label {
		margin: 5px 0 6px;
	}
	.allEvents-add .event-summary-all .eventPicBg {
		width: 140px;
	}
	.allEvents-add .event-summary-all .event-all-schedule .eventStatus {
		font-size: 11px;
	}
	.allEvents-add .event-summary-all .event-all-schedule p {
		font-size: 10px;
	}
	.content .card-custom .card-header .card-title .card-label {
		font-size: 20px;
	}
	.content .card-custom .card-header {
		margin: 0 0 0px;
	}
	.NoSchedeleEvent {
		padding: 12px;
	}
	.NoSchedeleEvent .AddNewEvents .SchedulePicBg {
		padding: 10px;
		float: left;
		width: 90px;
	}
	.NoSchedeleEvent .AddNewEvents .SchedulePicBg img {width: 100%;}
	.NoSchedeleEvent .AddNewEvents .EventScheduleTxt p {
		font-size: 16px;
	}
	.BrandingMixhubInfo .col-md-11 {
		flex: 0 0 100%;
    	max-width: 100%;
	}
	.AllEventContainer .container-fluid {
		padding: 0 10px !important;
	}
	.ListHeaderMain .card-header {
		padding: 0 10px !important;
	}
	.viewAllevent {
		padding-bottom: 70px;
	}
	.OnoffSwitch .MuiSwitch-root {
		padding: 12px 6px !important;
		width: 41px;
		height: 36px;
	}
	.OnoffSwitch .MuiSwitch-root .MuiButtonBase-root {
		padding: 13px 7px !important;
	}
	.OnoffSwitch .MuiSwitch-root .Mui-checked .MuiIconButton-label  {
		position: relative;
    	right: 3px;
	}
	.OnoffSwitch .MuiSwitch-root .MuiButtonBase-root .MuiSwitch-thumb {
		width: 10px !important;
		height: 10px !important;
	}
	.allEvents-add .event-summary-all {
		padding: 10px !important;
		height: 115px !important;
	}
	.allEvents-add .event-summary-all .event-all-schedule .detail-view {
		padding: 2px 7px;
	}
	.HelpYou {
		right: 36px;
	}
	.allEvents-add .event-summary-all .event-all-schedule {
		padding: 0px 0 0 10px;
	}
	.aside-menu .menu-nav > .menu-item > .menu-link {
		padding: 9px 18px;
	}
	.listType h3 {
		font-size: 28px;
	}
	.listType label {
		font-size: 13px;
	}
	.heading-main h2 {
		font-size: 18px;
	}
	html body .aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
		font-size: 14px !important;
	}


	.sub-heading-main h4 {
		font-size: 20px;
		margin-right: 25px;
	}
	.sub-heading-main .editBtn-detil {
		padding: 5px 18px !important;
		font-size: 14px;
		font-weight: 600;
	}
	.eventExprience h3 {
		font-size: 20px;
	}
	.eventExprience p {
		font-size: 13px;
	}
	.YourNavBarInfo label {
		font-size: 11px !important;
		font-weight: 500;
	}
	.top-heading-main .headingH1 h1 {
		font-size: 22px;
	}
	.top-heading-main .headingH1 span {
		font-size: 13px;
	}
	.top-heading-main .menuRight ul li {
		margin: 0 3px;
	}
	.top-heading-main .menuRight ul li a {
		font-size: 13px;
		font-weight: 600;
	}

	.MainNavYour .tab-pane .NavHeaderTop {width: 100% !important; max-width: 100% !important;margin: 50px auto 0 !important}
	.MainNavYour .tab-pane .NavHeaderTop .MuiGrid-grid-md-12 {padding: 0px !important;}
	.MainNavYour .tab-pane {width: 100% !important; max-width: 100% !important;}
	.BasicDetails {
		padding: 20px 15px 5px;
	}
	.sub-heading-main {
		margin: 12px 0 15px !important;
	}
	.YourNavBarInfo div {
		gap: 4px;
		margin-right: 0px !important;
	}

	.top-heading-main .NofifyH1 h1 {
		font-size: 22px !important;
	}

	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label {
		font-size: 10px;
		padding-left: 17px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label span {
		font-size: 9px;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label .radiobtn {
		height: 12px;
    	width: 12px;
		margin: 2px 0 0;
	}
	.SelectPageTheme .ThemeSelectBrand .RadioLandingPg label .radiobtn:after {
		width: 10px;
    	height: 10px;
	}
	.SelectPageTheme .ThemeSelectBrand .EditThemeBtn {
		padding: 6px 0;
		font-size: 12px;
	}
	.SelectPageTheme .ThemeSelectBrand {
		padding: 14px 7px;
	}
	.feaderList h4 {
		font-size: 14px;
	}
	.HeadingStatic h2 {
		font-size: 16px;
	}
	.BtnActionBtn {
		padding: 22px 48px 22px 228px;
	}
	.ScheduleHeader h5 {
		color: var(--blue);
		font-weight: 600;
		font-size: 16px;
	}
	.CommonGtBtn {
		padding: 6px 18px !important;
	}
	.WhiteBtn {
		padding: 6px 28px !important;
	}
	.card-custom .col-md-11 {max-width: 100%; width: 100%; flex: 0 0 100%}
	.card-custom .col-lg-11 {max-width: 100%; width: 100%; flex: 0 0 100%}

	.card-custom .tab-pane {width: 100% !important; }
	.BasicDetails h3 {
		font-size: 16px;
	}
	.feaderList p {
		font-size: 13px;
		line-height: 17px;
	}
	.RadioLandingPg .checkRadio .radiobtn {
		height: 15px;
		width: 15px;
		margin-left: 8px;
	}

	.Top-Event-Schedule .Schedule-Date {
		padding: 11px 3px;
	}
	.Top-Event-Schedule .Schedule-Date h5 {
		font-size: 10px;
   		font-weight: 700;
	}
	.Top-Event-Schedule .Schedule-Date h2 {
		font-size: 32px;
		line-height: 50px;
	}
	.Top-Event-Schedule .Schedule-Date p {
		font-size: 9px;
	}
	.Top-Event-Schedule .Schedule-Day-Time {
		padding: 13px 4px;
	}
	.Top-Event-Schedule .Schedule-Day-Time h4 {
		font-size: 12px;
		margin: 0 0 8px;
	}
	.Top-Event-Schedule .Schedule-Day-Time span {
		font-size: 10px;
	}
	.Talk-Speakers {
		margin: 16px 0 0;
	}
	.Talk-Speakers .btn {
		padding: 3px 3px;
		font-size: 9px;
		border-radius: 4px;
	}
	.StageEventPg .Talk-Speakers .btn {
		margin-right: 5px;
	}
	.Talk-Speakers svg {
		width: 10px;
		height: 10px;
		margin-right: 3px;
	}
	.EnterSchedule {
		right: 12px;
		top: 10px;
	}
	.Top-Event-Schedule {
		border: 1px #F1F1F1 solid;
	}
	.aside-menu-wrapper .aside-menu .MenuNavSecond .menu-item .MenuSwitch {
		right: 5px;
	}
	
	





	.minHeightBox {
		height: auto !important;
		min-height: auto !important;
		aspect-ratio: 16/9;
	}
	.minHeightBox .UploaderFiles .UploadLabel {
		font-weight: 600;
		font-size: 10px !important;
		line-height: 15px;
		padding: 0;
		width: 100%;
	}
	.minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		width: 35px;
		margin: 0 0 5px;
	}
	.nav-tabs .nav-item .nav-link .nav-text {
		font-size: 13px;
	}
	.CheckBoxTicl .check-group label:before {
		padding: 8px;
	}
	.CheckBoxTicl .check-group input:checked + label:after {
		top: 6px;
		height: 9px;
		left: 7px;
		width: 5px;
	}
	.HeadingStatic img {
		width: 28px;
	}
	.HeadingStatic.mt-10.mb-6 {
		margin: 32px 0 12px!important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadPreviewIcon {
		right: 40px !important;
	}

	.LiveStream h4 {
		padding-right: 0px !important;
		font-size: 10px !important;
	}
	.LiveStream img {
		width: 27px;
		margin-right: 2px;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadPreviewIcon .UploadPreviewIco {
		width: 21px !important;
    	height: 21px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadPreviewIcon {
		width: 21px !important;
    	height: 21px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadImgIcon {
		width: 21px !important;
    	height: 21px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .uploadImgIcon .UploadDeleteIco {
		width: 21px !important;
    	height: 21px !important;
	}
	.feaderList {
		padding: 14px 12px;
		min-height: 122px;
	}
	.feaderList p {
		font-size: 13px;
		line-height: 16px;
	}
	.feaderList h5 {
		font-size: 12px;
	}
	.scrolltop {
		width: 42px;
		height: 42px;
		bottom: 22px;
	}
	.scrolltop .svg-icon svg {
		height: 21px;
		width: 21px;
	}
	.eventExprience .EventCopy-Edit .CopyHere {
		font-size: 11px;
		width: 120px;
		padding-left: 0 !important;
	}
	.eventExprience .EventCopy-Edit .copyLinkDel {
		padding-right: 4px !important;
	}
	.eventExprience .StreamIngLive .CopyHere {
		padding: 7px 4px;
		font-size: 9px;
		width: 195px;
		padding-left: 0 !important;
		font-weight: bold;
	}
	.eventExprience .StreamIngLive .copyLinkDel {
		padding: 7px;
		padding-right: 4px !important;
	}



	.StageEventPg {
		border: 1px #E4E6EF solid;
		padding: 13px;
	}
	.EnterStage {
		right: 19px;
		top: 17px;
	}
	.StageEventPg h4 {
		font-size: 15px;
		padding-right: 25px;
		font-weight: 600;
	}
	.RightIcons .EditDel .iconSvg {
		width: 23px;
		height: 24px;
		padding: 4px 4px;
	}
	.EnterStage svg {
		width: 14px;
		height: 14px;
	}
	.RightIcons .EditDel .DelIcon {
		margin-right: 0px !important;
	}
	.StageEventPg p {
		font-size: 12px;
	}

	.Responsive-col {
		flex: 0 0 83.3333333333% !important;
    	max-width: 83.3333333333% !important;
	}

	.table.SpeakersTables tbody tr td .ActionSvg .ActionIcon {
		display: inline-block;
		cursor: pointer;
		margin: 0 2px;
	}
	.table.SpeakersTables tbody tr td:last-child {
		width: 15%;
	}
	.table.SpeakersTables tbody tr td img {
		margin-right: 10px;
		width: 40px;
	}
	.table.SpeakersTables thead th, .table.SpeakersTables tbody tr td {
		font-size: 13px !important;
	}
	.table.Rooms thead th, .table.Rooms tbody tr td {
		font-size: 13px !important;
	}
	.ScheduleHeader {
		margin-bottom: 10px !important;
	}





	.InviteSpeakerBox {
		padding-right: 0px !important;
	}
	.NetworkingSetup .uplodLogo h5 {
		font-size: 13px;
		line-height: 17px;
	}
	.FilesRules {
		margin: 22px 0 8px 0px;
	}
	.FilesRules h3 {
		font-size: 16px;
	}
	.FilesRules ul li {
		font-size: 12px;
		margin-bottom: 14px;
	}
	.InviteSpeakerBox {
		.View-Col-Min {
			flex: 0 0 360px !important;
			max-width: 360px !important;
			.minHeightImgs {
				.img-fluid {
					margin-bottom: 12px;
				}
			}
		}
		.View-Col-Max {
			flex: 0 0 100% !important;
			max-width: 100% !important;
		}
	}


	.BoothCardInfo .TypeOfBooth .SearchBooth {
		width: 250px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg .BoothLogo .img-fluid {
		height: 60px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothEditDelete .EditDel .iconSvg {
		width: 28px;
		height: 28px;
		padding: 3px 6px;
	}






	.BoothInnerSetup .TagsWebsite {
		margin: 0 0 0px;
	}
	.feaderList p {
		font-size: 13px;
	}
	.feaderList h4 {
		font-size: 15px;
	}
	.ExpoAdditionalFeature .BoothSetupHeight {
		min-height: 108px !important;
	}
	.LogoCoverUpload .minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		width: 37px;
		margin: 0 0 5px;
	}
	.BoothInnerSetup .TagsWebsite .mt-6 {
		margin-top: 8px !important;
	} 
	.BoothInnerSetup .TagsWebsite .stateForm label {
		font-size: 12px;
	}
	.BoothInnerSetup .LogoCoverUpload .TextToolTip label {
		font-size: 12px;
	}
	.DisclaimerTable tbody tr td .fieldTypeAction label {
		font-size: 12px;
	}
	.DisclaimerTable tbody tr td:nth-child(4) {
		width: 26%;
	}
	.LandingStylesInner .menuRight a {
		font-weight: 600;
		font-size: 14px;
	}
	.LandingStylesInner .menuRight li {
		margin: 0 5px;
	}

	.WithAddMore {
		margin: 0 0 20px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) img {
		height: 70px;
	}
	.InformLandingTable .EventSponsor tbody tr td p {
		font-size: 14px;
		min-height: 38px;
	}
	.InformLandingTable .EventSponsor tbody tr td {
		font-size: 14px;
	}
	.InformLandingTable .EventSponsor tbody tr td:last-child {
		width: 10%;
	}
	.InformLandingTable .EventSponsor tbody tr td .ActionIcon {
		width: 32px;
		height: 32px;
	}
	.AboutEventPg .form-control {
		height: 110px;
		font-size: 14px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns h3 {
		font-size: 15px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns p {
		font-size: 13px;
	}


	.EventLogoupload {
		padding-right: 20px !important;
	}
	.BreakerCreativeupload {
		padding-left: 20px !important;
	}
	.BrandingHeadMain .sub-heading-main h4 {
		font-size: 19px;
		margin: 0;
	}
	.brandingColorPix .MuiGrid-root {
		margin-bottom: 14px !important;
	}
	.minHeightBox .default-image-selected .UploadLabel .img-fluid {
		height: 100%;
	}
	.minHeightBox .default-image-selected .UploadLabel {
		height: 100% !important;
		width: 100% !important;
	}
	.colorCodePx {
		height: 38px;
	}


	.DesignColorPicker .colorCodePx {
		width: 130px !important;
	}
	.LogoBgUploadIn .HeadingStatic, .LogoBgUploadIn .WithAddMore {margin-bottom: 0px !important;}
	.updateCoverDesign {
		padding-right: 0px !important;
	}
	.CompanyLogoUpload {
		padding: 0 0px !important;
	}
	.UpdateEventLogoDegns {
		padding-left: 0px !important;
	}
	.DesginPaddUpload label {
		font-size: 12px !important;
	}
	.LiveStream {
		margin: 8px 0 0;
	}
	.OrganizationListAll {
		.col-lg-3 {
			flex: 0 0 33.3333333333% !important;
			max-width: 33.3333333333% !important;
		}
	}
	table.EventSponsor {
		width: 1000px;
	}
	.subscribeHeader .subscribeLogo img {
		width: 170px;
	}
	.Trial-Fix-Cradit {
		margin: 0 !important;
		flex: 0 0 77.666667% !important;
		max-width: 77.666667% !important;
			.Select-AddonsMx {
				.AddonsTable {
					.table-responsive {
						table.AddonsPreTables {
							width: 700px;
							tbody {
								tr {
									td {
										font-size: 16px;
										padding: 18px 14px !important;
									}
								}
							}
						}
					}
				}
			}
		.PlanEventChargesTable {
			.row {
				.Plan-max-trial {
					flex: 0 0 50% !important;
					max-width: 50% !important;
				}
			}
		}
	}
	.UpgradePlaneFx  {
		.ChrgeBee {
			.Plan-max-trial {
				flex: 0 0 50% !important;
				max-width: 50% !important;
			}
		}
	}
	.Trial-Fix-Cradit {
		.PlanEventChargesTable {
			.MainPlanTrial {
				padding: 16px;
				.PlanCoastTrial {
					h3 {
						font-size: 16px;
						span {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
  .Select-AddonsMx {
    .AddonsTable {
      .table-responsive {
        table.AddonsPreTables {
          width: 660px;
          thead {
            tr {
              th {
                font-size: 15px !important;
              }
            }
          }
          tbody {
            tr {
              td {
                font-size: 15px !important;
                padding: 20px 18px !important;
              }
            }
          }
        }
      }
    }
  }
  .bar-chart {
	.LayoutRangeBox {
		height: 82px;
		.RangeFeedBak {
			.ButtonRange {
				padding: 6px 6px;
				font-size: 10px;
			}
			&:last-child {
				left: 86.5%;
			}
		}
	}
	}
	.PurchageHisTable {
		.top-heading-main {
			margin: 22px 0 16px !important;
		}
	}
	.MiscellanLg-wt {
		flex: 0 0 50%;
    	max-width: 50%;
	}
	.MiscellanList {
		padding: 14px 10px !important;
		.SwitchRight {
			right: 4px;
		}
		h4 {
			font-size: 14px;
			font-weight: 700;
		}
		p {
			font-size: 12px;
		}
		.ChatGroutAction {
			margin-top: 12px;
			.BdrButton {
				font-size: 11px;
				padding: 4px 6px;
				margin-right: 10px;
				svg {
					margin-right: 8px;
					width: 14px;
					height: 14px;
				}
				&:last-child {
					margin-right: 0px !important;
				}
			}
		}
	}
	.UserListHelp {
		.UserfeaderList {
			margin: 0 0 18px;
			.helpheader {
				font-size: 13px;
    			padding: 5px 12px;
			}
			.HelpUserAction {
				.ActionSvg {
					margin: 0 4px;
					padding: 6px 6px;
					.ActionIcon {
						width: 16px;
						height: 16px;
					}
				}
			}
		}
		h3 {
			font-size: 14px;
			margin: 0 0 8px;
		}
		p {
			font-size: 12px;
			word-break: break-word;
		}
	}
	.AttendeeUtm .AttendeePrior {
		width: 38%;
	}
}
@media(max-width:991px) {
	.subscribeHeader .subscribeLogo img {
		width: 150px;
	}
	.FinalEventPlan {
		width: 100% !important;
		padding: 0 80px;
	}
	.bar-chart {
		margin-top: 25px;
	}
	.RegisteruserPt .login-form p {
		padding: 0 0px;
	}
	.add-new-field-sticky-bar {
		position: static;
		top: 0;
		background-color: #fff;
		z-index: 1;
		padding-top: 0px;
	}
}
@media(min-width:768px) and (max-width:991px) {
	.BtnActionBtn {
		padding: 20px 50px !important;
		text-align: center;
	}
	.AllEventContainer .ListEventNo .col-md-2 {
		width: 33% !important;
	}
	.AllEventContainer .ListEventNo .listType h3 {
		font-size: 28px;
	}
	.UpcomingListEvent .allEvents-add {
		padding-left: 0;
		margin: 42px 0 0;
		padding-right: 0;
	}
	.UpcomingListEvent .allEvents-add .yestDate {
		height: auto;
    	top: -30px;
		width: 100%;
		font-size: 14px;
		left: 0;
	}
	.UpcomingListEvent .allEvents-add .yestDate span {
		background-color: #fff;
		position: relative;
		z-index: 3;
		padding-right: 10px;
	}
	.UpcomingListEvent .allEvents-add .yestDate:after {
		height: auto;
		top: 50%;
		margin: 0px auto;
		width: 100%;
		border-bottom: 1px #222 dashed;
	}
	.UpcomingListEvent .EventPageInfos {margin: 0px !important;}
	.UpcomingListEvent .EventPageInfos .viewAllevent {
		margin: 20px 0 0 !important;
	}
	.heading-main h2 {
		font-size: 18px;
	}
	
	.NoSchedeleEvent {
		margin: 15px 0 18px;
	}
	.table.EmailTempTable {
		width: 1000px;
	}
	.HelpYou {
		float: left;
		right: inherit;
		bottom: 0;
		left: 10px;
	}
	.allEvents-add .event-summary-all .event-all-schedule {
		padding: 0px 0 0 10px;
	}
	.allEvents-add .event-summary-all .eventPicBg {
		width: 140px;
	}
	.allEvents-add .event-summary-all .event-all-schedule .eventStatus {
		font-size: 12px;
		line-height: 12px;
	}
	.allEvents-add .event-summary-all .event-all-schedule h3 {
		font-size: 15px;
	}
	.allEvents-add .event-summary-all .event-all-schedule .detail-view {
		font-size: 13px;
		padding: 4px 8px;
	}
	.allEvents-add .event-summary-all .event-all-schedule p {
		font-size: 11px;
		margin: 0 0 3px;
	}
	.allEvents-add .event-summary-all .event-all-schedule label {
		margin: 3px 0 7px;
	}
	.UpcomingListEvent .allEvents-add .event-summary-all {
		margin: 12px 0 22px;
		padding: 8px;
		height: 110px !important;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header {
		margin: 0px;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header .card-title .MuiPaper-root {
		width: 280px;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header .card-title .card-label {
		margin: 0 0 16px;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header .card-title {
		margin: 0 0 12px;
	}
	.ListHeaderMain .card-header {padding: 0px;}

	.ListHeaderOne {
		.top-heading-main {
			margin: 20px 0 24px !important;
			.headingH1 {
				display: inline-block;
				h1 {
					font-size: 22px;
					margin-bottom: 16px;
				}
			}
			.eventExprience {
				text-align: left !important;
				float: right;
				.EventCopy-Edit {
					max-width: 300px !important;
					.copyLinkDel {
						padding-right: 0px !important;
					}
					.CopyHere {
						width: 175px;
					}
				}
			}
		}
	}

	.ScheduleHeader {
		margin: 0 0 10px !important;
		h5 {
			font-size: 18px;
		}
	}
	.ExpoZones h4 {
		font-size: 15px;
		margin: 0 0 8px;
	} 
	.ExpoZones p {
		font-size: 13px;
		line-height: 13px;
	}
	.ZoneTypeBooth .ZoonEventPg {
		padding: 16px;
	}
	.ZoneTypeBooth .ZoonEventPg p {
		padding-right: 0px !important;
	}
	.ZoneTypeBooth .ZoonEventPg h4 {
		font-size: 15px;
		padding-right: 0px !important;
		margin-bottom: 5px;
	}
	.ExpoAddZones {
		margin: 25px 0 0;
	}
	.ExpoAddZones .CommonGtBtn {
		margin-right: 15px !important;
	}
	.BoothCardInfo {
		padding: 10px 10px;
		margin: 20px 0;
	}.BoothCardInfo .TypeOfBooth {
		display: block;
	}
	.BoothCardInfo .TypeOfBooth .SearchBooth {
		width: 100%;
		margin: 18px 0 0;
	}
	.BoothCardInfo .TypeOfBooth .boothAddType h4 {
		font-size: 16px;
	}
	.CommonGtBtn {
		font-size: 14px !important;
		padding: 5px 12px !important;
	}
	.BoothCardInfo .TypeOfBooth .boothAddType .CommonGtBtn {
		float: right;
	}
	.BoothCardInfo .BoothDetailsInfo {
		padding: 12px 12px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg .BoothLogo {
		width: 95px;
		margin-right: 8px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg h3 {
		font-size: 15px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothEditDelete .EditDel .iconSvg {
		width: 24px;
		height: 24px;
		margin: 0 2px;
		padding: 1px 5px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothEditDelete .EditDel {margin-right: 0px !important;}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg .BoothLogo .img-fluid {
		height: 40px;
	}
	.scrolltop {
		width: 50px;
		height: 50px;
		bottom: 22px;
	}
	.scrolltop .svg-icon svg {
		height: 23px;
		width: 23px;
	}
	.eventExprience .EnterEventin .Event-EnterBtn {
		padding: 6px 8px !important;
	}
	.content .container .row .col-md-11, .content .container .row .col-sm-11, .content .container .row .col-lg-11, .container .row .col-lg-11 {
		flex: 0 0 100%;
    	max-width: 100%;
	}
	.ZoneFillDetail {
		padding: 10px;
	}
	.AddBoothSetup .nav-tabs .nav-item .nav-link .nav-text {
		font-size: 14px;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-item {
		width: 25%;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-item:first-child .nav-link {
		margin-left: 0 !important;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-item:last-child .nav-link {
		margin-right: 0 !important;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-link {
		margin: 0 3px !important;
		width: 100%;
		border-bottom: 3px solid #a3cbf3;
	}
	.nav.nav-tabs.nav-tabs-line .nav-link {
		margin: 0 10px;
	}
	.feaderList {
		margin-top: 12px;
		min-height: 124px;
	}
	.HeadingStatic h2 {
		font-size: 18px;
	}
	.feaderList h4 {
		font-weight: 600;
		font-size: 15px;
	}
	.feaderList p {
		font-size: 13px;
		margin-bottom: 0px;
		line-height: 18px;
	}
	.LogoCoverUpload {
		margin-bottom: 18px;
	}
	.BoothInnerSetup .TagsWebsite {
		margin: 0 0 22px;
	}
	.SelectIntroVideo {
		margin-bottom: 15px;
	}
	.RegisterBoothInfo {
		margin: 0 0 32px;
	}
	.CommonModalPopup .modal-dialog .modal-content .modal-header .modal-title h4 {
		font-size: 18px;
	}
	.MaxBottomPadd .w-90 {width: 100% !important;}
	.HeadingStatic img {
		margin-right: 5px;
		width: 30px;
	}
	.HeadingStatic {
		margin: 5px 0 24px !important;
	}
	.SelectImgs {
		margin: 0 0 15px;
	}
	.minHeightBox {
		border: 1px;
		height: auto !important;
		min-height: auto !important;
		aspect-ratio: 16/9;
	}
	.InviteSpeakerBox .View-Col-Min {
		flex: 0 0 320px !important;
		max-width: 320px !important;
	}
	.InviteSpeakerBox .View-Col-Max {
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}
	.InviteSpeakerBox {
		padding-right: 0px !important;
	}
	.FilesRules {
		margin: 22px 0 8px 0px;
	}



	.StageEventPg {
		padding: 12px;
	}
	.StageEventPg h4 {
		font-weight: 600;
		font-size: 16px;
		margin-bottom: 8px;
	}
	.StageEventPg p {
		margin-bottom: 16px;
		font-size: 13px;
	}
	.StageEventPg .Talk-Speakers .btn {
		margin-right: 10px;
	}
	.HeadingStageTxt h5 {
		font-size: 18px;
	}
	.EnterStage {
		right: 18px;
		top: 15px;
	}
	.StageEventPg .EnterStage svg {
		width: 12px;
		height: 12px;
	}
	.stateForm {
		padding: 0px !important;
	}
	.DesignSetupStage .stateForm {
		margin-bottom: 18px !important;
	}
	.DesignSetupStage .ToggleDiv {
		margin: 0 0 18px !important;
	}
	.uploadFileSlider .slick-slider .slick-list .slick-track .UploaderImgRow {
		padding: 4px 4px !important;
	}
	.uploadFileSlider .slick-slider .slick-prev {
		bottom: -45px;
		width: 23px;
		height: 23px;
		right: 25px;
	}
	.uploadFileSlider .slick-slider .slick-next {
		bottom: -45px;
		width: 23px;
		height: 23px;
	}
	.uploadFileSlider {
		margin: 0px 0 24px !important;
	}
	.table.Rooms tbody tr td:first-child {
		width: 10% !important;
	}
	.table.Rooms tbody tr td:last-child {
		width: 14%;
	}
	.content .card-custom .card-header .card-title .card-label {
		font-size: 20px;
		margin-right: 18px;
	}
	.container .container-fluid {padding: 0px !important}
	.top-heading-main .headingH1 h1 {
		font-size: 22px;
	}
	.top-heading-main {
		margin: 22px 0 26px!important;
	}
	.top-heading-main .headingH1 span {
		margin-left: 12px;
		font-size: 14px;
	}

	.top-heading-main .menuRight ul li {
		margin: 0 4px;
	}
	.top-heading-main .menuRight ul li:first-child {
		margin-left: 0px;
	}
	.top-heading-main .NofifyH1 h1 {
		font-size: 22px !important;
	}
	.EventBrandingMixhubb .card-custom {
		padding-left: 0px !important;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .w-90 {
		width: 100% !important;
		margin: 12px 0 0 !important;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .NavHeaderTop .MuiGrid-grid-md-12  {
		padding: 12px 0 0 !important;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .NavHeaderTop .MuiPaper-rounded  {
		overflow-x: auto;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .eventExprience h3 {
		font-size: 20px;
		margin: 12px 0 20px;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .eventExprience p {
		font-size: 13px;
	}
	.eventExprience .EventCopy-Edit {
		max-width: 324px !important;
	}
	.BrandingColorTheme .MuiGrid-grid-xs-2 {
		flex-grow: 0;
		max-width: 50%;
		flex-basis: 50%;
	}
	.BrandingColorTheme .colorCodePx div {
		font-size: 15px !important;
	}
	.YourNavBarInfo {
		width: 1000px; 
	}
	.EventBrandingMixhubb .tab-content .tab-pane {
		padding-left: 0px !important;
		padding-right: 0px !important;
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.EventBrandingMixhubb .tab-content .w-50 {
		width: 100% !important;
	}
	.sub-heading-main h4 {
		font-size: 20px;
		margin-right: 16px;  
	} 
	.sub-heading-main .editBtn-detil {
		font-size: 14px;
		font-weight: 600;
		padding: 5px 15px !important;
	} 
	.EventBrandingMixhubb .nav-tabs .nav-item .nav-link .nav-text {
		font-size: 14px;
	}
	.BrandingCreativesLogo {
		margin: 30px 0 0;
	}
	.createEvent {margin: 22px 0 10px !important;}
	.AbtEventUrl {
		padding: 0 0 24px !important;
	}
	.AbtEventUrl .EventCopy-Edit {
		margin-right: 15px !important;
		width: 100% !important;
		max-width: 65%;
	}
	.AbtEventUrl .EventCopy-Edit .copyLinkDel {
		padding-right: 0px !important;	
	}
	.AbtEventUrl .EventCopy-Edit .CopyHere {
		width: 200px !important;
	}
	.EditEventArea .form-control textarea {
		height: 70px;
	}
	.ThemePageLandingGo .LandingPageTheme .HeadingStatic {margin: 0 0 0 !important;}
	.LandingPageTheme .RegStyleHeight {margin-top: 20px !important;}
	.SelectPageTheme .HeadingStatic {
		margin: 0 0 20px !important;
	}
	.SelectPageTheme .ThemeSelectBrand {
		margin: 20px 0 0;
	}
	.LandingStylesInner .menuRight a {
		font-weight: 600;
		font-size: 13px;
		padding: 6px 9px;
	}
	.LandingStylesInner .menuRight li:first-child {
		margin-left: 0px !important;
	}
	.LandingStylesInner .menuRight li {
		display: inline-block;
		margin: 0 5px;
	}
	.InfoInputTable tbody tr td:first-child {
		width: 8%;
		padding: 0 0 38px;
	}
	.AddStageArea .EventDescript .form-control textarea {
		padding: 10px 0px 10px !important;
		height: 60px !important;
	}
	.DesignColorPicker .MuiGrid-grid-xs-2 {
		flex-grow: 0;
		max-width: 100%;
		flex-basis: 100%;
	}
	.DesignColorPicker .MuiGrid-root {
		margin: 0 0 20px !important;
		padding: 0px !important;
	}
	.LogoBgUploadIn {
		margin: 0px 0 0;
	}
	.MixhubbDesEvent .sub-heading-main {
		margin-bottom: 12px !important;
   		padding-top: 20px !important;
	}

	.minHeightBox .default-image-selected .UploadLabel .img-fluid {
		height: 100%;
	}
	.minHeightBox .default-image-selected .UploadLabel {
		height: 100% !important;
    	width: 100% !important;
	}

	.EventLogoupload {
		padding-right: 12px !important;
	}
	.BreakerCreativeupload {
		padding-left: 12px !important;
	}
	.brandingColorPix {
		margin: 0 0 30px;
	}
	.DesginLogoBgUpload {
		margin: 0px 0 0;
	}
	.minHeightBox .UploaderFiles .UploadLabel .img-fluid {
		width: 45px;
	}
	.minHeightBox .UploaderFiles .UploadLabel {
		font-size: 10px;
		line-height: 15px;
	}
	.updateCoverDesign, .CompanyLogoUpload, .UpdateEventLogoDegns {
		padding: 0px !important;
		padding-top: 15px !important;
	}
	.LogoBgUploadIn .WithAddMore {margin: 0px !important;}
	.LogoBgUploadIn .WithAddMore .HeadingStatic {margin: 0px !important;}
	.stateForm .MainAddDiv .AaddingTagBox {
		width: 80%;
	}
	.stateForm .MainAddDiv .MixhubbTagInner .AddTagesInput {
		width: 300px;
		margin-right: 10px;
	}
	.content .card-custom .ListHeaderTwo .card-header {
		margin: 0 0 0px !important;
	}
	.table.SpeakersTables tbody tr td:last-child {
		width: 16%;
	}
	.ThemeBgInner img {width: 100%;}

	table.InfoInputTable {width: 760px;}
	.InfoInputTable tbody tr td:nth-child(3) {
		width: 25%;
	}
	.InfoInputTable tbody tr td:nth-child(4) {
		width: 28%;
	}
	.RightIcons .EditDel .iconSvg {
		width: 26px;
		height: 26px;
		padding: 4px 5px;
	}
	.login-form h3 {
		font-size: 28px;
	}
	.login-form p {
		font-size: 14px;
		padding: 0 14px;
	}
	.ContBtn {
		font-size: 15px !important;
	}




	table.EventSponsor {
		width: 1000px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) img {
		height: 70px;
	}
	.InformLandingTable .EventSponsor tbody tr td:first-child {
		width: 5%;
	}
	.InformLandingTable .EventSponsor tbody tr td:last-child {
		width: 12%;
	}
	.AboutEventPg .form-control {
		height: 100px;
	}
	.InformLandingTable .EventSponsor tbody tr td p {
		font-size: 14px;
	}
	.InformLandingTable .EventSponsor tbody tr td {
		font-size: 14px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) {
		width: 33%;
	}
	.InformLandingTable {
		padding: 12px 12px;
		margin: 20px 0 0;
	}
	.InformLandingTable .WithAddMore .HeadingStatic {
		width: 100%;
    	float: left;
		margin: 0 0 12px !important;
	}
	.WithAddMore {
		display: block;
	}
	.WithAddMore .AddNewFact {
		margin: 0 0 30px;
	}
	.FAQNewQuestion .FAQlistADD {
		padding: 16px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns {
		padding-right: 4%;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns h3 {
		font-size: 15px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns p {
		font-size: 13px;
		line-height: 18px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQAction {
		text-align: right;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQAction .ActionIcon {
		width: 30px;
		height: 30px;
	}
	.YourNavBarInfo .MuiGrid-root {
    	flex-basis: 230px;
	}

	.Top-Event-Schedule {
		border: 1px #F1F1F1 solid;
	}
	.Top-Event-Schedule .Schedule-Date {
		padding: 11px 10px;
	}
	.Top-Event-Schedule .Schedule-Date h5 {
		font-size: 13px;
		font-weight: 700;
	}
	.Top-Event-Schedule .Schedule-Date h2 {
		font-size: 48px;
		line-height: 50px;
	}
	.Top-Event-Schedule .Schedule-Date p {
		font-size: 12px;
	}
	.Top-Event-Schedule .Schedule-Day-Time {
		padding: 11px 12px;
	}
	.EnterSchedule {
		right: 12px;
		top: 10px;
	}
	.Top-Event-Schedule .Schedule-Day-Time h4 {
		font-size: 16px;
		margin: 0 0 8px;
	}
	.Top-Event-Schedule .Schedule-Day-Time span {
		font-size: 12px;
	}
	.Talk-Speakers {
		margin: 10px 0 0;
	}
	.Talk-Speakers .btn {
		padding: 4px 10px;
		font-size: 12px;
		border-radius: 4px;
	}
	.Talk-Speakers svg {
		width: 16px;
		height: 16px;
	}
	.FbUrlCopyTx .CopyRrlFd {
		width: 100% !important;
		max-width: 100% !important;
	}
	.CheckBoxTicl .check-group label:before {
		padding: 8px;
		margin-right: 7px;
	}
	.CheckBoxTicl .check-group label {
		font-size: 12px;
		margin-top: 7px;
	}
	.CheckBoxTicl .check-group input:checked + label:after {
		top: 6px !important;
		left: 7px;
		width: 5px;
		height: 10px;
	}
	.LiveStream {
		margin: 25px 0 10px !important;
		min-height: auto !important;
	}
	.QuickNetworking { h4 {
		font-size: 18px; 
		}
		p {
			font-size: 13px;
		}
	}

	.NetworkingMatching {
		margin-top: 2rem !important;
		.SelectMatch {
			h3 {
				font-size: 16px;
			}
			p {
				font-size: 12px;
			}
		}
	}
	.ZoonEventPg .RightIcons .Talk-Speakers .btn {
		width: auto;
		font-size: 13px;
	}
	.ZoonEventPg .RightIcons .Talk-Speakers svg {
		width: 18px;
		height: 18px;
		margin-right: 5px;
	}
	.ExpoAdditionalFeature .HeadingStatic {
		margin: 0px 0 6px !important;
	}
	.table.SpeakersTables tbody tr td {
		padding: 12px 12px !important;
	}
	.entAtDate {
		padding-top: 0px !important;
	}
	.brand-dark .header-mobile {
		background-color: #fff !important;
	}
	.brand-dark .header-mobile img {
		width: 150px;
	}
	#kt_header_mobile_topbar_toggle span {margin-right: 0px !important;}
	#kt_header_mobile_toggle {display: none;}
	.brand-dark .header-mobile .burger-icon span {
		background-color: #333 !important;
	}
	.brand-dark .header-mobile .burger-icon span::before, .brand-dark .header-mobile .burger-icon span::after {
		background-color: #333 !important;
	}
	.brand-dark .header-mobile .btn.active .svg-icon svg g [fill], .brand-dark .header-mobile .btn:hover .svg-icon svg g [fill] {
		fill: #333 !important;
		opacity: 1 !important;
	}
	.brand-dark .header-mobile .btn .svg-icon svg g [fill] {
		opacity: 1 !important;
		fill: #333 !important;
	}
	.CommonModalPopup {
		width: 95% !important;
		margin: 0px auto;
		right: 0;
	}
	.tab-content .w-90 {
		width: 100% !important;
	}
	.BtnActionBtn {
		padding: 20px 10px !important;
		text-align: center;
	}
	.ScheduleCalanderForms {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		overflow: auto;
		width: 100%;
		height: 100%;
		padding-bottom: 25px;
	}
	.CalanderFormPaper {
		width: 95%;
		padding: 20px 20px !important;
	}
	.table.SpeakersTables {
		width: 820px;
	}
	.table.RecordingTable {
		width: 820px;
	}
	.MobTop-20 {
		margin-top: 20px;
	}
	.FeedbackCreate h4 {
		font-size: 2.2rem;
		line-height: 42px;
	}
	.orgName {
		width: 100%;
		align-items: center;
		justify-content: center;
		padding: 10px 0 12px;
		border-top: 1px #f1f1f1 solid;
	}
	.table.FeedBackTable {
		width: 900px;
	}
	.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus .feedBackNameDiscs h4 {
		font-size: 16px;
	}
	.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus .feedBackNameDiscs h2 {
		font-size: 22px;
	}
	.YourCredits {
		top: 124px;
		height: calc(100vh - 212px);
	}
	.Trial-Fix-Cradit {
		margin: 20px 0 0 !important;
		flex: 0 0 71.666667% !important;
		max-width: 71.666667% !important;
			.Select-AddonsMx {
				.AddonsTable {
					.table-responsive {
						table.AddonsPreTables {
							width: 700px;
							tbody {
								tr {
									td {
										font-size: 16px;
										padding: 18px 14px !important;
									}
								}
							}
						}
					}
				}
			}
		.PlanEventChargesTable {
			.row {
				.Plan-max-trial {
					flex: 0 0 50% !important;
					max-width: 50% !important;
				}
			}
		}
	}
	.Trial-Fix-Cradit {
		.PlanEventChargesTable {
			.MainPlanTrial {
				padding: 16px;
				.PlanCoastTrial {
					h3 {
						font-size: 18px;
						span {
							font-size: 13px;
						}
					}
				}
			}
		}
	}
  .Select-AddonsMx {
    .AddonsTable {
      .table-responsive {
        table.AddonsPreTables {
          thead {
            tr {
              th {
                font-size: 15px !important;
              }
            }
          }
          tbody {
            tr {
              td {
                font-size: 15px !important;
                padding: 20px 18px !important;
              }
            }
          }
        }
      }
    }
  }
	.bar-chart {
		.LayoutRangeBox {
			height: 80px;
			.RangeFeedBak {
				.ButtonRange {
					padding: 6px 6px;
					font-size: 10px;
				}
				&:last-child {
					left: 86.5%;
				}
			}
		}
	}
	.card.card-custom > .RegsFiltSerch {
		justify-content: center !important;
		margin: 0 0 30px;
		.FilterAnalitsSearch {
			margin-bottom: 20px;
		}
	}
	.ListHeaderThree {
		.card-custom {
			.card-header {
				.EditAddFb {
					.AddFb {
						.WhiteBtn {
							font-size: 14px !important;
							padding: 5px 12px !important;
						}
					}
				}
			}
		}
	}
	.PurchageHisTable {
		.top-heading-main {
			margin: 22px 0 14px !important;
			.headingH1 {
				h1 {
					font-size: 20px;
				}
			}
		}
	}
	.AttendeeUtm .AttendeePrior {
		width: 38%;
	}
}
@media(max-width:991px) { 
	.table.NotificatonTable {
		width: 1000px;
		thead {
			th {
				&:first-child {
					width: 22% !important;
				}
				&:nth-child(2) {
					width: 28% !important;
				}
				&:nth-child(3) {
					width: 12% !important;
				}
				&:nth-child(4) {
					width: 12% !important;
				}
				&:nth-child(5) {
					width: 12% !important;
				}
				&:last-child {
					width: 14% !important;
				}
			}
		}
	}
	.table.EmailTempTable {
		width: 1000px;
	}
	.topbar .dropdown .dropdown-menu {
		width: 170px !important;
		transform: translate3d(-95px, 49px, 0px) !important;
	}
	.MobileViewInfo {
		display: flex !important;
	}
}




@media (max-width: 767px) {
	.brand-dark .header-mobile {
		background-color: #fff !important;
	}
	.brand-dark .header-mobile img {
		width: 150px;
	}
	#kt_header_mobile_topbar_toggle span {margin-right: 0px !important;}
	#kt_header_mobile_toggle {display: none;}
	.brand-dark .header-mobile .burger-icon span {
		background-color: #333 !important;
	}
	.brand-dark .header-mobile .burger-icon span::before, .brand-dark .header-mobile .burger-icon span::after {
		background-color: #333 !important;
	}
	.brand-dark .header-mobile .btn.active .svg-icon svg g [fill], .brand-dark .header-mobile .btn:hover .svg-icon svg g [fill] {
		fill: #333 !important;
		opacity: 1 !important;
	}
	.brand-dark .header-mobile .btn .svg-icon svg g [fill] {
		opacity: 1 !important;
		fill: #333 !important;
	}
	.CommonModalPopup {
		width: 95% !important;
		margin: 0px auto;
		right: 0;
	}
	.ContBtn {
		font-size: 15px !important;
		padding: 10px 12px !important;
		width: 100%;
	}
	.tab-content .w-90 {
		width: 100% !important;
	}
	.BtnActionBtn {
		padding: 20px 10px !important;
		text-align: center;
	}
	.ScheduleCalanderForms {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		overflow: auto;
		width: 100%;
		height: 100%;
		padding-bottom: 25px;
	}
	.CalanderFormPaper {
		width: 95%;
		padding: 20px 20px !important;
	}
	.InformLandingTable .EventSponsor tbody tr td:first-child {
		width: 5%;
	}
	.CommonModalPopup .modal-dialog {
		margin: 10px auto;
		height: 100%;
		display: block;
	}
	.MixhubbLogo {
		background: #ffffff;
		box-shadow: 0px 0px 10px rgb(0 0 0 / 21%);
	}
	.EventUrlCopyTx {
		width: 100%;
	}
	.SelectPageTheme {
		.theme-md-3 {
			flex: 0 0 50%;
    		max-width: 50%;
		}
		.HeadingStatic {
			margin: 0px 0 18px !important;
		}
	}
	table.AllBoothTable {
		width: 620px !important;
	}
	table.AllEventTable {
		width: 820px;
	}table.RecordingTable {
		width: 820px;
	}
	.Select-AddonsMx {
      .AddonsTable {
        .table-responsive {
          table.AddonsPreTables {
            width: 660px;
            thead {
              tr {
                th {
                  font-size: 14px !important;
                }
              }
            }
            tbody {
              tr {
                td {
                  font-size: 14px !important;
                  padding: 20px 8px !important;
				  &:first-child {
					  padding: 20px 18px !important;
				  }
                }
              }
            }
          }
        }
      }
    }
	.table.NotificatonTable { 
		width: 1000px;
	}
	.table.EmailTempTable { 
		width: 1000px;
	}
	.table.FeedBackTable {
		width: 900px;
	}
	.MobTop-20 {
		margin-top: 20px;
	}
	.orgName {
		width: 100%;
		align-items: center;
		justify-content: center;
		padding: 10px 0 12px;
		border-top: 1px #f1f1f1 solid;
		h3 {
			font-size: 18px;
		}
	}
	.FBPopForm {
		height: auto !important;
		margin-top: 50px;
		.FeedbackInnerAdd {
			padding: 20px 20px 50px;
		}
	}
	.YourCredits {
		top: 8px;
		height: calc(100vh - 73px);
		z-index: 9;
		background-color: #fff;
	}
	.Trial-Fix-Cradit {
		margin: 0 !important;
		flex: 0 0 100% !important;
		max-width: 100% !important;
		&::after {
			background-color: rgb(0 0 0 / 30%);
			position: fixed;
			width: 100%;
			height: 100%;
			content: '';
			top: 0;
			left: 0;
			z-index: 7;
		}
	}
	.ListHeaderThree {
		.card-custom {
			.RegsFiltSerch {
				justify-content: center !important;
				.FilterAnalitsSearch {
					.ListSearchBar {
						margin-top: 0px !important;
						&:last-child {
							margin-right: 0px !important;
						}
					}
				}
			}
		}
	}
	.DashBoardInfo {
		margin: 0 0 14px;
	}
	.PurchageHisTable {
		.top-heading-main {
			margin: 22px 0 14px !important;
			.headingH1 {
				h1 {
					font-size: 20px;
				}
			}
		}
	}
	.ThemePopup {
		overflow-y: hidden !important;
		.modal-dialog {
			max-width: 100%;
			display: flex !important;
			.modal-content {
				padding: 6px 6px;
    			border-radius: 4px;
				.modal-header {
					padding: 12px 0;
					.ModalCloseBtn {
						z-index: 8;
						right: 4px;
						display: block;
						top: 6px;
						.MuiButton-label {
							height: 22px;
							width: 22px;
						}
					}
				}
			}
		}
	}
	.RegisteruserPt {
		height: auto;
    	position: relative;
		.row {
			.sepratorMid {
				position: inherit;
				width: 260px;
				height: auto;
				align-items: center;
				justify-content: center;
				margin: 30px auto 30px;
				span {
					padding: 0 10px;
					text-align: center;
					position: relative;
				}
				&:before {
					width: 250px;
					height: 2px;
					top: 12px;
				}
			}
		}
	}
	.RegisteruserPt .login-form h3 {
		font-size: 20px;
	}
	footer {
		position: absolute;
	}
}
@media (min-width: 600px) and (max-width: 767px) {
	.AllEventContainer .ListEventNo .col-md-2 {
		width: 33% !important;
	}
	.AllEventContainer .ListEventNo .listType h3 {
		font-size: 28px;
	}
	.UpcomingListEvent .allEvents-add {
		padding-left: 0;
		margin: 42px 0 0;
		padding-right: 0;
	}
	.UpcomingListEvent .allEvents-add .yestDate {
		height: auto;
    	top: -30px;
		width: 100%;
		font-size: 14px;
		left: 0;
	}
	.UpcomingListEvent .allEvents-add .yestDate span {
		background-color: #fff;
		position: relative;
		z-index: 3;
		padding-right: 10px;
	}
	.UpcomingListEvent .allEvents-add .yestDate:after {
		height: auto;
		top: 50%;
		margin: 0px auto;
		width: 100%;
		border-bottom: 1px #222 dashed;
	}
	.UpcomingListEvent .EventPageInfos {margin: 0px !important;}
	.UpcomingListEvent .EventPageInfos .viewAllevent {
		margin: 20px 0 0 !important;
	}
	.heading-main h2 {
		font-size: 18px;
	}
	
	.NoSchedeleEvent {
		margin: 15px 0 18px;
	}
	.HelpYou {
		float: left;
		right: inherit;
		bottom: 0;
		left: 10px;
	}
	.WhiteBtn {
		padding: 5px 12px !important;
		font-size: 14px;
	}
	.allEvents-add .event-summary-all .event-all-schedule h3 {
		font-size: 16px;
	}
	.allEvents-add .event-summary-all .event-all-schedule p {
		font-size: 12px;
	}
	.UpcomingListEvent .allEvents-add .event-summary-all {
		margin: 12px 0 22px;
		padding: 8px;
		height: 130px !important;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header {
		margin: 0px;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header .card-title .MuiPaper-root {
		width: 260px;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header .card-title .card-label {
		margin: 0 0 16px;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header .card-title {
		margin: 0 0 12px;
	}
	.ListHeaderMain .card-header {padding: 0px;}

	.ListHeaderOne {
		.top-heading-main {
			display: inline-block;
			margin: 20px 0 24px !important;
			.headingH1 {
				display: block;
				width: 100%;
				h1 {
					font-size: 22px;
					margin-bottom: 16px;
				}
			}
			.eventExprience {
				text-align: left !important;
				width: 365px;
				margin: 0;
				.EventCopy-Edit {
					.copyLinkDel {
						padding-right: 0px !important;
					}
					.CopyHere {
						width: 190px;
					}
				}
				.EnterEventin {
					float: right;
				}
			}
		}
	}
	.table.teamsMixTables  {
		width: 900px;
	}
	.ScheduleHeader {
		margin: 0 0 10px !important;
		h5 {
			font-size: 18px;
		}
	}
	.ExpoZones h4 {
		font-size: 15px;
		margin: 0 0 8px;
	} 
	.ExpoZones p {
		font-size: 13px;
		line-height: 13px;
	}
	.ZoneTypeBooth .ZoonEventPg {
		padding: 16px;
	}
	.ZoneTypeBooth .ZoonEventPg p {
		padding-right: 0px !important;
	}
	.ZoneTypeBooth .ZoonEventPg h4 {
		font-size: 15px;
		padding-right: 0px !important;
		margin-bottom: 5px;
	}
	.ExpoAddZones {
		margin: 25px 0 0;
	}
	.ExpoAddZones .CommonGtBtn {
		margin-right: 15px !important;
	}
	.BoothCardInfo {
		padding: 10px 10px;
		margin: 20px 0;
	}.BoothCardInfo .TypeOfBooth {
		display: block;
	}
	.BoothCardInfo .TypeOfBooth .SearchBooth {
		width: 100%;
		margin: 18px 0 0;
	}
	.BoothCardInfo .TypeOfBooth .boothAddType h4 {
		font-size: 16px;
	}
	.CommonGtBtn {
		font-size: 14px !important;
		padding: 5px 12px !important;
	}
	.BoothCardInfo .TypeOfBooth .boothAddType .CommonGtBtn {
		float: right;
	}
	.BoothCardInfo .BoothDetailsInfo {
		padding: 12px 12px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg .BoothLogo {
		width: 95px;
		margin-right: 8px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg h3 {
		font-size: 15px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothEditDelete .EditDel .iconSvg {
		width: 24px;
		height: 24px;
		margin: 0 2px;
		padding: 1px 5px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothEditDelete .EditDel {margin-right: 0px !important;}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg .BoothLogo .img-fluid {
		height: 40px;
	}
	.scrolltop {
		width: 50px;
		height: 50px;
		bottom: 22px;
	}
	.scrolltop .svg-icon svg {
		height: 23px;
		width: 23px;
	}
	.eventExprience .EnterEventin .Event-EnterBtn {
		padding: 6px 8px !important;
	}
	.content .container .row .col-md-11, .content .container .row .col-sm-11, .content .container .row .col-lg-11, .container .row .col-lg-11 {
		flex: 0 0 100%;
    	max-width: 100%;
	}
	.ZoneFillDetail {
		padding: 10px;
	}
	.AddBoothSetup .nav-tabs .nav-item .nav-link .nav-text {
		font-size: 14px;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-item {
		width: 25%;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-item:first-child .nav-link {
		margin-left: 0 !important;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-item:last-child .nav-link {
		margin-right: 0 !important;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-link {
		margin: 0 3px !important;
		width: 100%;
		border-bottom: 3px solid #a3cbf3;
	}
	.nav.nav-tabs.nav-tabs-line .nav-link {
		margin: 0 10px;
	}
	.feaderList {
		margin-top: 12px;
	}
	.HeadingStatic h2 {
		font-size: 18px;
	}
	.feaderList h4 {
		font-weight: 600;
		font-size: 15px;
	}
	.feaderList p {
		font-size: 13px;
		margin-bottom: 0px;
		line-height: 18px;
	}
	.LogoCoverUpload {
		margin-bottom: 18px;
	}
	.BoothInnerSetup .TagsWebsite {
		margin: 0 0 22px;
	}
	.SelectIntroVideo {
		margin-bottom: 15px;
	}
	.RegisterBoothInfo {
		margin: 0 0 32px;
	}
	.CommonModalPopup .modal-dialog .modal-content .modal-header .modal-title h4 {
		font-size: 18px;
	}
	.MaxBottomPadd .w-90 {width: 100% !important;}
	.HeadingStatic img {
		margin-right: 5px;
		width: 30px;
	}
	.HeadingStatic {
		margin: 5px 0 24px !important;
	}
	.SelectImgs {
		margin: 0 0 15px;
	}
	.minHeightBox {
		border: 1px;
		height: auto !important;
		min-height: auto !important;
		aspect-ratio: 16/9;
	}
	.StageEventPg {
		padding: 12px;
	}
	.StageEventPg h4 {
		font-weight: 600;
		font-size: 16px;
		margin-bottom: 8px;
	}
	.StageEventPg p {
		margin-bottom: 16px;
		font-size: 13px;
	}
	.StageEventPg .Talk-Speakers .btn {
		margin-right: 10px;
	}
	.HeadingStageTxt h5 {
		font-size: 18px;
	}
	.EnterStage {
		right: 18px;
		top: 15px;
	}
	.StageEventPg .EnterStage svg {
		width: 12px;
		height: 12px;
	}
	.stateForm {
		padding: 0px !important;
	}
	.DesignSetupStage .stateForm {
		margin-bottom: 18px !important;
	}
	.DesignSetupStage .ToggleDiv {
		margin: 0 0 18px !important;
	}
	.uploadFileSlider .slick-slider .slick-list .slick-track .UploaderImgRow {
		padding: 4px 4px !important;
	}
	.uploadFileSlider .slick-slider .slick-list .slick-track .UploaderImgRow .SelectImgs {
    	margin: 0 0 0;
	}
	.uploadFileSlider .slick-slider .slick-prev {
		bottom: -45px;
		width: 23px;
		height: 23px;
		right: 25px;
	}
	.uploadFileSlider .slick-slider .slick-next {
		bottom: -45px;
		width: 23px;
		height: 23px;
	}
	.uploadFileSlider {
		margin: 0px 0 64px !important;
	}
	table.Rooms {
		width: 620px !important;
	}
	.table.Rooms tbody tr td:first-child {
		width: 10% !important;
	}
	.table.Rooms tbody tr td:last-child {
		width: 14%;
	}
	.content .card-custom .card-header .card-title .card-label {
		font-size: 18px;
		margin-right: 15px;
	}
	.container .container-fluid {padding: 0px !important}
	.top-heading-main .headingH1 h1 {
		font-size: 22px;
	}
	.top-heading-main {
		margin: 22px 0 26px!important;
	}
	.top-heading-main .headingH1 span {
		margin-left: 12px;
		font-size: 14px;
	}

	.top-heading-main .menuRight ul li {
		margin: 0 4px;
	}
	.top-heading-main .menuRight ul li:first-child {
		margin-left: 0px;
	}
	.top-heading-main .menuRight {
		float: left;
		margin: 10px 0px 0px;
	}
	.top-heading-main .NofifyH1 h1 {
		font-size: 22px !important;
	}
	.EventBrandingMixhubb .card-custom {
		padding-left: 0px !important;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .w-90 {
		width: 100% !important;
		margin: 12px 0 0 !important;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .NavHeaderTop .MuiGrid-grid-md-12  {
		padding: 12px 0 0 !important;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .NavHeaderTop .MuiPaper-rounded  {
		overflow-x: auto;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .eventExprience h3 {
		font-size: 20px;
		margin: 12px 0 20px;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .eventExprience p {
		font-size: 13px;
	}
	.eventExprience .EventCopy-Edit {
		max-width: 255px !important;
	}
	.eventExprience .EventCopy-Edit .CopyHere {
		width: 150px;
	}
	.BrandingColorTheme .MuiGrid-grid-xs-2 {
		flex-grow: 0;
		max-width: 50%;
		flex-basis: 50%;
	}
	.BrandingColorTheme .colorCodePx div {
		font-size: 15px !important;
	}
	.YourNavBarInfo {
		width: 1000px;
	}
	.FbUrlCopyTx .CopyRrlFd {
		width: 100% !important;
		max-width: 100% !important;
	}
	.EventBrandingMixhubb .tab-content .tab-pane {
		padding-left: 0px !important;
		padding-right: 0px !important;
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.EventBrandingMixhubb .tab-content .w-50 {
		width: 100% !important;
	}
	.sub-heading-main h4 {
		font-size: 20px;
		margin-right: 16px;  
	} 
	.sub-heading-main .editBtn-detil {
		font-size: 14px;
		font-weight: 600;
		padding: 5px 15px !important;
	} 
	.EventBrandingMixhubb .nav-tabs .nav-item .nav-link .nav-text {
		font-size: 14px;
	}
	.BrandingCreativesLogo {
		margin: 30px 0 0;
	}
	.createEvent {margin: 22px 0 10px !important;}
	.AbtEventUrl {
		padding: 0 0 24px !important;
	}
	.AbtEventUrl .EventCopy-Edit {
		margin-right: 15px !important;
		width: 100% !important;
		max-width: 65%;
	}
	.AbtEventUrl .EventCopy-Edit .copyLinkDel {
		padding-right: 0px !important;	
	}
	.AbtEventUrl .EventCopy-Edit .CopyHere {
		width: 200px !important;
	}
	.EditEventArea .form-control textarea {
		height: 70px;
	}
	.ThemePageLandingGo .LandingPageTheme .HeadingStatic {margin: 0 0 0 !important;}
	.LandingPageTheme .RegStyleHeight {margin-top: 20px !important;}
	.SelectPageTheme .ThemeSelectBrand {
		margin: 20px 0 0;
	}
	.LandingStylesInner {
		display: block;
	}
	.LandingStylesInner .menuRight a {
		font-weight: 600;
		font-size: 13px;
		padding: 6px 9px;
	}
	.LandingStylesInner .menuRight li:first-child {
		margin-left: 0px !important;
	}
	.LandingStylesInner .menuRight li {
		display: inline-block;
		margin: 0 5px;
	}
	.DesignColorPicker .MuiGrid-grid-xs-2 {
		flex-grow: 0;
		max-width: 100%;
		flex-basis: 100%;
	}
	.DesignColorPicker .MuiGrid-root {
		margin: 0 0 20px !important;
		padding: 0px !important;
	}
	.LogoBgUploadIn {
		margin: 0px 0 0;
	}
	.MixhubbDesEvent .sub-heading-main {
		margin-bottom: 12px !important;
   		padding-top: 20px !important;
	}

	.minHeightBox .default-image-selected .UploadLabel .img-fluid {
		height: 100%;
	}
	.minHeightBox .default-image-selected .UploadLabel {
		height: 100% !important;
    	width: 100% !important;
	}

	.EventLogoupload {
		padding-right: 12px !important;
	}
	.BreakerCreativeupload {
		padding-left: 12px !important;
	}
	.brandingColorPix {
		margin: 0 0 30px;
	}
	.DesginLogoBgUpload {
		margin: 0px 0 0;
	}
	.updateCoverDesign, .CompanyLogoUpload, .UpdateEventLogoDegns {
		padding: 0px !important;
		padding-top: 15px !important;
	}
	.LogoBgUploadIn .WithAddMore {margin: 0px !important;}
	.LogoBgUploadIn .WithAddMore .HeadingStatic {margin: 0px !important;}
	.stateForm .MainAddDiv .AaddingTagBox {
		width: 100%;
	}
	.stateForm .MainAddDiv .MixhubbTagInner .AddTagesInput {
		width: 320px;
		margin-right: 10px;
	}
	.PoperSearchBar {
		display: block;
		float: left;
		width: 100%;
		margin: 15px 0 18px;
	}
	.content .card-custom .card-header .card-title .PoperSearchBar .MuiPaper-root {
		width: 100%;
	}
	.ListHeaderTwo .card-header .card-title button {margin: 0px !important;}
	.content .card-custom .ListHeaderTwo .card-header {
		margin: 0 0 0px !important;
	}
	.table.SpeakersTables {
		width: 820px;
	}
	.table.RecordingTable {
		width: 820px;
	}
	.table.SpeakersTables tbody tr td:last-child {
		width: 16%;
	}
	.ThemeBgInner img {width: 100%;}

	table.InfoInputTable {width: 760px;}
	.InfoInputTable tbody tr td:nth-child(3) {
		width: 25%;
	}
	.InfoInputTable tbody tr td:nth-child(4) {
		width: 28%;
	}
	.RightIcons .EditDel .iconSvg {
		width: 26px;
		height: 26px;
		padding: 4px 5px;
	}

	.login-form h3 {
		font-size: 28px;
	}
	.login-form p {
		font-size: 14px;
		padding: 0 10px;
	}



	table.EventSponsor {
		width: 800px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) img {
		height: 70px;
	}
	.InformLandingTable .EventSponsor tbody tr td:first-child {
		width: 5%;
	}
	.InformLandingTable .EventSponsor tbody tr td:last-child {
		width: 12%;
	}
	.AboutEventPg .form-control {
		height: 100px;
	}
	.InformLandingTable .EventSponsor tbody tr td p {
		font-size: 14px;
	}
	.InformLandingTable .EventSponsor tbody tr td {
		font-size: 14px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) {
		width: 33%;
	}
	.InformLandingTable {
		padding: 12px 12px;
		margin: 20px 0 0;
	}
	.InformLandingTable .WithAddMore .HeadingStatic {
		width: 100%;
    	float: left;
		margin: 0 0 12px !important;
	}
	.WithAddMore {
		display: block;
	}
	.WithAddMore .AddNewFact {
		margin: 0 0 30px;
	}
	.FAQNewQuestion .FAQlistADD {
		padding: 16px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData {
		display: block;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns {
		padding-right: 0%;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns h3 {
		font-size: 15px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns p {
		font-size: 13px;
		line-height: 18px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQAction {
		text-align: right;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQAction .ActionIcon {
		width: 30px;
		height: 30px;
	}
	.YourNavBarInfo .MuiGrid-root {
    	flex-basis: 230px;
	}


	.Top-Event-Schedule {
		border: 1px #F1F1F1 solid;
	}
	.Top-Event-Schedule .Schedule-Date {
		padding: 11px 10px;
	}
	.Top-Event-Schedule .Schedule-Date h5 {
		font-size: 12px;
		font-weight: 700;
	}
	.Top-Event-Schedule .Schedule-Date h2 {
		font-size: 38px;
		line-height: 50px;
	}
	.Top-Event-Schedule .Schedule-Date p {
		font-size: 11px;
	}
	.Top-Event-Schedule .Schedule-Day-Time {
		padding: 13px 12px;
	}
	.EnterSchedule {
		right: 12px;
		top: 10px;
	}
	.Top-Event-Schedule .Schedule-Day-Time h4 {
		font-size: 14px;
		margin: 0 0 8px;
	}
	.Top-Event-Schedule .Schedule-Day-Time span {
		font-size: 11px;
	}
	.Talk-Speakers {
		margin: 10px 0 0;
	}
	.Talk-Speakers .btn {
		padding: 4px 6px;
		font-size: 11px;
		border-radius: 4px;
	}
	.Talk-Speakers svg {
		width: 14px;
		height: 14px;
		margin-right: 3px;
	}

	.allEvents-add .event-summary-all .event-all-schedule .detail-view {
		font-size: 13px;
	}
	.CheckBoxTicl .check-group label:before {
		padding: 8px;
		margin-right: 7px;
	}
	.CheckBoxTicl .check-group label {
		font-size: 12px;
		margin-top: 7px;
	}
	.CheckBoxTicl .check-group input:checked + label:after {
		top: 6px !important;
		left: 7px;
		width: 5px;
		height: 10px;
	}
	.LiveStream {
		margin: 25px 0 10px !important;
		min-height: auto !important;
	}
	.RightIcons .Talk-Speakers .btn {
		width: 70px;
	}
	.QuickNetworking { h4 {
		font-size: 18px; 
		}
		p {
			font-size: 13px;
		}
	}

	.NetworkingMatching {
		margin-top: 2rem !important;
		.SelectMatch {
			h3 {
				font-size: 16px;
			}
			p {
				font-size: 12px;
			}
		}
	}
	.ZoonEventPg .RightIcons .Talk-Speakers .btn {
		width: auto;
		font-size: 13px;
	}
	.ZoonEventPg .RightIcons .Talk-Speakers svg {
		width: 18px;
		height: 18px;
		margin-right: 5px;
	}
	.ExpoAdditionalFeature .HeadingStatic {
		margin: 0px 0 6px !important;
	}
	.table.SpeakersTables tbody tr td {
		padding: 12px 12px !important;
	}
	.entAtDate {
		padding-top: 0px !important;
	}
	.InviteSpeakerBox .View-Col-Min {
		flex: 0 0 320px !important;
		max-width: 320px !important;
	}
	.InviteSpeakerBox .View-Col-Max {
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}
	.InviteSpeakerBox {
		padding-right: 0px !important;
	}
	.FilesRules {
		margin: 22px 0 8px 0px;
	}
	.ThemePageLandingGo {
		margin-top: 20px;
	}
	.FinalEventPlan .MonthlyAddonchargs .EventMonthlyInr p {
		font-size: 16px !important;
	}
	.FinalEventPlan .MonthlyAddonchargs .EventMonthlyInr h2 {
		font-size: 22px !important;
	}
	.FinalEventPlan .MonthlyAddonchargs .Continue .btnContinue {
		font-size: 16px !important;
		padding: 4px 16px !important;
	}
	.FreeTrailPlan .TrialCharges h2 {
		font-size: 22px;
		margin-bottom: 10px;
	}
	.FreeTrailPlan .TrialCharges p {
		font-size: 13px;
	}
	.FeedbackCreate h4 {
		font-size: 2rem;
		margin: 0 0 22px;
		line-height: 40px;
	}
	.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus .feedBackNameDiscs h2 {
		font-size: 20px;
	}
	.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus .feedBackNameDiscs h4 {
		font-size: 16px;
	}
	.ListHeaderThree {
		.card-custom {
			.RegsFiltSerch {
				.FilterAnalitsSearch {
					.ListSearchBar {
						width: auto !important;
					}
				}
			}
		}
	}
	.ListHeaderThree .card-custom .card-header .FilterAnalitsSearch .ListSearchBar .MuiPaper-root {
		width: 133px !important;
	}
	.ListHeaderThree .card-custom .card-header .FilterAnalitsSearch .ListSearchBar .FilterAllReports .filterDatePic .form-control {
		width: 133px !important;
	}
	.bar-chart {
		.LayoutRangeBox {
			height: 74px;
			.RangeFeedBak {
				.ButtonRange {
					padding: 4px 8px;
    				font-size: 10px;
					svg {
						fill: #fff;
						width: 8px;
						height: 8px;
					}
				}
				&:nth-child(2) {
					left: 28%;
				}
				&:nth-child(3) {
					left: 47.5%;
				}
				&:nth-child(4) {
					left: 66.6% ;
				}
				&:last-child {
					left: 85.6%;
				}
			}
		}
	}
	.AttendeeUtm .AttendeePrior {
		width: 50%;
	}
}




@media(max-width: 575px) {
	.FreeTrailPlan .PlanEventChargesTable .MainPlanTrial .subscribeBtn {
		position: static !important;
		width: 100% !important;
		margin: 25px auto 0 !important;
	}
	.FreeTrailPlan .PlanEventChargesTable .MainPlanTrial {
		min-height: auto !important;
	}
	.table.NotificatonTable thead th {
		font-size: 12px !important;
		font-weight: 700;
	}
	.table.NotificatonTable tbody td {
		font-size: 12px !important;
	}
	.FBPopForm .FeedbackInnerAdd {
		width: 100%;
	}
	.FBPopForm .FeedbackInnerAdd {
		padding: 20px 14px 30px;
	}
	.FeedDisMob {
		display: block !important;
		margin: 0px 0 0 !important;
	}
	.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus .EditAddFb {
		width: 100%;
		margin: 20px 0;
	}
	.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus .feedBackNameDiscs {
		width: 100% !important;
	}
	.ThankyouPopUp .modal-dialog .modal-content .modal-body .ThankyouNow {
        height: 220px !important;
    }
	.content .card-custom .RegAnalistHd {
		padding-bottom: 0px !important;
	}
	
}

@media (min-width: 480px) and (max-width: 599px) {
	.AllEventContainer .ListEventNo .col-md-2 {
		width: 33% !important;
	}
	.AllEventContainer .ListEventNo .listType h3 {
		font-size: 28px;
	}
	.UpcomingListEvent .allEvents-add {
		padding-left: 0;
		margin: 42px 0 0;
		padding-right: 0;
	}
	.UpcomingListEvent .allEvents-add .yestDate {
		height: auto;
    	top: -30px;
		width: 100%;
		font-size: 13px;
	}
	.UpcomingListEvent .allEvents-add .yestDate span {
		background-color: #fff;
		position: relative;
		z-index: 3;
		padding-right: 10px;
	}
	.UpcomingListEvent .allEvents-add .yestDate:after {
		height: auto;
		top: 50%;
		margin: 0px auto;
		width: 100%;
		border-bottom: 1px #222 dashed;
	}
	.UpcomingListEvent .EventPageInfos {margin: 0px !important;}
	.UpcomingListEvent .EventPageInfos .viewAllevent {margin: 35px 0 0 !important;}
	.heading-main h2 {
		font-size: 18px;
	}
	
	.NoSchedeleEvent {
		margin: 13px 0 0;
	}
	.HelpYou {
		float: left;
		right: inherit;
		bottom: 0;
		left: 10px;
	}

	.UpcomingListEvent .allEvents-add .event-summary-all {
		margin: 12px 0 22px;
		padding: 8px;
		height: 130px !important;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header {
		margin: 0px;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header .card-title .MuiPaper-root {
		width: 100%;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header .card-title .card-label {
		margin: 0 0 12px;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header .card-title {
		margin: 0 0 12px;
	}
	.ListHeaderMain .card-header {padding: 0px;}

	.ListHeaderOne {
		.top-heading-main {
			display: inline-block;
			margin: 20px 0 24px !important;
			.headingH1 {
				display: block;
				width: 100%;
				margin: 0 0 14px;
				h1 {
					font-size: 22px;
					margin-bottom: 16px;
				}
			}
			.eventExprience {
				text-align: left !important;
				margin: 0;
				width: 400px;
				float: right !important;
				.EventCopy-Edit {
					max-width: 290px;
					.copyLinkDel {
						padding-right: 0px !important;
					}
					.CopyHere {
						width: 190px;
					}
				}
				.EnterEventin {
					float: right;
				}
			}
		}
	}

	.ScheduleHeader {
		margin: 0 0 12px !important;
		h5 {
			font-size: 18px;
		}
	}
	.ExpoZones h4 {
		font-size: 15px;
		margin: 0 0 8px;
	} 
	.ExpoZones p {
		font-size: 13px;
		line-height: 13px;
	}
	.ZoneTypeBooth .ZoonEventPg {
		padding: 16px;
	}
	.ZoneTypeBooth .ZoonEventPg p {
		padding-right: 0px !important;
	}
	.ZoneTypeBooth .ZoonEventPg h4 {
		font-size: 17px;
		padding-right: 0px !important;
		margin-bottom: 5px;
	}
	.ExpoAddZones {
		margin: 25px 0 0;
	}
	.ExpoAddZones .CommonGtBtn {
		margin-right: 15px !important;
	}
	.BoothCardInfo {
		padding: 10px 10px;
		margin: 20px 0;
	}.BoothCardInfo .TypeOfBooth {
		display: block;
	}
	.BoothCardInfo .TypeOfBooth .SearchBooth {
		width: 100%;
		margin: 18px 0 0;
	}
	.BoothCardInfo .TypeOfBooth .boothAddType h4 {
		font-size: 16px;
	}
	.CommonGtBtn {
		font-size: 14px !important;
		padding: 6px 12px !important;
	}
	.BoothCardInfo .TypeOfBooth .boothAddType .CommonGtBtn {
		float: right;
	}
	.BoothCardInfo .BoothDetailsInfo {
		padding: 12px 12px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg .BoothLogo {
		width: 95px;
		margin-right: 8px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg h3 {
		font-size: 15px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothEditDelete .EditDel .iconSvg {
		width: 24px;
		height: 24px;
		margin: 0 2px;
		padding: 1px 5px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothEditDelete .EditDel {margin-right: 0px !important;}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg .BoothLogo .img-fluid {
		height: 40px;
	}
	.scrolltop {
		width: 50px;
		height: 50px;
		bottom: 22px;
	}
	.scrolltop .svg-icon svg {
		height: 23px;
		width: 23px;
	}
	.eventExprience .EnterEventin .Event-EnterBtn {
		padding: 6px 8px !important;
	}
	.content .container .row .col-md-11, .content .container .row .col-sm-11, .content .container .row .col-lg-11, .container .row .col-lg-11 {
		flex: 0 0 100%;
    	max-width: 100%;
	}
	.ZoneFillDetail {
		padding: 10px;
	}
	.AddBoothSetup .nav-tabs .nav-item .nav-link .nav-text {
		font-size: 14px;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-item {
		width: 50%;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-item:first-child .nav-link {
		margin-left: 0 !important;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-item:last-child .nav-link {
		margin-right: 0 !important;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-link {
		margin: 0 3px !important;
		width: 100%;
		border-bottom: 3px solid #a3cbf3;
	}
	.nav.nav-tabs.nav-tabs-line .nav-link {
		margin: 0 10px;
	}
	.feaderList {
		margin-top: 12px;
	}
	.HeadingStatic h2 {
		font-size: 18px;
	}
	.feaderList h4 {
		font-weight: 600;
		font-size: 16px;
	}
	.LogoCoverUpload {
		margin-bottom: 18px;
	}
	.BoothInnerSetup .TagsWebsite {
		margin: 0 0 22px;
	}
	.SelectIntroVideo {
		margin-bottom: 15px;
	}
	.RegisterBoothInfo {
		margin: 0 0 32px;
	}
	.CommonModalPopup .modal-dialog .modal-content .modal-header .modal-title h4 {
		font-size: 18px;
	}
	.MaxBottomPadd .w-90 {width: 100% !important;}
	.HeadingStatic img {
		margin-right: 5px;
		width: 30px;
	}
	.HeadingStatic {
		margin: 5px 0 24px !important;
	}
	.SelectImgs {
		margin: 0 0 15px;
	}
	.minHeightBox {
		border: 1px;
		height: auto !important;
		min-height: auto !important;
		aspect-ratio: 16/9;
	}
	.feaderList {min-height: auto !important;}
	.StageEventPg {
		padding: 18px;
	}
	.StageEventPg h4 {
		font-weight: 600;
	}
	.StageEventPg p {
		margin-bottom: 18px;
	}
	.StageEventPg .Talk-Speakers .btn {
		margin-right: 10px;
	}
	.Talk-Speakers .btn {
		font-size: 13px;
		padding: 3px 6px;
	}
	.HeadingStageTxt h5 {
		font-size: 18px;
	}
	.EnterStage {
		right: 18px;
		top: 15px;
	}
	.stateForm {
		padding: 0px !important;
	}
	.DesignSetupStage .stateForm {
		margin-bottom: 18px !important;
	}
	.DesignSetupStage .ToggleDiv {
		margin: 0 0 18px !important;
	}
	.uploadFileSlider .slick-slider .slick-list .slick-track .UploaderImgRow {
		padding: 0px 0px !important;
	}

	.uploadFileSlider .slick-slider .slick-prev {
		bottom: -45px;
		width: 23px;
		height: 23px;
		right: 25px;
	}
	.uploadFileSlider .slick-slider .slick-next {
		bottom: -45px;
		width: 23px;
		height: 23px;
	}
	.uploadFileSlider {
		margin: 0px 0 64px !important;
	}
	table.Rooms {
		width: 620px !important;
	}
	.table.Rooms tbody tr td:first-child {
		width: 10% !important;
	}
	.table.Rooms tbody tr td:last-child {
		width: 14%;
	}
	.content .card-custom .card-header .card-title .card-label {
		font-size: 18px;
		margin-right: 18px;
	}
	.container .container-fluid {padding: 0px !important}
	.top-heading-main .headingH1 h1 {
		font-size: 22px;
	}
	.top-heading-main {
		margin: 22px 0 26px!important;
	}
	.top-heading-main .headingH1 span {
		margin-left: 12px;
		font-size: 14px;
	}

	.top-heading-main .menuRight ul li {
		margin: 0 4px;
	}
	.top-heading-main .menuRight ul li:first-child {
		margin-left: 0px;
	}
	.top-heading-main .menuRight {
		float: left;
		margin: 10px 0px 0px;
	}
	.EventBrandingMixhubb .card-custom {
		padding-left: 0px !important;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .w-90 {
		width: 100% !important;
		margin: 12px 0 0 !important;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .NavHeaderTop .MuiGrid-grid-md-12  {
		padding: 12px 0 0 !important;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .NavHeaderTop .MuiPaper-rounded  {
		overflow-x: auto;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .eventExprience h3 {
		font-size: 20px;
		margin: 12px 0 20px;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .eventExprience p {
		font-size: 13px;
	}
	.eventExprience .EventCopy-Edit {
		max-width: 184px;
	}
	.BrandingColorTheme .MuiGrid-grid-xs-2 {
		flex-grow: 0;
		max-width: 50%;
		flex-basis: 50%;
	}
	.BrandingColorTheme .colorCodePx div {
		font-size: 15px !important;
	}
	.YourNavBarInfo {
		width: 1000px;
	}
	.EventBrandingMixhubb .tab-content .tab-pane {
		padding-left: 0px !important;
		padding-right: 0px !important;
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.EventBrandingMixhubb .tab-content .w-50 {
		width: 100% !important;
	}
	.sub-heading-main h4 {
		font-size: 20px;
		margin-right: 16px;  
	} 
	.sub-heading-main .editBtn-detil {
		font-size: 14px;
		font-weight: 600;
		padding: 5px 15px !important;
	} 
	.EventBrandingMixhubb .nav-tabs .nav-item .nav-link .nav-text {
		font-size: 14px;
	}
	.BrandingCreativesLogo {
		margin: 30px 0 0;
	}
	.createEvent {margin: 22px 0 10px !important;}
	.AbtEventUrl {
		padding: 0 0 24px !important;
	}
	.AbtEventUrl .EventCopy-Edit {
		margin-right: 15px !important;
		width: 100% !important;
		max-width: 65%;
	}
	.AbtEventUrl .EventCopy-Edit .copyLinkDel {
		padding-right: 0px !important;	
	}
	.AbtEventUrl .EventCopy-Edit .CopyHere {
		width: 200px !important;
	}
	.EditEventArea .form-control textarea {
		height: 70px;
	}
	.ThemePageLandingGo .LandingPageTheme .HeadingStatic {margin: 0 0 0 !important;}
	.LandingPageTheme .RegStyleHeight {margin-top: 20px !important;}
	.SelectPageTheme .ThemeSelectBrand {
		margin: 20px 0 0;
	}
	.LandingStylesInner {
		display: block;
	}
	.LandingStylesInner .menuRight a {
		font-weight: 600;
		font-size: 13px;
		padding: 6px 9px;
	}
	.LandingStylesInner .menuRight li:first-child {
		margin-left: 0px !important;
	}
	.LandingStylesInner .menuRight li {
		display: inline-block;
		margin: 0 5px;
	}
	.DesignColorPicker .MuiGrid-grid-xs-2 {
		flex-grow: 0;
		max-width: 100%;
		flex-basis: 100%;
	}
	.DesignColorPicker .MuiGrid-root {
		margin: 0 0 20px !important;
		padding: 0px !important;
	}
	.LogoBgUploadIn {
		margin: 0px 0 0;
	}
	.MixhubbDesEvent .sub-heading-main {
		margin-bottom: 12px !important;
   		padding-top: 20px !important;
	}

	.minHeightBox .default-image-selected .UploadLabel .img-fluid {
		height: 100%;
	}
	.minHeightBox .default-image-selected .UploadLabel {
		height: 100% !important;
    	width: 100% !important;
	}

	.EventLogoupload {
		padding-right: 12px !important;
	}
	.BreakerCreativeupload {
		padding-left: 12px !important;
	}
	.brandingColorPix {
		margin: 0 0 30px;
	}
	.DesginLogoBgUpload {
		margin: 0px 0 0;
	}
	.updateCoverDesign, .CompanyLogoUpload, .UpdateEventLogoDegns {
		padding: 0px !important;
		margin-top: 15px !important;
	}
	.LogoBgUploadIn .WithAddMore {margin: 0px !important;}
	.LogoBgUploadIn .WithAddMore .HeadingStatic {margin: 0px !important;}
	.stateForm .MainAddDiv .AaddingTagBox {
		width: 100%;
	}
	.stateForm .MainAddDiv .MixhubbTagInner .AddTagesInput {
		width: 280px;
		margin-right: 10px;
	}
	.PoperSearchBar {
		display: block;
		float: left;
		width: 100%;
		margin: 15px 0 18px;
	}
	.content .card-custom .card-header .card-title .PoperSearchBar .MuiPaper-root {
		width: 100%;
	}
	.ListHeaderTwo .card-header .card-title button {margin: 0px !important;}
	.content .card-custom .ListHeaderTwo .card-header {
		margin: 0 0 0px !important;
	}
	.table.SpeakersTables {
		width: 820px;
	}
	.table.RecordingTable {
		width: 820px;
	}
	.table.SpeakersTables tbody tr td:last-child {
		width: 16%;
	}
	.ThemeBgInner img {width: 100%;}

	table.InfoInputTable {width: 760px;}
	.InfoInputTable tbody tr td:nth-child(3) {
		width: 35%;
	}
	.InfoInputTable tbody tr td:nth-child(4) {
		width: 28%;
	}
	.login-form h3 {
		font-size: 26px;
	}




	table.EventSponsor {
		width: 750px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) img {
		height: 60px;
	}
	.InformLandingTable .EventSponsor tbody tr td:first-child {
		width: 5%;
	}
	.InformLandingTable .EventSponsor tbody tr td:last-child {
		width: 12%;
	}
	.top-heading-main .NofifyH1 h1 {
		font-size: 20px !important;
	}
	.AboutEventPg .form-control {
		height: 100px;
	}
	.InformLandingTable .EventSponsor tbody tr td p {
		font-size: 14px;
	}
	.InformLandingTable .EventSponsor tbody tr td {
		font-size: 14px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) {
		width: 33%;
	}
	.InformLandingTable {
		padding: 12px 12px;
		margin: 20px 0 0;
	}
	.InformLandingTable .WithAddMore .HeadingStatic {
		width: 100%;
    	float: left;
		margin: 0 0 12px !important;
	}
	.WithAddMore {
		display: block;
	}
	.WithAddMore .AddNewFact {
		margin: 0 0 30px;
	}
	.FAQNewQuestion .FAQlistADD {
		padding: 16px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData {
		display: block;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns {
		padding-right: 0%;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns h3 {
		font-size: 15px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns p {
		font-size: 13px;
		line-height: 18px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQAction {
		text-align: right;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQAction .ActionIcon {
		width: 30px;
		height: 30px;
	}
	.YourNavBarInfo .MuiGrid-root {
    	flex-basis: 230px;
	}
	.ZoneTypeBooth .ZoonEventPg .ZoneQtn {
		margin: 0 0 10px;
	}
	.entAtDateBx, .EndtAtTimeBx {
		padding-top: 0px !important;
	}
	.startAtDateBx {
		padding-bottom: 0px !important;
	}
	.LiveStream {
		margin: 35px 0 0 !important;
	}
	.InviteSpeakerBox .View-Col-Min {
		flex: 0 0 320px !important;
		max-width: 320px !important;
	}
	.InviteSpeakerBox .View-Col-Max {
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}
	.InviteSpeakerBox {
		padding-right: 0px !important;
	}
	.FilesRules {
		margin: 22px 0 8px 0px;
	}
	.FilesRules ul {
		padding-left: 14px;
	}
	.FilesRules h3 {
		margin: 0 0 22px;
		font-size: 16px;
	}
	.FilesRules .MuiButtonBase-root {
		margin-left: 0 !important;
	}
	.FilesRules ul li {
		margin-bottom: 12px;
	}
	.login-form p {
		color: var(--gray-dark);
		font-size: 14px;
	}
	.ThemePageLandingGo {
		margin-top: 30px;
	}
	
	.FinalEventPlan .MonthlyAddonchargs .EventMonthlyInr p {
		font-size: 16px !important;
	}
	.FinalEventPlan .MonthlyAddonchargs .EventMonthlyInr h2 {
		font-size: 22px !important;
	}
	.FinalEventPlan .MonthlyAddonchargs .Continue .btnContinue {
		font-size: 16px !important;
		padding: 4px 16px !important;
	}
	.FreeTrailPlan .TrialCharges h2 {
		font-size: 22px;
		margin-bottom: 10px;
	}
	.FreeTrailPlan .TrialCharges p {
		font-size: 13px;
	}
	.table.teamsMixTables  {
		width: 900px;
	}
	.FeedbackCreate h4 {
		font-size: 22px;
		margin: 0 0 17px;
		line-height: 33px;
	}
	.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus .feedBackNameDiscs h2 {
		font-size: 18px;
	}
	.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus .feedBackNameDiscs h4 {
		font-size: 15px;
	}
	.ListHeaderThree .card-custom .card-header .FilterAnalitsSearch .ListSearchBar .MuiPaper-root {
		width: 100% !important;
	}
	.ListHeaderThree .card-custom .card-header .FilterAnalitsSearch .ListSearchBar .FilterAllReports .filterDatePic .form-control {
		width: 100% !important;
	}
	.ListHeaderThree .card-custom .RegsFiltSerch .FilterAnalitsSearch .ListSearchBar {
		width: 48% !important;
	}
	.ListHeaderThree .card-custom .RegsFiltSerch .FilterAnalitsSearch .ListSearchBar:nth-child(2) {
		margin-right: 0px !important;
	}
	.bar-chart {
		.LayoutRangeBox {
			height: 58px;
			.RangeFeedBak {
				.ButtonRange {
					padding: 4px 5px;
    				font-size: 6px;
					svg {
						fill: #fff;
						width: 8px;
						height: 8px;
					}
				}
				&:last-child {
					left: 87%;
				}
			}
		}
	}
	.AttendeeUtm .AttendeePrior {
		width: 50%;
	}
}
 
@media (min-width: 480px) and (max-width: 575px) {
	.AttendeeUtm .AttendeePrior {
		width: 55%;
	}
}




@media (max-width: 479px) {
	.AllEventContainer .ListEventNo .col-md-2 {
		width: 33% !important;
	}
	.AllEventContainer .ListEventNo .listType h3 {
		font-size: 28px;
	}
	.UpcomingListEvent .allEvents-add {
		padding-left: 0;
		margin: 42px 0 0;
		padding-right: 0;
	}
	.UpcomingListEvent .allEvents-add .yestDate {
		height: auto;
    	top: -30px;
		width: 100%;
	}
	.UpcomingListEvent .allEvents-add .yestDate span {
		background-color: #fff;
		position: relative;
		z-index: 3;
		padding-right: 10px;
	}
	.UpcomingListEvent .allEvents-add .yestDate:after {
		height: auto;
		top: 50%;
		margin: 0px auto;
		width: 100%;
		border-bottom: 1px #222 dashed;
	}
	.NoSchedeleEvent .AddNewEvents .SchedulePicBg {
		padding: 8px;
		width: 70px;
	}
	.NoSchedeleEvent {
		margin: 12px 0 0;
		padding: 10px;
	}
	.NoSchedeleEvent .AddNewEvents .EventScheduleTxt p {
		font-size: 18px;
	}

	.UpcomingListEvent .EventPageInfos {margin: 0px !important;}
	.UpcomingListEvent .EventPageInfos .viewAllevent {margin: 35px 0 0 !important;}
	.heading-main h2 {
		font-size: 18px;
	}
	
	.allEvents-add .event-summary-all .eventPicBg {
		width: 120px;
	}
	.allEvents-add .event-summary-all .event-all-schedule {
		padding: 0px 0 0 10px;
	}
	.allEvents-add .event-summary-all .event-all-schedule .eventStatus {
		font-size: 11px;
	}
	.allEvents-add .event-summary-all .event-all-schedule p {
		margin: 0 0 2px;
		font-weight: 600;
		font-size: 11px;
    	letter-spacing: -0.1px;
	}
	.allEvents-add .event-summary-all .event-all-schedule h3 {
		margin: 0 0;
		font-size: 14px;
		letter-spacing: 0px;
	}
	.allEvents-add .event-summary-all .event-all-schedule label {
		margin: 3px 0 5px;
		font-size: 11px;
		letter-spacing: 0px;
	}
	.allEvents-add .event-summary-all .event-all-schedule .detail-view {
		padding: 4px 12px;
		font-size: 10px;
		letter-spacing: 0px;
		padding: 4px 8px !important;
	}
	.HelpYou {
		float: left;
		right: inherit;
		bottom: 0;
		left: 10px;
	}

	.UpcomingListEvent .allEvents-add .event-summary-all {
		margin: 12px 0 22px;
		padding: 8px;
		height: 100px !important;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header {
		margin: 0px;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header .card-title .MuiPaper-root {
		width: 100%;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header .card-title .card-label {
		margin: 0 0 12px;
	}
	.BrandingMixhubInfo .ListHeaderMain .card-header .card-title {
		margin: 0 0 12px;
	}
	.ListHeaderMain .card-header {padding: 0px;}
	.ListHeaderOne {
		.top-heading-main {
			.headingH1 {
				display: block;
				width: 100%;
				h1 {
					font-size: 20px;
					margin-bottom: 0px;
				}
			}
			.eventExprience {
				text-align: left !important;
				.EventCopy-Edit {
					max-width: 230px;
					.copyLinkDel {
						padding-right: 0px !important;
					}
					.CopyHere {
						width: 190px;
						padding: 10px 5px !important;
					}
				}
				.EnterEventin {
					float: right;
				}
			}
		}
	}
	h3 {
		font-size: 26px;
	}

	.ScheduleHeader {
		margin: 0 0 14px !important;
		h5 {
			font-size: 18px;
		}
	}
	.ExpoZones h4 {
		font-size: 15px;
		margin: 0 0 8px;
	} 
	.ExpoZones p {
		font-size: 13px;
		line-height: 13px;
	}
	.ZoneTypeBooth .ZoonEventPg {
		padding: 16px;
	}
	.ZoneTypeBooth .ZoonEventPg p {
		padding-right: 0px !important;
	}
	.ZoneTypeBooth .ZoonEventPg h4 {
		font-size: 17px;
		padding-right: 0px !important;
		margin-bottom: 5px;
	}
	.RightIcons .EditDel .iconSvg {
		width: 28px;
		height: 28px;
		padding: 6px 6px;
	}
	.ExpoAddZones {
		margin: 25px 0 0;
	}
	.ExpoAddZones .CommonGtBtn {
		margin-right: 15px !important;
	}
	.BoothCardInfo {
		padding: 10px 10px;
		margin: 20px 0;
	}.BoothCardInfo .TypeOfBooth {
		display: block;
	}
	.BoothCardInfo .TypeOfBooth .SearchBooth {
		width: 100%;
		margin: 18px 0 0;
	}
	.BoothCardInfo .TypeOfBooth .boothAddType h4 {
		font-size: 16px;
	}
	.CommonGtBtn {
		font-size: 14px !important;
		padding: 5px 12px !important;
	}
	.BoothCardInfo .TypeOfBooth .boothAddType .CommonGtBtn {
		float: right;
	}
	.BoothCardInfo .BoothDetailsInfo {
		padding: 12px 12px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg .BoothLogo {
		width: 95px;
		margin-right: 8px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg h3 {
		font-size: 15px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothEditDelete .EditDel .iconSvg {
		width: 24px;
		height: 24px;
		margin: 0 2px;
		padding: 1px 5px;
	}
	.BoothCardInfo .BoothDetailsInfo .BoothEditDelete .EditDel {margin-right: 0px !important;}
	.BoothCardInfo .BoothDetailsInfo .BoothLeftBg .BoothLogo .img-fluid {
		height: 40px;
	}
	.scrolltop {
		width: 40px;
		height: 40px;
		bottom: 22px;
	}
	.scrolltop .svg-icon svg {
		height: 23px;
		width: 23px;
	}
	.eventExprience .EnterEventin .Event-EnterBtn {
		padding: 6px 8px !important;
	}
	.content .container .row .col-md-11, .content .container .row .col-sm-11, .content .container .row .col-lg-11, .container .row .col-lg-11 {
		flex: 0 0 100%;
    	max-width: 100%;
	}
	.ZoneFillDetail {
		padding: 10px;
	}
	.AddBoothSetup .nav-tabs .nav-item .nav-link .nav-text {
		font-size: 13px;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-item {
		width: 50%;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-item:first-child .nav-link {
		margin-left: 0 !important;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-item:last-child .nav-link {
		margin-right: 0 !important;
	}
	.AddBoothSetup .nav.nav-tabs.nav-tabs-line .nav-link {
		margin: 0 3px !important;
		width: 100%;
		border-bottom: 3px solid #a3cbf3;
	}

	.nav.nav-tabs.nav-tabs-line .nav-link {
		margin: 0 10px;
	}

	.feaderList {
		margin-top: 12px;
	}

	.HeadingStatic h2 {
		font-size: 18px;
	}
	.feaderList h4 {
		font-weight: 600;
		font-size: 16px;
	}
	.LogoCoverUpload {
		margin-bottom: 18px;
	}
	.BoothInnerSetup .TagsWebsite {
		margin: 0 0 22px;
	}
	.SelectIntroVideo {
		margin-bottom: 15px;
	}
	.RegisterBoothInfo {
		margin: 0 0 32px;
	}
	.CommonModalPopup .modal-dialog .modal-content .modal-header .modal-title h4 {
		font-size: 18px;
	}
	.MaxBottomPadd .w-90 {width: 100% !important;}
	.HeadingStatic img {
		margin-right: 5px;
		width: 30px;
	}
	.HeadingStatic {
		margin: 5px 0 24px !important;
	}
	.SelectImgs {
		margin: 0 0 20px;
	}
	.minHeightBox {
		border: 1px;
		height: auto !important;
		min-height: auto !important;
		aspect-ratio: 16/9;
	}
	.uploadBrowser {
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}
	.eventExprience .EventCopy-Edit .copyLinkDel {
		padding: 8px 10px;
		padding-right: 0px;
	}
	.eventExprience .EventCopy-Edit .CopyHere {
		padding: 10px 5px;
	}
	.feaderList {min-height: auto !important;}
	.StageEventPg {
		padding: 12px;
	}
	.StageEventPg h4 {
		font-weight: 600;
		font-size: 17px;
		margin-bottom: 10px;
	}
	.StageEventPg p {
		margin-bottom: 18px;
	}
	.StageEventPg .Talk-Speakers .btn {
		margin-right: 6px;
	}
	.Talk-Speakers .btn {
		font-size: 12px;
		padding: 3px 6px;
	}
	.Talk-Speakers svg {
		width: 18px;
		height: 18px;
		margin-right: 6px;
	}
	.HeadingStageTxt h5 {
		font-size: 18px;
	}
	.EnterStage {
		right: 18px;
		top: 15px;
	}
	.stateForm {
		padding: 0px !important;
	}
	.DesignSetupStage .stateForm {
		margin-bottom: 18px !important;
	}
	.DesignSetupStage .ToggleDiv {
		margin: 0 0 18px !important;
	}
	.uploadFileSlider .slick-slider .slick-list .slick-track .UploaderImgRow {
		padding: 0px 0px !important;
	}

	.uploadFileSlider .slick-slider .slick-prev {
		bottom: -45px;
		width: 23px;
		height: 23px;
		right: 25px;
	}
	.uploadFileSlider .slick-slider .slick-next {
		bottom: -45px;
		width: 23px;
		height: 23px;
	}
	.uploadFileSlider {
		margin: 0px 0 64px !important;
	}
	table.Rooms {
		width: 620px !important;
	}
	.table.Rooms tbody tr td:first-child {
		width: 10% !important;
	}
	.table.Rooms tbody tr td:last-child {
		width: 14%;
	}
	.content .card-custom .card-header .card-title .card-label {
		font-size: 18px;
		margin-right: 4px;
		margin-bottom: 12px;
	}
	.container .container-fluid {padding: 0px !important}
	.top-heading-main .headingH1 h1 {
		font-size: 22px;
	}
	.top-heading-main {
		margin: 22px 0 26px!important;
	}
	.top-heading-main .headingH1 span {
		margin-left: 12px;
		font-size: 14px;
	}

	.top-heading-main .menuRight ul li {
		margin: 0 4px;
	}
	.top-heading-main .menuRight ul li:first-child {
		margin-left: 0px;
	}
	.top-heading-main .menuRight {
		float: left;
		margin: 24px 0px 0px;
	}
	.top-heading-main .menuRight ul li a {
		font-size: 13px;
		padding: 6px 7px;
	}
	.EventBrandingMixhubb .card-custom {
		padding-left: 0px !important;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .w-90 {
		width: 100% !important;
		margin: 12px 0 0 !important;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .NavHeaderTop .MuiGrid-grid-md-12  {
		padding: 12px 0 0 !important;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .NavHeaderTop .MuiPaper-rounded  {
		overflow-x: auto;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .eventExprience h3 {
		font-size: 20px;
		margin: 0 0 10px;
	}
	.EventBrandingMixhubb .card-custom .tab-pane .eventExprience p {
		font-size: 12px;
	}
	.eventExprience .EventCopy-Edit {
		max-width: 184px;
	}
	.BrandingColorTheme .MuiGrid-grid-xs-2 {
		flex-grow: 0;
		max-width: 50%;
		flex-basis: 50%;
	}
	.BrandingColorTheme .colorCodePx div {
		font-size: 15px !important;
	}
	.top-heading-main .NofifyH1 h1 {
		font-size: 20px !important;
	}
	.EventBrandingMixhubb .tab-content .tab-pane {
		padding-left: 0px !important;
		padding-right: 0px !important;
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.EventBrandingMixhubb .tab-content .w-50 {
		width: 100% !important;
	}
	.sub-heading-main h4 {
		font-size: 20px;
		margin-right: 16px;  
	} 
	.sub-heading-main .editBtn-detil {
		font-size: 14px;
		font-weight: 600;
		padding: 5px 15px !important;
	} 
	.EventBrandingMixhubb .nav-tabs .nav-item .nav-link .nav-text {
		font-size: 13px;
	}
	.BrandingCreativesLogo {
		margin: 30px 0 0;
	}
	.createEvent {margin: 22px 0 10px !important;}
	.AbtEventUrl {
		padding: 0 0 24px !important;
	}
	.AbtEventUrl .EventCopy-Edit {
		margin-right: 15px !important;
		width: 100% !important;
		max-width: 65%;
	}
	.AbtEventUrl .EventCopy-Edit .copyLinkDel {
		padding-right: 0px !important;	
	}
	.AbtEventUrl .EventCopy-Edit .CopyHere {
		width: 200px !important;
	}
	.EditEventArea .form-control textarea {
		height: 70px;
	}
	.ThemePageLandingGo .LandingPageTheme .HeadingStatic {margin: 0 0 0 !important;}
	.LandingPageTheme .RegStyleHeight {margin-top: 20px !important;}
	.SelectPageTheme .ThemeSelectBrand {
		margin: 20px 0 0;
	}
	.LandingStylesInner {
		display: block;
	}
	.LandingStylesInner .menuRight a {
		font-weight: 600;
		font-size: 13px;
		padding: 6px 9px;
	}
	.LandingStylesInner .menuRight li:first-child {
		margin-left: 0px !important;
	}
	.LandingStylesInner .menuRight li {
		display: inline-block;
		margin: 0 5px;
	}
	.DesignColorPicker .MuiGrid-grid-xs-2 {
		flex-grow: 0;
		max-width: 100%;
		flex-basis: 100%;
	}
	.DesignColorPicker .MuiGrid-root {
		margin: 0 0 20px !important;
		padding: 0px !important;
	}
	.LogoBgUploadIn {
		margin: 0px 0 0;
	}
	.MixhubbDesEvent .sub-heading-main {
		margin-bottom: 12px !important;
   		padding-top: 20px !important;
	}

	.minHeightBox .default-image-selected .UploadLabel .img-fluid {
		height: 100%;
	}
	.minHeightBox .default-image-selected .UploadLabel {
		height: 100% !important;
    	width: 100% !important;
	}
	.EventLogoupload {
		padding-right: 12px !important;
	}
	.BreakerCreativeupload {
		padding-left: 12px !important;
	}
	.brandingColorPix {
		margin: 0 0 30px;
	}
	.DesginLogoBgUpload {
		margin: 0px 0 0;
	}
	.updateCoverDesign, .CompanyLogoUpload, .UpdateEventLogoDegns {
		padding: 0px !important;
		margin-top: 10px !important;
	}
	.LogoBgUploadIn .WithAddMore {margin: 0px !important;}
	.LogoBgUploadIn .WithAddMore .HeadingStatic {margin: 0px !important;}
	.stateForm .MainAddDiv .AaddingTagBox {
		width: 100%;
	}
	.stateForm .MainAddDiv .MixhubbTagInner .AddTagesInput {
		width: 190px;
		margin-right: 10px;
	}
	.PoperSearchBar {
		display: block;
		float: left;
		width: 100%;
		margin: 15px 0 18px;
	}
	.content .card-custom .card-header .card-title .PoperSearchBar .MuiPaper-root {
		width: 100%;
	}
	.ListHeaderTwo .card-header .card-title button {margin: 0px !important;}
	.content .card-custom .ListHeaderTwo .card-header {
		margin: 0 0 0px !important;
	}
	.table.SpeakersTables {
		width: 820px;
	}
	.table.RecordingTable {
		width: 820px;
	}
	.table.SpeakersTables tbody tr td:last-child {
		width: 16%;
	}
	table.InfoInputTable {width: 760px;}
	.InfoInputTable tbody tr td:nth-child(3) {
		width: 28%;
	}
	.InfoInputTable tbody tr td:nth-child(4) {
		width: 40%;
	}



	table.EventSponsor {
		width: 750px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) img {
		height: 60px;
	}
	.InformLandingTable .EventSponsor tbody tr td:first-child {
		width: 5%;
	}
	.InformLandingTable .EventSponsor tbody tr td:last-child {
		width: 12%;
	}
	.AboutEventPg .form-control {
		height: 100px;
	}
	.InformLandingTable .EventSponsor tbody tr td p {
		font-size: 14px;
	}
	.InformLandingTable .EventSponsor tbody tr td {
		font-size: 14px;
	}
	.InformLandingTable .EventSponsor tbody tr td:nth-child(2) {
		width: 33%;
	}
	.InformLandingTable {
		padding: 12px 12px;
		margin: 10px 0 0;
	}
	.InformLandingTable .WithAddMore .HeadingStatic {
		width: 100%;
    	float: left;
		margin: 0 0 12px !important;
	}
	.WithAddMore {
		display: block;
	}
	.WithAddMore .AddNewFact {
		margin: 0 0 30px;
	}
	.FAQNewQuestion .FAQlistADD {
		padding: 16px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData {
		display: block;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns {
		padding-right: 0%;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns h3 {
		font-size: 15px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQuestionAns p {
		font-size: 13px;
		line-height: 18px;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQAction {
		text-align: right;
	}
	.FAQNewQuestion .FAQlistADD .fAQnewData .FAQAction .ActionIcon {
		width: 30px;
		height: 30px;
	}
	.YourNavBarInfo {
		width: 1000px;
	}
	.YourNavBarInfo .MuiGrid-root {
    	flex-basis: 230px;
	}
	.entAtDateBx, .EndtAtTimeBx {
		padding-top: 0px !important;
	}
	.startAtDateBx {
		padding-bottom: 0px !important;
	}
	.LiveStream {
		margin: 35px 0 0 !important;
	}
	.AddSponsorUpload {
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}
	.widgetContainer {
		background-color: #000;
		height: auto !important;
	}
	.LobbyBgWidgetHg {
		padding: 30px 0 !important;
	}
	.PrimaryWidgetLobby .LobbyBgWidgetHg, .PrimaryWidgetLobby, .BlackBg, .RightBrowseWidget, .WidgetBrowseList, .OtherWidgets {
		height: auto !important;
	}
	.ListBgUrl {
		padding: 5px 0 15px;
	}
	.EditFileModal {
		max-width: 100% !important;
		flex-basis: 100% !important;
	}
	.WhiteBtn {
		padding: 5px 12px !important;
		font-size: 14px;
	}
	.login-form, .width-column-fluid {
		width: 100%;
	}
	.login-form p {
		font-size: 14px;
	}
	.WelcomePage .BottomActivity ul li button {
		padding: 6px 14px;
	}
	.login-form .form-group label {
		font-size: 12px;
	}
	.RequireSelectField label {
		line-height: 18px;
	}
	.AccessCode .EnterOptValue .OptValue input {
		margin: 0 4px;
	}
	.ThemePageLandingGo {
		margin-top: 0px;
	}
	.eventExprience.EventUrlCopyTx {
		width: auto !important;
		float: left !important;
	}
	.FreeTrailPlan .TrialUpgradePlan p {
		font-size: 13px;
	}
	.FreeTrailPlan .TrialUpgradePlan h2 {
		font-size: 14px;
	}
	.FreeTrailPlan .TrialUpgradePlan .CraditBtnInfo .CraditBtnBlue {
		padding: 4px 2px;
		width: 85px;
		font-size: 10px;
	}
	.FreeTrailPlan {
		.TrialUpgradePlan {
			padding: 14px !important;
			.PlanUpTrilInfo {
				padding-right: 8px;
			}
		}
	}
	.FreeTrailPlan .TrialUpgradePlan h6 {
		font-size: 11px;
	}
	.FreeTrailPlan .TrialUpgradePlan small {
		font-size: 10px;
	}
	.FreeTrailPlan .TrialUpgradePlan small a svg {
		width: 10px;
		height: 10px;
	}
	.FreeTrailPlan .PlanEventChargesTable .Plan-and-Addons .PlanTypeChoose h3 {
		font-size: 18px;
	}
	.FreeTrailPlan .PlanEventChargesTable .Plan-and-Addons .Add-Here-Addons .Add-Addons-Btn {
		padding: 6px 12px;
	}
	.FreeTrailPlan .TrialCharges h2 {
		font-size: 22px;
		margin-bottom: 10px;
	}
	.FreeTrailPlan .TrialCharges p {
		font-size: 13px;
	}
	.table.teamsMixTables  {
		width: 900px;
	}
	.HeaderNofify {
		display: block;
		.NotifySearch {
			.MuiPaper-rounded {
				width: 100% !important;
				padding: 6px ​0 !important;
			}
		}
		.AddNofifications {
			.CommonGtBtn {
				width: 100%;
    			padding: 8px 0 !important;
			}
		}
	}
	.HeaderNofify {
		.PoperSearchBar {
			margin: 0px 0px 16px !important;
		}
	}
	.InviteSpeakerBox {
		.View-Col-Min {
			flex: 0 0 320px;
    		max-width: 320px;
		}
		padding-right: 0px; 
			.FilesRules {
				margin: 22px 0 8px 0px;
		}
		.FilesRules {
			padding: 22px 12px;
			h3 {
				font-size: 16px;
    			margin: 0 0 22px;
			}
			ul {
				padding-left: 14px;
				li {
					font-size: 13px;
					margin-bottom: 12px;
				}
			}
		}
	}
	.FeedbackCreate h4 {
		font-size: 18px;
		margin: 0 0 17px;
		line-height: 30px;
	}

	.FbUrlCopyTx {
		.CopyRrlFd {
			width: 100% !important;
			max-width: 100% !important;
			border-radius: 100px;
			height: 60px;
			.FormUrlHd {
				left: 35px;
				p {
					font-size: 11px;
				}
			}
			.copyLinkDel {
				height: 60px;
				font-size: 16px;
				padding: 22px 80px 22px 28px !important;
			}
			.CopyHere {
				width: 60px !important;
				height: 52px;
				span {
					width: 24px;
					height: 24px;
					display: block;
				}
			}
		}
	}
	.FBPopForm {
		margin-top: 20px !important;
	}
	.SubscribePlan {
		margin: 20px 0 20px;
	}
	.subscribeHeader {
		height: 55px;
		.subscribeLogo {
			height: 55px;
		}
	}
	.FinalEventPlan {
		padding: 0 45px;
	}
	.FreeTrailPlan .TrialUpgradePlan h2 {
		font-size: 15px !important;
	}
	.FreeTrailPlan .TrialUpgradePlan {
		padding: 12px !important;
	}
	.FreeTrailPlan .TrialUpgradePlan .CraditBtnInfo {
		width: 160px;
	}
	.FreeTrailPlan .TrialUpgradePlan .CraditBtnInfo .CraditBtnBlue {
		padding: 4px 6px !important;
		font-size: 12px !important;
	}
	.bar-chart {
		.LayoutRangeBox {
			height: 58px;
			.RangeFeedBak {
				.ButtonRange {
					padding: 4px 5px;
    				font-size: 6px;
					width: 48px;
					svg {
						fill: #fff;
						width: 8px;
						height: 8px;
					}
				}
				&:last-child {
					left: 87%;
				}
			}
		}
	}
  .AddonsQty input {
    width: 40px !important;
    font-size: 16px !important;
    margin: 0 2px !important;
  }
  .Select-AddonsMx .AddonsTable .table-responsive table.AddonsPreTables {
    width: 680px !important;
  }
  .AddonsQty button svg {
    width: 16px;
    height: 16px;
}
  .FreeTrailPlan .TrialUpgradePlan h2 {
    font-size: 15px !important;
  }
  .FreeTrailPlan .TrialUpgradePlan {
    padding: 12px !important;
  }
  .FreeTrailPlan .TrialUpgradePlan .CraditBtnInfo {
    width: 160px;
  }
  .FreeTrailPlan .TrialUpgradePlan .CraditBtnInfo .CraditBtnBlue {
    padding: 4px 6px !important;
    font-size: 12px !important;
  }
  .ListHeaderThree .card-custom .card-header .FilterAnalitsSearch .ListSearchBar .MuiPaper-root {
		width: 100% !important;
	}
	.ListHeaderThree .card-custom .card-header .FilterAnalitsSearch .ListSearchBar .FilterAllReports .filterDatePic .form-control {
		width: 100% !important;
	}
	.ListHeaderThree .card-custom .RegsFiltSerch .FilterAnalitsSearch .ListSearchBar {
		width: 48% !important;
	}
	.ListHeaderThree .card-custom .RegsFiltSerch .FilterAnalitsSearch .ListSearchBar:nth-child(2) {
		margin-right: 0px !important;
	}
	.CripViewImages .modal-dialog {
		max-width: 98%;
	}
	.ScheduleFrameFlex .ddlListframe select {
		width: 200px;
	}
	.AttendeeUtm {
		display: block !important;
		.AttendeePrior {
			width: 100%;
			margin: 0 0 12px;
		}
		.AddNewFact {
			margin: 0 0 12px;
		}
	}
}
@media(min-width:376px) and (max-width:479px) {
	.stateForm .MainAddDiv .MixhubbTagInner .AddTagesInput {
		width: 60% !important;
	}
	.ScheduleHeader {
		margin: 28px 0 10px !important;
	}
	.FreeTrailPlan {
		padding-bottom: 66px;
	}
	.FinalEventPlan {
		.MonthlyAddonchargs {
			.EventMonthlyInr {
				padding: 4px 0;
				p {
					font-size: 14px !important;
				}
			} 
		}
	}

	.FinalEventPlan .MonthlyAddonchargs .EventMonthlyInr h2 {
		font-size: 18px !important;
	}
	.FinalEventPlan .MonthlyAddonchargs .Continue .btnContinue {
		font-size: 14px !important;
    	padding: 4px 16px !important;
	}
	.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus {
		margin: 8px 0 0;
	}
	.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus .feedBackNameDiscs h2 {
		font-size: 18px;
	}
	.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus .feedBackNameDiscs h4 {
		font-size: 15px;
	}
	.ListHeaderOne .top-heading-main .eventExprience .EventCopy-Edit {
		max-width: 240px !important;
	}
	.ListHeaderOne .top-heading-main .eventExprience .EventCopy-Edit .CopyHere {
		width: 220px;
	}
	ul.expo-report-big-cards li, ul.expo-report-small-cards li {
		max-width: 100%;
		flex: 0 0 100%;
	}
}
@media (max-width: 375px) {
	ul.expo-report-big-cards li, ul.expo-report-small-cards li {
		max-width: 100%;
		flex: 0 0 100%;
	}
		.ListHeaderOne .top-heading-main .eventExprience .EventCopy-Edit {
			max-width: 220px;
			margin-right: 10px !important;
		}
		.eventExprience .EventCopy-Edit .CopyHere {
			padding: 10px 5px;
		}
		.eventExprience .EventCopy-Edit .copyLinkDel {
			padding: 10px 10px;
		}
		.stateForm .MainAddDiv .MixhubbTagInner .EnterEventin button {
			font-size: 12px !important;
			font-weight: 600 !important;
			padding: 6px 10px !important;
		}
		.ScheduleHeader {
			margin: 28px 0 10px !important;
		}
		.FinalEventPlan .MonthlyAddonchargs .EventMonthlyInr p {
			font-size: 13px !important;
			font-weight: bold !important;
		}
		.FinalEventPlan .MonthlyAddonchargs .EventMonthlyInr h2 {
			font-size: 18px !important;
		}
		.FinalEventPlan .MonthlyAddonchargs .Continue .btnContinue {
			font-size: 14px !important;
			padding: 4px 16px !important;
		}
		.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus {
			margin: 8px 0 0;
		}
		.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus .feedBackNameDiscs h2 {
			font-size: 18px;
		}
		.FeedbackMixhubbForm .FeedbackInnerAdd .FeedBackNstatus .feedBackNameDiscs h4 {
			font-size: 16px;
		}
		.FeedbackMixhubbForm {
			.FeedbackInnerAdd {
				.FeedBackNstatus {
					.EditAddFb {
						.CommonGtBtn {
							.MuiButton-label {
								font-size: 13px;
							}
						}
					}
				}
			}
		}
		.HeadingStatic h2 {
			font-size: 17px;
			small {
				display: block;
			}
		}
		.ListHeaderOne .top-heading-main .eventExprience .EventCopy-Edit .CopyHere {
			width: 250px;
		}
}

.MuiSelect-select:focus {
	background-color: transparent !important;
}

.DisclaimerBox {
	.DisclaimerTable {
		tbody {
			td {
				.stateForm {
					.jodit-container {
						min-height: 80px !important;
						.jodit-toolbar__box {
							display: none;
						}

						.jodit-workplace {
							min-height: 80px !important;
							height: 80px !important;
							text-align: left !important;
							.jodit-wysiwyg {
								min-height: 80px !important;
								width: calc(100% - 10px) !important;
								p {
									word-break: break-all;
									text-align: left;
								}
							}
						}

					}
				}
			}
		}
	}
}
.jodit-status-bar {
	display: none !important;
}
.jodit-toolbar__box {
	display: block !important;
}
.fixProgress {
	padding: 0 8px;
	text-align: center;
}
.loaderBackendScreen {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
	.img-fluid {
		width: 180px;
		margin: 0 0 20px;
	}
	svg {
		width: 30px;
		height: 30px;
		margin: 0px !important;
	}
}

.plan-added{
	background: linear-gradient(60deg, rgb(51 103 228 / 15%) 25%, rgb(51 103 228 / 15%) 0%, rgb(181 80 249 / 15%) 70%);
}

.ThankyouPopUp {
	height: 100vh;
    overflow: hidden;
	.modal-dialog {
		display: flex !important;
	  .modal-content {
		.modal-header {
		  padding: 0;
		  .modal-title {
			display: none;
		  }
		  .ModalCloseBtn {
			z-index: 5;
		  }
		}
		.modal-body {
		  .ThankyouNow {
			text-align: center;
			height: 260px;
			display: flex;
			align-items: center;
			justify-content: center;
			.SuccessThankx {
			  svg {
				width: 48px;
				height: 48px;
			  }
			  h3 {
				color: #3477F3;
				text-transform: uppercase;
				font-weight: 600;
				font-size: 30px;
				margin: 15px 0 10px;
			  }
			  p {
				color: #222222;
				font-size: 15px;
				font-weight: 300;
				margin: 0px 0;
			  }
			}
		  }
		}
	  }
	}
  }

.arrow-icon {
	&:after {
		transform: rotate(-45deg);
		top: 22px;
		transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
	}
}
.TimeZoneProfile {
	position: relative;
	.ZoneSelectBox {
		position: relative;
		background-color: #f1f1f1;
		height: 50px;
		border: 0px;
		border-radius: 6px;
	  .valueInput {
		width: 100%;
		font-size: 14px;
		color: #363636;
		cursor: pointer;
		position: relative;
		z-index: 9;
		background-color: transparent;
		border: 0;
		margin: 22px 0 0;
		padding: 0px 12px 0;
		&:hover {
		  border: 0px !important;
		  box-shadow: none;
		  outline: none;
		}
		&:focus {
		  box-shadow: none;
		  outline: none;
		  border: 0px!important;
		}
	  }
	  &:after {
		content: "";
		display: inline-block;
		width: 8px;
		height: 8px;
		border-top: 1px solid #000;
		border-right: 1px solid #000;
		transform: rotate(135deg);
		position: absolute;
		right: 14px;
		top: 26px;
		transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		z-index: 4;
	  }
	}
	.arrow-icon {
	  &:after {
		transform: rotate(-45deg);
		top: 31px;
		transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
	  }
	}
	.TimeZoneActive {
	  border: 1px #707070 solid;
	  margin: 8px 0 0;
	  border-radius: 10px;
	  padding: 8px 6px 8px;
	  position: absolute;
	  left: 0;
	  top: 48px;
	  width: 100%;
	  z-index: 9;
	  background-color: #F1F1F1;
	  .ZoneSearchHere {
		width: 100%;
		margin: 0 0 0;      
		position: relative;
		.SearchBarInput {
			width: 100%;
			height: 28px;
			border-radius: 100px;
			font-size: 12.5px;
			padding: 0 10px;
			border: 1px #929292 solid;
			color: #363636;
		  &:hover {
			border: 1px #929292 solid !important;
			box-shadow: none;
			outline: none;
		  }
		  &:focus {
			box-shadow: none;
			outline: none;
			border: 1px #929292 solid !important;
		  }
		}
	  }
	  
	  .ZoneOptionBox {
		height: 138px;
		overflow-y: auto;
		margin-top: 10px;
		ul {
		  margin: 0;
		  padding: 0;
		  position: relative;
		  li {
			display: block;
			font-size: 14px;
			margin: 6px 0;
			width: 100% !important;
			cursor: pointer;
			&:first-child {
			  margin-top: 0px;
			}
		  }
		}
	  }
	}
  }
  ::-webkit-scrollbar {
    width: 4px;
    background-color: #e7e7e7;
    height: 7px;
    border-radius: 4px;
}
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #1a78e5;
}

.RegisterCodeNumber{
	.ZoneSelectBox {
		background-color: white;
		border: 1px #e4e6eb solid;
	}
	.TimeZoneActive{
		background-color: white;
	}
}

.Toastify__close-button {
	opacity: 1;
	svg {
	  fill: #ffffff !important;
	  height: 16px !important;
	  width: 16px !important;
	}
  }
  .Toastify__close-button {
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translateY(-50%);
	opacity: 1 !important;
  }
  .Toastify {
	.Toastify__toast-container {
	  width: 600px;
	  .Toastify__toast {
		min-height: 54px;
		border-radius: 100px;
		width: 100%;
		position: absolute !important;
		padding: 2px 2px !important;
		top: 0px !important;
		.Toastify__toast-body {
		  padding-left: 14px;
		  display: inline-flex;
		  align-items: center;
		  span {
			svg {
			  width: 22px;
			  height: 22px;
			  fill: #fff !important;
			  path {
				fill: #fff !important;
			  }
			}
		  }
		  div {
			padding-left: 10px;
			p {
			  margin: 0;
			  font-weight: 400;
			  font-size: 15px;
			  padding-right: 22px;
			  color: #fff;
			  letter-spacing: .4px;
			  line-height: 19px;
			  strong {
				  font-weight: 600;
			  }
			}
			small {
			  font-weight: 500;
			  font-size: 9px;
			  font-family: "Poppins";
			  color: #616164;
			}
		  }
		}
	  }
	}
  }
  // .Toastify__progress-bar {
  //   height: 2px !important;
  //   opacity: 1 !important;
  //   background-color: #fff !important;
  // }
  .Toastify__toast--success {
	border: 1px #34A853 solid !important;
  }
  .Toastify__toast--success {
	background: #34A853 !important;
  }
  
  .Toastify__toast--error {
	border: 1px #DE3C34 solid !important;
  }
  .Toastify__toast--error {
	background: #DE3C34 !important;
  }
  
  .Toastify__toast--warning {
	border: 1px #FF9701 solid !important;
  }
  .Toastify__toast--warning {
	background: #FF9701 !important;
  }  
  .Toastify__toast--info {
	border: 1px #1A78E5 solid !important;
  }
  .Toastify__toast--info {
	background: #1A78E5 !important;
  }













  .languagePreDef {
	.ListSpeakerDel {
		background-color: transparent;
		border: 1px #E8E8E8A6 solid;
		height: auto;
		overflow-y: initial;
		padding: 0 0px 10px;
		.SpekerListAllInfo {
			height: 260px;
  			overflow-y: auto;
			  padding: 0px 14px 0;
			.inputGroup {
				border: 1px #f1f1f1 solid;
  				margin-bottom: 8px;
				label {
					margin: 0;
				}
				input {
					width: 32px;
					height: 32px;
					order: 1;
					z-index: 2;
					position: absolute;
					right: 30px;
					top: 50%;
					transform: translateY(-50%);
					cursor: pointer;
					visibility: hidden;
				}
				label {
					padding: 14px 15px;
					font-size: 14px;
					width: 100%;
					display: block;
					text-align: left;
					color: #3C454C;
					cursor: pointer;
					position: relative;
					z-index: 2;
					transition: color 200ms ease-in;
					overflow: hidden;
					word-break: break-word;
					padding-right: 52px;
					border-radius: 6px;
				}
			}
		}
		.selectLangusgeInfo {
			padding: 18px 14px 0;
			.SpeakerSearchBar {
				.MuiPaper-root {
					width: 210px !important;
				  }
			}
			.LangTopHead {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 0 0 20px
			  }
		}
	}
  }

.languagePreDef {
	.ListSpeakerDel {
		background-color: transparent;
		border: 1px #E8E8E8A6 solid;
		height: auto;
		border-radius: 8px;
		overflow-y: initial;
		padding: 0 0px 10px;
		.selectLangusgeInfo {
			padding: 18px 14px 0;
			.LangTopHead {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 0 0 20px;
				.headingleftLang {
					h4 {
						font-size: 18px;
						color: #2F73F7;
						font-weight: 600;
						margin: 0 0 2px;
					}
				}
				.SpeakerSearchBar {
					.MuiPaper-root {
						width: 210px !important;
						border: 1px #929294 solid;
						box-shadow: none !important;
						position: relative;
						padding: 3px 0;
						.MuiInputBase-root {
							.MuiInputBase-input {
								padding-left: 40px;
								color: #737376;
								color: var(--gray);
								font-weight: 400;
							}
						}
						.MuiIconButton-root {
							position: absolute;
							left: 6px;
							padding: 0;
							top: 5px;
							.MuiIconButton-label {
								svg {
									width: 25px;
									height: 25px;
									fill: #929294;
									fill: var(--smooth-gray);
								}
							}
						}
					}
				}
			}
			.LanguageEventType {
				margin: 0 0 10px;
				font-size: 15px;
				color: #222222;
				font-weight: 400;
			}
			.SpekerListAllInfo {
				.inputGroup {
					margin-bottom: 20px;
					border: 0px;
					background-color: #fff;
					display: block;
					margin: 0px 0;
					position: relative;
					border-radius: 5px;
					label {
						color: #222222;
						background-color: #F1F1F1;
						font-size: 15px;
						font-weight: 600;
						padding: 14px 18px !important;
						display: flex;
						align-items: center;
						justify-content: space-between;
					}
					input {
						width: 32px;
						height: 32px;
						order: 1;
						z-index: 2;
						position: absolute;
						right: 30px;
						top: 50%;
						transform: translateY(-50%);
						cursor: pointer;
						visibility: hidden;
					}
				}
			}
			.SelectedLanguage {
				height: auto;
				overflow-y: visible;
				overflow-y: initial;
				padding: 0;
				.inputGroup {
					margin-bottom: 20px;
					border: 0px;
					background-color: #F1F1F1;
					input {
						width: 32px;
						height: 32px;
						order: 1;
						z-index: 2;
						position: absolute;
						right: 30px;
						top: 50%;
						transform: translateY(-50%);
						cursor: pointer;
						visibility: hidden;
					}
					label {
						background-color: #F1F1F1;
						&::after {
							display: none;
						}
						&::before {
							display: none;
						}
					}
				}
			}
		}
	}
}








.SpekerListAllInfo {
	.inputGroup {
		background-color: #fff;
		display: block;
		margin: 0px 0;
		position: relative;
		border-radius: 5px;
		label {
			padding: 14px 15px;
			font-size: 14px;
			width: 100%;
			display: block;
			text-align: left;
			color: #3C454C;
			cursor: pointer;
			position: relative;
			z-index: 2;
			transition: color 200ms ease-in;
			overflow: hidden;
			word-break: break-word;
			padding-right: 52px;
			border-radius: 6px;
			&::before {
				width: 100%;
				height: 100%;
				border-radius: 5px;
				content: "";
				background-color: #ffffff;
				position: absolute;
				left: 0%;
				top: 0%;
				/* transform: translate(-50%, -50%) scale3d(1, 1, 1); */
				transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
				opacity: 0;
				z-index: -1;
				border: 1px #2F73F7 solid;
			  }
			  &::after {
				width: 18px;
				height: 18px;
				content: "";
				border: 2px solid #D1D7DC;
				background-color: #fff;
				background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
				background-repeat: no-repeat;
				background-position: 2px 3px;
				border-radius: 50%;
				z-index: 2;
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
				transition: all 200ms ease-in;
			  } 
		  }
	  }
}
  .SpekerListAllInfo .inputGroup input:checked ~ label:before {
	/* transform: translate(-50%, -50%) scale3d(56, 56, 1); */
	opacity: 1;
  }
  .SpekerListAllInfo .inputGroup input:checked ~ label:after {
	background-color: #3367e4;
	border-color: #3367e4;
	background-position: -1px 0px;
	background-size: 21px;
  }
  .expo-report-small-cards button.MuiButtonBase-root.MuiButton-root.MuiButton-text:last-child {
    cursor: auto;
}
  @media (max-height: 480px) {
	.MobileViewInfo {
		display: flex !important;
	}
}
