// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}
@import "variables", "mixin";
// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }
html{scroll-behavior:smooth}
.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

html {
  body {
    background: $darlblue;

    #kt_wrapper {
      background: $white;
    }

    &.brand-dark {
      .brand {
        background-color: $white;
      }

      .aside,
      .aside-menu {
        background-color: $leftMenuColor;
      }
    }
    .nosubheader,
    .noheadmenu,
    .noRightBtn .card-header .card-toolbar {
      display: none !important;
    }

    
    .header {
      .headerInner {
            background-color: #fff !important;
		    box-shadow: none;
		    border-bottom: 1px #f1f1f1 solid;
      }
    }
    .aside-menu-wrapper {
    	 border-right: 1px #f1f1f1 solid;
    	 border-top: 1px #f1f1f1 solid;
    }
    .aside-menu .menu-nav > .menu-item > .menu-link .menu-text { 
      font-size: 1.2rem;
    }
    .card.card-custom {
      @include dropshadowBox(0.15);
      box-shadow: none !important;
    }
    #splash-screen img {
      width: 140px;
      margin-left: 60px;
    }










    
    @media (min-width: 1400px) {
      .container,
      .container-sm,
      .container-md,
      .container-lg,
      .container-xl,
      .container-xxl {
        max-width: inherit;
      }
    }
    @media (min-width: 992px) {
      .wrapper {
        padding-top: 65px !important;
      }
    }
  }
}


